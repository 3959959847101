<mat-card-content class="row d-flex activietiessection">
 
  
<mat-tab-group [(selectedIndex)]="activeTabIndex" (selectedTabChange)="onTabChanges($event)">
  <mat-tab label="Group Profile">
    <!-- <h2>Content for Tab 1</h2> -->
    <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
      <mat-card-content>

        <app-groupdetails></app-groupdetails>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="Routes">
    <div class="row eventscard">
      <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
     
        <app-ride-routes></app-ride-routes>

      </mat-card>

    </div>
  </mat-tab>
  <mat-tab label="Feed">
    <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">

      <mat-card-content>
        <!-- <app-gallery></app-gallery> -->
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab label="Gallery">
    <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">

      <app-gallery></app-gallery>
      
    </mat-card>
  </mat-tab>

</mat-tab-group>

</mat-card-content>





