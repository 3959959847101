<mat-form-field>
    <i-feather name="list"></i-feather>
    <mat-label>Choose an option</mat-label>
    <select matNativeControl disabled>
        <option value="" selected></option>
        <option value="volvo">Volvo</option>
        <option value="saab" disabled>Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
    </select>
</mat-form-field>