<app-stats />

<div class="row">
    <div class="col-lg-4 col-xxxl-3">

        <!-- Profile Intro -->
        <app-profile-intro />

        <!-- User Bio -->
        <app-user-bio />

    </div>
    <div class="col-lg-8 col-xxxl-9">

        <!-- Revenue Overview -->
        <app-revenue-overview />

        <!-- To Do List -->
        <app-to-do-list />

        <!-- Activity -->
        <app-activity />
        
    </div>
</div>