<div class="row">
    <div class="col-xxxl-6">
        <mat-card
            class="trinta-card starter-card mb-25 bg-white border-none d-block text-center position-relative z-1"
        >
            <mat-card-content>
                <img src="assets/images/logo.svg" alt="logo">
                <h2>
                    Create Something Beautiful.
                </h2>
                <p class="text-black">
                    Congratulations on gaining access to the nerve center of [Your Company]'s operations! This admin panel is designed to empower you with the tools and insights needed to manage and optimize your experience with our platform.
                </p>
                <a routerLink="/" mat-button>
                    Getting Started
                    <i class="ri-send-plane-fill"></i>
                </a>
                <div class="shape1">
                    <img src="assets/images/icons/star1.svg" alt="icon">
                </div>
                <div class="shape2">
                    <img src="assets/images/icons/star2.svg" alt="icon">
                </div>
                <div class="shape3">
                    <img src="assets/images/icons/star2.svg" alt="icon">
                </div>
                <div class="shape4">
                    <img src="assets/images/icons/star1.svg" alt="icon">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-6">
        <mat-card
            class="trinta-card key-features-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Key Features:
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="features-list m-0 p-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Get a quick snapshot of key metrics and system health on your personalized dashboard.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Easily manage user accounts, permissions, and roles to ensure a secure and organized environment.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Control and update the content displayed across your platform effortlessly.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Dive into detailed analytics to understand user behavior, engagement, and platform performance.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Tailor the admin panel to your needs through customizable settings and configurations.
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>