<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Toolbar
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Toolbar
        </li>
    </ol>
</div>

<!-- Basic Toolbar -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Basic Toolbar
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-toolbar>
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                <mat-icon>menu</mat-icon>
            </button>
            <span>My App</span>
            <span class="example-spacer"></span>
            <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                <mat-icon>favorite</mat-icon>
            </button>
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                <mat-icon>share</mat-icon>
            </button>
        </mat-toolbar>
    </mat-card-content>
</mat-card>

<!-- Multi-row Toolbar -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Multi-row Toolbar
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <span>Custom Toolbar</span>
            </mat-toolbar-row>
            <mat-toolbar-row>
                <span>Second Line</span>
                <span class="example-spacer"></span>
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon">verified_user</mat-icon>
            </mat-toolbar-row>
            <mat-toolbar-row>
                <span>Third Line</span>
                <span class="example-spacer"></span>
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Example heart icon">favorite</mat-icon>
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Example delete icon">delete</mat-icon>
            </mat-toolbar-row>
        </mat-toolbar>
    </mat-card-content>
</mat-card>

<!-- Toolbar Overview -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Toolbar Overview
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            <mat-toolbar>
                <span>My Application</span>
            </mat-toolbar>
        </p>
        <p>
            <mat-toolbar>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                    <mat-icon>menu</mat-icon>
                </button>
                <span>My App</span>
                <span class="example-spacer"></span>
                <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                    <mat-icon>share</mat-icon>
                </button>
            </mat-toolbar>
        </p>
        <p>
            <mat-toolbar color="primary">
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                    <mat-icon>menu</mat-icon>
                </button>
                <span>My App</span>
                <span class="example-spacer"></span>
                <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                    <mat-icon>share</mat-icon>
                </button>
            </mat-toolbar>
        </p>
        <p>
            <mat-toolbar color="primary">
                <mat-toolbar-row>
                    <span>My App</span>
                    <span class="example-spacer"></span>
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
                        <mat-icon>menu</mat-icon>
                    </button>
                </mat-toolbar-row>
                <mat-toolbar-row>
                    <span>Second Line</span>
                    <span class="example-spacer"></span>
                    <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                    <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
                        <mat-icon>share</mat-icon>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>
        </p>
    </mat-card-content>
</mat-card>