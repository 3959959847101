<div class="row">
    <div class="col-lg-3">

        <!-- Side -->
        <mat-card
            class="trinta-card sidebar-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <form class="search-box position-relative">
                    <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search here..">
                    <button type="submit" class="bg-transparent p-0 border-none cursor-pointer text-primary">
                        <i class="ri-search-line"></i>
                    </button>
                </form>
                <ul class="sidebar-list p-0 list-unstyled">
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                drive_eta
                            </i>
                            My Drive
                            <span class="d-block text-body">
                                6
                            </span>
                        </a>
                        <ul class="list-unstyled">
                            <li>
                                <a href="javascript:void(0);" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Assets
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Projects
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Personal
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="d-inline-block position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Applications
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                description
                            </i>
                            Documents
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                perm_media
                            </i>
                            Media
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative">
                            <i class="material-symbols-outlined">
                                schedule
                            </i>
                            Recents
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative">
                            <i class="material-symbols-outlined">
                                grade
                            </i>
                            Important
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative">
                            <i class="material-symbols-outlined">
                                report_gmailerrorred
                            </i>
                            Spam
                            <span class="d-block text-body">
                                10
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fw-medium d-flex align-items-center justify-content-between position-relative">
                            <i class="material-symbols-outlined">
                                delete
                            </i>
                            Trash
                            <span class="d-block text-body">
                                15
                            </span>
                        </a>
                    </li>
                </ul>
                <div class="storage-status">
                    <h6 class="fw-medium">
                        Storage Status
                    </h6>
                    <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
                    <span class="d-block text-body">
                        % 86.5 GB Used of 120 GB
                    </span>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
    <div class="col-lg-9">

        <!-- Folders -->
        <div class="row">
            <div class="col-sm-6 col-xxl-3">
                <mat-card
                    class="trinta-card folder-card mb-25 bg-white border-none d-block"
                >
                    <mat-card-content>
                        <div class="d-flex align-items-center justify-content-between">
                            <mat-checkbox color="primary"></mat-checkbox>
                            <div class="action">
                                <button type="button" mat-button class="border-radius-0 p-0" [matMenuTriggerFor]="actionMenu">
                                    <i class="ri-more-2-fill"></i>
                                </button>
                                <mat-menu #actionMenu="matMenu" xPosition="before">
                                    <button mat-menu-item>
                                        Edit
                                    </button>
                                    <button mat-menu-item>
                                        Delete
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="folder text-center">
                            <i-feather name="file"></i-feather>
                            <h6 class="mb-0 fw-medium">
                                Assets
                            </h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-black d-block">
                                387 Files
                            </span>
                            <span class="text-black d-block">
                                4.5 GB
                            </span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6 col-xxl-3">
                <mat-card
                    class="trinta-card folder-card mb-25 bg-white border-none d-block"
                >
                    <mat-card-content>
                        <div class="d-flex align-items-center justify-content-between">
                            <mat-checkbox color="primary"></mat-checkbox>
                            <div class="action">
                                <button type="button" mat-button class="border-radius-0 p-0" [matMenuTriggerFor]="actionMenu">
                                    <i class="ri-more-2-fill"></i>
                                </button>
                                <mat-menu #actionMenu="matMenu" xPosition="before">
                                    <button mat-menu-item>
                                        Edit
                                    </button>
                                    <button mat-menu-item>
                                        Delete
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="folder text-center">
                            <i-feather name="file"></i-feather>
                            <h6 class="mb-0 fw-medium">
                                Projects
                            </h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-black d-block">
                                111 Files
                            </span>
                            <span class="text-black d-block">
                                1.4 GB
                            </span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6 col-xxl-3">
                <mat-card
                    class="trinta-card folder-card mb-25 bg-white border-none d-block"
                >
                    <mat-card-content>
                        <div class="d-flex align-items-center justify-content-between">
                            <mat-checkbox color="primary"></mat-checkbox>
                            <div class="action">
                                <button type="button" mat-button class="border-radius-0 p-0" [matMenuTriggerFor]="actionMenu">
                                    <i class="ri-more-2-fill"></i>
                                </button>
                                <mat-menu #actionMenu="matMenu" xPosition="before">
                                    <button mat-menu-item>
                                        Edit
                                    </button>
                                    <button mat-menu-item>
                                        Delete
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="folder text-center">
                            <i-feather name="file"></i-feather>
                            <h6 class="mb-0 fw-medium">
                                Personal
                            </h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-black d-block">
                                435 Files
                            </span>
                            <span class="text-black d-block">
                                5.2 GB
                            </span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6 col-xxl-3">
                <mat-card
                    class="trinta-card folder-card mb-25 bg-white border-none d-block"
                >
                    <mat-card-content>
                        <div class="d-flex align-items-center justify-content-between">
                            <mat-checkbox color="primary"></mat-checkbox>
                            <div class="action">
                                <button type="button" mat-button class="border-radius-0 p-0" [matMenuTriggerFor]="actionMenu">
                                    <i class="ri-more-2-fill"></i>
                                </button>
                                <mat-menu #actionMenu="matMenu" xPosition="before">
                                    <button mat-menu-item>
                                        Edit
                                    </button>
                                    <button mat-menu-item>
                                        Delete
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="folder text-center">
                            <i-feather name="file"></i-feather>
                            <h6 class="mb-0 fw-medium">
                                Templates
                            </h6>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="text-black d-block">
                                431 Files
                            </span>
                            <span class="text-black d-block">
                                1.3 GB
                            </span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <!-- Recent Files -->
        <mat-card
            class="trinta-card recent-files-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Recent Files
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <a routerLink="/apps/file-manager" class="default-btn" mat-button>
                        <i class="ri-add-line"></i> Add New
                    </a>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="recent-files-table">
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource">

                            <!-- Name Column -->
                            <ng-container matColumnDef="fileName">
                                <th mat-header-cell *matHeaderCellDef class="pl-0">
                                    Name
                                </th>
                                <td mat-cell *matCellDef="let element" class="pl-0">
                                    <div class="file-name position-relative">
                                        <mat-checkbox color="primary"></mat-checkbox>
                                        <h6 class="mt-0 mb-0">
                                            <i-feather name="file"></i-feather>
                                            {{element.fileName}}
                                        </h6>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Owner Column -->
                            <ng-container matColumnDef="owner">
                                <th mat-header-cell *matHeaderCellDef>
                                    Owner
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.owner}}
                                </td>
                            </ng-container>

                            <!-- File Size Column -->
                            <ng-container matColumnDef="fileSize">
                                <th mat-header-cell *matHeaderCellDef>
                                    File Size
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.fileSize}}
                                </td>
                            </ng-container>

                            <!-- Listed Date Column -->
                            <ng-container matColumnDef="listedDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    Listed Date
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.listedDate}}
                                </td>
                            </ng-container>

                            <!-- File Item Column -->
                            <ng-container matColumnDef="fileItem">
                                <th mat-header-cell *matHeaderCellDef>
                                    File Item
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.fileItem}}
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef class="text-end pr-0"></th>
                                <td mat-cell *matCellDef="let element" class="text-end pr-0">
                                    <div class="action">
                                        <button type="button" mat-button class="border-radius-0 p-0" [matMenuTriggerFor]="actionMenu">
                                            <i class="{{element.action}}"></i>
                                        </button>
                                        <mat-menu #actionMenu="matMenu" xPosition="before">
                                            <button mat-menu-item>
                                                Edit
                                            </button>
                                            <button mat-menu-item>
                                                Delete
                                            </button>
                                        </mat-menu>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[8, 16, 24]"></mat-paginator>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>