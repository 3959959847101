<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Form Field
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Form Field
        </li>
    </ol>
</div>

<!-- Form Field -->
<div class="row">
    <div class="col-md-6 col-xxl-4">

        <!-- Simple Form Field -->
        <app-simple-form-field></app-simple-form-field>

        <!-- Form Field with Prefix & Suffix -->
        <app-ffw-prefix-suffix></app-ffw-prefix-suffix>

    </div>
    <div class="col-md-6 col-xxl-4">

        <!-- Form Field Appearance Variants -->
        <app-form-field-appearance-variants></app-form-field-appearance-variants>

        <!-- Form Field with Label -->
        <app-ff-with-label></app-ff-with-label>

    </div>
    <div class="col-md-6 col-xxl-4">

        <!-- Form Field with Error Messages -->
        <app-ffw-error-messages></app-ffw-error-messages>

        <!-- Form Field with Hints -->
        <app-ff-with-hints></app-ff-with-hints>

        <!-- Form Field Theming -->
        <app-ff-theming></app-ff-theming>

    </div>
</div>