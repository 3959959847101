<!-- Stats -->
<app-stats />

<div class="row">
    <div class="col-xxl-8 col-md-12">

        <!-- Website Overview -->
        <app-website-overview />

    </div>
    <div class="col-xxl-4 col-md-12">
        
        <!-- Daily Performance -->
        <app-daily-performance />

    </div>
</div>

<div class="row">
    <div class="col-xxxl-8 col-md-12">

        <!-- Courses Overview -->
        <app-courses-overview />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">
        
        <!-- Courses Category -->
        <app-courses-category />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Top Instructors -->
        <app-top-instructors />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Total Performance -->
        <app-total-performance />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Students Progress -->
        <app-students-progress />

    </div>
</div>

<!-- All Courses -->
<app-all-courses />