<mat-card class="trinta-card leads-report-card mb-25 bg-white border-none d-block">
  <mat-card-header>
    <mat-card-title>
      <h5 class="mt-0 mb-0">
        Ride Comparison
      </h5>
    </mat-card-title>
    <mat-card-subtitle>
      <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
        By latest rides
      </button>
      <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="after">
        <button mat-menu-item>
          This Day
        </button>
        <button mat-menu-item>
          This Week
        </button>
        <button mat-menu-item>
          This Month
        </button>
        <button mat-menu-item>
          This Year
        </button>
      </mat-menu>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="leads-report-table">
      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource">

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="pl-0">
              Date
            </th>
            <td mat-cell *matCellDef="let element" class="pl-0">
              {{element.date}}

            </td>
          </ng-container>

          <!-- Ride Name Column -->
          <ng-container matColumnDef="rideName">
            <th mat-header-cell *matHeaderCellDef>
              Ride Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.rideName}}
            </td>
          </ng-container>



          <!-- Distance Covered Column -->
          <ng-container matColumnDef="avgSpeed">
            <th mat-header-cell *matHeaderCellDef>
              Distance Covered
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.distanceCovered}}
            </td>
          </ng-container>

          <!-- Avg Speed Column -->
          <ng-container matColumnDef="avgRideDuration">
            <th mat-header-cell *matHeaderCellDef>
              Avg Speed
            </th>
            <td mat-cell *matCellDef="let element">
              {{element.avgSpeed}}
            </td>
          </ng-container>

          <!-- Avg Ride Duration Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
              Avg Ride Duration
            </th>
            <td mat-cell *matCellDef="let element" class="text-end pr-0">
              {{element.avgRideDuration}}
            </td>
          </ng-container>

          <!--  Moving Time Column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
              Moving Time
            </th>
            <td mat-cell *matCellDef="let element" class="text-end pr-0">
              {{element.movingTime}}
            </td>
          </ng-container>

          <!--  Members Column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
              Moving Time
            </th>
            <td mat-cell *matCellDef="let element" class="text-end pr-0">
              {{element.members}}
            </td>
          </ng-container>




          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
