<div class="memberconfirm">
  <div class="modalheader" >
    <div  class="titletxt">Remove Member</div>
      <img (click)="onNoClick()" class="closeicon" src="../../../../assets/clx.png">
  </div>
  

  <div class="modalbodytext">Removing member will remove them from this group. It will not remove them from the app or any other group(s) they belong to.</div>
  
  <div class="actionbtns">

    <div class="btnssection">
      <div class="confirmbtnssubmit" (click)="onConfirm()" > 
  
        <h2>OK</h2>
    
      </div>
    </div>
  </div>

</div>