<div class="row">
    <div class="col-xxl-3">

        <!-- Profile -->
        <app-profile />

        <!-- User Bio -->
        <app-user-bio />

        <!-- Activity -->
        <app-activity />

    </div>
    <div class="col-xxl-6">

        <!-- Newsfeed -->
        <app-newsfeed />
        
    </div>
    <div class="col-xxl-3">

        <!-- Friends -->
        <app-friends />

        <!-- Photos -->
        <app-photos />
        
    </div>
</div>