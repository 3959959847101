<mat-drawer-container class="example-container" [hasBackdrop]="hasBackdrop.value">
    <mat-drawer #drawer [mode]="mode.value">I'm a drawer</mat-drawer>
    <mat-drawer-content>
        <div class="row mb-20">
            <div class="col-md-6">
                <mat-form-field>
                    <i-feather name="list"></i-feather>
                    <mat-label>Sidenav mode</mat-label>
                    <mat-select #mode value="side">
                        <mat-option value="side">Side</mat-option>
                        <mat-option value="over">Over</mat-option>
                        <mat-option value="push">Push</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <i-feather name="list"></i-feather>
                    <mat-label>Has backdrop</mat-label>
                    <mat-select #hasBackdrop>
                        <mat-option>Unset</mat-option>
                        <mat-option [value]="true">True</mat-option>
                        <mat-option [value]="false">False</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <button mat-flat-button color="primary" (click)="drawer.toggle()">Toggle drawer</button>
    </mat-drawer-content>
</mat-drawer-container>