<mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
    <mat-card-content>

      <mat-accordion>
        <mat-expansion-panel *ngFor="let element of dataSource.filteredData">
          <mat-expansion-panel-header style="margin-bottom: 8px; border-radius: 7px;
                background: #FFF;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);">
            <div class="header-content">
              <mat-panel-title>
                <div class="date-container">
                  <!-- <div class="formatted-date"> -->
                    <div class="dayweek">{{ formatDate(element.date).dayOfWeek }}</div>
                    <div class="daydate">{{ formatDate(element.date).day }}</div>
                    <div class="monthyyyy">{{ formatDate(element.date).month }}</div>
                  <!-- </div> -->
                </div>

                <div class="ridesectioncontainer" style="width: 70%; padding-left: 8px; padding-right: 8px;">
                  <div style="color: black; font-weight: 600; font-size: 16px; padding-bottom: 10px;">{{ element.name }}</div>
                  <div class="lowersec">
                    <img src="../../../../assets/clock.png" style="height: 13px;">
                    {{ formatTimeTo12Hour(element.time) }} |
                    <img src="../../../../assets/location.png" style="height: 13px;">
                    {{ element.startlocation }} |
                    {{ element.pace }} |
                    <img src="../../../../assets/star.png" style="height: 13px;">
                    {{ element.outDoor ? 'Outdoor' : 'Indoor' }}

                  </div>
                </div>

                <button class="viewroutebtnsreg"><a>Registered {{element.totalActiveMemberJoined}}</a></button>
                <!-- <button class="viewroutebtns"><a>Openings 12</a></button> -->

                <!-- <svg  (click)="$event.stopPropagation()" (click)="openEditDialog(element)" width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.459 8.87436L15.8333 7.5C16.0679 7.2655 16.1851 7.14825 16.2696 7.03605C16.8056 6.32383 16.8056 5.34284 16.2696 4.63063C16.1851 4.51842 16.0679 4.40117 15.8333 4.16667C15.5988 3.93217 15.4816 3.81492 15.3694 3.73047C14.6572 3.19442 13.6762 3.19442 12.964 3.73047C12.8518 3.81491 12.7345 3.93216 12.5 4.16664L12.5 4.16667L11.1064 5.56029C11.9033 6.94053 13.06 8.08831 14.459 8.87436ZM9.6518 7.01488L4.18973 12.477C3.76467 12.902 3.55214 13.1145 3.4124 13.3756C3.27267 13.6367 3.21372 13.9315 3.09583 14.5209L2.6471 16.7646C2.58058 17.0972 2.54732 17.2635 2.64193 17.3581C2.73654 17.4527 2.90284 17.4194 3.23545 17.3529L5.4791 16.9042C6.06855 16.7863 6.36328 16.7274 6.62437 16.5876C6.88547 16.4479 7.098 16.2354 7.52306 15.8103L13.0006 10.3327C11.6528 9.48197 10.5106 8.34755 9.6518 7.01488Z" fill="#1F2A44"/>
                  </svg> -->
                  


                <!-- <mat-card-content style="padding-right: 4px; padding-left: 4px">
                  <svg mat-stroked-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="basicMenu"
                    style="cursor: pointer; padding-left: 8px;" width="36" height="36" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 7H19" stroke="#1F2A44" stroke-linecap="round" />
                    <path d="M5 12H19" stroke="#1F2A44" stroke-linecap="round" />
                    <path d="M5 17H19" stroke="#1F2A44" stroke-linecap="round" />
                  </svg>

              
                </mat-card-content> -->

                <!-- <span mat-stroked-button (click)="$event.stopPropagation()" class="viewdetails" (click)="openUpcomingridesviewdetailsComponentDialog(element)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"> <path d="m280-240-56-56 184-184-184-184 56-56 240 240-240 240Zm360 0v-480h80v480h-80Z"/></svg>
                </span> -->
              </mat-panel-title>

            </div>
          </mat-expansion-panel-header>
          <div class="mat-expansion-panel-content">
            <div class="content-details">
              <div class="row viewsection">
                <div class="row" style="width: 100%;">

                  <div class="left" style="width: 70%;">
                    <div class="row"
                      style="width: 100%; display: flex; justify-content: space-between; padding-top: 6px;">
                      <div>Start Location: <span style="float: right;">{{ element.startlocation }}</span></div>
                    </div>

                    <div class="row"
                      style="width: 100%; display: flex; justify-content: space-between; padding-top: 6px;">
                      <div>Riding Type: <span style="float: right;">{{ element.preferredBikeTypes }}</span></div>
                    </div>


                    <div class="row"
                      style="width: 100%; display: flex; justify-content: space-between; padding-top: 6px;">
                      <div>Pace: <span style="float: right;">{{ element.pace }}</span></div>
                    </div>

                    <div class="row"
                      style="width: 100%; display: flex; justify-content: space-between; padding-top: 6px;">
                      <div>Purpose: <span style="float: right;">{{ element.purpose }}</span></div>
                    </div>

                    <div class="row"
                      style="width: 100%; display: flex; justify-content: space-between; padding-top: 6px;">
                      <div>Level: <span style="float: right;">{{ element.level }}</span></div>
                    </div>

                    <div class="row"
                      style="width: 100%; display: flex; justify-content: space-between; padding-top: 6px;">
                      <div>Ride Leader: <span style="float: right;">{{ element.rideLeader}}</span></div>
                    </div>


                  

                  </div>

                  <div class="right" style="width: 30%; position: relative;">
                    <!-- <button class="viewroutebtn viewroutebtnexp overlay-button"
                      style="width: 100%; background-image: url('../../../../assets/map.png'); background-size: cover; background-repeat: no-repeat; background-position: center; height: 160px; position: relative; color: white; font-weight: bold;"
                      (click)="openViewRouteDialog(element)">
                      View Route
                    </button> -->





                   

                  </div>


                </div>
              </div>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
      <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
    </mat-card-content>
  </mat-card>