export class ApiEndpoints {
    static readonly login = "accounts/login";
    static readonly createAccount = "accounts/register";
    static readonly changePassword = "accounts/changePassword";
    static readonly deactivateAccount = "accounts/deactivateAccount";
    static readonly resetpassword = "accounts/password/change";

    

    static readonly acknowledgeNotification = "notifications/acknowledgeNotification?note_id=";

    

    static readonly createProfile = "profile/createProfile";
    static readonly createRidingProfile = "profile/createRidingProfile";
    static readonly createBike = "bikes/createBike";
    static readonly editBike = "bikes/editBike";
    static readonly listBikes = "bikes/list";
    static readonly joinGroup = "groups/joinGroup";
    static readonly createService = "service/createService";
    static readonly joinRide = "rides/joinRide";
    static readonly leaveRide = "rides/leaveRide";
    static readonly createGroup = "groups/createRidingGroup";
    static readonly createEvent = "events/createEvent";
    static readonly invitemember = "groups/inviteMember";
    static readonly updateGroup = "groups/createRidingGroup";
    static readonly getGroupMembers = "groups/getGroupMembers?group_id=";
    static readonly ridesHistoryPerGroup = "rides/ridesHistoryPerGroup?groupId=";
    static readonly ridesUpComingPerGroup = "rides/ridesUpComingPerGroup?groupId=";
    static readonly cancelRide = "rides/cancelRide?rideId=";
    static readonly ridersPerRide = "rides/ridersPerRide/";
    static readonly createRoute = "rides/createRoute";
    static readonly createRide = "rides/createRide";
    static readonly createRidedraft = "rides/createRide";
    static readonly registerAndCreateGroup = "accounts/registerAndCreateGroup";
    static readonly joinEvent = "events/joinEvent";

    // static readonly joinRide = "rides/joinRide";

    
    static readonly getEventMembers = "events/getEventMembers?event_id=";
    static readonly getRideById = "rides/rideImage?ride_id=";

    
    static readonly uploadPhoto = "groups/uploadPhoto";
    static readonly deleteDraftRide = "rides/deleteDraftRide?ride_id=";
    static readonly getdraftRidesPerGroup = "rides/draftRidesPerGroup?group_id=";
    static readonly cancelEventByGroup = "events/cancelEventByGroup?group_id=";
    static readonly deleteGroupById = "groups/deleteGroupById?group_id=";
    static readonly draftRidesPerGroup = "rides/draftRidesPerGroup?group_id=";
    static readonly routes = "rides/listRoutes";
    static readonly getGalleryByGroup = "groups/getGalleryByGroup?group_id=";
    static readonly invitedMembersByGroup = "groups/invitedMembersByGroup?group_id=";


    static readonly groupsPerUserSummary = "groups/groupsPerUserSummary";
    static readonly cancelEvent = "events/cancelEvent?event_id=";
    static readonly createCleanPrepSchedule = "cleanprep/createCleanPrepSchedule";
    static readonly createCheckoutRequest = "checkout/request/create";
    static readonly cancelServiceOrder = "service/cancelOrderService";
    static readonly scheduleService = "service/scheduleService";
    static readonly createUpdatePrivacyProfile = "profile/createUpdatePrivacyProfile";
    static readonly createUpdateUomProfile = "profile/createUpdateUomProfile";
    static readonly createUpdateNotificationsProfile = "profile/createUpdateNotificationsProfile";
    // static readonly joinEvent = "events/joinEvent";
    static readonly deactivateEvent = "events/deactivateEvent";
    static readonly leaveGroup = "groups/leaveGroup";
    static readonly usersCountPerGroup = "groups/usersCountPerGroup";


    static readonly getGroupSummaryByID = "groups/getGroupSummaryByID/";


    static readonly changeGroupAdmin = "groups/changeGroupAdmin";

    // Additional Endpoints
    static readonly getActiveCountries = "common/countries";
    static readonly userNotifications = "notifications/userNotifications";

    static readonly getActiveStates = "profile/states";
    static readonly getActiveGender = "profile/gender";
    static readonly getCategories = "service/getProductCategories";
    static readonly getActiveCity = "profile/cities";
    static readonly getActiveClubhouses = "profile/clubHouses";
    static readonly getActiveRidingStyle = "profile/ridingStyle";
    static readonly getActivePace = "profile/pace";
    // static readonly getActivePreferredType = "profile/preferredTypes";

    static readonly getActiveridingPaceList = "groupSetup/ridingPaceList";
    static readonly getActiveridingTypeList = "groupSetup/ridingTypeList";
    static readonly getActiveridingLevelList = "groupSetup/ridingLevelList";



    static readonly getActivePreferredType = "profile/preferredTypes";
    static readonly getActiveAnnualMileage = "profile/annualMileage";
    static readonly getCitiesByState = "profile/citiesByState/";
    static readonly listBikesPerUsers = "profile/bikes";
    static readonly listBikesPerUser = "bikes/bikesPerUser?";
    static readonly getGroups = "groups/ridingGroup";
    static readonly getGroupsByUser = "groups/getGroupJoined";
    static readonly optionsServiceList = "groups/ridingGroup";
    static readonly getGroup = "groups/getGroups";
    static readonly getGroupsNotJoined = "groups/getGroupNotJoined";
    static readonly getGroupsJoinedByUser = "groups/getGroupJoined";
    static readonly totalRideCountPerUser = "rides/rideCountForUser";
    static readonly getUserProfile = "profile/getUserProfile";
    static readonly suggestedRidesPerUser = "rides/suggestedRides";
    static readonly currentServiceRequest = "service/getServiceRequestbyUser?partner_id=";
    static readonly getServiceHistoryByUser = "service/getServiceHistorybyUser?partner_id=";
    static readonly suggestedServiceRequest = "service/getSuggestedServiceRequestbyUser?partner_id=";
    static readonly ridesPerUser = "rides/ridesJoined";
    static readonly allActiveRides = "rides/list";
    static readonly getGroupsByID = "groups/getGroupsByID/";
    static readonly allEventsList = "events/upcomingEvents";
    static readonly upcomingEventsJoined = "events/upcomingEventsJoined";
    static readonly upcomingEventsNotJoined = "events/upcomingEventsNotJoined";
    static readonly draftUpcomingEvents = "events/draftUpcomingEvents";

    static readonly draftUpcomingEventsPerGroup = "events/draftUpcomingEventsPerGroup/";
    static readonly UpcomingEventsPerGroup = "events/UpcomingEventsPerGroup/";

    static readonly getGroupsCreatedByUser = "groups/getGroupsByUser";

    
    static readonly ridingGroup = "groups/ridingGroup";
    static readonly levels = "profile/levels";
    static readonly purpose = "profile/purpose";
    static readonly recurrence = "profile/recurrence";
    static readonly notifications = "common/notifications";
    static readonly notificationsByUser = "service/getServiceNotification?partner_id=";
    static readonly notificationsPerUser = "profile/notificationsProfilePerUser";
    static readonly privacyProfilePerUser = "profile/privacyProfilePerUser?cbPartnerId=";
    static readonly uomProfilePerUser = "profile/uomProfilePerUser?cbPartnerId=";
    static readonly notificationsProfilePerUser = "profile/notificationsProfilePerUser?cbPartnerId=";
    static readonly eventTypes = "profile/eventTypes";
    static readonly pastRidesList = "rides/pastRidesList";
    static readonly getALLMembers = "members/getALLMembers";     
  }
  