<!-- User Profile -->
<mat-card
    class="trinta-card user-profile-card mb-25 bg-dark border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0 text-white">
                User Profile
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-dot-btn text-white lh-1 p-0 min-w-auto h-auto">
                <i-feather name="edit-3"></i-feather>
            </button>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="user d-md-flex align-items-center justify-content-between ml-auto mr-auto">
            <div class="d-flex align-items-center">
                <img src="assets/images/users/user2.jpg" class="rounded-circle" alt="user-image">
                <div>
                    <h6 class="text-white">
                        Marcus Davis
                    </h6>
                    <span class="d-block text-body">
                        &#64;Alvarado
                    </span>
                </div>
            </div>
            <div class="btn-box">
                <a routerLink="/profile/teams" mat-button>
                    Team
                </a>
                <a routerLink="/profile/projects" mat-button>
                    Projects
                </a>
                <button type="button" mat-button>
                    Message
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Router Outlet -->
<router-outlet />