<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Datepicker Inline Calendar
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-calendar [(selected)]="selected"></mat-calendar>
    </mat-card-content>
</mat-card>