<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Autocomplete
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Autocomplete
        </li>
    </ol>
</div>

<!-- Autocomplete -->
<div class="row">
    <div class="col-lg-6">

        <!-- Simple Autocomplete -->
        <app-simple-autocomplete></app-simple-autocomplete>

    </div>
    <div class="col-lg-6">

        <!-- Highlight the first Autocomplete Option -->
        <app-highlight-first-option></app-highlight-first-option>

    </div>
    <div class="col-lg-6">

        <!-- Display Value Autocomplete -->
        <app-display-value></app-display-value>

    </div>
    <div class="col-lg-6">

        <!-- Filter Autocomplete -->
        <app-filter-options></app-filter-options>

    </div>
    <div class="col-lg-6">

        <!-- Option Groups Autocomplete -->
        <app-option-groups></app-option-groups>

    </div>
    <div class="col-lg-6">

        <!-- Autocomplete Overview -->
        <app-overview></app-overview>

    </div>
    <div class="col-lg-6">

        <!-- Plain Input Autocomplete -->
        <app-plain-input></app-plain-input>

    </div>
    <div class="col-lg-6">

        <!-- Require an Autocomplete Option to be Selected -->
        <app-require-option-selected></app-require-option-selected>

    </div>
</div>