<mat-card class="trinta-card mb-25 bg-white border-none d-block">
  <mat-card-header>
    <mat-card-title class="d-flex justify-content-between align-items-center mt-10" >
      <h5 class="mb-0 fs-14">
        Avg Speed
      </h5>
      <div class="d-flex filters">
        <button mat-stroked-button color="primary" class="fs-14 fw-semibold">
          12 months
        </button>
        <button mat-button color="primary" class="fs-14 fw-semibold">
          6 months
        </button>
        <button mat-button color="primary" class="fs-14 fw-semibold">
          30 days
        </button>
        <button mat-button color="primary" class="fs-14 fw-semibold">
          7 days
        </button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <apx-chart
      [series]="chartOptions.series!"
      [chart]="chartOptions.chart!"
      [dataLabels]="chartOptions.dataLabels!"
      [plotOptions]="chartOptions.plotOptions!"
      [yaxis]="chartOptions.yaxis!"
      [legend]="chartOptions.legend!"
      [fill]="chartOptions.fill!"
      [stroke]="chartOptions.stroke!"
      [tooltip]="chartOptions.tooltip!"
      [colors]="chartOptions.colors!"
      [grid]="chartOptions.grid!"
      [xaxis]="chartOptions.xaxis!"
    ></apx-chart>
  </mat-card-content>
</mat-card>
