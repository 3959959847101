<div class="row mb-10">
  <h2 class="fw-bold fs-16">
    Payment
  </h2>
</div>
<div class="row">
  <div class="col-xxxl-4 col-sm-6 col-md-4">
    <mat-card
      class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
    >
      <mat-card-content>
        <div class = "card-title">
          <span class="d-block">
                      Group payment status
                  </span>
          <div class="icon d-flex align-items-center justify-content-center ml-auto ">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <rect width="18" height="12" x="3" y="6" stroke="#fff" rx="2"/>
              <path stroke="#fff" stroke-linecap="round" d="M5 9h3m8 6h3"/>
              <circle cx="12" cy="12" r="2" stroke="#fff"/>
            </svg>



          </div>

        </div>
        <h5 class="fw-semibold">
         71%

        </h5>

      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-xxxl-4 col-sm-6 col-md-4">
    <mat-card
      class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
    >
      <mat-card-content>
        <div class = "card-title">
            <span class="d-block">
              Upcoming Renewals
            </span>
          <div class="icon d-flex align-items-center justify-content-center ml-auto ">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="#fff" d="M17.5 19.005V4.995a.8.8 0 0 0-1.244-.666l-1.27.847a.8.8 0 0 1-.943-.041L12.5 3.9a.8.8 0 0 0-1 0L9.957 5.135a.8.8 0 0 1-.944.04l-1.27-.846a.8.8 0 0 0-1.243.666v14.01a.8.8 0 0 0 1.244.666l1.27-.846a.8.8 0 0 1 .943.04L11.5 20.1a.8.8 0 0 0 1 0l1.543-1.235a.8.8 0 0 1 .944-.04l1.27.846a.8.8 0 0 0 1.243-.666Z"/>
              <path stroke="#fff" stroke-linecap="round" d="M9.5 9.5h5m-5 2.5h5m-5 2.5h5"/>
            </svg>

          </div>

        </div>

        <h5 class="fw-semibold">
          16
        </h5>

      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-xxxl-4 col-sm-6 col-md-4">
    <mat-card
      class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
    >
      <mat-card-content>
        <div class = "card-title">
          <span class="d-block">
                      Total Income generated
                  </span>
          <div class="icon d-flex align-items-center justify-content-center ml-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="#fff" d="M14.25 4h-4.5C6.568 4 4.977 4 3.989 5.004 3 6.008 3 7.624 3 10.857v2.286c0 3.232 0 4.849.989 5.853C4.977 20 6.568 20 9.75 20h4.5c3.182 0 4.773 0 5.762-1.004.988-1.004.988-2.62.988-5.853v-2.286c0-3.232 0-4.849-.988-5.853C19.023 4 17.431 4 14.25 4Z"/>
              <path stroke="#fff" stroke-linecap="round" d="M7 8h3"/>
              <path stroke="#fff" d="M19 16h-2c-.943 0-1.414 0-1.707-.293C15 15.414 15 14.943 15 14c0-.943 0-1.414.293-1.707C15.586 12 16.057 12 17 12h2c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707 0 .943 0 1.414-.293 1.707C20.414 16 19.943 16 19 16Z"/>
            </svg>



          </div>

        </div>
        <h5 class="fw-semibold">
          $4,480.92

        </h5>

      </mat-card-content>
    </mat-card>
  </div>


</div>
