<mat-card
    class="trinta-card attachments-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Attachments
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <ul class="m-0 p-0 list-unstyled">
            <li class="d-md-flex align-items-center justify-content-between">
                <h6 class="mb-0 d-flex align-items-center fw-medium">
                    <i class="ri-file-warning-line"></i>
                    Course_attachment_1.zip
                </h6>
                <span class="d-block text-body">
                    3.25 MB
                </span>
                <button type="button" mat-button>
                    <i class="ri-download-2-line"></i>
                </button>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <h6 class="mb-0 d-flex align-items-center fw-medium">
                    <i class="ri-file-warning-line"></i>
                    Course_attachment_2.zip
                </h6>
                <span class="d-block text-body">
                    3.25 MB
                </span>
                <button type="button" mat-button>
                    <i class="ri-download-2-line"></i>
                </button>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <h6 class="mb-0 d-flex align-items-center fw-medium">
                    <i class="ri-file-warning-line"></i>
                    Course_attachment_3.zip
                </h6>
                <span class="d-block text-body">
                    3.25 MB
                </span>
                <button type="button" mat-button>
                    <i class="ri-download-2-line"></i>
                </button>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <h6 class="mb-0 d-flex align-items-center fw-medium">
                    <i class="ri-file-warning-line"></i>
                    Course_attachment_4.zip
                </h6>
                <span class="d-block text-body">
                    3.25 MB
                </span>
                <button type="button" mat-button>
                    <i class="ri-download-2-line"></i>
                </button>
            </li>
        </ul>
    </mat-card-content>
</mat-card>