<div class="row">
    <div class="col-xxxl-8">
        <mat-card
            class="trinta-card opportunities-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="info d-flex align-items-center">
                            <img src="assets/images/companies/company1.png" alt="company-image">
                            <div class="title">
                                <h6>
                                    Comfort Furniture
                                </h6>
                                <span class="d-block text-primary">
                                    Furniture
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="email">
                            <span class="d-block fw-medium heading-font-family">
                                Email
                            </span>
                            <a href="mailto:demo@contact.com" class="text-black">
                                demo&#64;contact.com
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="phone">
                            <span class="d-block fw-medium heading-font-family">
                                Phone
                            </span>
                            <a href="tel:+1 2354 6798" class="text-black">
                                +1 2354 6798
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="action d-flex align-items-center justify-content-md-end">
                            <div class="dot"></div>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="edit-3"></i-feather>
                            </button>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="trash-2"></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="trinta-card opportunities-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="info d-flex align-items-center">
                            <img src="assets/images/companies/company2.png" alt="company-image">
                            <div class="title">
                                <h6>
                                    Serene Soaps & Scents
                                </h6>
                                <span class="d-block text-primary">
                                    Beauty Product
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="email">
                            <span class="d-block fw-medium heading-font-family">
                                Email
                            </span>
                            <a href="mailto:demo@contact.com" class="text-black">
                                demo&#64;contact.com
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="phone">
                            <span class="d-block fw-medium heading-font-family">
                                Phone
                            </span>
                            <a href="tel:+1 2354 6798" class="text-black">
                                +1 2354 6798
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="action d-flex align-items-center justify-content-md-end">
                            <div class="dot bg-c767dc"></div>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="edit-3"></i-feather>
                            </button>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="trash-2"></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="trinta-card opportunities-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="info d-flex align-items-center">
                            <img src="assets/images/companies/company3.png" alt="company-image">
                            <div class="title">
                                <h6>
                                    Pixel Paradise Electronics
                                </h6>
                                <span class="d-block text-primary">
                                    Interior
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="email">
                            <span class="d-block fw-medium heading-font-family">
                                Email
                            </span>
                            <a href="mailto:demo@contact.com" class="text-black">
                                demo&#64;contact.com
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="phone">
                            <span class="d-block fw-medium heading-font-family">
                                Phone
                            </span>
                            <a href="tel:+1 2354 6798" class="text-black">
                                +1 2354 6798
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="action d-flex align-items-center justify-content-md-end">
                            <div class="dot"></div>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="edit-3"></i-feather>
                            </button>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="trash-2"></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="trinta-card opportunities-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="info d-flex align-items-center">
                            <img src="assets/images/companies/company4.png" alt="company-image">
                            <div class="title">
                                <h6>
                                    Cosmic Cupcakes Corner
                                </h6>
                                <span class="d-block text-primary">
                                    Cosmetic
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="email">
                            <span class="d-block fw-medium heading-font-family">
                                Email
                            </span>
                            <a href="mailto:demo@contact.com" class="text-black">
                                demo&#64;contact.com
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="phone">
                            <span class="d-block fw-medium heading-font-family">
                                Phone
                            </span>
                            <a href="tel:+1 2354 6798" class="text-black">
                                +1 2354 6798
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="action d-flex align-items-center justify-content-md-end">
                            <div class="dot bg-6771dc"></div>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="edit-3"></i-feather>
                            </button>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="trash-2"></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="trinta-card opportunities-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="info d-flex align-items-center">
                            <img src="assets/images/companies/company5.png" alt="company-image">
                            <div class="title">
                                <h6>
                                    Mystic Threads Emporium
                                </h6>
                                <span class="d-block text-primary">
                                    Electric
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="email">
                            <span class="d-block fw-medium heading-font-family">
                                Email
                            </span>
                            <a href="mailto:demo@contact.com" class="text-black">
                                demo&#64;contact.com
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="phone">
                            <span class="d-block fw-medium heading-font-family">
                                Phone
                            </span>
                            <a href="tel:+1 2354 6798" class="text-black">
                                +1 2354 6798
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="action d-flex align-items-center justify-content-md-end">
                            <div class="dot bg-a367dc"></div>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="edit-3"></i-feather>
                            </button>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="trash-2"></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="trinta-card opportunities-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="info d-flex align-items-center">
                            <img src="assets/images/companies/company4.png" alt="company-image">
                            <div class="title">
                                <h6>
                                    Quantum Quilts & Co.
                                </h6>
                                <span class="d-block text-primary">
                                    Furniture
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="email">
                            <span class="d-block fw-medium heading-font-family">
                                Email
                            </span>
                            <a href="mailto:demo@contact.com" class="text-black">
                                demo&#64;contact.com
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="phone">
                            <span class="d-block fw-medium heading-font-family">
                                Phone
                            </span>
                            <a href="tel:+1 2354 6798" class="text-black">
                                +1 2354 6798
                            </a>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="action d-flex align-items-center justify-content-md-end">
                            <div class="dot"></div>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="edit-3"></i-feather>
                            </button>
                            <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                <i-feather name="trash-2"></i-feather>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-4">
        <mat-card
            class="trinta-card order-statistics-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        All Opportunities By Owner
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Last Day
                        </button>
                        <button mat-menu-item>
                            Last Week
                        </button>
                        <button mat-menu-item>
                            Last Month
                        </button>
                        <button mat-menu-item>
                            Last Year
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="chart">
                    <apx-chart
                        [series]="chartOptions.series!"
                        [chart]="chartOptions.chart!"
                        [plotOptions]="chartOptions.plotOptions!"
                        [colors]="chartOptions.colors!"
                        [labels]="chartOptions.labels!"
                    ></apx-chart>
                </div>
                <ul class="p-0 m-0 text-center list-unstyled">
                    <li class="position-relative d-inline-block">
                        <div class="dot"></div>
                        <span class="d-block text-gray">
                            Won
                        </span>
                        <h6 class="m-0 fw-medium">
                            %45
                        </h6>
                    </li>
                    <li class="position-relative d-inline-block">
                        <div class="dot"></div>
                        <span class="d-block text-gray">
                            In Progress
                        </span>
                        <h6 class="m-0 fw-medium">
                            %32
                        </h6>
                    </li>
                    <li class="position-relative d-inline-block">
                        <div class="dot"></div>
                        <span class="d-block text-gray">
                            Lost
                        </span>
                        <h6 class="m-0 fw-medium">
                            %28
                        </h6>
                    </li>
                    <li class="position-relative d-inline-block">
                        <div class="dot"></div>
                        <span class="d-block text-gray">
                            Hot
                        </span>
                        <h6 class="m-0 fw-medium">
                            %87
                        </h6>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>