import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatIcon } from "@angular/material/icon";
import { MatSort } from '@angular/material/sort';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';
import { MembersconfirmComponent } from '../membersconfirm/membersconfirm.component';
// import { MatDialog } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-membersstatistics',
  standalone: true,
  imports: [MatCardModule,NgIf,MatCheckboxModule,MatSortModule,MatIconModule,MatIcon, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
  templateUrl: './membersstatistics.component.html',
  styleUrl: './membersstatistics.component.scss'
})
export class MembersstatisticsComponent  {
  groupdID: string | null = null;
  totalGroupMembers: string = '';
  totalrides: string = '';
  totalevents: string = '';
  percentageActive: string = '';
  newMembersToday: string = '';
  upcomingRidesSevenDays: string = '';
  ridesCancelledThirtyDays: string = '';
  averageNoOfRidersThirtyDaysOfGroupMembers: string = '';

displayedColumns: string[] = ['select','profilePhoto',  'email','ownerAdmin', 'actions'];
dataSource = new MatTableDataSource<any>(); 
@ViewChild(MatSort) sort: MatSort;
@ViewChild(MatPaginator) paginator: MatPaginator;


filterValues: any = {};

// @ViewChild(MatSort) sort!: MatSort;
// @ViewChild(MatPaginator) paginator!: MatPaginator;

// ngOnInit() {
//   this.dataSource.sort = this.sort;
//   this.dataSource.paginator = this.paginator;

//   // Initialize the filter predicate with a custom function
//   this.dataSource.filterPredicate = this.customFilterPredicate();
// }

// Custom filter predicate
customFilterPredicate() {
  return (data: any, filter: string): boolean => {
    const searchTerms = JSON.parse(filter);
    let isMatch = true;
    
    // Check for matches in each column
    for (let column in searchTerms) {
      if (searchTerms[column] && !data[column].toString().toLowerCase().includes(searchTerms[column].toLowerCase())) {
        isMatch = false;
      }
    }
    return isMatch;
  };
}

// Apply filter for a specific column
applyColumnFilter(event: Event, columnName: string) {
  const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  this.filterValues[columnName] = filterValue;

  this.dataSource.filter = JSON.stringify(this.filterValues);
}
// ngAfterViewInit() {
//     this.dataSource.paginator = this.paginator;
// }
ngAfterViewInit() {
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
constructor(private http: HttpClient, private dialog: MatDialog, private router: Router) { }

ngOnInit(): void {
  // this.fetmemberslist();
  this.loadTotalgroups();
  // this.totalUpcomingridesnxt7days();

}


totalUpcomingridesnxt7days() {
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  const groupid =  localStorage.getItem('selectedGroupId');
  this.http.get(BASE_URL + ApiEndpoints.getGroupSummaryByID+groupid, { headers: headers }).subscribe(
    (data: any) => {
      this.totalGroupMembers = data.totalMembers || 0;
      this.totalrides = data.totalRides || 0;
      this.totalevents = data.totalEvents || 0;
      this.percentageActive= data.percentageActive || 0;
      this.newMembersToday= data.newMembersToday || 0;
      this.upcomingRidesSevenDays= data.upcomingRidesSevenDays || 0;
      this.ridesCancelledThirtyDays= data.ridesCancelledThirtyDays || 0;
      this.averageNoOfRidersThirtyDaysOfGroupMembers= data.averageNoOfRidersThirtyDaysOfGroupMembers || 0;
    },
    error => {
      console.log('Error fetching purposes:', error);
    }
  );
}

loadTotalgroups() {
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  const groupid =  localStorage.getItem('selectedGroupId');
  this.http.get(BASE_URL + ApiEndpoints.getGroupSummaryByID+groupid, { headers: headers }).subscribe(
    (data: any) => {
      console.log("totalGroupMembers", data)
      this.totalGroupMembers = data.totalMembers || 0;
      this.totalrides = data.totalRides || 0;
      this.totalevents = data.totalEvents || 0;
      this.percentageActive= data.percentageActive || 0;
      this.newMembersToday= data.newMembersToday || 0;
      this.upcomingRidesSevenDays= data.upcomingRidesSevenDays || 0;
      this.ridesCancelledThirtyDays= data.ridesCancelledThirtyDays || 0;
      this.averageNoOfRidersThirtyDaysOfGroupMembers= data.averageNoOfRidersThirtyDaysOfGroupMembers || 0;



    },
    error => {
      console.log('Error fetching purposes:', error);
    }
  );
}

navigatetomemberspage(){
  this.router.navigate(['/tools']);

}

fetmemberslist() {

 this.groupdID = localStorage.getItem('selectedGroupId');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.getGroupMembers+ this.groupdID, { headers: headers }).subscribe(
    (data) => {
      this.dataSource.data = data.entity; // Assign fetched data to the data source
      this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}


onDelete(element: any): void {
  const dialogRef = this.dialog.open(MembersconfirmComponent, {
    width: '460px',
    disableClose: true,
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Call your delete function here
      this.deleteUser(element);
    }
  });
}

deleteUser(element: any): void {
  var jsobj = {
    "cbPartner_id": element.cBPartnerID,
    "ridingGroup_id": this.groupdID,
    "approved": true
  }
  
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.post(BASE_URL + ApiEndpoints.leaveGroup, jsobj, { headers: headers }).subscribe(
    (res: any) => {
      // alert("Account Removed");
      this.fetmemberslist();
    },
    (error) => {
      if (error.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Unauthorized");
      } else if (error.status === 500) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Internal server error");
      } else {
        // Handle other errors
        console.error("Error logging in:", error);
        alert("Failed creating record. Please try again later.");
      }
    }
  );


}

onDeletes(element: any): void {

  console.log("elementelement", element)

  var jsobj = {
    "cbPartner_id": element.cBPartnerID,
    "ridingGroup_id": this.groupdID,
    "approved": true
  }
  
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.post(BASE_URL + ApiEndpoints.leaveGroup, jsobj, { headers: headers }).subscribe(
    (res: any) => {
      // alert("Account Removed");
      this.fetmemberslist();
    },
    (error) => {
      if (error.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Unauthorized");
      } else if (error.status === 500) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Internal server error");
      } else {
        // Handle other errors
        console.error("Error logging in:", error);
        alert("Failed creating record. Please try again later.");
      }
    }
  );

}


makegroupadmin(element: any): void {
  console.log("elementelement", element)

  var jsobj = {
    "cbPartner_id": element.cBPartnerID,
    "ridingGroup_id": this.groupdID,
    "approved": true
  }

  // var jsn = {
  //   "cbPartner_id": 0,
  //   "ridingGroup_id": 0,
  //   "approved": true
  // }
  
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.post(BASE_URL + ApiEndpoints.changeGroupAdmin, jsobj, { headers: headers }).subscribe(
    (res: any) => {
      alert("Successfully added as group admin");
      this.fetmemberslist();
    },
    (error) => {
      if (error.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Unauthorized");
      } else if (error.status === 500) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Internal server error");
      } else {
        // Handle other errors
        console.error("Error logging in:", error);
        alert("Failed creating record. Please try again later.");
      }
    }
  );

}
}





