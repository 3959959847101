 <div class="row">
    <div class="col-xxxl-3 col-sm-6 col-md-4">
      <mat-card
        class="trinta-card pm-stat-card mb-25 bg-white border-none d-block  text-center" >
        <mat-card-content>
          <div class = "card-title">
            <div class="icon d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 26 26">
                <path fill="#fff" d="M21.054 8.405c-.37 0-.729.07-1.075.192l-.874-4.892c.018-1.574.885-2.847 1.949-2.847 1.026 0 1.86 1.3 1.86 2.898h.545C23.46 1.685 22.38 0 21.054 0c-1.293 0-2.358 1.487-2.483 3.384l-7.225 1.753L12.5 1.502H9.62v.859h2l-.979 3.083-2.623 4.658c-.844-1.058-1.91-1.696-3.071-1.696C2.219 8.406 0 11.903 0 16.203S2.219 24 4.946 24c2.635 0 4.79-3.268 4.932-7.368h3.512L18.723 4.74l.732 4.093c-1.944 1.052-3.347 3.955-3.347 7.371 0 4.3 2.219 7.797 4.946 7.797S26 20.503 26 16.203s-2.219-7.798-4.946-7.798Zm-9.82-2.373 7.004-1.699-4.908 10.948-2.095-9.249Zm-.468.483 2.097 9.259H9.878c-.07-1.99-.608-3.785-1.452-5.105l2.34-4.154Zm-2.702 4.797c.732 1.16 1.202 2.726 1.27 4.462H5.552l2.512-4.462Zm-3.118 11.83c-2.427 0-4.401-3.113-4.401-6.94 0-3.825 1.974-6.938 4.401-6.938 1.022 0 1.961.556 2.709 1.481L4.34 16.632h4.993c-.141 3.626-2.052 6.51-4.387 6.51Zm16.108 0c-2.427 0-4.401-3.113-4.401-6.939 0-3.023 1.235-5.594 2.95-6.543l1.189 6.66.524-.234-1.19-6.664c.3-.102.61-.158.928-.158 2.427 0 4.402 3.112 4.402 6.939 0 3.826-1.975 6.939-4.402 6.939Z"/>
              </svg>
            </div>


            <div class="tilesheader">
              <span class="tilestitle">
                Total Rides
            </span>
            <p style="color: #fff;">All</p>
            </div>
          </div>
          <h5  class="tiletotalcounts">
            {{totalrides}}
          </h5>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-xxxl-3 col-sm-6 col-md-4" >
     

      <mat-card
      class="trinta-card pm-stat-card mb-25 bg-white border-none d-block  text-center"
    >
      <mat-card-content>
        <div class = "card-title">
          
          <div class="icon d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24" height="24" fill="none" stroke="#fff" stroke-width="2">
              <rect x="8" y="16" width="48" height="40" rx="4" ry="4" stroke="#fff" stroke-width="4" fill="none"/>
              <line x1="16" y1="8" x2="16" y2="16" stroke="#fff" stroke-width="4"/>
              <line x1="48" y1="8" x2="48" y2="16" stroke="#fff" stroke-width="4"/>
              <line x1="8" y1="24" x2="56" y2="24" stroke="#fff" stroke-width="4"/>
              <rect x="20" y="32" width="8" height="8" fill="#fff"/>
              <rect x="36" y="32" width="8" height="8" fill="#fff"/>
              <rect x="20" y="48" width="8" height="8" fill="#fff"/>
              <rect x="36" y="48" width="8" height="8" fill="#fff"/>
            </svg>
          </div>

          <div class="tilesheader">
            <span class="tilestitle">
              Upcoming Rides
          </span>
          <p class="subheadingtilestitle">in the next 7 days</p>
          </div>

        </div>
        <h5  class="tiletotalcounts">
          {{upcomingRidesSevenDays}}
        </h5>
      </mat-card-content>
    </mat-card>

    </div>
   
    <div class="col-xxxl-3 col-sm-6 col-md-4">
      <mat-card
        class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
      >
        <mat-card-content>
          <div class = "card-title">
            
            <div class="icon d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24" height="24" fill="none" stroke="#fff" stroke-width="4">
                <circle cx="32" cy="32" r="30" stroke="#fff" stroke-width="4" fill="none"/>
                <line x1="20" y1="20" x2="44" y2="44" stroke="#fff" stroke-width="4"/>
                <line x1="44" y1="20" x2="20" y2="44" stroke="#fff" stroke-width="4"/>
              </svg>
  
  
            </div>

            <div class="tilesheader">
              <span class="tilestitle">
                Cancelled Rides
            </span>
            <p class="subheadingtilestitle">in the last 30 days</p>
            </div>
  
          </div>
          <h5 class="tiletotalcounts">
            {{ridesCancelledThirtyDays}}
          </h5>
  
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-xxxl-3 col-sm-6 col-md-4">
     
      <mat-card
      class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
    >
      <mat-card-content>
        <div class = "card-title">
          <div class="icon d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24" height="24" fill="none" stroke="#fff" stroke-width="2">
              <!-- Calendar Frame -->
              <rect x="8" y="16" width="48" height="40" rx="4" ry="4" stroke="#fff" stroke-width="4" fill="none"/>
              
              <!-- Calendar Header (for month/year) -->
              <line x1="8" y1="24" x2="56" y2="24" stroke="#fff" stroke-width="4"/>
              
              <!-- Calendar rings/handles -->
              <line x1="16" y1="8" x2="16" y2="16" stroke="#fff" stroke-width="4"/>
              <line x1="48" y1="8" x2="48" y2="16" stroke="#fff" stroke-width="4"/>
              
              <!-- Event Star Icon -->
              <polygon points="32,36 28,44 36,44 32,52 38,48 26,48" fill="#fff"/>
            </svg>
            
          </div>

          <div class="tilesheader">
            <span class="tilestitle">
              Total Events
          </span>
          <p style="color: #fff;">All</p>
          </div>

        </div>
        <h5  class="tiletotalcounts">
          {{totalevents}}
        </h5>
      </mat-card-content>
    </mat-card>
    </div>

    
  </div>
