<div class="row">
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user2.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Marcus Davis
                            </h6>
                            <span class="d-block text-body">
                                From - 07/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user3.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Emily Johnson
                            </h6>
                            <span class="d-block text-body">
                                From - 06/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user9.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                William Anderson
                            </h6>
                            <span class="d-block text-body">
                                From - 05/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Sarah Rodriguez
                            </h6>
                            <span class="d-block text-body">
                                From - 05/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user22.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Liam Thompson
                            </h6>
                            <span class="d-block text-body">
                                From - 04/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Charlotte Lee
                            </h6>
                            <span class="d-block text-body">
                                From - 03/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user16.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Noah Wilson
                            </h6>
                            <span class="d-block text-body">
                                From - 02/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card customer-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user23.jpg" class="rounded-circle" alt="user-image">
                        <div class="info">
                            <h6>
                                Alex Rodriguez
                            </h6>
                            <span class="d-block text-body">
                                From - 01/07/2024
                            </span>
                        </div>
                    </div>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <ul class="list p-0 list-unstyled">
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Email :
                        </span>
                        demo&#64;contact.com
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Phone :
                        </span>
                        +1 2354 6798
                    </li>
                    <li class="text-black">
                        <span class="text-dark fw-medium heading-font-family">
                            Location :
                        </span>
                        123 Street Albany, New York
                    </li>
                </ul>
                <a routerLink="/crm-page/customers" class="default-btn" mat-button>
                    Send Message
                </a>
            </mat-card-content>
        </mat-card>
    </div>
</div>