<mat-card
    class="trinta-card issues-summary-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Tickets Status
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Year
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [grid]="chartOptions.grid!"
                [chart]="chartOptions.chart!"
                [dataLabels]="chartOptions.dataLabels!"
                [plotOptions]="chartOptions.plotOptions!"
                [yaxis]="chartOptions.yaxis!"
                [colors]="chartOptions.colors!"
                [legend]="chartOptions.legend!"
                [fill]="chartOptions.fill!"
                [stroke]="chartOptions.stroke!"
                [tooltip]="chartOptions.tooltip!"
                [xaxis]="chartOptions.xaxis!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>