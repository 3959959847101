<div class="example-container">
    <h6>Available numbers</h6>
    <div
        id="all"
        cdkDropList
        [cdkDropListData]="all"
        cdkDropListConnectedTo="even"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="noReturnPredicate"
    >
        @for (number of all; track number) {
            <div class="example-box" [cdkDragData]="number" cdkDrag>{{number}}</div>
        }
    </div>
</div>
<div class="example-container">
    <h6>Even numbers</h6>
    <div
        id="even"
        cdkDropList
        [cdkDropListData]="even"
        cdkDropListConnectedTo="all"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="evenPredicate"
    >
        @for (number of even; track number) {
            <div class="example-box" cdkDrag [cdkDragData]="number">{{number}}</div>
        }
    </div>
</div>