<div class="row" class="latestposts">


    <h4 style="text-align: left;">Latest post</h4>
    <mat-card-content>
        <div class="user text-center">
            <img src="assets/social.png" class="profile-img" style="width: 100%;">
        </div>
        <ul class="list p-0 list-unstyled">
            <li class="text-black">
                <p>
                    Calling all cycling enthusiasts! Join us for an exhilarating ride through picturesque landscapes and challenging routes. 
                </p>
                
            </li>
            
        </ul>
        <div class="buttons-list d-flex align-items-center justify-content-between">
            <a routerLink="/" class="default-btn" mat-button>
                <img src="assets/pr1.png" class="profile-img" style="height: 28px"> <span>Jave walker  April 20, 2024</span>
            </a>
        </div>
    </mat-card-content>



    <mat-card-content>
        <div class="user text-center">
            <img src="assets/social.png" class="profile-img" style="width: 100%;">
        </div>
        <ul class="list p-0 list-unstyled">
            <li class="text-black">
               
                <p>
                    Calling all cycling enthusiasts! Join us for an exhilarating ride through picturesque landscapes and challenging routes. 
                </p>
                
            </li>
            
        </ul>
        <div class="buttons-list d-flex align-items-center justify-content-between">
            <a routerLink="/" class="default-btn" mat-button>
                <img src="assets/pr1.png" class="profile-img" style="height: 28px"> <span>Jave walker  April 20, 2024</span>
            </a>
        </div>
    </mat-card-content>

    

</div>