<mat-card
    class="trinta-card delivery-info-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Delivery Info
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h6>
            UPS Delivery
        </h6>
        <ul class="p-0 m-0 list-unstyled">
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Order ID :
                </span>
                XXXXX2501
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Payment Mode :
                </span>
                COD
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Email :
                </span>
                info&#64;contact.com
            </li>
        </ul>
    </mat-card-content>
</mat-card>