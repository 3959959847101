<mat-card
    class="trinta-card profile-intro-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Profile Intro
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="user text-center">
            <img src="assets/images/admin.jpg" class="rounded-circle" alt="user-image">
            <h5>
                Marcus Davis
            </h5>
            <span class="d-block text-body">
                &#64;Alvarado
            </span>
        </div>
        <div class="buttons-list d-flex align-items-center justify-content-between">
            <a routerLink="/social-feed" class="default-btn" mat-button>
                View Projects
            </a>
            <a href="mailto:demo@contact.com" class="default-btn" mat-button>
                Message
            </a>
        </div>
    </mat-card-content>
</mat-card>