<div class="row">
    <div class="col-lg-6 col-xxxl-3 order-lg-1 order-xxxl-1">
        <mat-card
            class="trinta-card user-bio-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <h5>
                    User Bio
                </h5>
                <p class="text-black">
                    Nam malesuada dolor tellus pretium amet was hendrerit facilisi id vitae enim sed ornare there suspendisse sed orci neque ac sed aliquet risus faucibus in pretium nis.
                </p>
                <h5>
                    Skills :
                </h5>
                <div class="skills">
                    <div class="position-relative d-inline-block fw-medium">
                        JavaScript
                        <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="position-relative d-inline-block fw-medium">
                        PHP Developer
                        <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="position-relative d-inline-block fw-medium">
                        Vue.js
                        <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="position-relative d-inline-block fw-medium">
                        MySQL
                        <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                    <div class="position-relative d-inline-block fw-medium">
                        Angular
                        <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                            <i class="ri-close-line"></i>
                        </button>
                    </div>
                </div>
                <h5>
                    Contact Info :
                </h5>
                <ul class="contact-info p-0 mb-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="mail"></i-feather>
                        demo&#64;contact.com
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="phone"></i-feather>
                        +1 2354 6798
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="globe"></i-feather>
                        www.example.com
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="tag"></i-feather>
                        16 tagged images
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="calendar"></i-feather>
                        05 events
                    </li>
                </ul>
                <h5>
                    Social Profiles :
                </h5>
                <ul class="social-profiles p-0 mb-0 list-unstyled">
                    <li class="d-inline-block">
                        <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                            <i class="ri-facebook-box-fill"></i>
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                            <i class="ri-messenger-fill"></i>
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                            <i class="ri-twitter-fill"></i>
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                            <i class="ri-linkedin-fill"></i>
                        </a>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-xxxl-6 order-lg-3 order-xxxl-2">
        <mat-card
            class="trinta-card user-profile-activity-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Activity
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="m-0 p-0 list-unstyled">
                    <li class="d-md-flex">
                        <img src="assets/images/users/user25.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Shane Watson
                            </h6>
                            <p class="text-black position-relative">
                                <i class="ri-message-2-line"></i>
                                Mentioned you in a comment "Well done! Proud of you ❤️ "
                            </p>
                            <span class="time text-black position-relative d-block">
                                <i class="ri-time-line"></i>
                                10:20 AM <span class="text-body">August 7, 2024</span>
                            </span>
                        </div>
                    </li>
                    <li class="d-md-flex">
                        <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Duran Clayton
                            </h6>
                            <p class="text-black position-relative">
                                <i class="ri-calendar-2-line"></i>
                                Created an event Rome holidays
                            </p>
                            <span class="time text-black position-relative d-block">
                                <i class="ri-time-line"></i>
                                10:11 AM <span class="text-body">August 6, 2024</span>
                            </span>
                        </div>
                    </li>
                    <li class="d-md-flex">
                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Black Smith
                            </h6>
                            <p class="text-black position-relative">
                                <i class="ri-thumb-up-line"></i>
                                Liked your comment "Amazing Works️"
                            </p>
                            <span class="time text-black position-relative d-block">
                                <i class="ri-time-line"></i>
                                12:12 AM <span class="text-body">August 5, 2024</span>
                            </span>
                        </div>
                    </li>
                    <li class="d-md-flex">
                        <img src="assets/images/users/user3.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Alexis Clarke
                            </h6>
                            <p class="text-black position-relative">
                                <i class="ri-message-2-line"></i>
                                Mentioned you in a comment "This is too good to be true!"
                            </p>
                            <span class="time text-black position-relative d-block">
                                <i class="ri-time-line"></i>
                                08:40 AM <span class="text-body">August 4, 2024</span>
                            </span>
                        </div>
                    </li>
                    <li class="d-md-flex">
                        <img src="assets/images/users/user4.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                David Warner
                            </h6>
                            <p class="text-black position-relative">
                                <i class="ri-thumb-up-line"></i>
                                Liked your comment "Welcome to the team️"
                            </p>
                            <span class="time text-black position-relative d-block">
                                <i class="ri-time-line"></i>
                                11:23 PM <span class="text-body">August 3, 2024</span>
                            </span>
                        </div>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-xxxl-3 order-lg-2 order-xxxl-3">
        <mat-card
            class="trinta-card friends-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Friends
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="m-0 p-0 lint-unstyled">
                    <li class="d-flex align-items-center">
                        <img src="assets/images/users/user6.jpg" alt="user-image">
                        <div>
                            <h6>
                                Alexander Johnson
                            </h6>
                            <span class="d-block text-body">
                                johnson&#64;example.com
                            </span>
                        </div>
                    </li>
                    <li class="d-flex align-items-center">
                        <img src="assets/images/users/user8.jpg" alt="user-image">
                        <div>
                            <h6>
                                Olivia Bennett
                            </h6>
                            <span class="d-block text-body">
                                bennett&#64;example.com
                            </span>
                        </div>
                    </li>
                    <li class="d-flex align-items-center">
                        <img src="assets/images/users/user4.jpg" alt="user-image">
                        <div>
                            <h6>
                                Mason Williams
                            </h6>
                            <span class="d-block text-body">
                                mason&#64;example.com
                            </span>
                        </div>
                    </li>
                    <li class="d-flex align-items-center">
                        <img src="assets/images/users/user7.jpg" alt="user-image">
                        <div>
                            <h6>
                                Benjamin Clark
                            </h6>
                            <span class="d-block text-body">
                                clark&#64;example.com
                            </span>
                        </div>
                    </li>
                    <li class="d-flex align-items-center">
                        <img src="assets/images/users/user5.jpg" alt="user-image">
                        <div>
                            <h6>
                                Sophia Chen
                            </h6>
                            <span class="d-block text-body">
                                sophia&#64;example.com
                            </span>
                        </div>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>