<div class="row">
    <div class="col-xxxl-6 col-md-12">

        <!-- Tickets Status -->
        <app-tickets-status />

    </div>
    <div class="col-xxxl-6 col-md-12">

        <!-- Avg. Response Time -->
        <app-avg-response-time />
        
    </div>
</div>

<!-- Agents Performance -->
<app-agents-performance />