<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Basic Grid List
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-grid-list cols="2" rowHeight="2:1">
            <mat-grid-tile>1</mat-grid-tile>
            <mat-grid-tile>2</mat-grid-tile>
            <mat-grid-tile>3</mat-grid-tile>
            <mat-grid-tile>4</mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
</mat-card>