<mat-card
    class="trinta-card products-list-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Products List
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <a routerLink="/ecommerce-page/products-list" class="default-btn" mat-button>
                <i class="ri-add-line"></i> Add New
            </a>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="products-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Product
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/" class="image d-block">
                                    <img [src]="element.product.img" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="d-block fw-semibold">
                                        {{element.product.title}}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Category Column -->
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>
                            Category
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary">
                            {{element.category}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <!-- Ratings Column -->
                    <ng-container matColumnDef="ratings">
                        <th mat-header-cell *matHeaderCellDef>
                            Ratings
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="ratings lh-1">
                                @for (content of element.ratings.star; track content) {
                                    <i class="{{content.star}}"></i>
                                }
                                <span class="lh-1 text-body">
                                    ({{element.ratings.totalRatings}})
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Stock Column -->
                    <ng-container matColumnDef="stock">
                        <th mat-header-cell *matHeaderCellDef>
                            Stock
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.stock}}
                        </td>
                    </ng-container>

                    <!-- Total Orders Column -->
                    <ng-container matColumnDef="totalOrders">
                        <th mat-header-cell *matHeaderCellDef>
                            Total Orders
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.totalOrders}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.view}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.edit}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.delete}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>