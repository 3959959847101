<div class="title text-center">
  <h2 mat-dialog-title class="fw-semibold">Invite Member</h2>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <form [formGroup]="inviteMemberForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">


    <div class="row" class="custom-form-field" style="padding-top: 12px;">
      <mat-form-field class="col-sm-6" style="padding-right: 4px;">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" id="firstName" name="firstName" type="text" required>
      </mat-form-field>

      <mat-form-field class="col-sm-6" style="padding-left: 4px;">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastNameString" id="lastNameString" name="lastNameString" type="text" required>
      </mat-form-field>
    </div>

    <mat-form-field style="padding-top: 12px;">
      <mat-label>Enter email</mat-label>
      <input matInput formControlName="email" id="email" name="email" type="text"  placeholder="janedoe@yahoo.com" required>
    </mat-form-field>
    

    <div class="row" style="padding-top: 18px;">
      <button mat-raised-button color="primary" type="submit" class="col-sm-6 ml-auto mr-auto">Invite Member</button>
    </div>
  </form>
</div>