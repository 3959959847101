<mat-card
    class="trinta-card calendar-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Calendar
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                January
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    January
                </button>
                <button mat-menu-item>
                    February
                </button>
                <button mat-menu-item>
                    March
                </button>
                <button mat-menu-item>
                    April
                </button>
                <button mat-menu-item>
                    May
                </button>
                <button mat-menu-item>
                    June
                </button>
                <button mat-menu-item>
                    July
                </button>
                <button mat-menu-item>
                    August
                </button>
                <button mat-menu-item>
                    September
                </button>
                <button mat-menu-item>
                    October
                </button>
                <button mat-menu-item>
                    November
                </button>
                <button mat-menu-item>
                    December
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-calendar [(selected)]="selected"></mat-calendar>
      
    </mat-card-content>
</mat-card>