<div class="row">
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user2.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Marcus Davis
                    </h5>
                    <span class="d-block text-body">
                        &#64;Alvarado
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user4.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Charlotte Lee
                    </h5>
                    <span class="d-block text-body">
                        &#64;Charlotte
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user8.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        William Anderson
                    </h5>
                    <span class="d-block text-body">
                        &#64;William
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Sarah Rodriguez
                    </h5>
                    <span class="d-block text-body">
                        &#64;Sarah
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user3.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Emily Johnson
                    </h5>
                    <span class="d-block text-body">
                        &#64;Emily
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user6.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Thomas Johnson
                    </h5>
                    <span class="d-block text-body">
                        &#64;Thomas
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user9.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Martine Jeck
                    </h5>
                    <span class="d-block text-body">
                        &#64;Martine
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-3 col-xxl-4 col-sm-6">
        <mat-card
            class="trinta-card team-member-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Block
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="user text-center">
                    <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                    <h5>
                        Benjamin Clark
                    </h5>
                    <span class="d-block text-body">
                        &#64;Benjamin
                    </span>
                    <ul class="socials p-0 mb-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="info d-md-flex justify-content-between">
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Email
                        </span>
                        <span class="d-block text-body">
                            demo&#64;contact.com
                        </span>
                    </div>
                    <div>
                        <span class="d-block fw-medium heading-font-family">
                            Phone
                        </span>
                        <span class="d-block text-body">
                            +1 2354 6798
                        </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>