<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Table
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Table
        </li>
    </ol>
</div>

<!-- Basic Table -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Basic Table
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-basic-table></app-basic-table>
    </mat-card-content>
</mat-card>

<!-- Styling Columns using their Auto-generated Column Names -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Styling Columns using their Auto-generated Column Names
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-styling-columns-utag-column-names></app-styling-columns-utag-column-names>
    </mat-card-content>
</mat-card>

<!-- Adding & Removing Data when using an Array-based Datasource -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Adding & Removing Data when using an Array-based Datasource
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-adding-removing-data-wuaab-datasource></app-adding-removing-data-wuaab-datasource>
    </mat-card-content>
</mat-card>

<!-- Table Dynamically Changing the Columns Displayed -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table Dynamically Changing the Columns Displayed
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-dynamically-changing-columns-displayed></app-dynamically-changing-columns-displayed>
    </mat-card-content>
</mat-card>

<!-- Adding & Removing Data when using an Observable-based Datasource -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Adding & Removing Data when using an Observable-based Datasource
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-ar-data-wua-observable-based-datasource></app-ar-data-wua-observable-based-datasource>
    </mat-card-content>
</mat-card>

<!-- Table with Expandable Rows -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Expandable Rows
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-expandable-rows></app-tw-expandable-rows>
    </mat-card-content>
</mat-card>

<!-- Table with Filtering -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Filtering
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-filtering></app-table-with-filtering>
    </mat-card-content>
</mat-card>

<!-- Footer Row Table -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Footer Row Table
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-footer-row-table></app-footer-row-table>
    </mat-card-content>
</mat-card>

<!-- Table Retrieving Data Through HTTP -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table Retrieving Data Through HTTP
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-retrieving-data-through-http></app-retrieving-data-through-http>
    </mat-card-content>
</mat-card>

<!-- Table with Multiple Header & Footer Rows -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Multiple Header & Footer Rows
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-multiple-header-footer-rows></app-multiple-header-footer-rows>
    </mat-card-content>
</mat-card>

<!-- Data Table with Sorting, Pagination, & Filtering -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Data Table with Sorting, Pagination, & Filtering
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-dtw-sorting-pagination-filtering></app-dtw-sorting-pagination-filtering>
    </mat-card-content>
</mat-card>

<!-- Table with Pagination -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Pagination
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-pagination></app-table-with-pagination>
    </mat-card-content>
</mat-card>

<!-- Table with Re-orderable Columns -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Re-orderable Columns
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-re-orderable-columns></app-tw-re-orderable-columns>
    </mat-card-content>
</mat-card>

<!-- Table with Selection -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Selection
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-selection></app-table-with-selection>
    </mat-card-content>
</mat-card>

<!-- Table with Sorting -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Sorting
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-sorting></app-table-with-sorting>
    </mat-card-content>
</mat-card>

<!-- Table with Sticky Columns -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Sticky Columns
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-sticky-columns></app-table-with-sticky-columns>
    </mat-card-content>
</mat-card>

<!-- Table with Sticky Footer -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Sticky Footer
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-sticky-footer></app-table-with-sticky-footer>
    </mat-card-content>
</mat-card>

<!-- Table with Sticky Header -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Sticky Header
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-table-with-sticky-header></app-table-with-sticky-header>
    </mat-card-content>
</mat-card>

<!-- Table with Material Design Ripples -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Table with Material Design Ripples
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-material-design-ripples></app-tw-material-design-ripples>
    </mat-card-content>
</mat-card>