<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Datepicker Action Buttons
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <i-feather name="list"></i-feather>
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="datepicker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                        <mat-datepicker-actions>
                        <button mat-button matDatepickerCancel>Cancel</button>
                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <i-feather name="list"></i-feather>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="rangePicker">
                        <input matStartDate placeholder="Start date">
                        <input matEndDate placeholder="End date">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker>
                        <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel>Cancel</button>
                        <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                        </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
    </mat-card-content>
</mat-card>