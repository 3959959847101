<div class="example-container">
    <h6>To do</h6>
    <div
        cdkDropList
        #todoList="cdkDropList"
        [cdkDropListData]="todo"
        [cdkDropListConnectedTo]="[doneList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
    >
        @for (item of todo; track item) {
        <div class="example-box" cdkDrag>{{item}}</div>
        }
    </div>
</div>
<div class="example-container">
    <h6>Done</h6>
    <div
        cdkDropList
        #doneList="cdkDropList"
        [cdkDropListData]="done"
        [cdkDropListConnectedTo]="[todoList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
    >
        @for (item of done; track item) {
            <div class="example-box" cdkDrag>{{item}}</div>
        }
    </div>
</div>