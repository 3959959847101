<mat-card-content>
    <div class="container membershipectiontab">
        <div class="row">
            <p class="ridestitle">Members and Invites</p>

            <div class="col-xl-9 col-lg-9 col-sm-12 ">
                <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
                    <p class="ridestitle">Members</p>
                    <mat-card-content>

                        <app-membersonly></app-membersonly>
                        
                        
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-3 col-lg-3 col-sm-12 ">
                <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
                    <!-- <p class="ridestitle">Invites</p> -->

                    <mat-card-content>
                        <app-invites></app-invites>
                                          
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</mat-card-content>