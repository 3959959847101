<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Menus
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Menus
        </li>
    </ol>
</div>

<!-- Menus -->
<div class="row">
    <div class="col-md-6 col-xxl-4">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Menu
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-stroked-button [matMenuTriggerFor]="basicMenu">
                    Menu
                </button>
                <mat-menu #basicMenu="matMenu">
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item>Item 2</button>
                    <button mat-menu-item>Item 3</button>
                    <button mat-menu-item>Item 4</button>
                    <button mat-menu-item>Item 5</button>
                </mat-menu>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Menu with Icon
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-icon-button [matMenuTriggerFor]="menuWithIcons" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuWithIcons="matMenu">
                    <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>Redial</span>
                    </button>
                    <button mat-menu-item disabled>
                        <mat-icon>voicemail</mat-icon>
                        <span>Check voice mail</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>notifications_off</mat-icon>
                        <span>Disable alerts</span>
                    </button>
                </mat-menu>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Nested Menu
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button mat-stroked-button [matMenuTriggerFor]="animals">
                    Animal Index
                </button>
                <mat-menu #animals="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="vertebrates">Vertebrates</button>
                    <button mat-menu-item [matMenuTriggerFor]="invertebrates">Invertebrates</button>
                </mat-menu>
                <mat-menu #vertebrates="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="fish">Fishes</button>
                    <button mat-menu-item [matMenuTriggerFor]="amphibians">Amphibians</button>
                    <button mat-menu-item [matMenuTriggerFor]="reptiles">Reptiles</button>
                    <button mat-menu-item>Birds</button>
                    <button mat-menu-item>Mammals</button>
                </mat-menu>
                <mat-menu #invertebrates="matMenu">
                    <button mat-menu-item>Insects</button>
                    <button mat-menu-item>Molluscs</button>
                    <button mat-menu-item>Crustaceans</button>
                    <button mat-menu-item>Corals</button>
                    <button mat-menu-item>Arachnids</button>
                    <button mat-menu-item>Velvet worms</button>
                    <button mat-menu-item>Horseshoe crabs</button>
                </mat-menu>
                <mat-menu #fish="matMenu">
                    <button mat-menu-item>Baikal oilfish</button>
                    <button mat-menu-item>Bala shark</button>
                    <button mat-menu-item>Ballan wrasse</button>
                    <button mat-menu-item>Bamboo shark</button>
                    <button mat-menu-item>Banded killifish</button>
                </mat-menu>
                <mat-menu #amphibians="matMenu">
                    <button mat-menu-item>Sonoran desert toad</button>
                    <button mat-menu-item>Western toad</button>
                    <button mat-menu-item>Arroyo toad</button>
                    <button mat-menu-item>Yosemite toad</button>
                </mat-menu>
                <mat-menu #reptiles="matMenu">
                    <button mat-menu-item>Banded Day Gecko</button>
                    <button mat-menu-item>Banded Gila Monster</button>
                    <button mat-menu-item>Black Tree Monitor</button>
                    <button mat-menu-item>Blue Spiny Lizard</button>
                    <button mat-menu-item disabled>Velociraptor</button>
                </mat-menu>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header class="mb-0">
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Year Menu
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                        Year 2023
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            This Day
                        </button>
                        <button mat-menu-item>
                            This Week
                        </button>
                        <button mat-menu-item>
                            This Month
                        </button>
                        <button mat-menu-item>
                            This Year
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header class="mb-0">
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Action Menu
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Last Day
                        </button>
                        <button mat-menu-item>
                            Last Week
                        </button>
                        <button mat-menu-item>
                            Last Month
                        </button>
                        <button mat-menu-item>
                            Last Year
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
        </mat-card>
    </div>
    <div class="col-md-12 col-xxl-12">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Menu Positioning
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>

                <!-- Above -->
                <button mat-button [matMenuTriggerFor]="aboveMenu">
                    Above
                </button>
                <mat-menu #aboveMenu="matMenu" yPosition="above">
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item>Item 2</button>
                </mat-menu>

                <!-- Below -->
                <button mat-button [matMenuTriggerFor]="belowMenu">
                    Below
                </button>
                <mat-menu #belowMenu="matMenu" yPosition="below">
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item>Item 2</button>
                </mat-menu>

                <!-- Before -->
                <button mat-button [matMenuTriggerFor]="beforeMenu">
                    Before
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item>Item 2</button>
                </mat-menu>

                <!-- After -->
                <button mat-button [matMenuTriggerFor]="afterMenu">
                    After
                </button>
                <mat-menu #afterMenu="matMenu" xPosition="after">
                    <button mat-menu-item>Item 1</button>
                    <button mat-menu-item>Item 2</button>
                </mat-menu>
                
            </mat-card-content>
        </mat-card>
    </div>
</div>