<!-- Course Details -->
<mat-card
    class="trinta-card course-details-card mb-25 bg-dark border-none d-block"
>
    <mat-card-content>
        <div class="top d-md-flex justify-content-between">
            <h3 class="mb-0 text-white">
                Course Details
            </h3>
            <div class="ratings">
                <p class="text-white">Total <span class="fw-semibold">25k</span> Students Take This Course</p>
                <div class="stars text-body lh-1 d-flex align-items-center">
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-line"></i>
                    <span>
                        (3250)
                    </span>
                </div>
            </div>
        </div>
        <div class="row align-items-end">
            <div class="col-lg-7 col-xxxl-8">
                <div class="content">
                    <div class="d-flex align-items-center">
                        <img src="assets/images/courses/lesson-preview.jpg" alt="icon">
                        <h5 class="mb-0 text-white">
                            Programming Language
                        </h5>
                    </div>
                    <p class="fs-15">
                        Node.js for Beginners: Alias animi labque, deserunt distinctio eum excepturi fuga iure labore magni molestias mollitia natus, officia pofro quis sunt temporibus veritatis voluptatem, voluptatum. 
                    </p>
                    <button mat-button type="button">
                        <i class="ri-play-line"></i>
                        Watch Promo
                    </button>
                </div>
            </div>
            <div class="col-lg-5 col-xxxl-4">
                <div class="info">
                    <div class="item d-inline-block">
                        <span class="d-block text-white fw-medium heading-font-family">
                            Course For
                        </span>
                        <span class="d-block text-body">
                            Beginner
                        </span>
                    </div>
                    <div class="item d-inline-block">
                        <span class="d-block text-white fw-medium heading-font-family">
                            Instructor
                        </span>
                        <span class="d-block text-body">
                            Jason Andrews
                        </span>
                    </div>
                    <div class="item d-inline-block">
                        <span class="d-block text-white fw-medium heading-font-family">
                            Course Duration
                        </span>
                        <span class="d-block text-body">
                            06:50:10
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Tables Of Content -->
<app-tables-of-content />

<div class="row">
    <div class="col-xxxl-8">

        <!-- About Course -->
        <app-about-course />

    </div>
    <div class="col-xxxl-4">
        
        <!-- Attachments -->
        <app-attachments />

    </div>
</div>

<!-- Reviews -->
<app-reviews />