<div class="row">
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller1.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Comfort Furniture
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Liam Anderson
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $17,095
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            4092
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller2.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Mystic Threads Emporium
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Caleb Foster
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $22,014
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            5011
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller3.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Quantum Quilts & Co.
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Isabella Nguyen
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $48,256
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            7321
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller4.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Velvet Visions Boutique
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Dallin Schowalter
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $12,096
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            1428
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller5.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Paws & Whiskers Haven
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Elijah Thompson
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $34,088
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            7802
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller6.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Serene Soaps & Scents
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Sophia Ramirez
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $25,126
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            6045
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller7.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Pixel Paradise Electronics
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Xavier Rodriguez
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $11,320
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            9204
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-4 col-xxxl-3 col-sm-6">
        <mat-card
            class="trinta-card seller-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title></mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            Edit
                        </button>
                        <button mat-menu-item>
                            Delete
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="text-center">
                <img src="assets/images/sellers/seller8.png" alt="seller-image">
                <h3>
                    <a routerLink="/ecommerce-page/seller-details">
                        Cosmic Cupcakes Corner
                    </a>
                </h3>
                <span class="user text-body d-block">
                    Olivia Bennett
                </span>
                <a href="tel:+0345678900" class="number">
                    +03 4567 8900
                </a>
                <div class="info d-flex align-items-center justify-content-between">
                    <div class="text-start">
                        <h4 class="fw-medium">
                            $35,047
                        </h4>
                        <span class="title d-block text-gray">
                            Revenue
                        </span>
                    </div>
                    <div class="text-start">
                        <h4 class="fw-medium">
                            12,205
                        </h4>
                        <span class="title d-block text-gray">
                            Products
                        </span>
                    </div>
                    <div>
                        <a routerLink="/ecommerce-page/seller-details" mat-button>
                            View Details
                        </a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>