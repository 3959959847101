import { Component } from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {Location, NgForOf} from '@angular/common';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {MatPaginator} from "@angular/material/paginator";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {UpcomingridesComponent} from "../upcomingrides/upcomingrides.component";
import {UpcomingrideshistoryComponent} from "../upcomingrideshistory/upcomingrideshistory.component";
import {RouteInfoComponent} from "./route-info/route-info.component";
import {RouteHistoryComponent} from "./route-history/route-history.component";

@Component({
  selector: 'app-route-details',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatAccordion,
    MatCard,
    MatCardContent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatMenu,
    MatMenuItem,
    MatPaginator,
    NgForOf,
    MatCardHeader,
    MatTabGroup,
    MatTab,
    UpcomingridesComponent,
    UpcomingrideshistoryComponent,
    RouteInfoComponent,
    RouteHistoryComponent
  ],
  templateUrl: './route-details.component.html',
  styleUrl: './route-details.component.scss'
})
export class RouteDetailsComponent {
  constructor(private location : Location) {

  }

  goBack(){
    this.location.back();
  }

}
