<mat-card class="trinta-card mb-25 bg-white border-none d-block">
       <mat-card-content>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Dashboard">Dashboard</mat-tab>
            <mat-tab label="Profile">Profile</mat-tab>
            <mat-tab label="Members">Members</mat-tab>
            <mat-tab label="Activities">Activities</mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>


<div class="row">
    <div class="col-xxl-4 col-md-12">

        <!-- Top Selling Products -->
        <!-- <app-top-selling-products /> -->

    </div>
    <div class="col-xxl-8 col-md-12">

        <!-- Revenue Overview -->
        <!-- <app-revenue-overview /> -->

    </div>
</div>