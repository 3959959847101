<mat-card class=" mb-25 bg-white border-none border-radius d-block m-0 shadow-light is-focusable" role="button" >
  <mat-card-content class="m-0">
    <div class="route-image border-none" style="background-image: url(assets/images/cards/card4.jpg);">
      <span class="badge text-bg-light position-absolute">9 miles</span>
    </div>
    <div class="mt-15 text-start" style="padding-inline:1rem">
      <h6 class="mb-10">Psycho Morning</h6>
      <div class="d-flex">
        <i class="material-icons">place</i>
        <p class="text-dark">Campton Hills</p>
      </div>

    </div>
  </mat-card-content>
</mat-card>
