<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Chips
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Chips
        </li>
    </ol>
</div>

<!-- Chips -->
<div class="row">
    <div class="col-md-6 col-xxl-4">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Chips
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-chip-listbox aria-label="Fish selection">
                    <mat-chip-option>One fish</mat-chip-option>
                    <mat-chip-option selected>Two fish</mat-chip-option>
                    <mat-chip-option color="accent">Accent fish</mat-chip-option>
                    <mat-chip-option color="warn">Warn fish</mat-chip-option>
                </mat-chip-listbox>
            </mat-card-content>
        </mat-card>
        
        <!-- Chips Drag & Drop -->
        <app-chips-drag-drop></app-chips-drag-drop>
        
        <!-- Stacked Chips -->
        <app-stacked-chips></app-stacked-chips>

    </div>
    <div class="col-md-6 col-xxl-4">
        
        <!-- Chips Autocomplete -->
        <app-chips-autocomplete></app-chips-autocomplete>
        
        <!-- Chips with Form Control -->
        <app-chips-with-form-control></app-chips-with-form-control>

    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Avatar
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-chip-set aria-label="Dog selection">
                    <mat-chip>
                        <img matChipAvatar src="assets/images/users/user1.jpg" class="rounded-circle" alt="Photo of a User"/>
                        Mateo Luca
                    </mat-chip>
                    <mat-chip color="primary">
                        <img matChipAvatar src="assets/images/users/user2.jpg" alt="Photo of a User"/>
                        Alina Smith
                    </mat-chip>
                    <mat-chip color="accent">
                        <img matChipAvatar src="assets/images/users/user3.jpg" alt="Photo of a User"/>
                        James Andy
                    </mat-chip>
                </mat-chip-set>
            </mat-card-content>
        </mat-card>
        
        <!-- Chips with Input -->
        <app-chips-with-input></app-chips-with-input>

    </div>
</div>