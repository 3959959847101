import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { TransactionsHistoryComponent } from '../transactions-history/transactions-history.component';
import { MembersComponent } from '../members/members.component';
import { InvitesComponent } from '../invites/invites.component';
import { LeaderboardComponent } from '../leaderboard/leaderboard.component';
import { WaitlistComponent } from '../waitlist/waitlist.component';
import { GroupdetailsComponent } from '../groupdetails/groupdetails.component';
import { ScialfeedsComponent } from '../scialfeeds/scialfeeds.component';
import { GalleryComponent } from '../gallery/gallery.component';
import { ChatComponent } from '../chat/chat.component';
import { PaymentsComponent } from '../payments/payments.component';
import { MatTabGroup } from '@angular/material/tabs';
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import {  RouterLinkActive, RouterModule } from '@angular/router';
import { DraftridesComponent } from '../draftrides/draftrides.component';
import { RideRoutesComponent } from '../ride-routes/ride-routes/ride-routes.component';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-userprofile',
  standalone: true,
  imports: [ChatComponent,
    RouterLink,
    RouterModule,
    RouterLinkActive,
    MatTabsModule,
    MatMenuTrigger,
    MatMenu,
    MatButtonModule,
    MatMenu,
    MatMenuItem,DraftridesComponent,
    PaymentsComponent,MatMenuTrigger,RideRoutesComponent,GalleryComponent, ScialfeedsComponent, WaitlistComponent, GroupdetailsComponent, MatCardModule, MatButtonModule, MatMenuModule, MembersComponent, MatTabsModule, TransactionsHistoryComponent, InvitesComponent, LeaderboardComponent],
  templateUrl: './userprofile.component.html',
  styleUrl: './userprofile.component.scss',
  providers: [DatePipe]

})


export class UserprofileComponent implements OnInit {
  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  selectedTabIndex: number = 0;
  activeTabIndex: number; // Declare the variable


  constructor(private router: Router, private route: ActivatedRoute) {
    this.activeTabIndex = 0; // Initialize with a default value

   }

   onTabChanges(event: any): void {
    const tabIndex = event.index;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabIndex },
      queryParamsHandling: 'merge',
    });
  }

  ngOnInit(): void {
    this.loadSelectedTabIndex();
    this.route.queryParams.subscribe(params => {
      const tabIndex = params['tab'];
      this.activeTabIndex = tabIndex ? +tabIndex : 0; // Default to the first tab if no query param exists
    });
  }

  loadSelectedTabIndex(): void {
    const storedTabIndex = localStorage.getItem('selectedTabIndex');
    if (storedTabIndex !== null) {
      this.selectedTabIndex = parseInt(storedTabIndex, 10);
    }
  }

  onTabChange(index: number): void {
    this.selectedTabIndex = index;
    localStorage.setItem('selectedTabIndex', index.toString());
  }


}
