import {Component, OnInit,Inject} from '@angular/core';
import {FeatherModule} from "angular-feather";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {data} from "../../../charts/apexcharts/datetime-area-chart/series-data";
import {DialogData} from "../../../ui-elements/dialog/basic-dialog/basic-dialog.component";
import {Router, RouterLink} from "@angular/router";
import {BasicFormComponent} from "../../basic-elements/basic-form/basic-form.component";
import {MatOption} from "@angular/material/autocomplete";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSelect} from "@angular/material/select";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {SimpleFileUploaderComponent} from "../../file-uploader/simple-file-uploader/simple-file-uploader.component";
import {MatIcon} from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { provideNativeDateAdapter } from '@angular/material/core';
import { DateAdapter } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormControl } from '@angular/forms';
import { MatSelectSearchModule } from 'mat-select-search';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService, ToastType } from '../../../../app/toast.service'; // adjust the path accordingly
import { MatDialog } from '@angular/material/dialog';
import { EventcloseComponent } from '../eventclose/eventclose.component';
import { TimezoneService } from '../../../timezone'; // Adjust the path as needed



@Component({
  selector: 'app-event',
  standalone: true,
    imports: [
        FormsModule,MatSelectSearchModule,
        MatButton,
        MatCard,
        MatCardContent,
        MatCardHeader,
        MatCardTitle,
        MatCheckbox,
        MatFormField,
        MatIconButton,
        MatInput,
        MatLabel,
        MatSuffix,MatSnackBarModule,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatDialogClose,
        RouterLink,
        BasicFormComponent,
        MatOption,
        MatRadioButton,
        MatRadioGroup,
        MatSelect,
        MatSlideToggle,
        ReactiveFormsModule,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatHint,
        SimpleFileUploaderComponent,
        MatIcon,
        ReactiveFormsModule,
        MatFormField,
        MatRadioGroup, FileUploadModule,
        MatRadioButton,
        MatInput,
        MatLabel,
        MatSelect,
        MatTooltip,
        HttpClientModule,
        CommonModule,
        RouterLink
    ],
  templateUrl: './event.component.html',
  providers: [provideNativeDateAdapter()],
  styleUrl: './event.component.scss'
})
export class EventComponent implements OnInit {

  eventTypes: any[] = [];
  logoBase64: string | null = null;
  displayDate: string | null = null;
  displayEndDate: string | null = null;
  minDate: Date; // Add this property to set the minimum date
  members: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  genders: any[] = [];
  isSaving: boolean = false;
  ispOSTSaving: boolean = false;
  userTimeZone: string;
  dateError: string | null = null;









  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    public dialogRef: MatDialogRef<EventComponent>,
    private dateAdapter: DateAdapter<Date>,
    private toastService: ToastService,
    private dialog: MatDialog,
    private timezoneService: TimezoneService

  ) {
    this.minDate = new Date(); // Set minDate to today's date

    this.createEventForm= this.fb.group({
      title: [ '',[Validators.required]],
      description: ['',[Validators.required]], // Assuming optional
      amount: ['',[Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]{1,4})?$')]],
      url: [''],
      longitude: [''] ,
      latitude: [''], // Assuming optional
      location: ['',[Validators.required]],
      image: [''] ,
      date: [[Validators.required]],
      isRecurrent: [], // Assuming optional
      organizer: ['',[Validators.required]],
      time: [[Validators.required]],
      groupSize: [[Validators.required, Validators.min(0)]],
      address: [''],
      endDate: ['', [Validators.required]],
      stateId: ['', Validators.required],
      cityId: ['', Validators.required],
    }, { validator: this.dateRangeValidator });
    // this.dateAdapter.setLocale('en-US'); // Set the locale to en-US to ensure the date format

    this.createEventForm.get('date')?.valueChanges.subscribe(() => {
      this.validateDates();
    });
    this.createEventForm.get('endDate')?.valueChanges.subscribe(() => {
      this.validateDates();
    });
  }

  onGroupChange(event: any) {
    // Handle group selection change
    console.log('Selected Group ID:', event.value);
  }

  // Custom Validator
  validateDates() {
    const startDate = this.createEventForm.get('date')?.value;
    const endDate = this.createEventForm.get('endDate')?.value;

    if (startDate && endDate && startDate > endDate) {
      // Display error message and clear end date
      this.dateError = 'End date cannot be earlier than the start date.';
      this.createEventForm.get('endDate')?.setValue(null);
    } else {
      this.dateError = null;
    }
  }
  

  dateRangeValidator(formGroup: FormGroup) {
    const startDate = formGroup.get('date')?.value;
    const endDate = formGroup.get('endDate')?.value;
    return startDate && endDate && startDate > endDate ? { dateRangeInvalid: true } : null;
  }

  // Password Hide
  hide = true;
  createEventForm: FormGroup;


  onDateChange(event: any): void {
    const date = event.value;
    // if (date) {
    //   const formattedDate = this.formatDate(date);
    //   this.displayDate = formattedDate;
    //   this.createEventForm.get('date')?.setValue(date);
    // }
  }

  showSuccess(message: string) {
    this.toastService.showToast(message, ToastType.Success);
  }
  

  showError() {
    this.toastService.showToast('An error occurred!', ToastType.Error);
  }

  showWarning() {
    this.toastService.showToast('This is a warning message.', ToastType.Warning);
  }

  
  saveDraftEvents(){
    // this.showSuccess();
    
    
    if (this.createEventForm.valid) {
      console.log(this.createEventForm.value);
      this.isSaving = true;

      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");

    
     

      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }
      const formattedDate = this.formatDate(this.createEventForm.get('date')?.value);

      const formattedEndDate = this.formatDate(this.createEventForm.get('endDate')?.value);

      const storedGroupId = localStorage.getItem('selectedGroupId');
      this.userTimeZone = this.timezoneService.getUserTimeZone();


      const requestBody = {
        "title": this.createEventForm.get('title')?.value,
        "description": this.createEventForm.get('description')?.value,
        "image": this.logoBase64, // You may need to handle image upload separately
        "longitude": 0,
        "latitude": 0, // Assuming this is hardcoded to true
        "location": this.createEventForm.get('location')?.value,
        "amount":  this.createEventForm.get('amount')?.value, // You may need to handle logo upload separately
        "date": formattedDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "la_Event_Type_ID": 1000000,
        "isRecurrent": false,
        "url": this.createEventForm.get('url')?.value,
        "organizer": this.createEventForm.get('organizer')?.value,
        "time": formattedDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "address": this.createEventForm.get('address')?.value,
        "endDate": formattedEndDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "groupSize": this.createEventForm.get('groupSize')?.value,
        "la_ridinggroup_id": storedGroupId,
        "cityId": this.createEventForm.get('cityId')?.value,
        "stateId": this.createEventForm.get('stateId')?.value,
        "timeZone": this.userTimeZone

      };

      var obj = console.log("userobjuserobj", userobj);
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

      this.http.post(BASE_URL + ApiEndpoints.createEvent+"?is_draft=true", requestBody, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            if (res) {
              this.showSuccess("Your draft has been saved.");
              this.isSaving = false;
  
              // window.location.reload();
              setTimeout(() => {
                window.location.reload();
                this.isSaving = false;
                // this.dialogRef.close(this.createRideForm.value);
              }, 2000);
    
            } else {
              console.error("Unexpected response:", res);
              this.showSuccess("Error, Failed");
              this.isSaving = false;
            }
           
          } else {
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            console.log("Unauthorized:", error.status);
            this.showSuccess("Unauthorized: Invalid username or password");
            this.isSaving = false;
          } else if (error.status === 500) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Internal server error");
            this.isSaving = false;
          }else if (error.status === 200) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Sorry, the group doesn't allow members to post.");
            this.isSaving = false;
          } else {
            this.showSuccess("Error creating ride failed. Please try again later.");
            this.isSaving = false;
          }
        }



       
      );

    } else {

      console.log('Form is invalid. Please check the fields.');
    }
  }

  onDateChangeEnddate(event: any): void {
    const date = event.value;
    // if (date) {
    //   const formattedEndDate = this.formatDate(date);
    //   this.displayEndDate = formattedEndDate;
    //   console.log("formattedEndDate", formattedEndDate)
    //   this.createEventForm.get('endDate')?.setValue(date);
    // }
  }
 
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  // Form

  ngOnInit(): void {
    this.loadeventTypes();
    this.listmembers();
    this.loadstates();
    this.loadCity();
    this.userTimeZone = this.timezoneService.getUserTimeZone();

    console.log("userTimeZone", this.userTimeZone)
   
  }



  loadstates() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
      (data: any) => {
        this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadCity() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
      (data: any) => {
        this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  listmembers() {
    const storedGroupId = localStorage.getItem('selectedGroupId');

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getGroupMembers+storedGroupId, { headers: headers }).subscribe(
      (data: any) => {
        console.log("My getGroupMembers", data.entity[0])
        this.members = data.entity; // Assuming data is an array of objects with properties 'value' and 'label'
      },


      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  onNoClick() {
    // EventcloseComponent
    const title = this.createEventForm.get('title')?.value;
    if (!title) {
      this.dialogRef.close();

    }else{
      this.showalertdialog();
    }
  }

  showalertdialog(): void {
    const dialogRef = this.dialog.open(EventcloseComponent, {
      restoreFocus: false,
      panelClass: 'custom-dialog',
      width: '500px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close();
        // Call your delete function here
        // this.deleteUser();
      }
    });
  }


 

  autojoinEvent(eventid: String) {
   
      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }
    var jsondata = {
      "cbPartner_id": cbPartnerId,
      "upcomingEvent_id": eventid
  
    }
    var obj =
    console.log("userobjuserobj", userobj)
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.post(BASE_URL + ApiEndpoints.joinEvent, jsondata, { headers: headers }).subscribe(
      (res: any) => {
       
      },
      (error) => {
       
      }
    );

  }

  onSubmit() {
    if (this.createEventForm.valid) {
      console.log(this.createEventForm.value);
      this.ispOSTSaving = true;

      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }

    
      const formattedDate = this.formatDate(this.createEventForm.get('date')?.value);
      const formattedEndDate = this.formatDate(this.createEventForm.get('endDate')?.value);

    this.userTimeZone = this.timezoneService.getUserTimeZone();

      const storedGroupId = localStorage.getItem('selectedGroupId');
      // Construct the login object
      const requestBody = {
        "title": this.createEventForm.get('title')?.value,
        "description": this.createEventForm.get('description')?.value,
        "image": this.logoBase64, // You may need to handle image upload separately
        "longitude": 0,
        "latitude": 0, // Assuming this is hardcoded to true
        "location": this.createEventForm.get('location')?.value,
        "amount":  this.createEventForm.get('amount')?.value, // You may need to handle logo upload separately
        "date": formattedDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "la_Event_Type_ID": this.createEventForm.get('la_Event_Type_ID')?.value,
        "isRecurrent": this.createEventForm.get('isRecurrent')?.value,
        "url": this.createEventForm.get('url')?.value,
        "organizer": this.createEventForm.get('organizer')?.value,
        "time": formattedDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "address": this.createEventForm.get('address')?.value,
        "endDate": formattedEndDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "groupSize": this.createEventForm.get('groupSize')?.value,
        "la_ridinggroup_id": storedGroupId,
        "cityId": this.createEventForm.get('cityId')?.value,
        "stateId": this.createEventForm.get('stateId')?.value,
        "timeZone": this.userTimeZone
      };

      var obj =  console.log("userobjuserobj", userobj)
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

      this.http.post(BASE_URL + ApiEndpoints.createEvent+"?is_draft=false", requestBody, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            if (res) {
              this.showSuccess("Event created successfully.");

              console.log("autojoinEventautojoinEvent", res.upcomingEventId)
              this.autojoinEvent(res.upcomingEventId);
              this.isSaving = false;
  
              // window.location.reload();
              setTimeout(() => {
                window.location.reload();
                this.isSaving = false;
                // this.dialogRef.close(this.createRideForm.value);
              }, 2000);
    
            } else {
              console.error("Unexpected response:", res);
              this.showSuccess("Error, Failed");
              this.isSaving = false;
            }
           
          } else {
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            console.log("Unauthorized:", error.status);
            this.showSuccess("Unauthorized: Invalid username or password");
            this.isSaving = false;
          } else if (error.status === 500) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Internal server error");
            this.isSaving = false;
          }else if (error.status === 200) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Sorry, the group doesn't allow members to post.");
            this.isSaving = false;
          } else {
            this.showSuccess("Error creating ride failed. Please try again later.");
          }
        }
      );

    } else {

      console.log('Form is invalid. Please check the fields.');
    }
  }



  loadeventTypes() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.eventTypes, { headers: headers }).subscribe(
      (data: any) => {
        this.eventTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  onFileSelected(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.createEventForm.patchValue({
          [controlName]: base64String
        });
        console.log(`${controlName} base64String:`, base64String);
        if (controlName === 'logo') {
          this.logoBase64 = base64String;
        } 
      };
      reader.readAsDataURL(file);
    }
  }
}


