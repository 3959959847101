<div class="table-responsive">
    <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        class="expandable-rows main-table"
    >

        @for (column of columnsToDisplay; track column) {
            <ng-container matColumnDef="{{column}}">
                <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
        }
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    @if (expandedElement === element) {
                        <mat-icon>keyboard_arrow_up</mat-icon>
                    } @else {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    }
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                >
                    <div class="example-element-diagram">
                        <div class="example-element-position"> {{element.position}} </div>
                        <div class="example-element-symbol"> {{element.symbol}} </div>
                        <div class="example-element-name"> {{element.name}} </div>
                    </div>
                    <div class="example-element-description">
                        <p class="mb-10">
                            {{element.description}}
                        </p>
                        <span class="text-black fw-medium">-- Wikipedia</span>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        
    </table>
</div>