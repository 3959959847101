import { Component } from '@angular/core';
import {FeatherModule} from "angular-feather";
import {MatCard, MatCardContent} from "@angular/material/card";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-go-pro-widget',
  standalone: true,
  imports: [
    FeatherModule,
    MatCard,
    MatCardContent,
    MatButton
  ],
  templateUrl: './go-pro-widget.component.html',
  styleUrl: './go-pro-widget.component.scss'
})
export class GoProWidgetComponent {

}
