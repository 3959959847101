import { Component } from '@angular/core';
import {MatCard, MatCardContent} from "@angular/material/card";

@Component({
  selector: 'app-engagement-stats',
  standalone: true,
    imports: [
        MatCard,
        MatCardContent
    ],
  templateUrl: './engagement-stats.component.html',
  styleUrl: './engagement-stats.component.scss'
})
export class EngagementStatsComponent {

}
