<div class="row">
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Simple File Uploader
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-simple-file-uploader></app-simple-file-uploader>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Multiple File Uploader
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-multiple-file-uploader></app-multiple-file-uploader>
            </mat-card-content>
        </mat-card>
    </div>
</div>