<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Snackbar
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Snackbar
        </li>
    </ol>
</div>

<!-- Snackbar -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Snackbar
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-basic-snackbar></app-basic-snackbar>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Snackbar with an Annotated Custom Component
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-sbwa-annotated-custom-component></app-sbwa-annotated-custom-component>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Snackbar with a Custom Component
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-sbwa-custom-component></app-sbwa-custom-component>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Snackbar with Configurable Position
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-sbw-configurable-position></app-sbw-configurable-position>
            </mat-card-content>
        </mat-card>
    </div>
</div>