import { Component } from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatButton} from "@angular/material/button";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {MatPaginator} from "@angular/material/paginator";
import {NgForOf} from "@angular/common";
import {MatTab} from "@angular/material/tabs";

@Component({
  selector: 'app-route-info',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatIcon,
    MatAccordion,
    MatButton,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatMenu,
    MatMenuItem,
    MatPaginator,
    NgForOf,
    MatCardHeader,
    MatTab
  ],
  templateUrl: './route-info.component.html',
  styleUrl: './route-info.component.scss'
})
export class RouteInfoComponent {

}
