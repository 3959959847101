<div class="row container dashboarditems">
  <div class="col-xxxl-9 col-xxl-10 col-md-12">
    <div class="row">
      <app-group-overview-stats></app-group-overview-stats>
    </div>



    <!-- <app-group-activity-charts></app-group-activity-charts> -->
    <div class="row">
      <h2  class="fw-bold fs-16"> Engagement</h2>
      
      <div class="col-xxxl-9 col-xxl-10 col-md-9">
        <app-engagement-stats></app-engagement-stats>
        <!-- <app-payment-stats></app-payment-stats> -->
      </div>
      <div class="col-xxxl-3 col-xxl-2 col-md-3 pro-card-section">
        <app-go-pro-widget></app-go-pro-widget>
      </div>
    </div>
  </div>
  <div class="col-xxxl-3 col-xxl-2 col-md-12 mt-10">
    <app-ridcalendar></app-ridcalendar>
    <!-- <app-latestpost></app-latestpost> -->
  </div>
</div>