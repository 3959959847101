<div class="memberconfirm">
    <div class="row title text-center">
      <h2 mat-dialog-title class="fw-semibold">Cancel Ride</h2>
      <button mat-mini-fab (click)="onNoClick()" class="cancel" style="background-color: #ffffff;">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
          <circle cx="24" cy="24" r="23" stroke="black" stroke-width="2" fill="none" />
          <line x1="16" y1="16" x2="32" y2="32" stroke="black" stroke-width="3" />
          <line x1="32" y1="16" x2="16" y2="32" stroke="black" stroke-width="3" />
        </svg>
        
      </button>
    </div>
    
    
    <mat-dialog-content>Canceling this ride will remove it from the app and from registered users' feeds. Are you sure you want to cancel it?</mat-dialog-content>
    <mat-dialog-actions class="actionbtns">
      <button mat-button (click)="onConfirm()" style="background-color: var(--accentColor);
      width: 200px;
      color: #ffffff !important; font-weight: 600;">YES</button>
     
    </mat-dialog-actions>
  </div>
  
  