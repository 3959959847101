<mat-card
    class="trinta-card order-summary-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Order Summary
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="p-0 m-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
                <span class="d-block fw-medium">
                    Description
                </span>
                <span class="d-block fw-medium">
                    Price
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <span class="text-black d-block">
                    Grand Total :
                </span>
                <span class="text-black d-block">
                    $1641
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <span class="text-black d-block">
                    Shipping Charge :
                </span>
                <span class="text-black d-block">
                    $120
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <span class="text-black d-block">
                    Discount :
                </span>
                <span class="text-black d-block">
                    $40
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <span class="text-black d-block">
                    Estimated Tax :
                </span>
                <span class="text-black d-block">
                    $250
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <span class="d-block fw-medium">
                    Total :
                </span>
                <span class="d-block fw-medium">
                    $2405.00
                </span>
            </li>
        </ul>
    </mat-card-content>
</mat-card>