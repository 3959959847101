<mat-card
    class="trinta-card recent-orders-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Waitlist
            </h5>
        </mat-card-title>
       
    </mat-card-header>
    <mat-card-content>
        <div class="recent-orders-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- user Column -->
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef>

                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/" class="image d-block">
                                    <img [src]="element.user.img" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="d-block fw-semibold">
                                        {{element.user.title}}
                                    </a>
                                  
                                </div>
                            </div>
                        </td>
                    </ng-container>

                  
                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">

                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <span class="trinta-badge fw-medium pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>