<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        {{node.item}}
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle>
        <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
        </button>
        {{node.item}}
        @if (node.isLoading) {
        <mat-progress-bar
            mode="indeterminate"
            class="example-tree-progress-bar"></mat-progress-bar>
        }
    </mat-tree-node>
</mat-tree>