<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Dashed Line Chart
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <apx-chart
            [series]="chartOptions.series!"
            [chart]="chartOptions.chart!"
            [xaxis]="chartOptions.xaxis!"
            [stroke]="chartOptions.stroke!"
            [tooltip]="chartOptions.tooltip!"
            [dataLabels]="chartOptions.dataLabels!"
            [legend]="chartOptions.legend!"
            [markers]="chartOptions.markers!"
            [colors]="chartOptions.colors!"
            [grid]="chartOptions.grid!"
            [yaxis]="chartOptions.yaxis!"
            [title]="chartOptions.title!"
        ></apx-chart>
    </mat-card-content>
</mat-card>