<mat-card
    class="trinta-card friends-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Friends
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="p-0 m-0 list-unstyled">
            <li class="d-flex align-items-center">
                <img src="assets/images/users/user6.jpg" alt="user-image">
                <div>
                    <h6>
                        Alexander Johnson
                    </h6>
                    <span class="d-block text-body">
                        johnson&#64;trinta.com
                    </span>
                </div>
            </li>
            <li class="d-flex align-items-center">
                <img src="assets/images/users/user8.jpg" alt="user-image">
                <div>
                    <h6>
                        Olivia Bennett
                    </h6>
                    <span class="d-block text-body">
                        bennett&#64;trinta.com
                    </span>
                </div>
            </li>
            <li class="d-flex align-items-center">
                <img src="assets/images/users/user4.jpg" alt="user-image">
                <div>
                    <h6>
                        Mason Williams
                    </h6>
                    <span class="d-block text-body">
                        williams&#64;trinta.com
                    </span>
                </div>
            </li>
            <li class="d-flex align-items-center">
                <img src="assets/images/users/user7.jpg" alt="user-image">
                <div>
                    <h6>
                        Benjamin Clark
                    </h6>
                    <span class="d-block text-body">
                        benjamin&#64;trinta.com
                    </span>
                </div>
            </li>
            <li class="d-flex align-items-center">
                <img src="assets/images/users/user5.jpg" alt="user-image">
                <div>
                    <h6>
                        Sophia Chen
                    </h6>
                    <span class="d-block text-body">
                        sophia&#64;trinta.com
                    </span>
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>