<div class="row">
    <div class="col-xxxl-4 col-sm-6 col-md-4">
      <mat-card  
        class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center" >
        <mat-card-content>
          <div class = "card-title">
            
            <div class="icon d-flex align-items-center justify-content-center ">
              <div class="flex justify-start items-center flex-row gap-2.5 p-[5px] bg-[#1F499F] rounded-[7px]"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="8" r="3" stroke="white" stroke-width="2" stroke-linecap="round"/>
                <path d="M15.2679 8C15.5332 7.54063 15.97 7.20543 16.4824 7.06815C16.9947 6.93086 17.5406 7.00273 18 7.26795C18.4594 7.53317 18.7946 7.97 18.9319 8.48236C19.0691 8.99472 18.9973 9.54063 18.7321 10C18.4668 10.4594 18.03 10.7946 17.5176 10.9319C17.0053 11.0691 16.4594 10.9973 16 10.7321C15.5406 10.4668 15.2054 10.03 15.0681 9.51764C14.9309 9.00528 15.0027 8.45937 15.2679 8L15.2679 8Z" stroke="white" stroke-width="2"/>
                <path d="M5.26795 8C5.53317 7.54063 5.97 7.20543 6.48236 7.06815C6.99472 6.93086 7.54063 7.00273 8 7.26795C8.45937 7.53317 8.79457 7.97 8.93185 8.48236C9.06914 8.99472 8.99727 9.54063 8.73205 10C8.46683 10.4594 8.03 10.7946 7.51764 10.9319C7.00528 11.0691 6.45937 10.9973 6 10.7321C5.54063 10.4668 5.20543 10.03 5.06815 9.51764C4.93086 9.00528 5.00273 8.45937 5.26795 8L5.26795 8Z" stroke="white" stroke-width="2"/>
                <path d="M16.8821 18L15.9017 18.1974L16.0634 19H16.8821V18ZM20.7206 16.9042L21.6631 16.5699L20.7206 16.9042ZM14.7812 14.7105L14.1764 13.9142L13.0198 14.7927L14.2531 15.5597L14.7812 14.7105ZM19.8676 17H16.8821V19H19.8676V17ZM19.7782 17.2384C19.7711 17.2186 19.7646 17.181 19.773 17.1354C19.7808 17.0921 19.7987 17.0593 19.8156 17.0383C19.8478 16.9982 19.8744 17 19.8676 17V19C21.0137 19 22.1418 17.9194 21.6631 16.5699L19.7782 17.2384ZM17.0004 15C18.642 15 19.4031 16.1811 19.7782 17.2384L21.6631 16.5699C21.1981 15.2588 19.949 13 17.0004 13V15ZM15.3861 15.5069C15.7706 15.2148 16.2824 15 17.0004 15V13C15.8385 13 14.9032 13.3622 14.1764 13.9142L15.3861 15.5069ZM14.2531 15.5597C15.2923 16.206 15.7275 17.3324 15.9017 18.1974L17.8624 17.8026C17.6444 16.7204 17.0378 14.9364 15.3094 13.8614L14.2531 15.5597Z" fill="white"/>
                <path d="M9.21826 14.7105L9.74639 15.5597L10.9797 14.7927L9.82312 13.9142L9.21826 14.7105ZM3.27888 16.9041L4.22135 17.2384V17.2384L3.27888 16.9041ZM7.11744 18V19H7.93612L8.09775 18.1974L7.11744 18ZM6.9991 15C7.71712 15 8.22894 15.2148 8.61341 15.5069L9.82312 13.9142C9.09631 13.3621 8.16098 13 6.9991 13V15ZM4.22135 17.2384C4.5964 16.1811 5.35751 15 6.9991 15V13C4.05059 13 2.80147 15.2587 2.33642 16.5699L4.22135 17.2384ZM4.13187 17C4.12509 17 4.15172 16.9982 4.18394 17.0383C4.20084 17.0593 4.21869 17.0921 4.22657 17.1354C4.23488 17.181 4.2284 17.2186 4.22135 17.2384L2.33642 16.5699C1.85772 17.9194 2.98585 19 4.13187 19V17ZM7.11744 17H4.13187V19H7.11744V17ZM8.09775 18.1974C8.27197 17.3324 8.70723 16.206 9.74639 15.5597L8.69014 13.8614C6.96165 14.9363 6.35508 16.7203 6.13712 17.8026L8.09775 18.1974Z" fill="white"/>
                <path d="M12 14C15.5715 14 16.5919 16.5512 16.8834 18.0089C16.9917 18.5504 16.5523 19 16 19H8C7.44772 19 7.00829 18.5504 7.11659 18.0089C7.4081 16.5512 8.42846 14 12 14Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>
                </div>

            </div>

            <div class="tilesheader">
              <span class="tilestitle">
                Members
            </span>
            <p style="color: #fff;">All</p>
            </div>
            <!-- <span class="d-block fw-500" style="padding-left: 16px;">Members</span> -->
  
          </div>
          <h5  class="tiletotalcounts">
            {{totalGroupMembers}}
          </h5>
  
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-xxxl-4 col-sm-6 col-md-4">

      <mat-card  
      class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center" >
      <mat-card-content>
        <div class = "card-title">
          <div class="icon d-flex align-items-center justify-content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="8" r="4" stroke="white" stroke-width="2" stroke-linecap="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3258 15.0759C12.8878 15.0255 12.4444 15 11.9992 15C10.0797 15 8.19298 15.4738 6.63028 16.3732C5.06816 17.2721 3.88038 18.5702 3.33005 20.1106C3.14424 20.6307 3.41523 21.203 3.93532 21.3888C4.45541 21.5746 5.02765 21.3036 5.21346 20.7835C5.57473 19.7723 6.39568 18.8157 7.62787 18.1066C8.64187 17.523 9.8629 17.1503 11.1571 17.0368C11.488 16.0601 12.3082 15.3092 13.3258 15.0759Z" fill="white"/>
              <path d="M18 14L18 22" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
              <path d="M22 18L14 18" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
              </svg>
              
          </div>

          <div class="tilesheader">
            <span class="tilestitle">
              New Members
          </span>
          <p style="color: #fff;">All</p>

          </div>
        </div>
        <h5 class="tiletotalcounts">
          {{newMembersToday}}
        </h5>

      </mat-card-content>
    </mat-card>
    </div>
    <div class="col-xxxl-4 col-sm-6 col-md-4">

      <mat-card class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center">
        <mat-card-content>
          <div class = "card-title">
            
            <div class="icon d-flex align-items-center justify-content-center">
              <div class="bg-[#5E4389] rounded-[7px] w-[34px] h-[34px]" style="width: 34px"><svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3359 15.0044C22.9118 15.0044 25 12.9162 25 10.3403C25 7.76444 22.9118 5.67627 20.3359 5.67627C17.76 5.67627 15.6719 7.76444 15.6719 10.3403C15.6719 12.9162 17.76 15.0044 20.3359 15.0044Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.66405 15.0044C8.23994 15.0044 10.3281 12.9162 10.3281 10.3403C10.3281 7.76444 8.23994 5.67627 5.66405 5.67627C3.08817 5.67627 1 7.76444 1 10.3403C1 12.9162 3.08817 15.0044 5.66405 15.0044Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.0726 3.20557L5.66406 10.3406H12.9479L17.344 3.20557H10.0726Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.11914 1.14648L12.9471 10.3406" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.1795 10.0823L16.5723 1.10156H19.4675C19.6138 1.10156 19.7552 1.15118 19.8644 1.24793C20.1398 1.49106 20.3804 2.29487 19.4377 2.52311" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.16016 1H11.0353" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </div>
            </div>

         
            <div class="tilesheader">
              <span class="tilestitle">
                Active Members
            </span>
            <p style="color: #fff;">All</p>
            </div>

          </div>
          <h5 class="tiletotalcounts">
            <!-- {{percentageActive}}% -->
            {{ formatPercentage(percentageActive) }}

          </h5>
  
        </mat-card-content>
      </mat-card>

     
    </div>
  </div>
<p class="ridestitle">Members</p>
<mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">

    <mat-card-content>
        <div class="recent-orders-table">

            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource.data" matSort>
                    <ng-container matColumnDef="profilePhoto">
                        <th  mat-header-cell *matHeaderCellDef style="width: 60px;">
                            
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a  class="image d-block">
                                    <img *ngIf="element.profilePhoto; else defaultImage"
                                        [src]="'data:image/png;base64,' + element.profilePhoto" class="profile-img"
                                        style="border-radius: 50%; width: 50px; height: 50px;">
                                    <ng-template #defaultImage>
                                        <img src="../../../../assets/profile.png" alt="product-image"
                                            class="profile-img" style="border-radius: 50%; width: 50px;">
                                    </ng-template>

                                </a>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name}}   {{element.otherNames}}
                        </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="otherNames">
                      <th mat-header-cell *matHeaderCellDef>
                          Last Name
                      </th>
                      <td mat-cell *matCellDef="let element">
                          {{element.otherNames}}
                      </td>
                  </ng-container> -->
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>
                        Email
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                </ng-container>
                    <ng-container matColumnDef="owner">
                      <th mat-header-cell *matHeaderCellDef> Members Type </th>
                      <td mat-cell *matCellDef="let element">
                        <!-- <p style="font-size: 14px;"> -->
                          {{ element.owner ? 'Owner' : '' }}
                        <!-- </p> -->
                        <!-- <p style="font-size: 14px; "> -->
                          {{ element.admin ? 'Admin' : 'Member' }}
                        <!-- </p> -->
                       
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="active">
                      <th mat-header-cell *matHeaderCellDef> Status </th>
                      <td mat-cell *matCellDef="let element">

                        <!-- <p style="font-size: 14px; "> -->
                          {{ element.active ? 'Active' : 'Inactive' }}
                        <!-- </p> -->
                      </td>
                    </ng-container>

                    
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <div
                            class="flex justify-start items-start flex-col gap-2 py-[13px] px-2 bg-[#FFFFFF] border-solid border-[#A2AAAD] border rounded-[3px] shadow-[_0px_2px_4px_0px_rgba(0,0,0,0.08),0px_0px_6px_0px_rgba(0,0,0,0.02)]">
                            <div class="flex justify-start items-start flex-col gap-1">
                              <!-- Group Admin Option -->
                              <div  *ngIf="element.active" (click)="makegroupadmin(element)" style="padding-top: 6px; cursor: pointer;"
                                class="flex self-stretch justify-start items-center flex-row gap-4 px-[5px] h-[25px]">
                                <svg width="16" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.8333 7.16665C12.8333 8.73145 11.5648 9.99998 9.99996 9.99998C8.43515 9.99998 7.16663 8.73145 7.16663 7.16665C7.16663 5.60184 8.43515 4.33331 9.99996 4.33331C11.5648 4.33331 12.8333 5.60184 12.8333 7.16665Z" stroke="#1F2A44" stroke-linecap="round" />
                                  <path d="M3.89557 15.2476C4.35607 12.8614 6.8314 11.75 9.26167 11.75H10.7383C13.1686 11.75 15.6439 12.8614 16.1044 15.2476C16.1361 15.4116 16.1634 15.58 16.1856 15.7523C16.256 16.3001 15.8023 16.75 15.25 16.75H4.75C4.19771 16.75 3.74395 16.3001 3.81441 15.7523C3.83657 15.58 3.86392 15.4116 3.89557 15.2476Z" stroke="#1F2A44" stroke-linecap="round" />
                                </svg>
                                <span style="font-size: 14px; font-weight: 500; padding-left: 6px;">Group Admin</span>
                              </div>
                    
                              <!-- Remove Option (shown only if element is active) -->
                              <div *ngIf="element.active" (click)="onDelete(element)" style="padding-top: 8px; cursor: pointer;"
                                class="flex self-stretch justify-start items-center flex-row gap-4 px-[5px] h-[25px]">
                                <svg width="16" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect y="0.5" width="20" height="20" rx="3" fill="white" />
                                  <circle cx="10" cy="10.5" r="7.5" stroke="#F14E50" />
                                  <path d="M6.25 10.5H13.75" stroke="#F14E50" />
                                </svg>
                                <span style="font-size: 14px; font-weight: 500; padding-left: 6px;">Remove</span>
                              </div>
                            </div>
                          </div>
                        </mat-menu>
                      </td>
                    </ng-container>
                    


                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="headersection"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <div class="paginator-container">
             
              

              <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
              <!-- <mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons [hidePageSize]="true"></mat-paginator> -->

            </div>
        </div>
    </mat-card-content>
</mat-card>