<div class="memberconfirm">
  <div class="modalheader" >
    <div  class="titletxt">Change Password</div>
      <img (click)="onNoClick()" class="closeicon" src="../../../../assets/clx.png">
  </div>
  

  <div class="modalbodytext">Changes to your account in the group admin site affect your account in the app as well. Please use your new password the next time you log into the LaCorsa app.</div>
  
  <div class="actionbtns">

    <div class="btnssection">
      <div class="confirmbtnssubmit" (click)="onConfirm()" > 
  
        <h2>OK</h2>
    
      </div>
    </div>
  </div>

</div>