<mat-card class="trinta-card mt-15 bg-white border-none d-block pd-inline-16" style="padding-bottom: 16px;">
  <mat-card-header class="text-center">
    <div class="d-flex justify-content-start">
      <button mat-button class="btn-back d-inline-block" (click)="goBack()">
        <mat-icon svgIcon="back" aria-hidden="false" aria-label="Back SVG icon"></mat-icon>
        Back
      </button>
    </div>


    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <button mat-button class="btn-back d-inline-block" (click)="goBack()">
          <mat-icon svgIcon="carret_left_outline" aria-hidden="false" aria-label="Carret left SVG icon"></mat-icon>

        </button>
        <button mat-button class="btn-back d-inline-block" (click)="goBack()">
          <mat-icon svgIcon="carret_right_outline" aria-hidden="false" aria-label="Carret right SVG icon"></mat-icon>
        </button>
      </div>
      <h5 class="mt-20 fw-semibold">Psycho Morning</h5>
      <button mat-button class="btn-back d-inline-block" (click)="goBack()">
        <mat-icon svgIcon="share" aria-hidden="false" aria-label="Share SVG icon"></mat-icon>
      </button>
    </div>

  </mat-card-header>
  <mat-card-content>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" class="membersectiontab" style="padding-bottom: 16px;">
      <mat-tab label="Route Details">
        <app-route-info/>
      </mat-tab>

      <mat-tab label="History">
        <app-route-history/>
      </mat-tab>

    </mat-tab-group>

  </mat-card-content>
</mat-card>



