<mat-card
    class="trinta-card shipping-information-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Shipping Information
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h6>
            Thomas Jonson
        </h6>
        <ul class="p-0 m-0 list-unstyled">
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Address :
                </span>
                3559 Roosevelt Wilson Lane San Bernardino, San Francisco, CA 94107
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Phone :
                </span>
                (123) 456-7890
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Email :
                </span>
                info&#64;contact.com
            </li>
        </ul>
    </mat-card-content>
</mat-card>