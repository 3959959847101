<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Dialog
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Dialog
        </li>
    </ol>
</div>

<!-- Dialog -->
<div class="row">
    <div class="col-md-6">

        <!-- Basic Dialog -->
        <app-basic-dialog></app-basic-dialog>

    </div>
    <div class="col-md-6">

        <!-- Dialog Animations -->
        <app-dialog-animations></app-dialog-animations>

    </div>
    <div class="col-md-6">

        <!-- Dialog with Scrollable Content -->
        <app-dialog-scrollable-content></app-dialog-scrollable-content>

    </div>
    <div class="col-md-6">

        <!-- Dialog Launched From a Menu -->
        <app-dialog-launched-from-menu></app-dialog-launched-from-menu>

    </div>
</div>