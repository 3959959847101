<mat-card
    class="trinta-card calendar-card mb-25 bg-white border-none d-block  latestridespostcalendar"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Calendars
            </h5>
        </mat-card-title>
       
    </mat-card-header>
    <mat-card-content>
        <!-- <mat-calendar [(selected)]="selected"></mat-calendar> -->
        <!-- <div class="container mt-5">
            <carousel>
              <slide>
                <div class="row">
                  <div class="col-md-6">
                    <img src="https://via.placeholder.com/400x300?text=Slide+1" alt="First slide">
                    <div class="carousel-caption">
                      <h3>First Slide</h3>
                      <p>Some description for the first slide.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <img src="https://via.placeholder.com/400x300?text=Slide+2" alt="Second slide">
                    <div class="carousel-caption">
                      <h3>Second Slide</h3>
                      <p>Some description for the second slide.</p>
                    </div>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="row">
                  <div class="col-md-6">
                    <img src="https://via.placeholder.com/400x300?text=Slide+3" alt="Third slide">
                    <div class="carousel-caption">
                      <h3>Third Slide</h3>
                      <p>Some description for the third slide.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <img src="https://via.placeholder.com/400x300?text=Slide+4" alt="Fourth slide">
                    <div class="carousel-caption">
                      <h3>Fourth Slide</h3>
                      <p>Some description for the fourth slide.</p>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div> -->
          
          
    </mat-card-content>
</mat-card>