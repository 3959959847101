import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButton, MatMiniFabButton } from "@angular/material/button";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { Router } from "@angular/router";
import { MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import {NgForOf} from "@angular/common";
import { DomSanitizer } from '@angular/platform-browser';





@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [RouterLink, MatCardModule, NgIf,
    FormsModule,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatSlideToggle,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatMiniFabButton,NgForOf,
    HttpClientModule],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss'
})
export class GalleryComponent implements OnInit {
  selectedFile: File | null = null;
  filePreview: string | ArrayBuffer | null = null;
  groupdID: string | null = null;
  photos: string | null = null;
  // photos: { photo: string }[] = [];
  photosArray: any[] = [];
  // filePreview: string | ArrayBuffer | null = null;
  dragging = false;


  constructor(
    private fb: FormBuilder,
    private http: HttpClient,

  ) {
    this.inviteMemberForm = this.fb.group({
      fileInput: [''],
    });
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.previewFile(this.selectedFile);
    }
  }

  createEventForm: FormGroup;

  onFileSelected(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;

        console.log(`${controlName} base64String:`, base64String);
        if (controlName === 'fileInput') {
          this.photos = base64String;
          this.savePhoto();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragging = true;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragging = false;
    if (event.dataTransfer?.files.length) {
      this.handleFileInput(event.dataTransfer.files[0]);
    }
  }

  onDragLeave() {
    this.dragging = false;
  }
  handleFileInput(file: File) {
    this.selectedFile = file;
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.filePreview = e.target?.result ?? null;
    };
    reader.readAsDataURL(file);
  }

  
  previewFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.filePreview = reader.result;
      }
    };
    reader.readAsDataURL(file);
  }
  inviteMemberForm: FormGroup;

  onSubmit() { }

  ngOnInit(): void {
    this.fetchData();
    // this.loadTotalgroups();
  }

  savePhoto() {
    if (this.inviteMemberForm.valid) {
      console.log(this.inviteMemberForm.value);
      const groupdId = localStorage.getItem('selectedGroupId');
      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);
          // cbPartnerId = parsedUserObj.cbPartnerId;
          const requestBody = {
            "groupID": groupdId,
            "rideID": 1000131,
            "cBPartnerID": parsedUserObj.cbPartnerId,
            "name": "N/A",
            "description": "N/A",
            "photos": [this.photos]

          };

          const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

          this.http.post(BASE_URL + ApiEndpoints.uploadPhoto, requestBody, { headers: headers }).subscribe(
            (res: any) => {
              alert("Created successfully");
              window.location.reload();
            },
            (error) => {
              if (error.status === 401) {
                // Handle 401 Unauthorized error
                console.log("Unauthorized:", error.status);
                alert("Unauthorized");
              } else if (error.status === 500) {
                // Handle 401 Unauthorized error
                console.log("Unauthorized:", error.status);
                alert("Internal server error");
              } else if (error.status === 200) {
                alert("Photo uploaded successfully");
                // window.location.reload();
                this.fetchData();

              }else {
                // Handle other errors
                console.error("Error logging in:", error);
                alert("Failed creating record. Please try again later.");
              }
            }

          );

        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }
    } else {
      console.log('Form is invalid. Please check the fields.');
    }
  }

  fetchData() {
    this.groupdID = localStorage.getItem('selectedGroupId');

    // Make HTTP GET request to fetch rides data
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get<any>(BASE_URL + ApiEndpoints.getGalleryByGroup+this.groupdID, { headers: headers }).subscribe(
      (data) => {
        console.log('Photos data:', data);
        this.photosArray = data;

      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }
  
}


