<div class="row" >
  <h2 class="fw-bold fs-16">
    Activity
  </h2>
  <div class="col-xxxl-6 col-xxl-6 col-md-6" >
    <app-avg-speed-chart/>
  </div>
  <div class="col-xxxl-6 col-xxl-6 col-md-6">
    <app-elevation-gain-chart/>

  </div>
</div>
