<div class="header-area bg-white transition" [ngClass]="{'active': isToggled}">
    <div class="d-md-flex align-items-center justify-content-between" style="margin-bottom: 8px;">

        <div class="header-left-side d-flex align-items-center">

            <div class="lacorsaLogo">
                <img src="assets/headerlogo.png">
            </div>



        </div>

        <ul class="header-right-side d-flex align-items-center m-0 p-0 list-unstyled">
            <li>
                <!-- <form class="search-box position-relative">
                    <input type="text" class="input-search w-100 d-block border-none" placeholder="Search here.." style="height: 52px;">
                    <button type="submit" class="bg-transparent p-0 border-none transition">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6667 23.9999C19.8214 23.9999 24 19.8212 24 14.6666C24 9.51193 19.8214 5.33325 14.6667 5.33325C9.51205 5.33325 5.33337 9.51193 5.33337 14.6666C5.33337 19.8212 9.51205 23.9999 14.6667 23.9999ZM14.6667 8.33325C13.835 8.33325 13.0114 8.49707 12.243 8.81535C11.4746 9.13363 10.7765 9.60014 10.1884 10.1882C9.60026 10.7763 9.13375 11.4745 8.81547 12.2429C8.49719 13.0113 8.33337 13.8349 8.33337 14.6666C8.33337 15.2189 8.78109 15.6666 9.33337 15.6666C9.88566 15.6666 10.3334 15.2189 10.3334 14.6666C10.3334 14.0975 10.4455 13.534 10.6632 13.0083C10.881 12.4825 11.2002 12.0048 11.6026 11.6025C12.005 11.2001 12.4827 10.8809 13.0084 10.6631C13.5342 10.4453 14.0976 10.3333 14.6667 10.3333C15.219 10.3333 15.6667 9.88554 15.6667 9.33325C15.6667 8.78097 15.219 8.33325 14.6667 8.33325Z" fill="#1F2A44"/>
                            <path d="M26.6667 26.6667L24 24" stroke="#1F2A44" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                    </button>
                </form> -->
                <mat-menu class="messages-menu-dropdown" #messagesMenu="matMenu" xPosition="before">
                    <div class="title d-flex align-items-center justify-content-between">
                        <span class="fw-medium fs-16">
                            Messages
                            <span class="text-body fw-normal fs-15">
                                (05)
                            </span>
                        </span>
                        <a routerLink="/chat" class="link-btn fw-medium text-primary">
                            Mark all as read
                        </a>
                    </div>
                    <ul class="menu-body pl-0 mt-0 list-unstyled">
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" class="rounded-circle position-absolute"
                                alt="user">
                            <span class="sub-title d-block fw-medium">
                                Jacob Liwiski <span class="text-body fw-normal">35 mins ago</span>
                            </span>
                            <span class="message d-block text-body">
                                Hey Victor! Could you please...
                            </span>
                            <a routerLink="/chat"
                                class="d-block left-0 top-0 bottom-0 right-0 z-1 position-absolute"></a>
                            <span class="unread d-inline-block rounded-circle bg-primary position-absolute"></span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user2.jpg" class="rounded-circle position-absolute"
                                alt="user">
                            <span class="sub-title d-block fw-medium">
                                Angela Carter <span class="text-body fw-normal">1 day ago</span>
                            </span>
                            <span class="message d-block text-body">
                                How are you Angela? Would you...
                            </span>
                            <a routerLink="/chat"
                                class="d-block left-0 top-0 bottom-0 right-0 z-1 position-absolute"></a>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user3.jpg" class="rounded-circle position-absolute"
                                alt="user">
                            <span class="sub-title d-block fw-medium">
                                Brad Traversy <span class="text-body fw-normal">2 days ago</span>
                            </span>
                            <span class="message d-block text-body">
                                Hey Brad Traversy! Could you please...
                            </span>
                            <a routerLink="/chat"
                                class="d-block left-0 top-0 bottom-0 right-0 z-1 position-absolute"></a>
                        </li>
                    </ul>
                    <div class="menu-footer text-center">
                        <a routerLink="/chat" class="text-primary text-decoration-underline d-inline-block fw-medium">
                            View All Messages
                        </a>
                    </div>
                </mat-menu>
            </li>
            <li>
                <button mat-button [matMenuTriggerFor]="notificationsMenu" class="notifications-menu-btn">

                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M19.3324 4.78598C18.3264 4.28204 17.1932 4 16 4C12.2047 4 9.01628 6.85376 8.59716 10.6259L8.26142 13.6475C8.19319 14.2616 8.15907 14.5686 8.10219 14.8691C7.96463 15.5959 7.72695 16.3 7.39594 16.9614C7.25907 17.2349 7.10013 17.4998 6.78224 18.0297L5.81743 19.6377C5.01167 20.9806 4.60879 21.6521 4.89602 22.1594C5.18325 22.6667 5.96631 22.6667 7.53241 22.6667H24.4677C26.0338 22.6667 26.8168 22.6667 27.104 22.1594C27.3913 21.6521 26.9884 20.9806 26.1826 19.6377L26.1826 19.6377L25.2178 18.0297L25.2178 18.0295C24.8999 17.4998 24.741 17.2349 24.6041 16.9614C24.2731 16.3 24.0354 15.5959 23.8979 14.8691C23.8414 14.5708 23.8074 14.266 23.7401 13.6608C20.7311 13.525 18.3334 11.0425 18.3334 8C18.3334 6.80655 18.7023 5.69926 19.3324 4.78598ZM21.6988 6.65177C22.6145 7.73842 23.2273 9.09513 23.3999 10.5989C22.2161 10.3266 21.3334 9.26639 21.3334 8C21.3334 7.50812 21.4665 7.04735 21.6988 6.65177Z"
                            fill="#1F2A44" />
                        <path
                            d="M12 22.6667C12 23.192 12.1035 23.7122 12.3045 24.1975C12.5055 24.6828 12.8001 25.1237 13.1716 25.4952C13.543 25.8666 13.984 26.1612 14.4693 26.3623C14.9546 26.5633 15.4747 26.6667 16 26.6667C16.5253 26.6667 17.0454 26.5633 17.5307 26.3623C18.016 26.1612 18.457 25.8666 18.8284 25.4952C19.1999 25.1237 19.4945 24.6828 19.6955 24.1975C19.8965 23.7122 20 23.192 20 22.6667L16 22.6667H12Z"
                            fill="#1F2A44" stroke="#1F2A44" stroke-width="2" stroke-linecap="round" />
                        <path
                            d="M24 11.1666C25.7489 11.1666 27.1667 9.74882 27.1667 7.99992C27.1667 6.25102 25.7489 4.83325 24 4.83325C22.2511 4.83325 20.8334 6.25102 20.8334 7.99992C20.8334 9.74882 22.2511 11.1666 24 11.1666Z"
                            fill="#DA1884" stroke="#1F2A44" />
                    </svg>


                </button>
                <mat-menu class="notifications-menu-dropdown" #notificationsMenu="matMenu">
                    <div class="title d-flex align-items-center justify-content-between">
                        <span class="fw-medium fs-16">
                            Notifications
                            <span class="text-body fw-normal fs-15">
                                ({{totalNotifications}})
                            </span>
                        </span>
                        <a routerLink="/notifications" class="link-btn fw-medium text-primary">
                            Clear All
                        </a>
                    </div>
                    <ul class="menu-body p-0 mt-0 list-unstyled">
                        <li *ngFor="let notification of notifications" class="position-relative">
                            <div
                                class="icon rounded-circle d-flex align-items-center justify-content-center position-absolute text-center transition">

                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"
                                    viewBox="0 0 32 20">
                                    <!-- SVG path for ride icon -->
                                </svg>
                            </div>

                            <span class="sub-title d-block">
                                {{ notification.subject }} <strong class="fw-semibold">{{ notification.message
                                    }}</strong>
                            </span>
                            <span class="time d-block text-body">
                                {{ notification.timestamp }}
                            </span>

                            <button mat-menu-item (click)="makrasread(notification)" style="font-size: 14px;">Mark as
                                Read</button>


                        </li>
                    </ul>
                    <div class="menu-footer text-center">
                        <a routerLink="/notifications"
                            class="text-primary text-decoration-underline d-inline-block fw-medium">
                            See All Notifications
                        </a>
                    </div>
                </mat-menu>
            </li>



            <li class="profileMenuright">
                <button mat-button [matMenuTriggerFor]="profileMenu"
                    class="profile-menu-btn  language-menu-btn toprightsection">
                    <div class="toprighticon menu-header d-flex align-items-center">
                        <img src="assets/profile.png" alt="admin-image" style="height: 46px; width: 46px;">
                        <div class="title" style="display: block; text-align: left;">
                            <a class="d-inline-block fw-medium">{{ username }}</a>
                            <h5>Group Admin</h5>
                        </div>
                    </div>
                </button>


                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">

                    <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
                        <li class="position-relative transition fw-medium">
                            <span class="menu-text">Change Password</span>
                            <a routerLink="/resetpassword"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">lock</i>
                        </li>
                        <li class="position-relative transition fw-medium">
                            <span class="menu-text">Logout</span>
                            <a style="cursor: pointer;" (click)="logout()"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">logout</i>
                        </li>
                        <!-- <li class="position-relative transition fw-medium">
                            <span class="menu-text">Profile</span>
                            <a routerLink="/"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">person</i>
                        </li> -->
                        <!-- <li class="position-relative transition fw-medium">
                            <span class="menu-text">Create user</span>
                            <a routerLink="/"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">create</i>
                        </li>
                        <li class="position-relative transition fw-medium">
                            <span class="menu-text">Keyboard shortcuts</span>
                            <a routerLink="/"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">keyboard</i>
                        </li>
                        <li class="position-relative transition fw-medium">
                            <span class="menu-text">Preferences</span>
                            <a routerLink="/"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">settings</i>
                        </li> -->
                        <!-- <li class="position-relative transition fw-medium">
                            <span class="menu-text">Logout</span>

                            <a style="cursor: pointer;" (click)="logout()"
                                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">logout</i>
                        </li> -->
                    </ul>

                </mat-menu>
            </li>


            <!-- <li class="profileMenuright">
                <div class="row profile-menu-btn language-menu-btn toprightsection" [matMenuTriggerFor]="profileMenu">
                    <div class="title" style="display: flex; align-items: center; width: 100%;">
                        <img src="assets/profile.png" style="height: 40px; margin-right: 10px;">
                        <div style="display: flex; flex-direction: column; flex-grow: 1;">
                            <span class="d-inline-block fw-medium username-text">{{ username }}</span>
                            <span style="font-size: 12px; color: grey;">Group Admin</span>
                        </div>
                        <i class="material-icons" style="font-size: 24px; color: grey;  cursor: pointer;">expand_more</i>
                    </div>
                </div>
            
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
                        <li class="position-relative transition fw-medium">
                            <span class="menu-text">Change Password</span>
                            <a routerLink="/resetpassword" class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">lock</i>
                        </li>
                        <li class="position-relative transition fw-medium">
                            <span class="menu-text">Logout</span>
                            <a style="cursor: pointer;" (click)="logout()" class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"></a>
                            <i class="material-symbols-outlined">logout</i>
                        </li>
                    </ul>
                </mat-menu>
            </li>
             -->
        </ul>
    </div>



    <div class="row dropdownbtnclass"
        style="padding-top: 2px; margin-bottom: 2px;     border-top: 1px solid #e5e5e5; height: 80px;">
        <div class="col-xl-8 col-lg-8 col-sm-8 d-flex flex-row align-items-center">

            <div class="dropdown">
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-group d-flex align-items-center">
                    <mat-select formControlName="groupId" id="groupId" name="groupId" [(ngModel)]="selectedGroup_Id"
                        (selectionChange)="onGroupChange($event)" style="width: 200px; margin-right: 20px;  color: var(--lacorsa-theme-key-colors-primary, #1F2A44);
                        font-size: 16px  !important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px !important;">
                        <mat-option *ngFor="let group of groups" [value]="group.groupID" style="width: 200px; margin-right: 20px;  color: var(--lacorsa-theme-key-colors-primary, #1F2A44);
                        font-size: 16px  !important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px !important;">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </form>
            </div>
            <nav mat-tab-nav-bar class="custom-tab-nav">
                <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.path" routerLinkActive
                    #rla="routerLinkActive" [active]="rla.isActive">
                    {{ link.label }}
                </a>
            </nav>
            <div class="details-container"
                style="display: flex; justify-content: flex-end; align-items: center;     padding-right: 24px;  padding-left: 24px; border-radius: 7px;">
                <div class="detail" (click)="openDialog(RideComponent)"
                    style="color: #DA1884; font-size: 16px; font-family: Libre Franklin; font-weight: 600; cursor: pointer; display: flex; align-items: center;">
                    <mat-icon
                        style="color: #fff; background-color: #DA1884; border-radius: 4px; margin-right: 4px;">add</mat-icon>
                    Create Ride
                </div>

            </div>

        </div>

        <div class="col-xl-4 col-lg-4 col-sm-4 d-flex justify-content-end align-items-center" style="padding-right: 16px;">


        </div>
    </div>


</div>