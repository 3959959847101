<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Stepper
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Stepper
        </li>
    </ol>
</div>

<!-- Stepper Overview -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper Overview
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-stepper-overview></app-stepper-overview>
    </mat-card-content>
</mat-card>

<!-- Stepper Animations -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper Animations
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-stepper-animations></app-stepper-animations>
    </mat-card-content>
</mat-card>

<!-- Stepper that Displays Errors in the Steps -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper that Displays Errors in the Steps
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-st-displays-errors-it-steps></app-st-displays-errors-it-steps>
    </mat-card-content>
</mat-card>

<!-- Stepper Header Position -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper Header Position
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-stepper-header-position></app-stepper-header-position>
    </mat-card-content>
</mat-card>

<!-- Stepper Label Bottom Position -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper Label Bottom Position
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-stepper-label-bottom-position></app-stepper-label-bottom-position>
    </mat-card-content>
</mat-card>

<!-- Stepper Lazy Content Rendering -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper Lazy Content Rendering
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-stepper-lazy-content-rendering></app-stepper-lazy-content-rendering>
    </mat-card-content>
</mat-card>

<!-- Stepper with Required Steps -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Stepper with Required Steps
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-sw-required-steps></app-sw-required-steps>
    </mat-card-content>
</mat-card>