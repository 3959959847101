<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Disabled Datepicker
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="mb-25">
            <mat-form-field>
                <i-feather name="list"></i-feather>
                <mat-label>Completely disabled</mat-label>
                <input matInput [matDatepicker]="dp1" disabled>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="mb-25">
            <mat-form-field>
                <i-feather name="list"></i-feather>
                <mat-label>Popup disabled</mat-label>
                <input matInput [matDatepicker]="dp2">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="dp2" disabled></mat-datepicker-toggle>
                <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="mb-25">
            <mat-form-field>
                <i-feather name="list"></i-feather>
                <mat-label>Input disabled</mat-label>
                <input matInput [matDatepicker]="dp3" disabled>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
                <mat-datepicker #dp3 disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
    </mat-card-content>
</mat-card>