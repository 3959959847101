<mat-card
    class="trinta-card advanced-form mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Advanced Form
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="signin-with-socials">
            <button type="button" mat-button>
                <img src="assets/images/icons/google2.svg" alt="google">
                Sign In With Google
            </button>
            <div class="or text-center position-relative z-1 text-body">
                <span class="d-inline-block bg-white">
                    OR
                </span>
            </div>
        </div>
        <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="label d-block fw-medium mb-10">
                    Email Address
                </label>
                <mat-form-field>
                    <i-feather name="mail"></i-feather>
                    <mat-label>Enter email address</mat-label>
                    <input matInput type="email" id="email" formControlName="email">
                </mat-form-field>
                <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('required') && authForm.get('email')!.touched">
                    Email is required.
                </div>
                <div class="error text-danger" *ngIf="authForm.get('email')!.hasError('email') && authForm.get('email')!.touched">
                    Please enter a valid email address.
                </div>
            </div>
            <div class="form-group">
                <label class="label d-block fw-medium mb-10">
                    Your Password
                </label>
                <mat-form-field>
                    <i-feather name="lock"></i-feather>
                    <mat-label>Enter your password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" id="password" formControlName="password">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <span class="material-symbols-outlined">
                            {{hide ? 'visibility_off' : 'visibility'}}
                        </span>
                    </button>
                </mat-form-field>
                <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('required') && authForm.get('password')!.touched">
                    Password is required.
                </div>
                <div class="error text-danger" *ngIf="authForm.get('password')!.hasError('minlength') && authForm.get('password')!.touched">
                    Password must be at least 8 characters long.
                </div>
            </div>
            <div class="info d-flex justify-content-between align-items-center">
                <mat-checkbox color="primary">
                    Remember me
                </mat-checkbox>
                <a routerLink="/authentication/forgot-password" class="d-inline-block text-primary">
                    Forgot your password?
                </a>
            </div>
            <button mat-button type="submit" [disabled]="authForm.invalid">
                Sign In
            </button>
        </form>
    </mat-card-content>
</mat-card>