<div class="row">
    <div class="col-lg-3">
        <mat-card
            class="trinta-card sidebar-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <a routerLink="/apps/email/compose" mat-button>
                    Compose
                </a>
                <span class="d-block fs-15 text-body">
                    Mailbox
                </span>
                <ul class="sidebar-list p-0 list-unstyled">
                    <li>
                        <a routerLink="/apps/email" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="mail"></i-feather>
                            Inbox
                            <span class="d-block text-primary">
                                (20)
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="clock"></i-feather>
                            Snoozed
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="file-text"></i-feather>
                            Draft
                            <span class="d-block text-danger">
                                (08)
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="send"></i-feather>
                            Sent Mail
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="trash"></i-feather>
                            Trash
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="alert-triangle"></i-feather>
                            Spam
                            <span class="d-block text-info">
                                (04)
                            </span>
                        </a>
                    </li>
                </ul>
                <span class="d-block fs-15 text-body">
                    Filters
                </span>
                <ul class="sidebar-list p-0 list-unstyled">
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="star"></i-feather>
                            Starred
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="crosshair"></i-feather>
                            Important
                        </a>
                    </li>
                </ul>
                <span class="d-block fs-15 text-body">
                    Labels
                </span>
                <ul class="sidebar-list p-0 list-unstyled">
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="tag"></i-feather>
                            Personal
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="tag"></i-feather>
                            Company
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="tag"></i-feather>
                            Wallet Balance
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="tag"></i-feather>
                            Friends
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between position-relative fw-medium" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i-feather name="plus"></i-feather>
                            Add New Label
                        </a>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-9">
        
        <router-outlet />

    </div>
</div>