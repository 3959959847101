<mat-card
    class="trinta-card orders-list-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Orders List
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="orders-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Tracking No Column -->
                    <ng-container matColumnDef="trackingID">
                        <th mat-header-cell *matHeaderCellDef class="text-primary pl-0">
                            Tracking No
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary fw-medium pl-0">
                            {{element.trackingID}}
                        </td>
                    </ng-container>

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>
                            Product
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/ecommerce-page/product-details" class="image d-block">
                                    <img [src]="element.product.img" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/ecommerce-page/product-details" class="d-block fw-semibold">
                                        {{element.product.title}}
                                    </a>
                                    <span class="d-block text-body">
                                        {{element.product.date}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>
                            Customer
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.customer}}
                        </td>
                    </ng-container>

                    <!-- Payment Status Column -->
                    <ng-container matColumnDef="paymentStatus">
                        <th mat-header-cell *matHeaderCellDef>
                            Payment Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="trinta-badge unpaid fw-medium d-inline-block" *ngIf="element.paymentStatus.unpaid">
                                <i class="ri-file-list-3-line"></i> {{element.paymentStatus.unpaid}}
                            </span>
                            <span class="trinta-badge fw-medium d-inline-block" *ngIf="element.paymentStatus.paid">
                                <i class="ri-money-dollar-circle-line"></i> {{element.paymentStatus.paid}}
                            </span>
                            <span class="trinta-badge fw-medium awaiting d-inline-block" *ngIf="element.paymentStatus.awaiting">
                                <i class="ri-hourglass-2-fill"></i> {{element.paymentStatus.awaiting}}
                            </span>
                            <span class="trinta-badge fw-medium canceled d-inline-block" *ngIf="element.paymentStatus.failed">
                                <i class="ri-information-line"></i> {{element.paymentStatus.failed}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <!-- Quantity Column -->
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>
                            Quantity
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.quantity}}
                        </td>
                    </ng-container>

                    <!-- Vendor Column -->
                    <ng-container matColumnDef="vendor">
                        <th mat-header-cell *matHeaderCellDef>
                            Vendor
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.vendor}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="trinta-badge fw-medium d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                            <span class="trinta-badge fw-medium delivered d-inline-block" *ngIf="element.status.delivered">
                                {{element.status.delivered}}
                            </span>
                            <span class="trinta-badge fw-medium canceled d-inline-block" *ngIf="element.status.canceled">
                                {{element.status.canceled}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <a routerLink="/ecommerce-page/order-details" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.view}}"></i>
                                </a>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.delete}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>