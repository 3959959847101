<div class="example-listbox-container">
    <label class="example-listbox-label fw-medium" id="example-language-label">
        Preferred Language
    </label>
    <ul cdkListbox
        [formControl]="languageCtrl"
        aria-labelledby="example-language-label"
        class="example-listbox"
    >
        @for (language of languages; track language) {
            <li [cdkOption]="language" class="example-option text-black">{{language}}</li>
        }
    </ul>
</div>
<p class="mt-15">
    Your preferred language: <strong class="fw-medium text-black">{{languageCtrl.value | json}}</strong>&nbsp;
    <button (click)="languageCtrl.reset()">Reset</button>
</p>