import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FeathericonsModule } from '../../../icons/feathericons/feathericons.module';



@Component({
  selector: 'app-leaderboard',
  standalone: true,
  imports: [MatCardModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule, NgIf,MatSelectModule,MatFormFieldModule,MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule, FeathericonsModule],
  templateUrl: './leaderboard.component.html',
  styleUrl: './leaderboard.component.scss'
})




export class LeaderboardComponent {
  displayedColumns: string[] = ['trackingID', 'product', 'customer','distance'];
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    selectedFilter: string = 'week'; // default value

    constructor() { }
  
    // You can add methods to handle changes in the filter if needed
    onFilterChange() {
      console.log('Selected filter:', this.selectedFilter);
      // Add your logic here to handle the filter change
    }

    
    indoors: Indoor[] = [
      {value: '30days', viewValue: 'Last 30 days'},
      {value: 'alltimes', viewValue: 'All - time'},
  ];

  distances: Distance[] = [
    {value: 'longeest', viewValue: 'Longest Ride'},
    {value: 'Commute', viewValue: 'Commute days'},
    {value: 'Rides', viewValue: 'Ride'},
    {value: 'Elevations', viewValue: 'Elevation'},

];
}

interface Indoor {
  value: string;
  viewValue: string;
}


interface Distance {
  value: string;
  viewValue: string;
}


export interface PeriodicElement {
  trackingID: string;
  product: any;
  customer: string;
  distance: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
      trackingID: '4',
      product: {
          img: 'assets/pr1.png',
          title: 'Comforta Sofa EDM',
          date: 'Dec 15, 02:20 PM'
      },
      customer: 'Isabella Anderson',
      distance: '268.5km'
  },
  {
      trackingID: '5',
      product: {
          img: 'assets/pr2.png',
          title: 'Electric Bicycle',
          date: 'Dec 14, 10:00 AM'
      },
      customer: 'Mason Martinez',
      distance: '218.5km'
  },
  {
      trackingID: '6',
      product: {
          img: 'assets/pr3.png',
          title: 'Sport Shoes',
          date: 'Dec 13, 03:43 AM'
      },
      customer: 'Sophia Jones',
      distance: '211.5km'
  },
  {
    trackingID: '7',
    product: {
        img: 'assets/pr3.png',
        title: 'Sport Shoes',
        date: 'Dec 13, 03:43 AM'
    },
    customer: 'Koskei Jones',
    distance: '201.5km'
  },
];