<div class="sign-in-area">



  <div class="branding-section">
    <img src="/assets/logo/lacorsalogo.svg" alt="La Corsa official logo" aria-hidden="true">
    <h3 class="description">It's time to ride!</h3>
  </div>
  <div class="d-table login logisection">
    <img src="/assets/logo/Logo-WithText-color.svg" alt="La Corsa official logo" aria-hidden="true">
    <div>
      <div class="trinta-form  border-radius loginpageform"
        style="padding-top: 5%; padding-left: 4%; padding-right: 6%; padding-top: 4%; max-height: 800px; overflow-y: scroll;">
        <div class="title text-left">
          <h3 style="font-size: 22px; font-weight: 700;">Group Registration</h3>
        </div>

        <form [formGroup]="createRideForm" (ngSubmit)="onSubmit()"
          class="d-flex form-group flex-column registerform createredforms">


          <mat-form-field class="custom-form-field">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="name" id="name" name="name">
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>Description</mat-label>
            <textarea rows="4" matInput type="text" formControlName="description" id="description"
              name="description"></textarea>
          </mat-form-field>





          <div class="d-flex justify-content-between">
            <mat-form-field class="custom-form-field" style="flex: 1; margin-right: 10px;">
              <mat-label>State</mat-label>
              <mat-select formControlName="stateId" id="stateId" name="stateId"
                [ngClass]="{'invalid-input': createRideForm.get('stateId')?.invalid && createRideForm.get('stateId')?.touched}">
                <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="custom-form-field" style="flex: 1;">
              <mat-label>City</mat-label>
              <mat-select formControlName="cityId" id="cityId" name="cityId"
                [ngClass]="{'invalid-input': createRideForm.get('cityId')?.invalid && createRideForm.get('cityId')?.touched}">
                <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>




          <mat-form-field class="custom-form-field" style="display: none;">
            <input matInput formControlName="locationComment" name="locationComment" id="locationComment" value="N/A">
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>Typical Pace</mat-label>
            <mat-select formControlName="la_TypicalPace_ID" id="la_TypicalPace_ID" name="la_TypicalPace_ID"
              [ngClass]="{'invalid-input': createRideForm.get('la_TypicalPace_ID')?.invalid && createRideForm.get('la_TypicalPace_ID')?.touched}">
              <!-- <mat-option value="" disabled>Select Pace</mat-option> -->
              <mat-option *ngFor="let typicalpace of typicalpaces" [value]="typicalpace.la_TypicalPace_ID">{{
                typicalpace.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field class="custom-form-field">
            <mat-label>Type of Ride</mat-label>
            <mat-select formControlName="ridingType" id="ridingType" name="ridingType"
              [ngClass]="{'invalid-input': createRideForm.get('la_RidingType_ID')?.invalid && createRideForm.get('la_RidingType_ID')?.touched}"
              multiple>
              <mat-option *ngFor="let rideType of rideTypes" [value]="rideType.id">{{ rideType.name }}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field class="custom-form-field">
            <mat-label>Difficulty Level</mat-label>
            <mat-select formControlName="ridingLevel" id="ridingLevel" name="ridingLevel"
              [ngClass]="{'invalid-input': createRideForm.get('ridingLevel')?.invalid && createRideForm.get('ridingLevel')?.touched}"
              multiple>
              <mat-option *ngFor="let level of levels" [value]="level.id">{{ level.name }}</mat-option>
            </mat-select>
          </mat-form-field>



          <mat-form-field class="custom-form-field">
            <mat-label>Annual Mileage</mat-label>
            <mat-select formControlName="la_AnnualMileage_ID" id="la_AnnualMileage_ID" name="la_AnnualMileage_ID"
              [ngClass]="{'invalid-input': createRideForm.get('la_AnnualMileage_ID')?.invalid && createRideForm.get('la_AnnualMileage_ID')?.touched}">
              <!-- <mat-option value="" disabled>Select Annual Mileage</mat-option> -->
              <mat-option *ngFor="let mileage of mileages" [value]="mileage.id">{{ mileage.name }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>Composition</mat-label>
            <mat-select formControlName="compisitionID" id="compisitionID" name="compisitionID">
              <mat-option *ngFor="let gender of genders" [value]="gender.id">{{ gender.name }}</mat-option>
            </mat-select>
          </mat-form-field>


          <!-- <div class="row custom-form-field" style="padding-top: 12px;">
      <div class="col-sm-5">
        <mat-label>Restrictions</mat-label>
      </div>
      <div class="row col-sm-7">
        <mat-radio-group aria-label="Select an option" class="col-sm-6 d-flex justify-content-between flex-wrap"
          formControlName="invitedRidersOnly" id="invitedRidersOnly" name="invitedRidersOnly"
          (change)="onRestrictionChange('invitedRidersOnly', $event)">
          <mat-radio-button [value]="true">Invites only</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group aria-label="Select an option" class="col-sm-6 d-flex justify-content-between flex-wrap"
          formControlName="adminApproval" id="adminApproval" name="adminApproval"
          (change)="onRestrictionChange('adminApproval', $event)">
          <mat-radio-button [value]="true">Admin approval</mat-radio-button>
        </mat-radio-group>
      </div>
    </div> -->

          <div class="row custom-form-field" style="padding-top: 12px;">
            <div class="col-sm-5">
              <label class="flabel">Restrictions</label>
            </div>
            <div class="col-sm-7 d-flex align-items-center justify-content-between">
              <!-- Invites Only Checkbox -->
              <mat-checkbox formControlName="invitedRidersOnly" id="invitedRidersOnly" name="invitedRidersOnly"
                (change)="onCheckboxChange('invitedRidersOnly', $event)">
                Invites only
              </mat-checkbox>

              <!-- Admin Approval Checkbox -->
              <mat-checkbox formControlName="adminApproval" id="adminApproval" name="adminApproval"
                (change)="onCheckboxChange('adminApproval', $event)">
                Admin approval
              </mat-checkbox>
            </div>
          </div>


          <div class="row custom-form-field" style="padding-top: 8px; padding-bottom: 8px;">
            <div class="col-sm-5">
              <label class="flabel">Allow members to create/post rides?</label>
            </div>
            <div class="col-sm-7 d-flex align-items-center">
              <!-- Yes Checkbox -->
              <mat-checkbox [checked]="allowMemberPost === true" (change)="onCheckboxChangeVal(true)">
                Yes
              </mat-checkbox>


              <mat-checkbox [checked]="allowMemberPost === false" (change)="onCheckboxChangeVal(false)">
                No
              </mat-checkbox>
            </div>
          </div>



          <mat-form-field class="custom-form-field">
            <mat-label>Cost</mat-label>
            <input matInput type="number" formControlName="amount" id="amount" name="amount">
          </mat-form-field>


          <div class="upload-button-container">
            <input type="file" (change)="onFileSelected($event, 'logo')" accept="image/*" placeholder="Logo">
            <p>Logo</p>
          </div>

          <div class="upload-button-container">
            <input type="file" (change)="onFileSelected($event, 'coverphoto')" accept="image/*">
            <p>Cover Photo</p>
          </div>

          <mat-form-field class="custom-form-field" style="display: none;">
            <mat-label>Group Admin</mat-label>
            <input matInput type="text" formControlName="groupAdmin" id="groupAdmin" name="groupAdmin" value="">
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>Website link</mat-label>
            <input matInput formControlName="url" name="url" id="url">
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>Facebook link</mat-label>
            <input matInput formControlName="faceBook" name="faceBook" id="faceBook">
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>X link</mat-label>
            <input matInput formControlName="x" name="x" id="x">
          </mat-form-field>

          <mat-form-field class="custom-form-field">
            <mat-label>Instagram link</mat-label>
            <input matInput formControlName="instagram" name="instagram" id="instagram">
          </mat-form-field>

          <div class="button-container submitbtn" style="padding-top: 16px; padding-bottom: 16px">
            <button mat-raised-button color="primary" type="submit" class="col-sm-5" [disabled]="isSaving">
              <!-- Bind the disabled state to isSaving -->
              {{ isSaving ? 'Submitting...Please wait!' : 'Submit' }} <!-- Change the text based on isSaving -->
            </button>
          </div>

        </form>
      </div>

      <div class="info " style="padding-top: 2px; display: flex; justify-content: center; ">
        <a href="/authentication" routerLink="/authentication" class="d-inline-block text-primary"
          style="color: black; font-size: 16px;">
          Already have a group? login
        </a>
      </div>
    </div>
  </div>
</div>