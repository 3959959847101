<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Input
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Input
        </li>
    </ol>
</div>

<!-- Input -->
<div class="row">
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Inputs
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Favorite food</mat-label>
                        <input matInput placeholder="Ex. Pizza" placeholder="Sushi">
                    </mat-form-field>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Favorite food</mat-label>
                        <input matInput placeholder="Ex. Pizza" value="Sushi">
                    </mat-form-field>
                    <mat-form-field class="textarea">
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Leave a comment</mat-label>
                        <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Input with Error Messages
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Email</mat-label>
                        <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
                        @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
                            <mat-error>Please enter a valid email address</mat-error>
                        }
                        @if (emailFormControl.hasError('required')) {
                            <mat-error>Email is <strong>required</strong></mat-error>
                        }
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card inputs-with-ps-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Inputs with Prefixes & Suffixes
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Telephone</mat-label>
                        <span matTextPrefix>+1 &nbsp;</span>
                        <input type="tel" matInput placeholder="555-555-1234">
                        <mat-icon matSuffix>mode_edit</mat-icon>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Input with a Clear Button
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Clearable input</mat-label>
                        <input matInput type="text" [(ngModel)]="value" name="clearable-input">
                        @if (value) {
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Input with a Custom ErrorStateMatcher
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-custom-errorstatematcher></app-custom-errorstatematcher>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Input with Hints
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Message</mat-label>
                        <input matInput #message maxlength="256" placeholder="Ex. I need help with...">
                        <mat-hint align="start"><strong>Don't disclose personal info</strong> </mat-hint>
                        <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                    </mat-form-field>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- Inputs in a Form -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Inputs in a Form
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <mat-form-field>
                <i-feather name="edit-3"></i-feather>
                <mat-label>Company (disabled)</mat-label>
                <input matInput disabled value="Google">
            </mat-form-field>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>First name</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Long Last Name That Will Be Truncated</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
            </div>
            <mat-form-field>
                <i-feather name="edit-3"></i-feather>
                <mat-label>Address</mat-label>
                <textarea matInput placeholder="Ex. 100 Main St">1600 Amphitheatre Pkwy</textarea>
            </mat-form-field>
            <mat-form-field class="textarea">
                <i-feather name="edit-3"></i-feather>
                <mat-label>Address 2</mat-label>
                <textarea matInput></textarea>
            </mat-form-field>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>City</mat-label>
                        <input matInput placeholder="Ex. San Francisco">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>State</mat-label>
                        <input matInput placeholder="Ex. California">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Postal Code</mat-label>
                        <input matInput #postalCode maxlength="5" placeholder="Ex. 94105" value="94043">
                        <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>