<div class="title text-center">
  <h2 mat-dialog-title class="fw-semibold">Create Route</h2>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <form [formGroup]="createRouteForm" (ngSubmit)="onSubmit()" class="d-flex form-group flex-column">
    <mat-label>Route Name</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput formControlName="name" id="name" name="name">
    </mat-form-field>


    <mat-label>Route link</mat-label>
    <mat-form-field class="custom-form-field">
      <input matInput formControlName="linkurl" id="linkurl" name="linkurl" >
    </mat-form-field>

    <div class="row">
      <button mat-raised-button color="primary" type="submit" class="col-sm-4 ml-auto mr-auto">Save Route</button>
    </div>

  </form>


</div>

