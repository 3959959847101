<div class="routessection">

 
  
        <div class="row" *ngFor="let route of routes" style="padding-left: 16px; padding-right: 16px;">
        <div class="col-xl-6 col-lg-6 col-sm-6">
            <p class="nametxt" >{{ route.name }}</p>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-6 " style="text-align: right;">
              <p class="milestxt">12 miles</p>
              </div>
        </div>
  
  
      </div>

  
  