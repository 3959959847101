import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-latestpost',
  standalone: true,
  imports: [RouterLink, MatCardModule, MatMenuModule, MatButtonModule],
  templateUrl: './latestpost.component.html',
  styleUrl: './latestpost.component.scss'
})

export class LatestpostComponent {

}
