<div class="title text-center">
  <h2 mat-dialog-title class="fw-semibold">Edit Ride</h2>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>


<div mat-dialog-content>
  <form [formGroup]="createRideForm" (ngSubmit)="onSubmit()" class="d-flex form-group flex-column">

    <mat-form-field class="custom-form-field">
      <mat-label>Riding Group</mat-label>
      <mat-select formControlName="ridinggroupId" id="ridinggroupId" name="ridinggroupId">
        <mat-option value="" disabled>Select Group</mat-option>
        <mat-option *ngFor="let group of groups" [value]="group.groupID">{{ group.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="custom-form-field">
      <mat-label>Ride Name</mat-label>
      <input matInput formControlName="rideName" id="rideName" name="rideName">
    </mat-form-field>


    <mat-form-field class="custom-form-field">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" id="description" name="description" rows="4"
        ></textarea>
    </mat-form-field>

    <div class="row custom-form-field" style="padding-top: 8px; padding-bottom: 8px;">
      <div class="col-sm-12 d-flex align-items-center">
        <mat-radio-group
          formControlName="locationType"
          (change)="onRadioChange($event)">
          
          <mat-radio-button
            class="mr-3"  
            value="outDoor"
            id="outDoor"
            name="locationType">
            Outdoor
          </mat-radio-button>
    
          <mat-radio-button
            value="inDoor"
            id="inDoor"
            name="locationType" style="padding-left: 6px;">
            Indoor
          </mat-radio-button>
    
        </mat-radio-group>
      </div>
    </div>
    


    <div class="row" class="custom-form-field" style="padding-top: 12px;">
      <mat-form-field class="col-sm-6" style="padding-right: 4px;">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date"
          (dateChange)="onDateChange($event)" [min]="minDate">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="col-sm-6" style="padding-left: 4px;">
        <mat-label>Start Time</mat-label>
        <input matInput formControlName="time" type="time" id="time" name="time" rows="5">
      </mat-form-field>
    </div>

    <div class="row custom-form-field">
      <mat-form-field class="col-sm-6">
        <mat-label>End Time</mat-label>
        <input matInput formControlName="endTime" type="time" id="endTime" name="endTime">
      </mat-form-field>
    </div>



    <mat-form-field class="custom-form-field">
      <mat-label>Starting Location</mat-label>
      <input matInput type="text" formControlName="startLocation" id="startLocation" name="startLocation">
    </mat-form-field>


    <div class="d-flex justify-content-between">
      <mat-form-field class="custom-form-field" style="flex: 1; margin-right: 10px;">
        <mat-label>State</mat-label>
        <mat-select formControlName="stateId" id="stateId" name="stateId"
          [ngClass]="{'invalid-input': createRideForm.get('stateId')?.invalid && createRideForm.get('stateId')?.touched}">
          <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field class="custom-form-field" style="flex: 1;">
        <mat-label>City</mat-label>
        <mat-select formControlName="cityId" id="cityId" name="cityId"
          [ngClass]="{'invalid-input': createRideForm.get('cityId')?.invalid && createRideForm.get('cityId')?.touched}">
          <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <mat-form-field class="custom-form-field">
      <mat-label>Route Name</mat-label>
      <input matInput type="text" formControlName="route" id="route" name="route">
    </mat-form-field>

    <mat-form-field class="custom-form-field">
      <mat-label>Route Link</mat-label>
      <input matInput type="text" formControlName="link" id="link" name="link">
      <mat-icon matSuffix>link</mat-icon>
    </mat-form-field>

    <mat-form-field class="custom-form-field">
      <mat-label>Level</mat-label>
      <mat-select formControlName="levelId" id="levelId" name="levelId">
        <mat-option value="" disabled>Select Level</mat-option>
        <mat-option *ngFor="let level of levels" [value]="level.id">{{ level.name }}</mat-option>
      </mat-select>
    </mat-form-field>


    <div class="row custom-form-field" style="padding-top: 8px; padding-bottom: 8px;">
      <div class="col-sm-8">
        <label class="flabel">This is a public ride open to all interested users?</label>
      </div>
      <div class="col-sm-4 d-flex align-items-center">
        <mat-slide-toggle class="custom-toggle"
          id="privacyId" 
          name="privacyId"
          [checked]="privacyId"
          (change)="onToggleChange($event.checked)">
        </mat-slide-toggle>
      </div>
    </div>

    <!-- <div class="row custom-form-field" style="padding-top: 12px;">
      <div class="col-sm-12 d-flex align-items-center">
        <mat-radio-group
          formControlName="locationType"
          (change)="onRadioChange($event)">
          <mat-radio-button
            class="mr-3"  
            value="outDoor"
            id="outDoor"
            name="locationType">
            Outdoor
          </mat-radio-button>
  
          <mat-radio-button
            value="inDoor"
            id="inDoor"
            name="locationType" style="padding-left: 6px;">
            Indoor
          </mat-radio-button>
    
        </mat-radio-group>
      </div>
    </div> -->


  

    <mat-form-field class="custom-form-field">
      <mat-label>Participant Limit</mat-label>
      <input matInput formControlName="limit" id="limit" name="limit">
    </mat-form-field>


    <mat-form-field class="custom-form-field">
      <mat-label>Ride Type</mat-label>
      <mat-select formControlName="preferredBikeTypesId" id="preferredBikeTypesId" name="preferredBikeTypesId">
        <mat-option value="" disabled>Select Ride Type</mat-option>
        <mat-option *ngFor="let rideType of rideTypes" [value]="rideType.id">{{ rideType.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="custom-form-field">
      <mat-label>Distance</mat-label>
      <input matInput type="number" formControlName="distance" id="distance" name="distance">
    </mat-form-field>



    <div class="d-flex justify-content-between">
      <mat-form-field class="custom-form-field" style="flex: 1; margin-right: 10px;">
        <mat-label>Pace</mat-label>
        <mat-select formControlName="paceId" id="paceId" name="paceId">
          <mat-option *ngFor="let pace of paces" [value]="pace.id">{{ pace.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field class="custom-form-field" style="flex: 1;">
        <mat-label>Purpose</mat-label>
        <mat-select formControlName="purposeId" id="purposeId" name="purposeId">
          <mat-option *ngFor="let purpose of purposes" [value]="purpose.id">{{ purpose.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


   
    <mat-form-field class="custom-form-field" >
      <mat-label>Ride Leader</mat-label>
      <mat-select formControlName="rideLeader" id="rideLeader" name="rideLeader">
        <mat-option *ngFor="let member of members" [value]="member.name">{{ member.name  }} {{member.otherNames}}</mat-option>
      </mat-select>
    </mat-form-field>

    

    <!-- <mat-label>Cover Photo</mat-label> -->
    <div class="upload-button-container">
      <input formControlName="logo" id="logo" name="logo" type="file" (change)="onFileChange($event)" />
      <p>Cover Photo</p>
    </div>


    <img  [src]="photoimg ? 'data:image/png;base64,' + photoimg : null"
      [ngStyle]="{
        'width': '100%',
        'height': photoimg ? '200px' : 'auto',
        'object-fit': 'contain',
        'margin': 'auto',
        'display': 'flex',
        'margin-bottom': '16px'
      }"
      *ngIf="photoimg; else defaultImage">
    
    <ng-template #defaultImage>
      
    </ng-template>

    <mat-form-field class="d-flex justify-content-end custom-form-field" style="display: none !important;">
      <input matInput formControlName="rideId" name="rideId" id="rideId">
    </mat-form-field>
    <div class="button-container submitbtn">
    
      <button mat-raised-button color="primary" type="submit" class="col-sm-5 postbtn">Update Ride</button>
    </div>

  </form>
</div>