<div class="table-responsive">
    <table class="main-table" mat-table [dataSource]="transactions">

        <!-- Item Column -->
        <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef> Item </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.item}} </td>
            <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <!-- Cost Column -->
        <ng-container matColumnDef="cost">
            <th mat-header-cell *matHeaderCellDef> Cost </th>
            <td mat-cell *matCellDef="let transaction"> {{transaction.cost | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{getTotalCost() | currency}} </td>
        </ng-container>

        <!-- Item Description Column -->
        <ng-container matColumnDef="item-description">
            <th mat-header-cell *matHeaderCellDef> Name of the item purchased </th>
        </ng-container>

        <!-- Cost Description Column -->
        <ng-container matColumnDef="cost-description">
            <th mat-header-cell *matHeaderCellDef> Cost of the item in USD </th>
        </ng-container>

        <!-- Disclaimer column -->
        <ng-container matColumnDef="disclaimer">
            <td mat-footer-cell *matFooterCellDef colspan="2">
                Please note that the cost of items displayed are completely and totally made up.
            </td>
        </ng-container>

        <!-- The table will render two header rows, one data row per data object, and two footer rows. -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="example-first-header-row"></tr>
        <tr mat-header-row *matHeaderRowDef="['item-description', 'cost-description']" class="fst-italic"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="fw-bold"></tr>
        <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="fst-italic"></tr>

    </table>
</div>