<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Chips with Input
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <i-feather name="list"></i-feather>
            <mat-label>Favorite Fruits</mat-label>
            <mat-chip-grid #chipGrid aria-label="Enter fruits">
                @for (fruit of fruits; track fruit) {
                    <mat-chip-row
                        (removed)="remove(fruit)"
                        [editable]="true"
                        (edited)="edit(fruit, $event)"
                        [aria-description]="'press enter to edit ' + fruit.name"
                    >
                        {{fruit.name}}
                        <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input
                    placeholder="New fruit..."
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)"
                />
            </mat-chip-grid>
        </mat-form-field>
    </mat-card-content>
</mat-card>