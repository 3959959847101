import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { OnInit } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { BASE_URL } from '../../../app/constants';
import { ApiEndpoints } from '../../../app/api-endpoints';
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatTooltip } from "@angular/material/tooltip";
import { CommonModule } from '@angular/common';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { Router } from '@angular/router';
import { ToastService, ToastType } from '../../../app/toast.service'; // adjust the path accordingly


@Component({
    selector: 'app-forgot-password',
    standalone: true,
    imports: [RouterLink,
        MatButton, MatStepperModule, MatButtonModule, MatFormField,
        MatIconButton, CommonModule, MatInput,
        MatFormFieldModule, MatOption,
        MatInputModule,
        FeathericonsModule,
        MatCheckboxModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgIf,
        MatCardModule,
        FormsModule,

        MatFormField,
        MatRadioGroup, FileUploadModule,
        MatRadioButton,
        MatInput,
        MatLabel,
        MatSelect,
        MatTooltip,
        HttpClientModule,
        CommonModule,
        RouterLink,

        MatFormFieldModule, MatInputModule, MatButtonModule, ReactiveFormsModule, FeathericonsModule],
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

    authForm: FormGroup;
    issubmit: boolean = false;


    constructor(private http: HttpClient, private router: Router, private fb: FormBuilder,     private toastService: ToastService,
    ) {
        this.authForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

  
    onSubmit(){

    }

    showSuccess() {
        this.toastService.showToast('Password changed successfully. Please check your email for a temporary password.', ToastType.Success);
      }
    
      showError() {
        this.toastService.showToast('An error occurred!', ToastType.Error);
      }
    
      showWarning() {
        this.toastService.showToast('This is a warning message.', ToastType.Warning);
      }
    

    submitrequest() {
        if (this.authForm.valid) {
            this.issubmit = true;
            const email = this.authForm.get('email')?.value.toLowerCase();
            const loginObj = {
                "email": email,
                "token": "",
                "firstname": "",
                "lastname": "",
                "cbPartnerId": ""
            };
            this.http.post(BASE_URL + 'accounts/forgotPassword', loginObj).subscribe(
                (res: any) => {
                    if (res) {
                        this.showSuccess();
                        setTimeout(() => {
                            this.router.navigate(['/authentication']);
                        localStorage.clear();
                          }, 1500);
                        // alert("Password changed successfully, check your email for a temporary password");
                        

                    } else {
                        console.error("Unexpected response:", res);
                        alert("Login failed");
                        this.issubmit = false;
                    }
                },
                (error) => {
                    if (error.status === 401) {
                        console.log("Unauthorized:", error.status);
                        alert("Unauthorized: Invalid username or password");
                        this.issubmit = false;

                    } else if (error.status === 500) {
                        console.log("Internal server error:", error.status);
                        alert("Internal server error");
                        this.issubmit = false;

                    } else {
                        console.error("Error logging in:", error);
                        alert("Login failed. Please try again later.");
                        this.issubmit = false;

                    }
                }
            );
        } else {
            this.authForm.markAllAsTouched();
        }
    }


}