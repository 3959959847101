<div class="row">
    <div class="col-lg-4 col-xxxl-3">
        <mat-card
            class="trinta-card chat-sidebar-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Messages
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form class="search-box position-relative">
                    <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search here..">
                    <button type="submit" class="bg-transparent p-0 border-none cursor-pointer text-primary">
                        <i class="ri-search-line"></i>
                    </button>
                </form>
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="All Message">
                        <div class="chat-users-list">
                            <span class="sub-title d-block text-body">
                                Pinned
                            </span>
                            <div class="single-user-item active d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Sarah Rodriguez
                                        </span>
                                        <span class="d-block text-success">
                                            Typing...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Now
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user2.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Carolyn Barnes
                                        </span>
                                        <span class="d-block text-body">
                                            Hello Mateo...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        9:10 PM
                                    </span>
                                    <span class="trinta-badge d-inline-block rounded-circle">
                                        2
                                    </span>
                                </div>
                            </div>
                            <span class="sub-title d-block text-body">
                                All Messages
                            </span>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user4.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Donna Miller
                                        </span>
                                        <span class="d-block text-body">
                                            Great ! 🔥
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        2:12 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Barbara Cross
                                        </span>
                                        <span class="d-block text-body">
                                            That cool, go for it...😀
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        8:50 AM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user6.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Rebecca Block
                                        </span>
                                        <span class="d-block text-body">
                                            Hello!
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        9:20 PAM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user7.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Ramiro McCarty
                                        </span>
                                        <span class="d-block text-body">
                                            How are you Luca?😐
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        11:50 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user8.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Robert Fairweather
                                        </span>
                                        <span class="voice d-block text-body">
                                            <i class="ri-mic-line"></i>
                                            Voice message
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        6:10 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user9.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Marcelino Haddock
                                        </span>
                                        <span class="d-block text-body">
                                            Cool ! 🔥
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Fri 3:09 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Thomas Wilson
                                        </span>
                                        <span class="d-block text-body">
                                            What are you doing...🙁
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        9:51 PM
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user11.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Nathaniel Hulsey
                                        </span>
                                        <span class="d-block text-body">
                                            Could you please...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        7:23 AM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Group Chat">
                        <button mat-button>
                            + Create New Group 
                        </button>
                        <div class="chat-users-list">
                            <div class="single-user-item active d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/courses/course1.jpg" class="rounded-circle" alt="user-image">
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            UI/UX Designer Group
                                        </span>
                                        <span class="d-block text-body">
                                            Divan: I’ll submit the file...
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        Now
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/courses/course2.jpg" class="rounded-circle" alt="user-image">
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Web Development Group
                                        </span>
                                        <span class="d-block text-body">
                                            Divan: <span class="text-success">Typing...</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        9:10 PM
                                    </span>
                                    <span class="trinta-badge d-inline-block rounded-circle">
                                        2
                                    </span>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/courses/course3.jpg" class="rounded-circle" alt="user-image">
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Discussion Group
                                        </span>
                                        <span class="d-block text-body">
                                            Divan: Great !
                                        </span>
                                    </div>
                                </div>
                                <div class="info">
                                    <span class="time d-block text-body">
                                        2:12 PM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Contacts">
                        <div class="chat-users-list">
                            <div class="single-user-item active d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Sarah Rodriguez
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user2.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Carolyn Barnes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user4.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Donna Miller
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Barbara Cross
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user6.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Rebecca Block
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user7.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Ramiro McCarty
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user8.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Robert Fairweather
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user9.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Marcelino Haddock
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status off"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Thomas Wilson
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="single-user-item d-flex align-items-center justify-content-between">
                                <div class="user d-flex align-items-center">
                                    <div class="img position-relative">
                                        <img src="assets/images/users/user11.jpg" class="rounded-circle" alt="user-image">
                                        <span class="active-status"></span>
                                    </div>
                                    <div class="title">
                                        <span class="d-block fw-medium">
                                            Nathaniel Hulsey
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-8 col-xxxl-9">
        <mat-card
            class="trinta-card chat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="chat-header d-md-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <div class="img position-relative">
                            <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                            <span class="active-status"></span>
                        </div>
                        <div class="title">
                            <span class="d-block fw-semibold heading-font-family">
                                Sarah Rodriguez
                            </span>
                            <span class="d-block text-body">
                                Active Now
                            </span>
                        </div>
                    </div>
                    <div class="buttons-list d-flex align-items-center">
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                videocam
                            </i>
                        </button>
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                call
                            </i>
                        </button>
                        <button type="button" mat-button [matMenuTriggerFor]="chatHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button>
                        <mat-menu #chatHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item>
                                Mute Chat
                            </button>
                            <button mat-menu-item>
                                Delete
                            </button>
                            <button mat-menu-item>
                                Block
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="chat-body">
                    <ul class="list-unstyled mb-0 mt-0">
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Hey Micheals, have you had a chance to check out the new admin dashboard?
                                    </p>
                                </div>
                                <div>
                                    <p class="text-black">
                                        Oh, they've got this Kanban board for task management. You can drag and drop tasks between columns – it's so intuitive. Makes managing tasks a breeze. 🔥
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:10 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        Oh, you mean the one for project management?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:20 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Yeah, that's the one! It's got a sleek Material Design, and the features are pretty robust.
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:20 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        Nice! What features are you finding interesting?
                                    </p>
                                </div>
                                <div>
                                    <p class="text-white">
                                        Hey Micheals, have you had a chance to check out the new admin dashboard?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:21 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Well, it has a project overview with all the key metrics on the landing page – project progress, pending tasks, and even a Gantt chart.
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:22 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        That sounds handy. How about the task management features?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:25 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Oh, they've got this Kanban board for task management. You can drag and drop tasks between columns – it's so intuitive. Makes managing tasks a breeze. 🔥
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:30 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        Sweet! What about team collaboration?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:31 AM
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="chat-footer border-radius d-md-flex align-items-center">
                    <div class="buttons-list d-flex align-items-center">
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                sentiment_satisfied
                            </i>
                        </button>
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                attach_file
                            </i>
                        </button>
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                mic_none
                            </i>
                        </button>
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                image
                            </i>
                        </button>
                    </div>
                    <form class="position-relative">
                        <mat-form-field>
                            <mat-label>Type something....</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <button mat-button type="button">
                            <i class="material-symbols-outlined">
                                send
                            </i>
                        </button>
                    </form>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>