<mat-card-content>
    <div class="container membershipectiontab">
          
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-sm-6 ">
            <app-clubmembership></app-clubmembership>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-6 ">
            <app-groupmembership></app-groupmembership>

          </div>
        </div>
      </div>
</mat-card-content>