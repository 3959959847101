<mat-card
    class="trinta-card lesson-preview-card mb-25 bg-white border-none d-block"
>
    <mat-card-content>
        <video controls>
            <source src="assets/images/courses/video.mp4" class="border-radius" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="row">
            <div class="col-lg-7 col-xxxl-8">
                <div class="content">
                    <div class="d-flex align-items-center">
                        <img src="assets/images/courses/lesson-preview.jpg" alt="icon">
                        <h5 class="mb-0">
                            Programming Language
                        </h5>
                    </div>
                    <p class="fs-15 text-black">
                        Node.js for Beginners: Alias animi labque, deserunt distinctio eum excepturi fuga iure labore magni molestias mollitia natus, officia pofro quis sunt temporibus veritatis voluptatem, voluptatum. 
                    </p>
                    <button mat-button type="button">
                        <i class="ri-play-line"></i>
                        Watch Promo
                    </button>
                </div>
            </div>
            <div class="col-lg-5 col-xxxl-4">
                <div class="info">
                    <div class="item d-inline-block">
                        <span class="d-block fw-medium heading-font-family">
                            Course For
                        </span>
                        <span class="d-block text-black">
                            Beginner
                        </span>
                    </div>
                    <div class="item d-inline-block">
                        <span class="d-block fw-medium heading-font-family">
                            Instructor
                        </span>
                        <span class="d-block text-black">
                            Jason Andrews
                        </span>
                    </div>
                    <div class="item d-inline-block">
                        <span class="d-block fw-medium heading-font-family">
                            Course Duration
                        </span>
                        <span class="d-block text-black">
                            06:50:10
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>