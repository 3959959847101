<mat-card class="trinta-card change-password-card mb-25 bg-white border-none d-block"
    style="display: flex; justify-content: center; align-items: center; max-width: 700px; margin: auto;">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">Change Password</h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">Old Password</label>
                    <mat-form-field>
                        <mat-label>Enter old password</mat-label>
                        <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" required>
                        <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <span class="material-symbols-outlined">{{ hide ? 'visibility_off' : 'visibility' }}</span>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">New Password</label>
                    <mat-form-field>
                        <input formControlName="newPassword" matInput [type]="hide2 ? 'password' : 'text'" required>
                        <button  type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide2">
                            <span class="material-symbols-outlined">{{ hide2 ? 'visibility_off' : 'visibility' }}</span>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">Confirm Password</label>
                    <mat-form-field>
                        <input formControlName="confirmPassword" matInput [type]="hide3 ? 'password' : 'text'" required>
                        <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide3">
                            <span class="material-symbols-outlined">{{ hide3 ? 'visibility_off' : 'visibility' }}</span>
                        </button>
                    </mat-form-field>
                    <mat-error *ngIf="
              changePasswordForm.hasError('mismatch') &&
              changePasswordForm.get('confirmPassword')?.dirty &&
              changePasswordForm.get('newPassword')?.value &&
              changePasswordForm.get('confirmPassword')?.value">
                        Passwords do not match
                    </mat-error>
                </div>
            </div>
            <div class="row save-password-btn">
                <button mat-button type="submit"
                    style="cursor: pointer; width: 300px; margin-top: 16px; justify-content: center;">
                    Change Password
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>