<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Slide Toggle
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Slide Toggle
        </li>
    </ol>
</div>

<!-- Slide Toggle -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Slide Toggle
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-slide-toggle color="primary">Slide me!</mat-slide-toggle>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Slide Toggle with Forms
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-st-with-forms></app-st-with-forms>
            </mat-card-content>
        </mat-card>
    </div>
</div>