import { Component } from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent} from "@angular/material/card";

@Component({
  selector: 'app-ride-route-card',
  standalone: true,
    imports: [
        MatButton,
        MatCard,
        MatCardContent
    ],
  templateUrl: './ride-route-card.component.html',
  styleUrl: './ride-route-card.component.scss'
})
export class RideRouteCardComponent {

}
