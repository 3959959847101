<!-- Gallery Style - 1 -->
<mat-card class="trinta-card gallery-card mb-25 bg-white border-none d-block gallerysectiontab">
   
    <mat-card-content class="gallerysectiontab">
       



        <div class="row gallery-list gallerysectiontab">
            <!-- <div class="col-lg-1 col-sm-4 col-md-4 mb-25">
                <img src="assets/group.png" class="border-radius" alt="gallery-image">
            </div> -->
            <div *ngFor="let photo of photosArray" class="col-lg-1 col-sm-4 col-md-4 mb-25">
                <img [src]="'data:image/jpeg;base64,' + photo.photo" class="border-radius" alt="gallery-image" style="height: 100%; width: 100%;">
                          </div>

            <div class="dropzone" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                <div class="text-wrapper">
                    <div class="centered">
                        <form  [formGroup]="createEventForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
                        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 5px; padding-top: 16px; padding-bottom: 16px; padding-right: 139px; padding-left: 139px; background: #EDEEEE; border-style: solid; border-width: 0px; border-radius: 7px; width: 690px"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 3.17157C2 4.34314 2 6.22876 2 9.99999V14C2 17.7712 2 19.6568 3.17157 20.8284C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.8284C22 19.6569 22 17.7712 22 14V14V9.99999C22 7.16065 22 5.39017 21.5 4.18855V17C20.5396 17 19.6185 16.6185 18.9393 15.9393L18.1877 15.1877C17.4664 14.4664 17.1057 14.1057 16.6968 13.9537C16.2473 13.7867 15.7527 13.7867 15.3032 13.9537C14.8943 14.1057 14.5336 14.4664 13.8123 15.1877L13.6992 15.3008C13.1138 15.8862 12.8212 16.1788 12.5102 16.2334C12.2685 16.2758 12.0197 16.2279 11.811 16.0988C11.5425 15.9326 11.3795 15.5522 11.0534 14.7913L11 14.6667C10.2504 12.9175 9.87554 12.0429 9.22167 11.7151C8.89249 11.5501 8.52413 11.4792 8.1572 11.5101C7.42836 11.5716 6.75554 12.2445 5.40989 13.5901L3.5 15.5V2.88739C3.3844 2.97349 3.27519 3.06795 3.17157 3.17157Z" fill="#1F2A44"/>
                            <path d="M3 10C3 8.08611 3.00212 6.75129 3.13753 5.74416C3.26907 4.76579 3.50966 4.2477 3.87868 3.87868C4.2477 3.50966 4.76579 3.26907 5.74416 3.13753C6.75129 3.00212 8.08611 3 10 3H14C15.9139 3 17.2487 3.00212 18.2558 3.13753C19.2342 3.26907 19.7523 3.50966 20.1213 3.87868C20.4903 4.2477 20.7309 4.76579 20.8625 5.74416C20.9979 6.75129 21 8.08611 21 10V14C21 15.9139 20.9979 17.2487 20.8625 18.2558C20.7309 19.2342 20.4903 19.7523 20.1213 20.1213C19.7523 20.4903 19.2342 20.7309 18.2558 20.8625C17.2487 20.9979 15.9139 21 14 21H10C8.08611 21 6.75129 20.9979 5.74416 20.8625C4.76579 20.7309 4.2477 20.4903 3.87868 20.1213C3.50966 19.7523 3.26907 19.2342 3.13753 18.2558C3.00212 17.2487 3 15.9139 3 14V10Z" stroke="#1F2A44" stroke-width="2"/>
                            <circle cx="15" cy="9" r="2" fill="#1F2A44"/>
                            </svg>
                            <div class="flex justify-start items-start flex-col"><p class="self-stretch text-[#000000] text-sm font-['Libre_Franklin'] text-center leading-[1.4]">Drop your image or </p><span class="text-[#5275C3] text-sm font-['Libre_Franklin'] text-center leading-[1.4]">
                                <input type="file" id="fileInput"  type="file" (change)="onFileSelected($event, 'fileInput')"  />
                                <label for="fileInput">
                                    <span class="textLink">select to browse</span>
                                </label>
                                </span>
                            </div>
                          </div>
                
                            <div *ngIf="selectedFile" class="file-details">
                                <img *ngIf="filePreview" [src]="filePreview" alt="File preview"  style="height: 400px;"/>
                            </div>
                       
                            </form>
                    </div>
                </div>
            </div>
            
        </div>
      
        
    </mat-card-content>
</mat-card>