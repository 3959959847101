<mat-card
    class="trinta-card student-progress-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Students Progress
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="student-progress-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Course Column -->
                    <ng-container matColumnDef="course">
                        <th mat-header-cell *matHeaderCellDef class="pl-0 pr-0">
                            Course
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0 pr-0">
                            <div class="course d-flex">
                                <div class="image flex-shrink-0">
                                    <img [src]="element.course.img" alt="course-image">
                                </div>
                                <div class="info flex-grow-1">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a routerLink="/" class="title d-block fw-semibold">
                                            {{element.course.name}}
                                        </a>
                                        <span class="d-block lh-1 text-black">
                                            {{element.course.progressPercentage}}%
                                        </span>
                                    </div>
                                    <mat-progress-bar mode="determinate" value="{{element.course.progressPercentage}}"></mat-progress-bar>
                                    <a routerLink="/" class="course-name d-inline-block position-relative">
                                        <i class="ri-book-open-line"></i>
                                        {{element.course.courseName}}
                                    </a>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[4, 8, 12]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>