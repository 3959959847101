<mat-card
    class="trinta-card daily-performance-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Daily Performance
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                <i class="ri-more-2-fill"></i>
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    Last Day
                </button>
                <button mat-menu-item>
                    Last Week
                </button>
                <button mat-menu-item>
                    Last Month
                </button>
                <button mat-menu-item>
                    Last Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [plotOptions]="chartOptions.plotOptions!"
                [labels]="chartOptions.labels!"
                [colors]="chartOptions.colors!"
                [stroke]="chartOptions.stroke!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>