import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSelect} from "@angular/material/select";
import {MatSlideToggle} from "@angular/material/slide-toggle";


import {MatTooltip} from "@angular/material/tooltip";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
  selector: 'app-route',
  standalone: true,
    imports: [
        FormsModule,
        MatButton,
        MatDialogContent,
        MatDialogTitle,
        MatFormField,
        MatIcon,
        MatInput,
        MatLabel,
        MatMiniFabButton,
        MatOption,
        MatRadioButton,
        MatRadioGroup,
        MatSelect,
        MatSlideToggle,
        ReactiveFormsModule,
        CommonModule,
        MatIcon,
        MatSlideToggle,
        MatOption,
        MatSelect,
        MatTooltip,
        HttpClientModule,
        CommonModule,
        RouterLink,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule,
    ],
  templateUrl: './route.component.html',
  styleUrl: './route.component.scss'
})
export class RouteComponent {
  constructor(
    public dialogRef: MatDialogRef<RouteComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,

  ) {
    this.createRouteForm = this.fb.group({
      name: ['', [Validators.required]],
      linkurl: ['', [Validators.required]],
    });
  }

  // Password Hide
  hide = true;

  // Form
  createRouteForm: FormGroup;

  onSubmit() {
    if (this.createRouteForm.valid) {
      console.log(this.createRouteForm.value);


      const linkurl = this.createRouteForm.get('linkurl')?.value; // Assuming username is entered in the email field
      const name = this.createRouteForm.get('name')?.value;
  
      
           
          // Construct the login object
            const requestBody = {
              linkurl: linkurl,
              name: name,
            
            };

          
           const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

            this.http.post(BASE_URL + ApiEndpoints.createRoute, requestBody, { headers: headers }).subscribe(
                (res: any) => {
                    if (res) {
                        alert("Route created successfully");
                        // this.router.navigate(['/dashboard']);
                        window.location.reload();

                    } else {
                        // Handle other cases where response is not as expected
                        console.error("Unexpected response:", res);
                        alert("Error, Failed");
                    }
                },
                (error) => {
                    if (error.status === 401) {
                        // Handle 401 Unauthorized error
                        console.log("Unauthorized:", error.status);
                        alert("Unauthorized: Invalid username or password");
                    } else  if (error.status === 500) {
                        // Handle 401 Unauthorized error
                        console.log("Unauthorized:", error.status);
                        alert("Internal server error");
                    } else {
                        // Handle other errors
                        console.error("Error logging in:", error);
                        alert("Login failed. Please try again later.");
                    }
                }
            );

    } else {
      console.log('Form is invalid. Please check the fields.');
    }
  }

  // Correctly place the onNoClick method outside the constructor
  onNoClick() {
    this.dialogRef.close();
  }

}
