<mat-card
    class="trinta-card orders-list-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Items From Order
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="orders-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Product
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/ecommerce-page/product-details" class="image d-block">
                                    <img [src]="element.product.img" alt="product-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/ecommerce-page/product-details" class="d-block fw-semibold">
                                        {{element.product.title}}
                                    </a>
                                    <span class="d-block text-body">
                                        {{element.product.date}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Quantity Column -->
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>
                            Quantity
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.quantity}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.price}}
                        </td>
                    </ng-container>

                    <!-- Total Column -->
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef>
                            Total
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.total}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>
    </mat-card-content>
</mat-card>