<mat-card
    class="trinta-card faq-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Frequently Asked Questions
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form class="search-box position-relative">
            <input type="text" class="d-block w-100 border-none" placeholder="Search your answer..">
            <button type="button" class="p-0 d-flex align-items-center justify-content-center cursor-pointer border-none">
                <i class="ri-search-line"></i>
            </button>
        </form>
        <div class="row">
            <div class="col-lg-6">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Dynamically procrastinate B2C users after installed.
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Efficiently unleash cross-media information without cross-media value.
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                How many variations exist?
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                It has roots in a piece of classical
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Sed do eiusmod tempor incididunt dolore magna.
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="col-lg-6">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                When can be used? License & Copyright
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Get access to business-critical applications like form Dynamically?
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Is safe use Lorem Ipsum?
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Spend for one & gain access to multiple !
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Plan and will i have access to all applications?
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="text-black">
                            Lorem ipsum dolor sit amet, vix an natum labitur eleifd, mel am laoreet menandri. Ei justo complectitur duo. Ei mundi solet utos soletu possit quo. Sea cu justo laudem. 
                        </p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </mat-card-content>
</mat-card>