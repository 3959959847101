<div class="title text-center">
    <button mat-mini-fab color="primary" (click)="onNoClick()" class="close" style="cursor: pointer; float: right; margin-right: 16px;     margin-right: 4px;
    margin-top: 4px;" >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>


    <div class="viewDetailsData">


        <img [src]="createRideForm.get('image')?.value ? 'data:image/png;base64,' + createRideForm.get('image')?.value : '../../../../assets/pimg.png'"
            class="profile-img" style="    width: 400px;
            height: 100%;
            height: 100%;
            margin: auto;
            display: flex;">

        <!-- {{ createRideForm.get('image')?.value }} -->


        <div class="row col-xxxl-12 col-lg-12 col-md-12" style="padding-top: 20px;">

            <div class="col-md-3">
                <label class="labeltime">Time: <span
                        class="datavalue">{{formatTimeTo12Hour(createRideForm.get('time')?.value) }}</span></label>
            </div>

            <div class="col-md-6">
                <h2 style="text-align: center;" class="titleheadersec">
                    {{ createRideForm.get('title')?.value }}
                </h2>
            </div>


            <div class="col-md-3 datavaluedesc">
                <label class="label">
                    <span class="datavaluemiles">
                        <!-- Limit {{ createRideForm.get('groupSize')?.value
                        }} -->

                    {{ createRideForm.get('groupSize')?.value == 0 ? 'Unlimited' : ' Limit  ' + createRideForm.get('groupSize')?.value }}

                        
                    </span>
                
                
                </label>
            </div>
        </div>

        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Start Date</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ formatDate(createRideForm.get('date')?.value) }} </span>

            </div>
        </div>


        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">End Date</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ formatDate(createRideForm.get('endDate')?.value) }} </span>

            </div>
        </div>


        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Cost</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                {{ createRideForm.get('amount')?.value == 0 ? 'FREE' : '$' + createRideForm.get('amount')?.value }}
            </div>
        </div>



        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Organizer</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('organizer')?.value }}</span>
            </div>
        </div>




        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">City</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('city')?.value }}</span>
            </div>
        </div>

        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">State</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('state')?.value }}</span>
            </div>
        </div>


        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Location</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('location')?.value }}</span>
            </div>
        </div>

        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Address</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('address')?.value }}</span>
            </div>
        </div>

        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Link</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span><a href="">{{ createRideForm.get('url')?.value }}</a></span>
            </div>
        </div>




        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="row col-md-12">
                <label class="label">Description</label>
            </div>

            <div class="row col-md-12 datavaluedescdc">
                <span >{{ createRideForm.get('description')?.value }}</span>
            </div>
        </div>


    </div>





    <mat-card-content>
        <h3 style="font-size: 16px; text-align: center;
          padding-top: 16px;  text-decoration: underline;">Registered</h3>
        <div class="recent-orders-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource.data">
                    <ng-container matColumnDef="profilePhoto">
                        <th mat-header-cell *matHeaderCellDef style="width: 80px;">

                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a class="image d-block">
                                    <!-- <img [src]="'data:image/png;base64,' + element.profilePhoto" class="profile-img" style="border-radius: 50%;">
                                <img src="../../../../assets/pr1.png" alt="product-image"> -->
                                    <img *ngIf="element.profilePhoto; else defaultImage"
                                        [src]="'data:image/png;base64,' + element.profilePhoto" class="profile-img"
                                        style="border-radius: 50%; width: 50px; height: 50px;">
                                    <ng-template #defaultImage>
                                        <img src="../../../../assets/profile.png" alt="product-image"
                                            class="profile-img" style="border-radius: 50%; width: 50px;">
                                    </ng-template>

                                </a>
                                <!-- <div class="info">
                                <a  class="d-block fw-semibold">
                                    {{element.name}}
                                </a>

                            </div> -->
                            </div>
                        </td>
                    </ng-container>


                    <!-- Quantity Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>


    </mat-card-content>
    <div class="row"
        style="display: flex; justify-content: space-between; align-items: center; padding-top: 16px; padding-left: 8%; padding-right: 8%;">
        <div class="column">
            <div class="details-container"  style="display: flex; justify-content: center; align-items: center; padding: 2px; border-radius: 7px;">
              <div class="detail" (click)="cancelUpcomingEvent()"  style="display: flex; align-items: center; color: #DA1884; font-size: 16px; font-family: Libre Franklin; font-weight: 600; cursor: pointer;">
          

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="26" height="26">
                    <!-- Outer magenta circle with a thinner stroke -->
                    <circle cx="40" cy="40" r="35" fill="white" stroke="#DA1884" stroke-width="2"/>
                  
                    <!-- X shape with thinner lines -->
                    <line x1="27" y1="27" x2="53" y2="53" stroke="#DA1884" stroke-width="3" stroke-linecap="round"/>
                    <line x1="53" y1="27" x2="27" y2="53" stroke="#DA1884" stroke-width="3" stroke-linecap="round"/>
                  </svg>
                  
                  

                
                Cancel Event
              </div>
            </div>
          </div>
          

    </div>
</div>