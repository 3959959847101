import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
@Component({
  selector: 'app-upcomingridesviewdetails',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatRadioGroup,
    MatCardModule,
    MatCardModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule,
    MatRadioButton,
    MatInput,
    MatDialogModule,
    MatButtonModule,
    MatLabel,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule, MatTabsModule
  ],

  providers: [provideNativeDateAdapter()],
  templateUrl: './upcomingridesviewdetails.component.html',
  styleUrl: './upcomingridesviewdetails.component.scss'
})
export class UpcomingridesviewdetailsComponent {


  displayedColumns: string[] = ['profilePhoto', 'firstname', 'phone'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  paces: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  purposes: any[] = [];
  levels: any[] = [];
  recurrences: any[] = [];
  inoutdoors: any[] = [];
  groups: any[] = [];
  rideTypes: any[] = [];
  selectedFile: File | null = null;
  base64String: string = '';
  displayDate: string | null = null;
  rideId: string | null = null;
  logo: string = '';
  photoimg: string = '';

  rideType: string = '';
  recurrence: string = '';
  pace: string = '';
  indooroutdoor: string = '';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpcomingridesviewdetailsComponent>,
    private http: HttpClient,
    private router: Router,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createRideForm = this.fb.group({
      rideId: [this.data.rideId],
      rideName: [this.data?.name || ''],
      description: [this.data?.description],
      ridinggroupId: [this.data?.ridinggroupId || ''],
      startLocation: [this.data?.startlocation || ''],
      cityId: [this.data?.cityId || ''],
      stateId: [this.data?.stateId || ''],
      route: [this.data?.route || ''],
      limit: [this.data?.size || ''],
      endTime: [this.data?.endTime || ''],
      privacyId: [this.data?.privacyId || ''],
      level: [this.data?.level || ''],
      distance: [this.extractNumberFromString(data?.distance) || ''],
      link: [this.data?.link || ''],
      pace: [this.data?.pace || ''],
      purpose: [this.data?.purpose || ''],
      date: [this.data?.date || ''],
      time: [this.data?.time || ''],
      recurrence: [this.data?.recurrence || ''],
      preferredBikeTypes: [this.data?.preferredBikeTypes || ''],
      rideLeader: [this.data?.rideLeader || ''],
      outDoor: [this.data?.outDoor || 'No'],
      inDoor: [this.data?.inDoor || 'No'],
      logo: [this.data?.rideImage],
    });

    console.log("this.datathis.data", this.data)
    // this.dateAdapter.setLocale('en-GB'); 
  }





  extractNumberFromString(input: string): number | null {
    const match = input.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  async onSubmit() {

  }

  formatTimeTo12Hour(time: string): string {
    if (!time) return '';

    const [hourString, minuteString] = time.split(':');
    let hour = parseInt(hourString, 10);
    const minute = parseInt(minuteString, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';

    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'

    const minuteFormatted = minute < 10 ? '0' + minute : minute;
    return `${hour}:${minuteFormatted} ${ampm}`;
  }

  submitForm() {

  }

  ngOnInit(): void {
    this.fetchgroupdetails();
    this.fetchRideByID();
  }



  // Password Hide
  hide = true;
  createRideForm: FormGroup;

  // Form
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    // Handle save action
    this.dialogRef.close(this.createRideForm.value);
  }


  fetchgroupdetails(): void {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    const rideId = this.data.rideId;
    this.http.get<any[]>(`${BASE_URL}${ApiEndpoints.ridersPerRide}${rideId}`, { headers })
      .subscribe(
        (data) => {
          this.dataSource.data = data;

          console.log("data", data)
        },
        (error) => {
        }
      );

  }

  fetchRideByID(): void {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    const rideId = this.data.rideId;
    
    this.http.get<any>(`${BASE_URL}${ApiEndpoints.getRideById}${rideId}`, { headers })
      .subscribe(
        (data) => {
          console.log("Response data:", data.entity);
          // Assuming 'entity' contains the ride data directly
          this.photoimg = data.entity; // Update this if the structure is different
        },
        (error) => {
          console.error('Error fetching ride:', error);
        }
      );
  }
  
}


