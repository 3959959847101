<mat-stepper #stepper>
    <mat-step>
        <ng-template matStepLabel>
            Step 1

            
        </ng-template>
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            First Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>
                                Enter first name
                            </mat-label>
                            <input matInput placeholder="E.g. Mateo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Last Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>
                                Enter last name
                            </mat-label>
                            <input matInput placeholder="E.g. Luca">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Email Address
                        </label>
                        <mat-form-field>
                            <i-feather name="mail"></i-feather>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput placeholder="E.g. hello@trinta.com">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Phone Number
                        </label>
                        <mat-form-field>
                            <i-feather name="phone"></i-feather>
                            <mat-label>
                                Enter phone number
                            </mat-label>
                            <input matInput placeholder="E.g. +123 452183">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Address
                        </label>
                        <mat-form-field>
                            <i-feather name="map-pin"></i-feather>
                            <mat-label>
                                Enter address
                            </mat-label>
                            <input matInput placeholder="E.g. Amsterdam">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Country
                        </label>
                        <mat-form-field>
                            <i-feather name="map"></i-feather>
                            <mat-label>
                                Enter country
                            </mat-label>
                            <input matInput placeholder="E.g. Netherlands">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div>
                <button mat-flat-button matStepperNext color="primary">Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            Step 2
        </ng-template>
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            First Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>
                                Enter first name
                            </mat-label>
                            <input matInput placeholder="E.g. Mateo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Last Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>
                                Enter last name
                            </mat-label>
                            <input matInput placeholder="E.g. Luca">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Email Address
                        </label>
                        <mat-form-field>
                            <i-feather name="mail"></i-feather>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput placeholder="E.g. hello@trinta.com">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Phone Number
                        </label>
                        <mat-form-field>
                            <i-feather name="phone"></i-feather>
                            <mat-label>
                                Enter phone number
                            </mat-label>
                            <input matInput placeholder="E.g. +123 452183">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Address
                        </label>
                        <mat-form-field>
                            <i-feather name="map-pin"></i-feather>
                            <mat-label>
                                Enter address
                            </mat-label>
                            <input matInput placeholder="E.g. Amsterdam">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Country
                        </label>
                        <mat-form-field>
                            <i-feather name="map"></i-feather>
                            <mat-label>
                                Enter country
                            </mat-label>
                            <input matInput placeholder="E.g. Netherlands">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div>
                <button mat-flat-button matStepperPrevious class="mr-15">Back</button>
                <button mat-flat-button matStepperNext color="primary">Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            Step 3
        </ng-template>
        <form>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            First Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>
                                Enter first name
                            </mat-label>
                            <input matInput placeholder="E.g. Mateo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Last Name
                        </label>
                        <mat-form-field>
                            <i-feather name="user"></i-feather>
                            <mat-label>
                                Enter last name
                            </mat-label>
                            <input matInput placeholder="E.g. Luca">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Email Address
                        </label>
                        <mat-form-field>
                            <i-feather name="mail"></i-feather>
                            <mat-label>
                                Enter email address
                            </mat-label>
                            <input matInput placeholder="E.g. hello@trinta.com">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Phone Number
                        </label>
                        <mat-form-field>
                            <i-feather name="phone"></i-feather>
                            <mat-label>
                                Enter phone number
                            </mat-label>
                            <input matInput placeholder="E.g. +123 452183">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Address
                        </label>
                        <mat-form-field>
                            <i-feather name="map-pin"></i-feather>
                            <mat-label>
                                Enter address
                            </mat-label>
                            <input matInput placeholder="E.g. Amsterdam">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-20">
                        <label class="main-label d-block lh-1 text-black mb-10">
                            Country
                        </label>
                        <mat-form-field>
                            <i-feather name="map"></i-feather>
                            <mat-label>
                                Enter country
                            </mat-label>
                            <input matInput placeholder="E.g. Netherlands">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div>
                <button mat-flat-button matStepperPrevious class="mr-15">Back</button>
                <button mat-flat-button matStepperNext color="primary">Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            Step 4
        </ng-template>
        <p>
            You are now done.
        </p>
        <div>
            <button mat-flat-button color="primary" class="mr-15" matStepperPrevious>Back</button>
            <button mat-flat-button color="warn" (click)="stepper.reset()">Reset</button>
        </div>
    </mat-step>
</mat-stepper>