<mat-card
    class="trinta-card contacts-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Contacts
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="contacts-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Lead Column -->
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Lead
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0">
                            <div class="user-info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.user.img" alt="user-image">
                                </div>
                                <div class="info">
                                    <h6 class="mt-0">
                                        {{element.user.name}}
                                    </h6>
                                    <span class="d-block text-body">
                                        {{element.user.number}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.email}}
                        </td>
                    </ng-container>

                    <!-- Deal Value Column -->
                    <ng-container matColumnDef="dealValue">
                        <th mat-header-cell *matHeaderCellDef>
                            Deal Value
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary">
                            {{element.dealValue}}
                        </td>
                    </ng-container>

                    <!-- Company Column -->
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef>
                            Company
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.company}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="trinta-badge fw-medium d-inline-block" *ngIf="element.status.new">
                                {{element.status.new}}
                            </span>
                            <span class="trinta-badge fw-medium lost d-inline-block" *ngIf="element.status.lost">
                                {{element.status.lost}}
                            </span>
                            <span class="trinta-badge fw-medium converted d-inline-block" *ngIf="element.status.converted">
                                {{element.status.converted}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.edit}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.delete}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 12, 16]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>