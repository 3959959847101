<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Accordion
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Accordion
        </li>
    </ol>
</div>

<!-- Accordion -->
<mat-card
    class="trinta-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Accordion
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <cdk-accordion class="accordion">
            @for (item of items; track item; let index = $index) {
                <cdk-accordion-item
                    #accordionItem="cdkAccordionItem"
                    class="accordion-item"
                    role="button"
                    tabindex="0"
                    [attr.id]="'accordion-header-' + index"
                    [attr.aria-expanded]="accordionItem.expanded"
                    [attr.aria-controls]="'accordion-body-' + index"
                >
                    <div class="accordion-item-header fw-medium" (click)="accordionItem.toggle()">
                        {{ item }}
                        <span class="accordion-item-description fw-normal">
                            Click to {{ accordionItem.expanded ? 'close' : 'open' }}
                        </span>
                    </div>
                    <div
                        class="accordion-item-body"
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index"
                    >
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis excepturi incidunt ipsum deleniti labore, tempore non nam doloribus blanditiis veritatis illo autem iure aliquid ullam rem tenetur deserunt velit culpa? Sit amet consectetur adipisicing elit. Perferendis excepturi incidunt ipsum deleniti labore, tempore non nam doloribus blanditiis veritatis illo autem iure aliquid ullam rem tenetur.
                        </p>
                    </div>
                </cdk-accordion-item>
            }
        </cdk-accordion>
    </mat-card-content>
</mat-card>