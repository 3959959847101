<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Datepicker that uses Moment.js Dates
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <i-feather name="list"></i-feather>
            <mat-label>Moment.js datepicker</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
    </mat-card-content>
</mat-card>