<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Expansion
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Expansion
        </li>
    </ol>
</div>

<!-- Basic Expansion -->
<app-basic-expansion></app-basic-expansion>

<!-- Expansion as Accordion -->
<app-expansion-as-accordion></app-expansion-as-accordion>

<!-- Accordion with Expand/Collapse all Toggles -->
<app-accordion-expand-collapse-toggles></app-accordion-expand-collapse-toggles>