import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';

import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from "@angular/material/icon";
@Component({
  selector: 'app-upcomingridesdelete',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIcon],
  templateUrl: './upcomingridesdelete.component.html',
  styleUrl: './upcomingridesdelete.component.scss'
})
export class UpcomingridesdeleteComponent {

  constructor(public dialogRef: MatDialogRef<UpcomingridesdeleteComponent>) {}

  onCancel(): void {
    this.dialogRef.close(false);  // Close and return false
  }

  onNoClick() {
    this.dialogRef.close();
  }



  onConfirm(): void {
    this.dialogRef.close(true);  // Close and return true
  }
}
