import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';

import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from "@angular/material/icon";
@Component({
  selector: 'app-rideclose',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIcon],
  templateUrl: './rideclose.component.html',
  styleUrl: './rideclose.component.scss'
})
export class RidecloseComponent {
  constructor(public dialogRef: MatDialogRef<RidecloseComponent>) {}

  onCancel(): void {
    this.dialogRef.close(false);  // Close and return false
  }

  onNoClick() {
    this.dialogRef.close();
  }
  
  saveDraftEvents(): void {
    this.dialogRef.close(true);  // Close and return true
  }



  onConfirm(): void {
    this.dialogRef.close(true);  // Close and return true
  }
}
