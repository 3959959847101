<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Avatars
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Avatars
        </li>
    </ol>
</div>

<!-- Avatars -->
<div class="row">
    <div class="col-xxl-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Image Size Rounded-Circle
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="d-md-flex align-items-center">
                    <img src="assets/images/admin.jpg" width="130" height="130" class="rounded-circle" alt="admin-image">
                    <img src="assets/images/admin.jpg" width="110" height="110" class="rounded-circle" alt="admin-image">
                    <img src="assets/images/admin.jpg" width="90" height="90" class="rounded-circle" alt="admin-image">
                    <img src="assets/images/admin.jpg" width="70" height="70" class="rounded-circle" alt="admin-image">
                    <img src="assets/images/admin.jpg" width="50" height="50" class="rounded-circle" alt="admin-image">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Image Size Square-Circle
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="d-md-flex align-items-center">
                    <img src="assets/images/users/user1.jpg" width="130" height="130" class="border-radius" alt="admin-image">
                    <img src="assets/images/users/user1.jpg" width="110" height="110" class="border-radius" alt="admin-image">
                    <img src="assets/images/users/user1.jpg" width="90" height="90" class="border-radius" alt="admin-image">
                    <img src="assets/images/users/user1.jpg" width="70" height="70" class="border-radius" alt="admin-image">
                    <img src="assets/images/users/user1.jpg" width="50" height="50" class="border-radius" alt="admin-image">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Avatar Size Rounded-Circle
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="d-md-flex align-items-center">
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-semibold fs-25 text-primary text-center w-130 h-130">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-semibold fs-20 text-primary text-center w-110 h-110">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-semibold fs-18 text-primary text-center w-90 h-90">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-semibold fs-18 text-primary text-center w-70 h-70">
                        Ab
                    </div>
                    <div class="rounded-circle justify-content-center bg-gray align-items-center d-flex fw-semibold text-primary text-center w-50 h-50">
                        Ab
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Avatar Size Square-Circle
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="d-md-flex align-items-center">
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-semibold fs-25 text-primary text-center w-130 h-130">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-semibold fs-20 text-primary text-center w-110 h-110">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-semibold fs-18 text-primary text-center w-90 h-90">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-semibold fs-18 text-primary text-center w-70 h-70">
                        Ab
                    </div>
                    <div class="border-radius justify-content-center bg-gray align-items-center d-flex fw-semibold text-primary text-center w-50 h-50">
                        Ab
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>