<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Line Charts
    </h5>
</div>

<!-- Line Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Line Chart -->
        <app-basic-line-chart></app-basic-line-chart>

    </div>
    <div class="col-lg-6">

        <!-- Zoomable Line Chart -->
        <app-zoomable-line-chart></app-zoomable-line-chart>

    </div>
    <div class="col-lg-6">

        <!-- Gradient Line Chart -->
        <app-gradient-line-chart></app-gradient-line-chart>

    </div>
    <div class="col-lg-6">

        <!-- Dashed Line Chart -->
        <app-dashed-line-chart></app-dashed-line-chart>

    </div>
    <div class="col-lg-6">

        <!-- Stepline Line Chart -->
        <app-stepline-line-chart></app-stepline-line-chart>

    </div>
    <div class="col-lg-6">

        <!-- Brush Line Chart -->
        <app-brush-line-chart></app-brush-line-chart>

    </div>
    <div class="col-lg-6">

        <!-- Syncing Line Chart -->
        <app-syncing-line-chart></app-syncing-line-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Area Charts
    </h5>
</div>

<!-- Area Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Area Chart -->
        <app-basic-area-chart></app-basic-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Spline Area Chart -->
        <app-spline-area-chart></app-spline-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Datetime Area Chart -->
        <app-datetime-area-chart></app-datetime-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Negative Values Area Chart -->
        <app-negative-values-area-chart></app-negative-values-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Stacked Area Chart -->
        <app-stacked-area-chart></app-stacked-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Missing Null Values Area Chart -->
        <app-missing-null-values-area-chart></app-missing-null-values-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Github Style Area Chart -->
        <app-github-style-area-chart></app-github-style-area-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Column Charts
    </h5>
</div>

<!-- Column Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Column Chart -->
        <app-basic-column-chart></app-basic-column-chart>

    </div>
    <div class="col-lg-6">

        <!-- Data Labels Column Chart -->
        <app-data-labels-column-chart></app-data-labels-column-chart>

    </div>
    <div class="col-lg-6">

        <!-- Stacked Column Chart -->
        <app-stacked-column-chart></app-stacked-column-chart>

    </div>
    <div class="col-lg-6">

        <!-- Range Column Chart -->
        <app-range-column-chart></app-range-column-chart>

    </div>
    <div class="col-lg-6">

        <!-- Rotated Labels Column Chart -->
        <app-rotated-labels-column-chart></app-rotated-labels-column-chart>

    </div>
    <div class="col-lg-6">

        <!-- Distributed Column Chart -->
        <app-distributed-column-chart></app-distributed-column-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Mixed Charts
    </h5>
</div>

<!-- Mixed Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Line & Column Chart -->
        <app-line-column-chart></app-line-column-chart>

    </div>
    <div class="col-lg-6">

        <!-- Multiple Y-Axis Chart -->
        <app-multiple-yaxis-chart></app-multiple-yaxis-chart>

    </div>
    <div class="col-lg-6">

        <!-- Line & Area Chart -->
        <app-line-area-chart></app-line-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Line, Column & Area Chart -->
        <app-line-column-area-chart></app-line-column-area-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Pie Charts
    </h5>
</div>

<!-- Pie Charts -->
<div class="row">
    <div class="col-lg-6 col-xxxl-4">

        <!-- Basic Pie Chart -->
        <app-basic-pie-chart></app-basic-pie-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Pie Donut Chart -->
        <app-pie-donut-chart></app-pie-donut-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Pie Monochrome Chart -->
        <app-pie-monochrome-chart></app-pie-monochrome-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        RadialBar Charts
    </h5>
</div>

<!-- RadialBar Charts -->
<div class="row">
    <div class="col-lg-6 col-xxxl-4">

        <!-- Basic RadialBar Chart -->
        <app-basic-radialbar-chart></app-basic-radialbar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Multiple RadialBar Chart -->
        <app-multiple-radialbar-chart></app-multiple-radialbar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Custom Angle Circle RadialBar Chart -->
        <app-custom-angle-circle-radialbar-chart></app-custom-angle-circle-radialbar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Gradient RadialBar Chart -->
        <app-gradient-radialbar-chart></app-gradient-radialbar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Strocked Circular Gauge RadialBar Chart -->
        <app-strocked-circular-gauge-radialbar-chart></app-strocked-circular-gauge-radialbar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Semi Circular Gauge RadialBar Chart -->
        <app-semi-circular-gauge-radialbar-chart></app-semi-circular-gauge-radialbar-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Radar Charts
    </h5>
</div>

<!-- Radar Charts -->
<div class="row">
    <div class="col-lg-6 col-xxxl-4">

        <!-- Basic Radar Chart -->
        <app-basic-radar-chart></app-basic-radar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Multiple Radar Chart -->
        <app-multiple-radar-chart></app-multiple-radar-chart>

    </div>
    <div class="col-lg-6 col-xxxl-4">

        <!-- Polygon Radar Chart -->
        <app-polygon-radar-chart></app-polygon-radar-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        Polar Charts
    </h5>
</div>

<!-- Polar Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Polar Chart -->
        <app-basic-polar-chart></app-basic-polar-chart>

    </div>
    <div class="col-lg-6">

        <!-- Monochrome Polar Chart -->
        <app-monochrome-polar-chart></app-monochrome-polar-chart>

    </div>
</div>

<!-- Breadcrumb -->
<div class="mb-25">
    <h5 class="m-0">
        More Charts
    </h5>
</div>

<!-- More Charts -->
<div class="row">
    <div class="col-lg-6">

        <!-- Basic Range Area Chart -->
        <app-basic-range-area-chart></app-basic-range-area-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Timeline Chart -->
        <app-basic-timeline-chart></app-basic-timeline-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Candlestick Chart -->
        <app-basic-candlestick-chart></app-basic-candlestick-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Boxplot Chart -->
        <app-basic-boxplot-chart></app-basic-boxplot-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Bubble Chart -->
        <app-basic-bubble-chart></app-basic-bubble-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Scatter Chart -->
        <app-basic-scatter-chart></app-basic-scatter-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Heatmap Chart -->
        <app-basic-heatmap-chart></app-basic-heatmap-chart>

    </div>
    <div class="col-lg-6">

        <!-- Basic Treemap Chart -->
        <app-basic-treemap-chart></app-basic-treemap-chart>

    </div>
</div>