<mat-card
    class="trinta-card add-user-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Add User
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        First Name
                    </label>
                    <mat-form-field>
                        <i-feather name="user"></i-feather>
                        <mat-label>Enter name</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Last Name
                    </label>
                    <mat-form-field>
                        <i-feather name="user"></i-feather>
                        <mat-label>Enter name</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Email Address
                    </label>
                    <mat-form-field>
                        <i-feather name="mail"></i-feather>
                        <mat-label>Enter email address</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Contact Number
                    </label>
                    <mat-form-field>
                        <i-feather name="phone"></i-feather>
                        <mat-label>Enter phone number</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Address
                    </label>
                    <mat-form-field>
                        <i-feather name="map-pin"></i-feather>
                        <mat-label>Your location</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Country
                    </label>
                    <mat-form-field>
                        <i-feather name="map-pin"></i-feather>
                        <mat-label>Select</mat-label>
                        <mat-select>
                            <mat-option value="Germany">Germany</mat-option>
                            <mat-option value="United States of America">United States of America</mat-option>
                            <mat-option value="Canada">Canada</mat-option>
                            <mat-option value="United Kingdom">United Kingdom</mat-option>
                            <mat-option value="Sweden">Sweden</mat-option>
                            <mat-option value="Netherlands">Netherlands</mat-option>
                            <mat-option value="Japan">Japan</mat-option>
                            <mat-option value="Switzerland">Switzerland</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <label class="label d-block fw-medium mb-10">
                        Select City
                    </label>
                    <mat-form-field>
                        <i-feather name="map-pin"></i-feather>
                        <mat-label>Select</mat-label>
                        <mat-select>
                            <mat-option value="Tokyo">Tokyo</mat-option>
                            <mat-option value="Beijing">Beijing</mat-option>
                            <mat-option value="Seoul">Seoul</mat-option>
                            <mat-option value="London">London</mat-option>
                            <mat-option value="Paris">Paris</mat-option>
                            <mat-option value="São Paulo">São Paulo</mat-option>
                            <mat-option value="Osaka">Osaka</mat-option>
                            <mat-option value="Barcelona">Barcelona</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <label class="label d-block fw-medium mb-10">
                        Select State
                    </label>
                    <mat-form-field>
                        <i-feather name="map-pin"></i-feather>
                        <mat-label>Select</mat-label>
                        <mat-select>
                            <mat-option value="Vermont">Vermont</mat-option>
                            <mat-option value="Minnesota">Minnesota</mat-option>
                            <mat-option value="Washington">Washington</mat-option>
                            <mat-option value="Idaho">Idaho</mat-option>
                            <mat-option value="Florida">Florida</mat-option>
                            <mat-option value="Iowa">Iowa</mat-option>
                            <mat-option value="New Hampshire">New Hampshire</mat-option>
                            <mat-option value="Nebraska">Nebraska</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <label class="label d-block fw-medium mb-10">
                        Zip Code
                    </label>
                    <mat-form-field>
                        <i-feather name="code"></i-feather>
                        <mat-label>Enter zip code</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Some Information
                    </label>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor [editor]="editor" [ngModel]="html" name="html" [disabled]="false" [placeholder]="'Some demo text...'"></ngx-editor>
                    </div>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Select Your Skills
                    </label>
                    <mat-form-field>
                        <i-feather name="tag"></i-feather>
                        <mat-label>Select</mat-label>
                        <mat-select multiple>
                            <mat-option value="Communication">Communication</mat-option>
                            <mat-option value="Data Analysis">Data Analysis</mat-option>
                            <mat-option value="Project Management">Project Management</mat-option>
                            <mat-option value="Leadership">Leadership</mat-option>
                            <mat-option value="User Experience">User Experience</mat-option>
                            <mat-option value="SEO">SEO</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Company Name
                    </label>
                    <mat-form-field>
                        <i-feather name="globe"></i-feather>
                        <mat-label>Enter name</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Company Websiet
                    </label>
                    <mat-form-field>
                        <i-feather name="coffee"></i-feather>
                        <mat-label>Enter website</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Department
                    </label>
                    <mat-form-field>
                        <i-feather name="octagon"></i-feather>
                        <mat-label>Enter department</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Designation
                    </label>
                    <mat-form-field>
                        <i-feather name="codepen"></i-feather>
                        <mat-label>Enter designation</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Hiring Date
                    </label>
                    <mat-form-field>
                        <i-feather name="calendar"></i-feather>
                        <mat-label>Enter date</mat-label>
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Facebook Link
                    </label>
                    <mat-form-field>
                        <i-feather name="facebook"></i-feather>
                        <mat-label>facebook&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Twitter Link
                    </label>
                    <mat-form-field>
                        <i-feather name="twitter"></i-feather>
                        <mat-label>twitter&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        YouTube Link
                    </label>
                    <mat-form-field>
                        <i-feather name="youtube"></i-feather>
                        <mat-label>youtube&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        LinkedIn Link
                    </label>
                    <mat-form-field>
                        <i-feather name="linkedin"></i-feather>
                        <mat-label>linkedin&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Dribbble Link
                    </label>
                    <mat-form-field>
                        <i-feather name="dribbble"></i-feather>
                        <mat-label>dribbble&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Twitch Link
                    </label>
                    <mat-form-field>
                        <i-feather name="twitch"></i-feather>
                        <mat-label>behance&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="save-user-btn">
                <button mat-button type="button">
                    Save Information
                </button>
                <button mat-button type="button">
                    Cancel
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>