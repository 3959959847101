import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RideEditComponent } from '../../../../app/forms/create-event-types/ride-edit/ride-edit.component';
import { RiderescheduleComponent } from '../../../../app/forms/create-event-types/ridereschedule/ridereschedule.component';
import { RidereditrouteComponent } from '../../../../app/forms/create-event-types/ridereditroute/ridereditroute.component';
import { ViewrouteComponent } from '../viewroute/viewroute.component';
import { UpcomingridesviewdetailsComponent } from '../upcomingridesviewdetails/upcomingridesviewdetails.component';
import { MatInput } from "@angular/material/input";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UpcomingeventseditComponent } from '../../../../app/dashboard/ecommerce/upcomingeventsedit/upcomingeventsedit.component';
import { UpcomingeventsviewComponent } from '../upcomingeventsview/upcomingeventsview.component';
import { DraftridesComponent } from '../draftrides/draftrides.component';




@Component({
  selector: 'app-upcomingrideshistory',
  standalone: true,
  imports: [
    MatCardModule, MatInput, MatTabsModule, DraftridesComponent, MatFormFieldModule, MatInputModule, FeatherModule, MatIcon, MatIconModule, MatSidenavModule, HttpClientModule, NgIf, MatExpansionModule, CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule

  ],
    templateUrl: './upcomingrideshistory.component.html',
  styleUrls: ['./upcomingrideshistory.component.scss'],
  providers: [DatePipe]

})
export class UpcomingrideshistoryComponent implements OnInit {
  groupdID: string | null = null;

  
  displayedColumns: string[] = ['name'];
  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sidenav') sidenav!: MatSidenav;

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }
  toggleSidenav() {

    this.sidenav.toggle();
}


  constructor(private http: HttpClient,  private datePipe: DatePipe) { }

    ngOnInit(): void {
      this.fetchRidesData();
    }



fetchRidesData() {
  this.groupdID = localStorage.getItem('selectedGroupId');

  // Make HTTP GET request to fetch rides data
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.ridesHistoryPerGroup+this.groupdID, { headers: headers }).subscribe(
    (data) => {
      this.dataSource.data = data; // Assign fetched data to the data source
      this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}

formatDate(dateString: string): { dayOfWeek: string; day: string; month: string } {
  const date = new Date(dateString);
  // console.log("formattedDate date", dateString);

  const formattedDate = this.datePipe.transform(date, 'EEE d MMM') || '';
  const parts = formattedDate.split(' ');

  return {
    dayOfWeek: parts[0].toUpperCase(), // Day of the week
    day: parts[1],       // Day
    month: parts[2].toUpperCase() // Month in uppercase
  };
}

formatTimeTo12Hour(time: string): string {
  if (!time) return '';

  const [hourString, minuteString] = time.split(':');
  let hour = parseInt(hourString, 10);
  const minute = parseInt(minuteString, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const minuteFormatted = minute < 10 ? '0' + minute : minute;
  return `${hour}:${minuteFormatted} ${ampm}`;
}







  
}

