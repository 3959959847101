<!-- Product Details Card -->
<mat-card class="trinta-card product-details-card mb-25 bg-white border-none d-block  eventspage">
    <mat-card-content>
        <div class="row">
            <div class="col-xxxl-7 col-lg-6 col-md-12">
                <div class="product-details-content">
                    <div class="container">
                        <div class="logo-section">
                            <div class="logo-container">
                                <img *ngIf="logo && logo.trim() !== ''" [src]="logo" class="logo" />
                                <div *ngIf="!logo || logo.trim() === ''" class="logo-placeholder"
                                    [ngStyle]="{'background-color': backgroundColor}">
                                    {{ initials }}
                                </div>
                            </div>
                            <div class="info-section">
                                <div class="title-container">
                                    <p class="headertitle">{{ name }}</p>
                                    <div class="action-container">
                                        <div class="ratings lh-1 d-flex align-items-center amonttxtsec">
                                            <p class="btn btn-success elitebutton">{{ amount === 0 ? 'Free' : 'Paid' }}</p>
                                          </div>
                                        <!-- <button type="button" class="btn btn-success elitebutton">Free</button> -->
                                    </div>
                                </div>
                                <div class="invites-container" *ngIf="invitesvalue">
                                    {{ invites }}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="details-container">
                        <div class="detail">
                            <img src="assets/date.png" class="icon" />
                            Member since {{ datecreated }}
                        </div>
                        <div class="detail">
                            <img src="assets/loc.png" class="icon" />
                            {{ city }}, {{ state }}
                        </div>
                    </div>


                    <p class="headertitle">About</p>

                    <p class="fs-15 text-black description" [ngClass]="{'expanded': showFullDescription}">
                        {{ showFullDescription ? description : (description | slice:0:300) + '...' }}
                        <button *ngIf="description.length > 300 && !showFullDescription" (click)="toggleDescription()"
                            class="read-more-btn">
                            More
                        </button>
                        <button *ngIf="description.length > 300 && showFullDescription" (click)="toggleDescription()"
                            class="read-more-btn">
                            Less
                        </button>
                    </p>



                    <div class="row owneradmin">


                        <div class="owner">
                            <p class="headertitle">Owner</p>

                            <div class="row ownersec">
                                <div class="profile">
                                    <!-- <img src="assets/profile.png" class="profile-img" style="width: 50px;"> -->
                                    <img [src]="groupOwnerphoto !== '' ? groupOwnerphoto : '../../../../assets/profile.png'"
                                        class="profile-img" style="width: 60px; height: 60px; border-radius: 50%;">

                                    <span class="profile-name">{{groupOwnerName}}</span>
                                </div>
                            </div>
                        </div>


                        <div class="admin">
                            <!-- <span class="d-block fs-14 text-primary txttitle">
                                Admins
                            </span> -->
                            <!-- <div class="row adminsec">
                                <div class="profile">
                                    <img src="assets/pr1.png" class="profile-img" style="width: 50px;">
                                    <span class="profile-name">Iggo Wilkins</span>
                                </div>
                                <div class="profile">
                                    <img src="assets/pr1.png" class="profile-img" style="width: 50px;">
                                    <span class="profile-name">Wiggo Wilkins</span>
                                </div>
                            </div> -->
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-xxxl-6 col-lg-6 col-md-12">
                            <div class="ratings lh-1 d-flex ridingtypessec">
                                <span class="d-block fs-14 text-primary txttitle">
                                    Typical Pace
                                </span>
                            </div>

                         
        
                            <div class="ratings lh-1 d-flex">

                                <div  class="ratings lh-1 d-flex">
                                    <div class="d-block fs-14 text-primary typesbtns">{{ la_TypicalPace }}</div>
                                  </div>
        
                            </div>
                        </div>
                        <div class="col-xxxl-6 col-lg-6 col-md-12">
                            <div class="ratings lh-1 d-flex ridingtypessec">
                                <span class="d-block fs-14 text-primary txttitle">
                                    Type of Ride
                                </span>
                            </div>
        
                            <div class="ratings lh-1 d-flex">
                                <!-- <span class="d-block fs-14 text-primary typesbtns">
                                    {{la_RidingTypes}}
                                </span> -->

                                <div *ngFor="let ridingType of la_RidingTypes" class="ratings lh-1 d-flex">
                                    <div class="d-block fs-14 text-primary typesbtns">{{ ridingType }}</div>
                                  </div>
                                
                            </div>

                           
                        </div>                       
                    </div>

                    <div class="row">
                        <div class="col-xxxl-6 col-lg-6 col-md-12">
                            <div class="ratings lh-1 d-flex align-items-center ridingtypessec">
                                <span class="d-block fs-14 text-primary txttitle">
                                    Rider Level
                                </span>
                            </div>
        
                            <div class="ratings lh-1 d-flex ">
                                <!-- <span class="d-block fs-14 text-primary typesbtns">
                                    {{la_RidingLevels}}
                                </span> -->
                                <div *ngFor="let la_RidingLevel of la_RidingLevels" class="ratings lh-1 d-flex">
                                    <div class="d-block fs-14 text-primary typesbtns">{{ la_RidingLevel }}</div>
                                  </div>
        
                            </div>
                        </div>
                        <div class="col-xxxl-6 col-lg-6 col-md-12">
                            <div class="ratings lh-1 d-flex align-items-center ridingtypessec">
                                <span class="d-block fs-14 text-primary txttitle">
                                    Annual Mileage
                                </span>
                            </div>
                            
                            <div class="ratings lh-1 d-flex">

                                <div  class="ratings lh-1 d-flex">
                                    <div class="d-block fs-14 text-primary typesbtns">{{ la_AnnualMileage }}</div>
                                  </div>
        
                            </div>
                        </div>
                    </div>
                   
                    

                    <div class="details-container">
                        <div class="detail">
                            Allow members to create/post rides?    {{ allowMemberPost ? 'Yes' : 'No' }}
                        </div>
                        <div class="detail">
                            
                            
                        </div>
                    </div>
                    
                  

                      
                      
                    <div class="ratings lh-1 d-flex align-items-center membershipfee">
                        <span class="d-block fs-14 text-primary membershiptxt">
                            Membership Fee
                        </span>
                    </div>

                    <div class="ratings lh-1 d-flex align-items-center amonttxtsec">
                        <p>${{amount}} Monthly</p>
                    </div>

                </div>
            </div>
            <div class="col-xxxl-5 col-lg-6 col-md-12">
                <div class="product-details-image">
                    <button (click)="deeleteroup(la_RidingGroup_ID)" class="viewroutebtns editproflesecdelete">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="26" height="26">
                            <circle cx="40" cy="40" r="35" fill="white" stroke="#DA1884" stroke-width="2"/>
                          
                            <line x1="27" y1="27" x2="53" y2="53" stroke="#DA1884" stroke-width="3" stroke-linecap="round"/>
                            <line x1="53" y1="27" x2="27" y2="53" stroke="#DA1884" stroke-width="3" stroke-linecap="round"/>
                          </svg>
                        Delete Group
                    </button>
                       
                       
        
                        <button mat-stroked-button class="editproflesec" (click)="openDialog(EditgroupdetailsComponent)"
                            style="border:1px solid var(--primaryColor)">
                            <mat-icon>edit</mat-icon>
                            Edit Profile
                        </button>
                   
                    <img [src]="image !== '' ? image : '../../../../assets/pimg.png'" class="profile-img" style="width: 100%; height: 100%;">

                    <div class="socilasec">
                        <div class="ratings lh-1 d-flex align-items-center socialheadersec">
                            <h3>Socials</h3>
                        </div>

                        <div class="socilalinks lh-1 d-flex align-items-center">
                            <a [href]="faceBook" target="_blank" class="d-inline-block text-primary">
                                <span class="d-flex align-items-center fs-14 text-primary socialicons">
                                    <img src="../../../../assets/fblogo.png" class="socialicon" />

                                    <!-- <span class="social-text">Facebook</span> -->
                                </span>
                            </a>

                            <a [href]="x" target="_blank" class="d-inline-block text-primary">
                                <span class="d-flex align-items-center fs-14 text-primary socialicons">
                                    <div class="flex justify-start items-start flex-row gap-2.5 p-1">
                                        <img src="../../../../assets/xtw.png" class="socialicon" />
                                    </div>
                                    <!-- <span class="social-text"></span> -->
                                </span>
                            </a>

                            <a [href]="instagram" target="_blank" class="d-inline-block text-primary">
                                <span class="d-flex align-items-center fs-14 text-primary socialicons">
                                    <img src="../../../../assets/instlogo.png" class="socialicon" />


                                    <!-- <span class="social-text">Instagram</span> -->
                                </span>
                            </a>


                        </div>
                    </div>

                </div>
            </div>

        </div>
    </mat-card-content>
</mat-card>