<mat-card
    class="trinta-card advance-timeline-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Advance Timeline
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="timeline p-0 m-0 list-unstyled z-1 position-relative">
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Project Kickoff
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Smith Johnson
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Brief meeting to initiate the project
                    </p>
                </div>
                <div class="date">
                    19 Nov, 2023 08:30AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Design Mockups
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Mildred Garrett
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Create visual mockups for client review
                    </p>
                </div>
                <div class="date">
                    18 Nov, 2023 05:10AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Requirement Gathering
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Jeffrey Peterson
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Conduct interviews and gather project specs
                    </p>
                </div>
                <div class="date">
                    17 Nov, 2023 10:93AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Development Phase
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Gabriel Ward
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Write code and build project functionalities
                    </p>
                </div>
                <div class="date">
                    15 Nov, 2023  02:30PM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Testing and QA
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Charles Thomas
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Perform quality assurance and testing
                    </p>
                </div>
                <div class="date">
                    14 Nov, 2023  06:48PM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Client Review
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Craig Bannister
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Present project to the client for feedback
                    </p>
                </div>
                <div class="date">
                    12 Nov, 2023 04:20AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Deployment
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Michael Winans
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Deploy the project to the production server
                    </p>
                </div>
                <div class="date">
                    11 Nov, 2023 08:30AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
            <li class="timeline-item position-relative">
                <div class="inner">
                    <h6>
                        Project Closure
                    </h6>
                    <span class="assigned-to d-block text-body">
                        <span class="text-black fw-medium">Assigned To:</span> Robert Tipton
                    </span>
                    <span class="description d-block fw-medium">
                        Description:
                    </span>
                    <p>
                        Finalize documentation and close the project
                    </p>
                </div>
                <div class="date">
                    10 Nov, 2023 07:30AM
                    <span class="arrow d-block"></span>
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>