import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardModule, MatCardTitle } from "@angular/material/card";
import { MatList, MatListItem, MatListModule } from "@angular/material/list";
import { DatePipe } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatMiniFabButton } from "@angular/material/button";
import { Router } from "@angular/router";
import { MatTooltip } from "@angular/material/tooltip";
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../../app/constants';
import { ApiEndpoints } from '../../../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from "@angular/material/input";
import { FeatherModule } from "angular-feather";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { I } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-ride-routes',
  standalone: true,
  imports: [
    MatCardModule,
    MatListModule,
    DatePipe,
    MatIcon,
    MatMiniFabButton,
    MatTooltip,
    MatCardModule, MatInput, MatTabsModule, MatFormFieldModule, MatInputModule, FeatherModule, MatIcon, MatIconModule, MatSidenavModule, HttpClientModule, NgIf, MatExpansionModule, CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule
  ],
  templateUrl: './ride-routes.component.html',
  styleUrl: './ride-routes.component.scss'
})
export class RideRoutesComponent implements OnInit {
  // constructor(private router: Router){}
  routes: any[] = [];
  drafts: any[] = [];

  constructor(private router: Router, private http: HttpClient, private datePipe: DatePipe, public dialog: MatDialog) { }

  openRouteListPage() {
    this.router.navigate(['/dashboard/routes'])
  }

  ngOnInit(): void {
    this.fetchroutestesData();

  }

  fetchroutestesData() {
    // Make HTTP GET request to fetch rides data
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get<any>(BASE_URL + ApiEndpoints.routes, { headers: headers }).subscribe(
      (data) => {
        console.log('routes data:', data);

        this.routes = data;


      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }

  fetchDraftsData() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    
    this.http.get<any>(BASE_URL + ApiEndpoints.draftRidesPerGroup + localStorage.getItem('selectedGroupId'), { headers: headers }).subscribe(
      (data) => {
        // Limit data to the first 5 records
        this.drafts = data.slice(0, 5); // Only take the first 5 records
      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }
  


  // fetchDraftsData() {
  //   const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  //   this.http.get<any>(BASE_URL + ApiEndpoints.draftRidesPerGroup + localStorage.getItem('selectedGroupId'), { headers: headers }).subscribe(
  //     (data) => {
  //       this.drafts = data;

  //     },
  //     (error) => {
  //       console.error('Error fetching rides data:', error);
  //     }
  //   );
  // }

  
  draftUpcomingEvents() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get<any>(BASE_URL + ApiEndpoints.draftUpcomingEventsPerGroup + localStorage.getItem('selectedGroupId'), { headers: headers }).subscribe(
      (data) => {
        console.error('Error fetching rides datadatadatadata:', data);
        this.drafts = data;
        // console.error('Error fetching rides datadatadatadata:', this.drafts);

      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }
}

