import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from "@angular/material/icon";

import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-changepasswordconfirm',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule,MatIcon],
  templateUrl: './changepasswordconfirm.component.html',
  styleUrl: './changepasswordconfirm.component.scss'
})
export class ChangepasswordconfirmComponent {
  constructor(public dialogRef: MatDialogRef<ChangepasswordconfirmComponent>) {}
  onCancel(): void {
    this.dialogRef.close(false);  // Close and return false
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(true);  // Close and return true
  }
}
