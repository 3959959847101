import {Component, OnInit,Inject} from '@angular/core';
import {FeatherModule} from "angular-feather";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";

import { DomSanitizer } from '@angular/platform-browser';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";


@Component({
  selector: 'app-viewroute',
  standalone: true,
  imports: [  
    FormsModule,
    MatButton,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatCheckbox,
    MatFormField,
    MatIconButton,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose,
   
    ReactiveFormsModule,
    MatHint,
    ReactiveFormsModule,
    MatFormField,
  
    MatInput,
    MatLabel,
   ],
  templateUrl: './viewroute.component.html',
  styleUrl: './viewroute.component.scss'
})
export class ViewrouteComponent implements OnInit {


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ViewrouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer


  ) {
    this.createEventForm= this.fb.group({
      link: [data.link],
      
    });

  }

  // Password Hide
  hide = true;
  createEventForm: FormGroup;


  

  // Form

  ngOnInit(): void {

  }

  onNoClick() {
    this.dialogRef.close();
    // this.loadPurposes()
  }

  onSubmit() {
  }

  get link(): string {
    return this.createEventForm.get('link')?.value || '';
  }
}



