import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';


const FORMFIELDOPTIONS = {
  appearance: 'outline',
  floatlabel: 'always',
  hideRequiredMarker: true,
};

export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes), provideClientHydration(), provideAnimationsAsync(), {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { ...FORMFIELDOPTIONS },
    },
      provideAnimationsAsync(),
    ],
};

