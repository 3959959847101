 <div class="row title text-center">
    <!-- <h2 mat-dialog-title class="fw-semibold">Remove Member</h2> -->
    <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
      <mat-icon>
        close
      </mat-icon>
    </button>
  </div>


  
  <mat-dialog-content>
    
    <!-- If you do not have an account, scan qr code to download <br>
    <img src="../../../assets/qrcd.png" alt="La Corsa official logo" aria-hidden="true" style="height: 120px; align-items: center; justify-content: center;"><br> -->
    You are not a group admin. Do you want to create one?
</mat-dialog-content>
  <mat-dialog-actions class="actionbtns">
    <button mat-button (click)="onConfirm()" style="background-color: var(--accentColor);
    width: 120px;
    color: #ffffff !important; font-weight: 600;">Yes</button>
   
  </mat-dialog-actions>
  
  