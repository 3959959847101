<mat-card-content class="row d-flex activietiessection" style="">
    <div  class="col-xl-9 col-lg-9 col-sm-12">
        <app-members></app-members>
        <!-- <app-membersstatistics></app-membersstatistics> -->

    </div>
    <div class="col-xl-3 col-lg-3 col-sm-12">
        <app-invites></app-invites>
    </div>
  
  </mat-card-content>
  

<!-- <div class="row container" style="padding-top: 16px; padding-right: 0px !important;">

     <div class="col-xl-9">
        <app-members></app-members>


    </div>
    <div class="col-xl-3">
        <app-invites></app-invites>
    </div>
</div> -->