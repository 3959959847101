<div class="table-responsive">
    <table 
        mat-table
        class="main-table"
        [dataSource]="dataSource" 
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
    >
        <ng-container matColumnDef="position">
            <th mat-header-cell cdkDrag *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell cdkDrag *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="weight">
            <th mat-header-cell cdkDrag *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>

        <ng-container matColumnDef="symbol">
            <th mat-header-cell cdkDrag *matHeaderCellDef> Symbol </th>
            <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</div>