<mat-card
    class="trinta-card courses-category-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Courses Category
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <ul class="p-0 m-0 list-unstyled">
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="category d-flex align-items-center">
                    <a routerLink="/" class="image d-block">
                        <img src="assets/images/categories/category1.jpg" alt="category-image">
                    </a>
                    <div>
                        <a routerLink="/" class="d-block fw-semibold">
                            Programming & Development
                        </a>
                        <span class="d-block text-body">
                            58+ Courses
                        </span>
                    </div>
                </div>
                <div class="info text-sm-end">
                    <span class="d-block text-body">
                        Enrolled by
                    </span>
                    <span class="d-block text-black">
                        1,158+
                    </span>
                </div>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="category d-flex align-items-center">
                    <a routerLink="/" class="image d-block">
                        <img src="assets/images/categories/category2.jpg" alt="category-image">
                    </a>
                    <div>
                        <a routerLink="/" class="d-block fw-semibold">
                            Business & Entrepreneurship
                        </a>
                        <span class="d-block text-body">
                            42+ Courses
                        </span>
                    </div>
                </div>
                <div class="info text-sm-end">
                    <span class="d-block text-body">
                        Enrolled by
                    </span>
                    <span class="d-block text-black">
                        2,105+
                    </span>
                </div>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="category d-flex align-items-center">
                    <a routerLink="/" class="image d-block">
                        <img src="assets/images/categories/category3.jpg" alt="category-image">
                    </a>
                    <div>
                        <a routerLink="/" class="d-block fw-semibold">
                            Science & Engineering
                        </a>
                        <span class="d-block text-body">
                            105+ Courses
                        </span>
                    </div>
                </div>
                <div class="info text-sm-end">
                    <span class="d-block text-body">
                        Enrolled by
                    </span>
                    <span class="d-block text-black">
                        670+
                    </span>
                </div>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="category d-flex align-items-center">
                    <a routerLink="/" class="image d-block">
                        <img src="assets/images/categories/category4.jpg" alt="category-image">
                    </a>
                    <div>
                        <a routerLink="/" class="d-block fw-semibold">
                            Social Sciences
                        </a>
                        <span class="d-block text-body">
                            36+ Courses
                        </span>
                    </div>
                </div>
                <div class="info text-sm-end">
                    <span class="d-block text-body">
                        Enrolled by
                    </span>
                    <span class="d-block text-black">
                        109+
                    </span>
                </div>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="category d-flex align-items-center">
                    <a routerLink="/" class="image d-block">
                        <img src="assets/images/categories/category5.jpg" alt="category-image">
                    </a>
                    <div>
                        <a routerLink="/" class="d-block fw-semibold">
                            IT & Computer Science
                        </a>
                        <span class="d-block text-body">
                            124+ Courses
                        </span>
                    </div>
                </div>
                <div class="info text-sm-end">
                    <span class="d-block text-body">
                        Enrolled by
                    </span>
                    <span class="d-block text-black">
                        1,825+
                    </span>
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>