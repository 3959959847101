<mat-card
    class="trinta-card photos-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Photos
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-4">
                <img src="assets/images/photos/photo1.jpg" class="border-radius" alt="photo-image">
            </div>
            <div class="col-4">
                <img src="assets/images/photos/photo2.jpg" class="border-radius" alt="photo-image">
            </div>
            <div class="col-4">
                <img src="assets/images/photos/photo3.jpg" class="border-radius" alt="photo-image">
            </div>
            <div class="col-4">
                <img src="assets/images/photos/photo4.jpg" class="border-radius" alt="photo-image">
            </div>
            <div class="col-4">
                <img src="assets/images/photos/photo5.jpg" class="border-radius" alt="photo-image">
            </div>
            <div class="col-4">
                <img src="assets/images/photos/photo6.jpg" class="border-radius" alt="photo-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>