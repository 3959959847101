<div class="row">
    <div class="col-xxxl-2 col-sm-6 col-md-4">
        <mat-card
            class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <span class="d-block text-gray">
                    Total Tasks
                </span>
                <h5>
                    258
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        This Month
                    </span>
                    <span class="trending d-block text-body position-relative">
                        2.60% <i class="material-symbols-outlined">trending_up</i>
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-2 col-sm-6 col-md-4">
        <mat-card
            class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <span class="d-block text-gray">
                    End Date
                </span>
                <h5>
                    14/08/23
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        Completed
                    </span>
                    <span class="trending d-block text-body position-relative">
                        65.0% <i class="material-symbols-outlined">trending_up</i>
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-2 col-sm-6 col-md-4">
        <mat-card
            class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <span class="d-block text-gray">
                    Hours Spent
                </span>
                <h5>
                    271h 30m
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        Status
                    </span>
                    <span class="trending d-block text-body position-relative">
                        5.90% <i class="material-symbols-outlined">trending_up</i>
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-2 col-sm-6 col-md-4">
        <mat-card
            class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <span class="d-block text-gray">
                    Team Members
                </span>
                <h5>
                    142
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        Leads
                    </span>
                    <span class="trending d-block text-body position-relative">
                        2.60% <i class="material-symbols-outlined">trending_up</i>
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-2 col-sm-6 col-md-4">
        <mat-card
            class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <span class="d-block text-gray">
                    Due Tasks
                </span>
                <h5>
                    185
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        Incomplete
                    </span>
                    <span class="trending down d-block text-body position-relative">
                        4.70% <i class="material-symbols-outlined">trending_down</i>
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-2 col-sm-6 col-md-4">
        <mat-card
            class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <span class="d-block text-gray">
                    Productivity
                </span>
                <h5>
                    94%
                </h5>
                <div class="info d-flex align-items-center justify-content-between">
                    <span class="d-block text-body">
                        Increase
                    </span>
                    <span class="trending d-block text-body position-relative">
                        3.68% <i class="material-symbols-outlined">trending_up</i>
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>