<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Buttons
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Buttons
        </li>
    </ol>
</div>

<!-- Buttons -->
<div class="row">
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-button>Basic</button>
                <button mat-button color="primary">Primary</button>
                <button mat-button color="accent">Accent</button>
                <button mat-button color="warn">Warn</button>
                <a mat-button routerLink=".">Link</a>
                <button mat-button disabled>Disabled</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Raised Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-raised-button>Basic</button>
                <button mat-raised-button color="primary">Primary</button>
                <button mat-raised-button color="accent">Accent</button>
                <button mat-raised-button color="warn">Warn</button>
                <a mat-raised-button routerLink=".">Link</a>
                <button mat-raised-button disabled>Disabled</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Stroked Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-stroked-button>Basic</button>
                <button mat-stroked-button color="primary">Primary</button>
                <button mat-stroked-button color="accent">Accent</button>
                <button mat-stroked-button color="warn">Warn</button>
                <a mat-stroked-button routerLink=".">Link</a>
                <button mat-stroked-button disabled>Disabled</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Flat Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-flat-button>Basic</button>
                <button mat-flat-button color="primary">Primary</button>
                <button mat-flat-button color="accent">Accent</button>
                <button mat-flat-button color="warn">Warn</button>
                <a mat-flat-button routerLink=".">Link</a>
                <button mat-flat-button disabled>Disabled</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Icon Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-icon-button matTooltip="Basic" aria-label="Example icon-button with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Primary" color="primary" aria-label="Example icon-button with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Accent" color="accent" aria-label="Example icon-button with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Warn" color="warn" aria-label="Example icon-button with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <div matTooltip="Disabled" class="example-disabled d-inline-block">
                    <button mat-icon-button disabled aria-label="Example icon-button with a heart icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                </div>
                <a mat-icon-button matTooltip="Link" routerLink="." aria-label="Example icon-button with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Fab Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-fab matTooltip="Primary" color="primary" aria-label="Example fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-fab matTooltip="Accent" color="accent" aria-label="Example fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-fab matTooltip="Warn" color="warn" aria-label="Example fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <div matTooltip="Disabled" class="example-disabled d-inline-block">
                    <button mat-fab disabled aria-label="Example fab with a heart icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                </div>
                <a mat-fab matTooltip="Link" routerLink="." aria-label="Example fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Mini Fab Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-mini-fab matTooltip="Primary" color="primary" aria-label="Example mini fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-mini-fab matTooltip="Accent" color="accent" aria-label="Example mini fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <button mat-mini-fab matTooltip="Warn" color="warn" aria-label="Example mini fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
                <div matTooltip="Disabled" class="example-disabled d-inline-block">
                    <button mat-mini-fab disabled aria-label="Example mini fab with a heart icon">
                        <mat-icon>favorite</mat-icon>
                    </button>
                </div>
                <a mat-mini-fab matTooltip="Link" routerLink="." aria-label="Example mini fab with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Extended Fab Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-fab extended color="primary">
                    <mat-icon>favorite</mat-icon>
                    Primary
                </button>
                <button mat-fab extended color="accent">
                    <mat-icon>favorite</mat-icon>
                    Accent
                </button>
                <button mat-fab extended color="warn">
                    <mat-icon>favorite</mat-icon>
                    Warn
                </button>
                <button mat-fab extended disabled>
                    <mat-icon>favorite</mat-icon>
                    Disabled
                </button>
                <a mat-fab extended routerLink=".">
                    <mat-icon>favorite</mat-icon>
                    Link
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Flat Fab Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-flat-button extended color="primary">
                    <mat-icon>favorite</mat-icon>
                    Primary
                </button>
                <button mat-flat-button extended color="accent">
                    <mat-icon>favorite</mat-icon>
                    Accent
                </button>
                <button mat-flat-button extended color="warn">
                    <mat-icon>favorite</mat-icon>
                    Warn
                </button>
                <button mat-flat-button extended disabled>
                    <mat-icon>favorite</mat-icon>
                    Disabled
                </button>
                <a mat-flat-button extended routerLink=".">
                    <mat-icon>favorite</mat-icon>
                    Link
                </a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6"></div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Block Basic Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-button class="d-block w-100 text-center">Basic</button>
                <button mat-button class="d-block w-100 text-center" color="primary">Primary</button>
                <button mat-button class="d-block w-100 text-center" color="accent">Accent</button>
                <button mat-button class="d-block w-100 text-center" color="warn">Warn</button>
                <button mat-button class="d-block w-100 text-center" disabled>Disabled</button>
                <a mat-button class="d-block w-100 text-center" routerLink=".">Link</a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Block Raised Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-raised-button class="d-block w-100 text-center">Basic</button>
                <button mat-raised-button class="d-block w-100 text-center" color="primary">Primary</button>
                <button mat-raised-button class="d-block w-100 text-center" color="accent">Accent</button>
                <button mat-raised-button class="d-block w-100 text-center" color="warn">Warn</button>
                <button mat-raised-button class="d-block w-100 text-center" disabled>Disabled</button>
                <a mat-raised-button class="d-block w-100 text-center" routerLink=".">Link</a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Block Stroked Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-stroked-button class="d-block w-100 text-center">Basic</button>
                <button mat-stroked-button class="d-block w-100 text-center" color="primary">Primary</button>
                <button mat-stroked-button class="d-block w-100 text-center" color="accent">Accent</button>
                <button mat-stroked-button class="d-block w-100 text-center" color="warn">Warn</button>
                <button mat-stroked-button class="d-block w-100 text-center" disabled>Disabled</button>
                <a mat-stroked-button class="d-block w-100 text-center" routerLink=".">Link</a>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Block Flat Buttons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="example-buttons">
                <button mat-flat-button class="d-block text-center w-100">Basic</button>
                <button mat-flat-button class="d-block text-center w-100" color="primary">Primary</button>
                <button mat-flat-button class="d-block text-center w-100" color="accent">Accent</button>
                <button mat-flat-button class="d-block text-center w-100" color="warn">Warn</button>
                <button mat-flat-button class="d-block text-center w-100" disabled>Disabled</button>
                <a mat-flat-button class="d-block text-center w-100" routerLink=".">Link</a>
            </mat-card-content>
        </mat-card>
    </div>
</div>