<div class="row">
    <div class="col-xxxl-6">

        <!-- Recent Orders -->
        <app-t-recent-orders />

        <!-- Earnings Reports -->
        <app-t-earnings-reports />

        <!-- Payment History -->
        <app-t-payment-history />

        <!-- All Projects -->
        <app-t-all-projects />

        <!-- All Courses -->
        <app-t-all-courses />

    </div>
    <div class="col-xxxl-6">

        <!-- Transactions History -->
        <app-t-transactions-history />

        <!-- Leads Report -->
        <app-t-leads-report />

        <!-- To Do List -->
        <app-t-to-do-list />

        <!-- Top Instructors -->
        <app-t-top-instructors />

        <!-- Agents List -->
        <app-t-agents-list />

    </div>
</div>