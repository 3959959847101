<mat-card
    class="trinta-card user-bio-card mb-25 bg-white border-none d-block"
>
    <mat-card-content>
        <h5>
            User Bio
        </h5>
        <p class="text-black">
            Nam malesuada dolor tellus pretium amet was hendrerit facilisi id vitae enim sed ornare there suspendisse sed orci neque ac sed aliquet risus faucibus in pretium nis.
        </p>
        <h5>
            Skills :
        </h5>
        <div class="skills">
            <div class="position-relative d-inline-block fw-medium">
                JavaScript
                <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="position-relative d-inline-block fw-medium">
                PHP Developer
                <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="position-relative d-inline-block fw-medium">
                Vue.js
                <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="position-relative d-inline-block fw-medium">
                MySQL
                <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                    <i class="ri-close-line"></i>
                </button>
            </div>
            <div class="position-relative d-inline-block fw-medium">
                Angular
                <button type="button" class="p-0 bg-transparent border-none cursor-pointer">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>
        <h5>
            Contact Info :
        </h5>
        <ul class="contact-info p-0 mb-0 list-unstyled">
            <li class="position-relative text-black">
                <i-feather name="mail"></i-feather>
                demo&#64;contact.com
            </li>
            <li class="position-relative text-black">
                <i-feather name="phone"></i-feather>
                +1 2354 6798
            </li>
            <li class="position-relative text-black">
                <i-feather name="globe"></i-feather>
                www.example.com
            </li>
            <li class="position-relative text-black">
                <i-feather name="tag"></i-feather>
                16 tagged images
            </li>
            <li class="position-relative text-black">
                <i-feather name="calendar"></i-feather>
                05 events
            </li>
        </ul>
        <h5>
            Social Profiles :
        </h5>
        <ul class="social-profiles p-0 mb-0 list-unstyled">
            <li class="d-inline-block">
                <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                    <i class="ri-facebook-box-fill"></i>
                </a>
            </li>
            <li class="d-inline-block">
                <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                    <i class="ri-messenger-fill"></i>
                </a>
            </li>
            <li class="d-inline-block">
                <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                    <i class="ri-twitter-fill"></i>
                </a>
            </li>
            <li class="d-inline-block">
                <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                    <i class="ri-linkedin-fill"></i>
                </a>
            </li>
        </ul>
    </mat-card-content>
</mat-card>