<div class="row">
    <div class="col-lg-6">

        <!-- Stats -->
        <app-stats />

    </div>
    <div class="col-lg-6">

        <!-- Support Status -->
        <app-support-tickets />

    </div>
</div>

<!-- All Tickets -->
<mat-card
    class="trinta-card all-tickets-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                All Tickets
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="all-tickets-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            ID
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0 text-primary">
                            {{element.id}}
                        </td>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>
                            Customer
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="customer-info d-flex align-items-center">
                                <div class="image rounded-circle">
                                    <img [src]="element.customer.img" class="rounded-circle" alt="user-image">
                                </div>
                                <div class="info position-relative">
                                    <h6 class="mt-0">
                                        {{element.customer.name}}
                                    </h6>
                                    <span class="d-block text-body">
                                        {{element.customer.phoneNumber}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Subject Column -->
                    <ng-container matColumnDef="subject">
                        <th mat-header-cell *matHeaderCellDef>
                            Subject
                        </th>
                        <td mat-cell *matCellDef="let element" style="white-space: normal;">
                            <p class="text-black">
                                {{element.subject}}
                            </p>
                        </td>
                    </ng-container>

                    <!-- Response Time Column -->
                    <ng-container matColumnDef="responseTime">
                        <th mat-header-cell *matHeaderCellDef>
                            Response Time
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.responseTime}}
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="trinta-badge fw-medium d-inline-block" *ngIf="element.status.ongoing">
                                {{element.status.ongoing}}
                            </span>
                            <span class="trinta-badge fw-medium pending d-inline-block" *ngIf="element.status.pending">
                                {{element.status.pending}}
                            </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[7, 14, 21]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>