<mat-card-content class="row d-flex activietiessection">
  <div  class="col-xl-9 col-lg-9 col-sm-12">
    <app-membersstatistics></app-membersstatistics>
    <div class="membersectiontab">
      <app-upcomingrides/>
    </div>
    
  </div>
  <div class="col-xl-3 col-lg-3 col-sm-12">
    <!-- <app-calendar></app-calendar> -->
    <app-ride-route-list/>
  </div>

</mat-card-content>
