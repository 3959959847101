
<div mat-dialog-content>
  <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
    <mat-icon>close</mat-icon>
  </button>
  <form [formGroup]="createEventForm" (ngSubmit)="onSubmit()" class="d-flex flex-column" style="padding: 20px;">
    <div class="col-xxxl-12 col-xxl-12 col-md-12" style="width: 100%;">
      <iframe
        [src]="sanitizer.bypassSecurityTrustResourceUrl(link || '')"
        allowfullscreen
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        width="100%"
        height="450"
        style="border:0;"
      ></iframe>
    </div>
  </form>
</div>
