<div class="row mt-15 bg-white border-none border-radius text-center mg-inline-16" >
  <h4 class="mt-20">Routes</h4>

    <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">
        <app-ride-route-card class="is-focusable" role="button" routerLink="/psycho-morning" ></app-ride-route-card>

    </div>
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->
<!--  <div class="col-xxxl-2 col-xl-2 col-sm-2 route-card ">-->
<!--    <app-ride-route-card></app-ride-route-card>-->
<!--  </div>-->

</div>


