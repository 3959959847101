<!-- Stats -->
<app-stats />

<div class="row">
    <div class="col-lg-6 col-md-12">

        <!-- Issues Summary -->
        <app-issues-summary />

    </div>
    <div class="col-lg-6 col-md-12">

        <!-- To Do List -->
        <app-to-do-list />

    </div>
    <div class="col-xxl-4 col-lg-5 col-md-12">

        <!-- Tasks Performance -->
        <app-tasks-performance />

    </div>
    <div class="col-xxl-8 col-lg-7 col-md-12">

        <!-- Tasks Overview -->
        <app-tasks-overview />

    </div>
    <div class="col-xxl-8 col-lg-7 col-md-12">

        <!-- All Projects -->
        <app-all-projects />

    </div>
    <div class="col-xxl-4 col-lg-5 col-md-12">

        <!-- Calendar -->
        <app-calendar />

    </div>
</div>