<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Typography
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Typography
        </li>
    </ol>
</div>

<!-- Typography -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Headings
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h1>h1. Bootstrap heading</h1>
                <h2>h2. Bootstrap heading</h2>
                <h3>h3. Bootstrap heading</h3>
                <h4>h4. Bootstrap heading</h4>
                <h5>h5. Bootstrap heading</h5>
                <h6>h6. Bootstrap heading</h6>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Headings
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="h1">h1. Bootstrap heading</div>
                <div class="h2">h2. Bootstrap heading</div>
                <div class="h3">h3. Bootstrap heading</div>
                <div class="h4">h4. Bootstrap heading</div>
                <div class="h5">h5. Bootstrap heading</div>
                <div class="h6">h6. Bootstrap heading</div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Display Headings
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="display-1">Display 1</div>
                <div class="display-2">Display 2</div>
                <div class="display-3">Display 3</div>
                <div class="display-4">Display 4</div>
                <div class="display-5">Display 5</div>
                <div class="display-6">Display 6</div>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Inline Text Elements
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>You can use the mark tag to <mark>highlight</mark> text.</p>
                <p><del>This line of text is meant to be treated as deleted text.</del></p>
                <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
                <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
                <p><u>This line of text will render as underlined.</u></p>
                <p><small>This line of text is meant to be treated as fine print.</small></p>
                <p><strong>This line rendered as bold text.</strong></p>
                <p><em>This line rendered as italicized text.</em></p>
                <p><abbr title="attribute">attr</abbr></p>
                <p><abbr title="HyperText Markup Language" class="initialism">HTML</abbr></p>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Customizing Headings
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h3>
                    Fancy display heading
                    <small class="text-body">With faded secondary text</small>
                </h3>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Lead
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="lead">
                    This is a lead paragraph. It stands out from regular paragraphs.
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Blockquotes
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <figure class="p-0 m-0">
                    <blockquote class="blockquote">
                        <span class="d-block">A well-known quote, contained in a blockquote element.</span>
                    </blockquote>
                    <figcaption class="blockquote-footer">
                        Someone famous in <cite title="Source Title">Source Title</cite>
                    </figcaption>
                </figure>
            </mat-card-content>
        </mat-card>
    </div>
</div>