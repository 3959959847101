import { MatCardModule } from '@angular/material/card';
import {  RouterLinkActive, RouterOutlet } from '@angular/router';
import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { DatePipe, NgClass, CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../app/constants';
import { ApiEndpoints } from '../../app/api-endpoints';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from '@angular/material/tabs';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';  
@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [RouterOutlet, MatCardModule, RouterLinkActive, RouterLink, MatButtonModule,
        CommonModule, ReactiveFormsModule, MatTabsModule, RouterModule,// Import CommonModule for *ngFor, *ngIf directives
        MatMenu, MatMenuItem, MatMenuTrigger, 
        MatButtonModule,
        MatMenuModule,
        RouterLink,
        NgClass,
        MatSelectModule,  // Import MatSelectModule
        MatOptionModule,  // Import MatOptionModule
        MatFormFieldModule,  // Import MatFormFieldModule
        HttpClientModule,
        MatIcon
    ],
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {
    @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    groups: any[] = [];
    notifications: any[];
    username: string = ''; // Variable to store the username
    selectedGroupId: number | null = null;
    description: string = '';
    name: string = '';
    invitedRidersOnly: boolean = false;
    socialLinks: string = '';
    locationComment: string = '';
    totalNotifications: string = '';
    selectedGroup_Id: string | null = null;
    isDropdownOpen: boolean = false; // Controls dropdown open/close stat
    dropdownData: any[] = [];
    selectedItem: any = null; // Default value is null
    selectedGroup: any = { imageUrl: 'defaultImage.png', name: 'Select Group' };
    dropdownOpen = false;
  
  
    amount: string = '';
    form: FormGroup;
  
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.isDropdownOpen = !this.isDropdownOpen;
  
    }
    
  
    navLinks = [
      // { path: '/dashboard', label: 'Dashboard' },
      { path: '/members', label: 'Members' },
      // { path: '/groupprofile', label: 'Profile' },
      { path: '/activities', label: 'Activities' },
      { path: '/tools', label: 'Tools' },
      { path: '/payments', label: 'Payments' },
  
  
  
    ];
    constructor(
      private fb: FormBuilder, public dialog: MatDialog,
      private http: HttpClient,
      private router: Router,
    ) {
      this.form = this.fb.group({
        groupId: [''],
  
      });
    }
  
  
    // Toggle Service
    isToggled = false;
  
  
    onSubmit() { }
  
  
 
  
    // Current Date
    ngOnInit(): void {
      console.log("HeaderComponent initialized");
      const userobj = localStorage.getItem("userObj");
  
      if (!userobj) {
        // this.router.navigate(['/authentication/sign-up']);
        // this.router.navigate(['/authentication/forgot-password']);
        // this.router.navigate(['/authentication']);
  
      } else {
        this.getUserProfiles();
       
      }
  
      // Get the selected group ID from localStorage, or use null if not available
      const storedGroupId = localStorage.getItem('selectedGroupId');
  
      // Initialize the form with the selectedGroupId from localStorage if it exists
      this.form = this.fb.group({
        groupId: [storedGroupId || this.selectedGroup_Id]
      });
  
      // Set the selectedGroup_Id to the value from the form control
      this.selectedGroup_Id = this.form.get('groupId')?.value;
  
      // If there is a selected group ID, fetch its details
      if (this.selectedGroup_Id) {
        this.fetchGroupDetails(this.selectedGroup_Id);
      }
    }
    
    loadSelectedGroupFromLocalStorage(): void {
      // Get the selected group ID from localStorage
      const storedGroupId = localStorage.getItem('selectedGroupId');
      console.log("storedGroupId from localStorage:", storedGroupId);
    
      // Set the form control with the stored group ID or default to null
      this.form.get('groupId')?.setValue(storedGroupId ? Number(storedGroupId) : null);
    
      // Set the selectedGroup_Id to the value from the form control
      this.selectedGroup_Id = this.form.get('groupId')?.value;
      console.log("selectedGroup_Id after setting form control:", this.selectedGroup_Id);
    }
    
    onGroupChange(event: any): void {
      const selectedGroupId = event.value;
      console.log('Selected Group ID:', selectedGroupId);
    
      // Save the selected group ID to localStorage
      localStorage.setItem('selectedGroupId', selectedGroupId);
    
      // Navigate to dashboard or any action based on group change
      // this.router.navigate(['/dashboard']);
      window.location.reload();
    }
    
  
    getDropdownData() {
      // Sample static data in place of API call
      this.dropdownData = [
        { id: 1, name: 'Option 1' },
        { id: 2, name: 'Option 2' },
        { id: 3, name: 'Option 3' },
        { id: 4, name: 'Option 4' }
      ];
  
      // Set default value, e.g., the first option
      // this.selectedItem = this.dropdownData[0];
    }
   
    selectItem(item: any) {
      this.selectedItem = item;
      localStorage.setItem('selectedItem', JSON.stringify(item)); // Save the selected item to local storage
    }
  
    // Method to load the selected item from local storage
    loadSelectedItem() {
      const savedItem = localStorage.getItem('selectedItem');
      if (savedItem) {
        this.selectedItem = JSON.parse(savedItem);
      }
    }
  
  
    fetchGroupDetails(groupId: string): void {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.get<any>(`${BASE_URL}${ApiEndpoints.getGroupsByID}${groupId}`, { headers: headers }).subscribe(
        (data) => {
          console.log('Group data:', data);
          this.description = data[0].description;
          this.name = data[0].name;
          this.invitedRidersOnly = data[0].invitedRidersOnly;
          this.socialLinks = data[0].socialLinks;
          this.locationComment = data[0].locationComment;
          this.amount = data[0].amount;
        },
        (error) => {
          console.error('Error fetching group data:', error);
        }
      );
    }
  
  
  
    getUserProfiles() {
      const token = localStorage.getItem('angular17token');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      this.http.get(BASE_URL + ApiEndpoints.getUserProfile, { headers: headers }).subscribe(
        (data: any) => {
          console.log("getUserProfile :::::::::", data)
          this.username = data.firstname;
        },
        error => {
          console.log('Error fetching data:', error);
        }
      );
    }
  
  
    loadGroups() {
      const token = localStorage.getItem('angular17token');
      if (token) {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    
        this.http.get(BASE_URL + ApiEndpoints.getGroupsCreatedByUser, { headers }).subscribe(
          (data: any) => {
            this.groups = data; // Assuming this is an array of groups
    
            // Set the default value from local storage
            const storedGroupId = localStorage.getItem('selectedGroupId');
            if (storedGroupId) {
              this.form.get('groupId')?.setValue(Number(storedGroupId));
            } else {
              // If no stored group, set to default or null
              this.form.get('groupId')?.setValue(null);
            }
          },
          error => {
            console.error('Error fetching groups:', error);
          }
        );
      } else {
        console.log('No token found in local storage.');
      }
    }
    
  
  
  

  }
  