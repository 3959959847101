<div class="title text-center">
    <h2 mat-dialog-title class="fw-semibold">Edit Draft Event</h2>
    <button mat-mini-fab color="primary" (click)="onNoClick()" class="cancel">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  
  <div mat-dialog-content style="min-height: 100%;">
  
    <form  [formGroup]="createEventForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
  
      <mat-form-field class="custom-form-field">
        <mat-label>Event Name</mat-label>
        <input matInput type="text" formControlName="title" id="title" name="title">
      </mat-form-field>
  
     
  
      <mat-form-field class="textarea custom-form-field">
        <mat-label>Description</mat-label>
        <textarea matInput rows="4" formControlName="description" ></textarea>
      </mat-form-field>
  
  
      
  
      <div class="row" class="custom-form-field">
        <mat-form-field class="col-sm-6" style="padding-right: 4px;">
          <mat-label>Start Date</mat-label>
          <input matInput [min]="minDate" [matDatepicker]="startPicker" formControlName="date" [value]="displayDate" (dateChange)="onDateChange($event)">
          <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
  
        <mat-form-field class="col-sm-6" style="padding-left: 4px;">
          <mat-label>End Date</mat-label>
          <input matInput [min]="minDate" [matDatepicker]="endPicker" formControlName="endDate" [value]="displayEndDate" (dateChange)="onDateChangeEnddate($event)">
          <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
      </div>
  
    
  
    
  
      <mat-form-field class="custom-form-field col-sm-6">
        <mat-label>Time</mat-label>
        <input matInput type="time" formControlName="time"  id="time" name="time">
      </mat-form-field>
  
  
       <!-- <div class="row" class="custom-form-field" style="padding-top: 4px;"> -->
        <mat-form-field class="custom-form-field">
          <mat-label>Location</mat-label>
          <input matInput type="text" formControlName="location" id="location" name="location">
        </mat-form-field>
  
        <mat-form-field class="custom-form-field">
          <mat-label>Address</mat-label>
          <input matInput type="text" formControlName="address"  id="address" name="address">
          </mat-form-field>
      <!-- </div> -->
  
  
  
      <div class="d-flex justify-content-between">
        <mat-form-field class="custom-form-field" style="flex: 1; margin-right: 10px;">
          <mat-label>State</mat-label>
          <mat-select formControlName="stateId" id="stateId" name="stateId"
            [ngClass]="{'invalid-input': createEventForm.get('stateId')?.invalid && createEventForm.get('stateId')?.touched}">
            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field class="custom-form-field" style="flex: 1;">
          <mat-label>City</mat-label>
          <mat-select formControlName="cityId" id="cityId" name="cityId"
            [ngClass]="{'invalid-input': createEventForm.get('cityId')?.invalid && createEventForm.get('cityId')?.touched}">
            <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
  
     
  
   
  
      <mat-form-field class="custom-form-field">
        <mat-label>Group Size</mat-label>
        <input matInput type="number" formControlName="groupSize" id="groupSize" name="groupSize" min="0">
      </mat-form-field>
  
  
      <mat-form-field class="custom-form-field" >
        <mat-label>Organizer</mat-label>
        <mat-select formControlName="organizer" id="organizer" name="organizer">
          <mat-option *ngFor="let member of members" [value]="member.name">{{ member.name }}</mat-option>
        </mat-select>
      </mat-form-field>
  
        <mat-form-field class="custom-form-field">
        <mat-label>Cost</mat-label>
        <input matInput type="number" formControlName="amount"  id="amount" name="amount">
      </mat-form-field>
    
  
      <div class="upload-button-container">
        <input type="file" (change)="onFileSelected($event, 'logo')" accept="image/*" placeholder="Logo">
        <p>Choose Cover Photo</p>
      </div>
  
      <div class="button-container submitbtn" style="margin-bottom: 36px;">  
      <button   color="primary" type="submit" class="col-sm-5 draftbtn">Update Draft</button>
      <button   (click)="postEvent()" color="primary" type="button" class="col-sm-5 postbtn">Post Event</button>
    </div>
  
    </form>
  </div>
  