<!-- Invoice Details -->
<mat-card
    class="trinta-card invoice-details-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Invoice Details
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="invoice-info">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <span class="d-block fw-medium heading-font-family fs-15">
                        Billed To :
                    </span>
                    <span class="d-block text-black fw-medium">
                        Thomas Adison
                    </span>
                    <span class="d-block text-black fw-medium">
                        32104 Main
                    </span>
                    <span class="d-block text-black fw-medium">
                        Apt. 4c
                    </span>
                    <span class="d-block text-black fw-medium">
                        Springfield ST 54321
                    </span>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <span class="d-block fw-medium heading-font-family fs-15">
                        Shipping To :
                    </span>
                    <span class="d-block text-black fw-medium">
                        Thomas Adison
                    </span>
                    <span class="d-block text-black fw-medium">
                        32104 Main
                    </span>
                    <span class="d-block text-black fw-medium">
                        Apt. 4c
                    </span>
                    <span class="d-block text-black fw-medium">
                        Springfield ST 54321
                    </span>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <span class="d-block fw-medium heading-font-family fs-15">
                        Payment :
                    </span>
                    <span class="d-block text-black fw-medium">
                        Total Due: $12,110.55
                    </span>
                    <span class="d-block text-black fw-medium">
                        Bank Name: American Bank
                    </span>
                    <span class="d-block text-black fw-medium">
                        Country: United States
                    </span>
                    <span class="d-block text-black fw-medium">
                        IBAN: ETD95476213874685
                    </span>
                    <span class="d-block text-black fw-medium">
                        SWIFT code: BR91905
                    </span>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="info d-flex align-items-center">
                        <div class="qr-code">
                            <img src="assets/images/qr-code.png" alt="qr-code-image">
                        </div>
                        <div>
                            <h5 class="text-primary">
                                Invoice A3651
                            </h5>
                            <span class="d-block text-black">
                                October 14, 2024
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- order Summary -->
<mat-card
    class="trinta-card order-summary-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Order Summary
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            No.
                        </th>
                        <th>
                            Item
                        </th>
                        <th>
                            Quantity
                        </th>
                        <th>
                            Unit Price
                        </th>
                        <th>
                            Total Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            1.
                        </td>
                        <td class="text-body">
                            <span class="d-block heading-font-family fs-15 fw-medium">
                                Laptop Zen-24
                            </span>
                            Brand Model VGN-TXN27N/B 11.1" Notebook PC
                        </td>
                        <td>
                            6
                        </td>
                        <td>
                            $400
                        </td>
                        <td>
                            $2400
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2.
                        </td>
                        <td class="text-body">
                            <span class="d-block heading-font-family fs-15 fw-medium">
                                Mobile ATX
                            </span>
                            Brand Model VGN-TXN27N/B 11.1" Notebook PC
                        </td>
                        <td>
                            4
                        </td>
                        <td>
                            $300
                        </td>
                        <td>
                            $1200
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            Grand Total :
                        </td>
                        <td>
                            $3600
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            Shipping Charge :
                        </td>
                        <td>
                            $120
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            Discount :
                        </td>
                        <td>
                            $40
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            Estimated Tax :
                        </td>
                        <td>
                            $250
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-dark fw-medium">
                            Total :
                        </td>
                        <td class="text-dark fw-medium">
                            $3190.00
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btn-box">
            <button type="button" mat-button>
                Print
            </button>
            <button type="button" mat-button>
                Download
            </button>
        </div>
    </mat-card-content>
</mat-card>