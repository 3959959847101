
<div class="row mt-20">
  <div class="col-xxxl-4 col-xxl-4 col-md-4 pd-inline-16">
    <mat-card class="shadow-light pd-16 mb-15">
      <mat-card-content class="d-flex flex-column align-items-center">
        <div class="bg-primary border-none d-flex justify-content-center mb-25 gap-16" style="padding-block: 5px; gap:16px; border-radius: 100px; width:60%">
          <span class="d-flex align-items-center text-white fs-14">
           <mat-icon svgIcon="sunny" aria-hidden="false" aria-label="Sunny day SVG icon" class="mr-5"></mat-icon>
            18
          </span>
          <span class="d-flex align-items-center text-white fs-14">
           <mat-icon svgIcon="wind" aria-hidden="false" aria-label="wind day SVG icon" class="mr-5"></mat-icon>
            26km/h
          </span>
          <span class="d-flex align-items-center text-white fs-14">
           <mat-icon svgIcon="rain" aria-hidden="false" aria-label="rain SVG icon" class="mr-5"></mat-icon>
            10%
          </span>

        </div>
        <ul class="p-0 m-0 list-unstyled d-flex flex-column" style="width:100%" >
          <li class="d-flex align-items-center justify-content-between">
                <span class="d-flex align-items-center text-black">
                  Distance
                </span>
            <span class="d-block fw-medium heading-font-family">
                   9 miles
                </span>
          </li>
          <li class="d-flex align-items-center justify-content-between mt-10">
                <span class="d-flex align-items-center text-black">
                  Terrain type
                </span>
            <span class="d-block fw-medium heading-font-family">
                   Gravel
                </span>
          </li>
          <li class="d-flex align-items-center justify-content-between mt-10">
                <span class="d-flex align-items-center text-black">
                 Difficulty Level
                </span>
            <span class="d-block fw-medium heading-font-family">
                   7/10
                </span>
          </li>

        </ul>
      </mat-card-content>
    </mat-card>

<!--    *ngFor="let element of dataSource.data-->
    <mat-card class=" mb-25 bg-white border-none border-radius-7 d-block shadow-light pd-16">
      <mat-card-header class="fw-semibold text-primary">
        Upcoming Rides
      </mat-card-header>
      <mat-card-content class=" d-flex justify-content-between  border-radius-7 pd-block-8 shadow-light pd-inline-16 gap-16">
        <div class="d-flex flex-column align-items-center bg-yellow border-none border-radius-7" style="padding-inline: 9px; padding-block: 10px;">
            <span class = "text-white fs-14 mb-10" >Mon</span>
            <span class = "text-white fs-21 fw-semibold">15</span>
        </div>
        <div class="d-flex flex-column justify-content-center text-center">
          <h6 class="mb-5">Campton Hills Double</h6>
  <!--                    <div class="time">{{ element.time }}</div>-->
  <!--                    <div class="location">{{ element.startlocation }}</div>-->
          <div class="d-flex justify-content-evenly gap-64">
            <span class="d-flex align-items-center text-primary fs-15 gap-8">
              <mat-icon svgIcon="time_fill" aria-hidden="false" aria-label="Time/Clock SVG icon" class="mr-5"></mat-icon>
              9am
            </span>
            <span class="d-flex align-items-center text-primary fs-15 gap-8">
              <mat-icon svgIcon="pin_fill" aria-hidden="false" aria-label="Location pin SVG icon" class="mr-5"></mat-icon>
              Clydesdale CT
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
  <div class="col-xxxl-8 col-xxl-8 col-md-8">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.94539481518!2d-74.26675559025065!3d40.69739290398433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1678355274384!5m2!1sen!2sbd" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

  </div>
</div>
