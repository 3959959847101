<div class="forgot-password-area">
    <div class="d-table">
        <div class="sign-in-area">



            <div class="branding-section">
                <img src="/assets/logo/lacorsalogo.svg" alt="La Corsa official logo" aria-hidden="true">
                <h3 class="description">It's time to ride!</h3>
            </div>
            <div class="d-table login logisection">
                <img src="/assets/logo/Logo-WithText-color.svg" alt="La Corsa official logo" aria-hidden="true">
                <div>
        
        
                    <div class="trinta-form  border-radius loginpageform" style="padding-top: 16%; margin: auto;">
                        <div class="title text-left">
                            <h3 style="font-size: 22px; font-weight: 700;">Forgot password</h3>
                        </div>
        
                        <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="signform">
                            <div class="form-group">
                                <mat-label>Email Address</mat-label>
                                <mat-form-field>
                                    <input matInput class="email" type="email" id="email" formControlName="email">
                                </mat-form-field>
                            </div>
                          
                            <button mat-button color="primary" (click)="submitrequest()" [disabled]="issubmit" style="margin-right: 4px;">  {{ issubmit ? 'Submitting, please wait...' : 'SUBMIT' }} </button>
                            <div class="text-center" style="padding-top: 16px;">
                                <a routerLink="/authentication" class="text-primary">
                                    Back To Sign In
                                </a>
                            </div>
                        </form>
        
        
                        
                    </div>
                    
                
                </div>
            </div>
        </div>
    </div>
</div>