<!-- Stats -->
<app-stats />

<div class="row">
    <div class="col-xxxl-8">

        <!-- Project Overview -->
        <app-project-overview />

        <!-- About Project -->
        <app-about-project />

    </div>
    <div class="col-xxxl-4">
        <div class="row">
            <div class="col-lg-6 col-xxxl-12">

                <!-- Team Members -->
                <app-team-members />

            </div>
            <div class="col-lg-6 col-xxxl-12">

                <!-- Attachments -->
                <app-attachments />

            </div>
        </div>
    </div>
</div>