<div class="memberconfirm">
    <div class="modalheader" >
      <div  class="titletxt">Delete Group</div>
        <img (click)="onNoClick()" class="closeicon" src="../../../../assets/clx.png">
    </div>
    

    <div class="modalbodytext">Are you sure you would like to delete this group?</div>
    
    <div class="actionbtns">

      <div class="btnssection">
        <div class="confirmbtnssubmit" (click)="onConfirm()" > 
    
          <h2>OK</h2>
      
        </div>
      </div>
    </div>

  </div>
  
  