<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Form Field Theming
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field [color]="colorControl.value!">
            <i-feather name="edit-3"></i-feather>
            <mat-label>Color</mat-label>
            <mat-select [formControl]="colorControl">
                <mat-option value="primary">Primary</mat-option>
                <mat-option value="accent">Accent</mat-option>
                <mat-option value="warn">Warn</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>
</mat-card>