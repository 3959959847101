<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Form Field with Error Messages
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field>
            <i-feather name="edit-3"></i-feather>
            <mat-label>Enter your email</mat-label>
            <input matInput placeholder="pat@example.com" [formControl]="email" required>
            @if (email.invalid) {
                <mat-error>{{getErrorMessage()}}</mat-error>
            }
        </mat-form-field>
    </mat-card-content>
</mat-card>