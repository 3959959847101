import { MatCardModule } from '@angular/material/card';
import { Component, OnInit } from '@angular/core';
import { DatePipe, NgClass, CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { RouterLink } from '@angular/router';
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../app/constants';
import { ApiEndpoints } from '../../../app/api-endpoints';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToggleService } from '../../../app/common/header/toggle.service';

@Component({
    selector: 'app-notifications-page',
    standalone: true,
    imports: [RouterLink, MatCardModule,  CommonModule,  // Import CommonModule for *ngFor, *ngIf directives
        FeathericonsModule,
        MatButtonModule,
        MatMenuModule,
        RouterLink,
        NgClass,
        MatSelectModule,  // Import MatSelectModule
        MatOptionModule,  // Import MatOptionModule
        MatFormFieldModule,  // Import MatFormFieldModule
        HttpClientModule,
        MatIcon],
    templateUrl: './notifications-page.component.html',
    styleUrl: './notifications-page.component.scss',
    providers: [
        DatePipe
      ]
})
export class NotificationsPageComponent implements OnInit {

    form: FormGroup;
    notifications: any[];
    totalNotifications: string = '';

    constructor(
      public toggleService: ToggleService,
      private datePipe: DatePipe,private router: Router,
      private http: HttpClient
    ) {
      this.toggleService.isToggled$.subscribe(isToggled => {
        this.isToggled = isToggled;
      });
    }
    isToggled = false;
    toggle() {
      this.toggleService.toggle();
    }
    ngOnInit(): void {
        this.loadNotifications();
      }
    
    loadNotifications() {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    
        this.http.get(BASE_URL + ApiEndpoints.userNotifications, { headers: headers }).subscribe(
          (data: any) => {
            console.log("notifications", data)
            this.notifications = data; // Assuming data is an array of objects with properties 'value' and 'label'
            this.totalNotifications = data.length;
          },
          error => {
            console.log('Error fetching purposes:', error);
          }
        );
    
      }
}

