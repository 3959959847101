<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Icon
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Icon
        </li>
    </ol>
</div>

<!-- Icon -->
<div class="row">
    <div class="col-sm-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Icons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-sm-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        SVG Icons
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-icon svgIcon="thumbs-up" aria-hidden="false" aria-label="Example thumbs up SVG icon"></mat-icon>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div class="mb-25 text-center">
    <a href="https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/" target="_blank" mat-flat-button color="primary">
        Click to See More Icon
    </a>
</div>