import {Component, ViewChild} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {ChartComponent, NgApexchartsModule} from "ng-apexcharts";
import {ChartOptions} from "../../../group-activity-charts/avg-speed-chart/avg-speed-chart.component";

@Component({
  selector: 'app-route-avg-speed-chart',
  standalone: true,
    imports: [
        MatButton,
        MatCard,
        MatCardContent,
        MatCardHeader,
        MatCardTitle,
        NgApexchartsModule
    ],
  templateUrl: './route-avg-speed-chart.component.html',
  styleUrl: './route-avg-speed-chart.component.scss'
})
export class RouteAvgSpeedChartComponent {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Speed",
          data: [8, 6, 3.8, 5.6, 2.8,7.1, 12, 12.4, 13,10,14.7,6.1]
        }
      ],
      chart: {
        type: "bar",
        height: 250,
        toolbar: {
          show: false
        }
      },
      colors: ['#1FBBCA'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 4,
        show: true,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [

          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
        ],
        axisBorder: {
          show: false,
          color: '#a9a9c8'
        },
        axisTicks: {
          show: true,
          color: '#a9a9c8',
          borderType: 'dotted'
        },
        labels: {
          show: true,
          style: {
            colors: "#262626",
            fontSize: "13px"
          }
        }
      },
      yaxis: {
        title: {
          text: "(mi/h)",
          style: {
            color: "#5b5b98",
            fontSize: "14px",
            fontWeight: 500
          }
        },
        labels: {
          show: true,
          style: {
            colors: "#a9a9c8",
            fontSize: "13px"
          }
        },
        axisBorder: {
          show: false,
          color: '#edeff5'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: [ '#1F499F'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + "mi/h";
          }
        }
      },
      legend: {
        show: true,
        offsetY: 10,
        fontSize: '13px',
        position: "bottom",
        horizontalAlign: "center",
        labels: {
          colors: '#77838f',
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        }
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        borderColor: "#edeff5"
      }
    };
  }


}
