<mat-card
    class="trinta-card event-details-image-card mb-25 bg-white border-none d-block"
>
    <mat-card-content>
        <img src="assets/images/events/event-details.jpg" alt="event-details-image">
    </mat-card-content>
</mat-card>

<!-- Event Details Card -->
<mat-card
    class="trinta-card event-details-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Event Details
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-lg-8">
                <div class="content">
                    <h4 class="fw-bold">
                        Spain Education Seminar
                    </h4>
                    <p class="text-black">
                        Node.js for Beginners: Alias animi labque, deserunt distinctio eum excepturi fuga iure labore magni molestias mollitia natus, officia pofro quis sunt temporibus veritatis voluptatem, voluptatum.
                    </p>
                    <div class="btn-box">
                        <button mat-button type="button">
                            <i-feather name="key"></i-feather>
                            Get Ticket
                        </button>
                        <button mat-button type="button">
                            <i-feather name="calendar"></i-feather>
                            Add To Calendar
                        </button>
                        <button mat-button type="button">
                            <i-feather name="heart"></i-feather>
                            4510
                        </button>
                        <button mat-button type="button">
                            <i-feather name="share-2"></i-feather>
                            1026
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="socials">
                    <span class="d-block text-body fs-15 fw-medium">
                        Share with Friends:
                    </span>
                    <ul class="p-0 m-0 list-unstyled">
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-facebook-box-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-messenger-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-twitter-fill"></i>
                            </a>
                        </li>
                        <li class="d-inline-block">
                            <a href="#" class="d-flex align-items-center justify-content-center" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="row">
    <div class="col-lg-7">

        <!-- About This Event -->
        <mat-card
            class="trinta-card about-this-event-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        About This Event
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p class="text-black">
                    This event will teach you the fundamentals of working with Angular 17. You will learn everything you need to know to create complete applications including: components, services, pipes, routing, HTTP, and even testing.
                </p>
                <p class="text-black">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa laborum eiusmod. Anim pariatur cliche reprehenderit, enim eiusmod high life terry richardson.
                </p>
            </mat-card-content>
        </mat-card>

    </div>
    <div class="col-lg-5">

        <!-- Topic To Be Covered -->
        <mat-card
            class="trinta-card topic-covered-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Topic To Be Covered
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="m-0 p-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Latest Update With Bitcoin
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Why Do We Need Cryptocurrency?
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        How Big Is Cryptocurrency Right Now?
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Is it Worth Buying To Keep?
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Bitcoin Vs Ethereum
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>

    </div>
</div>

<!-- Host -->
<mat-card
    class="trinta-card host-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Host
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-xxl-7">
                <div class="host d-md-flex">
                    <img src="assets/images/users/user11.jpg" class="rounded-circle" alt="user-image">
                    <div>
                        <h6>
                            Oliver Jenkins
                        </h6>
                        <span class="d-block text-body">
                            From: oliver&#64;domain.com
                        </span>
                        <p class="text-black">
                            Kellie Marquot is a software developer at our platform with more than 20 years of software development experience, he has gained a passion for Agile software development -- especially Lean.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xxl-5">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="box text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <i class="ri-calendar-check-line"></i>
                            </div>
                            <span class="d-block fs-15 fw-medium">
                                Date
                            </span>
                            <h6 class="mb-0 fw-bold">
                                January 17, 2024
                            </h6>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="box text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                                <i class="ri-map-pin-2-line"></i>
                            </div>
                            <span class="d-block fs-15 fw-medium">
                                Location
                            </span>
                            <h6 class="mb-0 fw-bold">
                                Evergreen, NY
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>