<div class="confirm-email-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="trinta-form text-center ml-auto mr-auto bg-white border-radius"
            >
                <h3>
                    Welcome
                </h3>
                <div class="icon text-primary ml-auto mr-auto d-flex align-items-center justify-content-center rounded-circle">
                    <i-feather name="mail"></i-feather>
                </div>
                <h4>
                    Success !
                </h4>
                <p class="text-black">
                    A email has been send to examplemail&#64;info.com. Please check for an email from company and click on the included link to reset your password.
                </p>
                <a routerLink="/authentication" mat-button>
                    Back To Home
                </a>
            </div>
        </div>
    </div>
</div>