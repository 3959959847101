<app-stats />

<div class="row">
    <div class="col-xxxl-8 col-md-12">

        <!-- Balance Overview -->
        <app-balance-overview />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Contact Leads -->
        <app-contact-leads />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Deals Statistics -->
        <app-deals-statistics />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Sales Forecast -->
        <app-sales-forecast />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Organic Sessions -->
        <app-organic-sessions />

    </div>
    <div class="col-xxxl-8 col-md-12">

        <!-- Leads Report -->
        <app-leads-report />

    </div>
    <div class="col-xxxl-4 col-md-12">

        <!-- Payment History -->
        <app-payment-history />

    </div>
</div>