<mat-card
    class="trinta-card notifications-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Notifications
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="menu-body p-0 mt-0 list-unstyled">
            <li *ngFor="let notification of notifications" class="position-relative">
              <div
                class="icon rounded-circle d-flex align-items-center justify-content-center position-absolute text-center transition">
              
                <svg  xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none"
                  viewBox="0 0 32 20">
                  <!-- SVG path for ride icon -->
                </svg>
              </div>

              <span class="sub-title d-block">
                {{ notification.subject }} <strong class="fw-semibold">{{ notification.message }}</strong>
              </span>

              <span class="time d-block text-body">
                {{ notification.timestamp }}
              </span>
              <a routerLink="/notifications"
                class="d-block left-0 top-0 bottom-0 right-0 z-1 position-absolute"></a>
              <span  class="unread d-inline-block rounded-circle bg-primary position-absolute"></span>
            </li>
          </ul>
    </mat-card-content>
</mat-card>