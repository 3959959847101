<mat-card
    class="trinta-card emails-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Inbox
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <div class="info-buttons-list d-flex align-items-center">
                <button mat-button>
                    <i class="material-symbols-outlined">
                        refresh
                    </i>
                </button>
                <button mat-button>
                    <i class="material-symbols-outlined">
                        print
                    </i>
                </button>
                <button mat-button>
                    <i class="material-symbols-outlined">
                        report_gmailerrorred
                    </i>
                </button>
                <button mat-button>
                    <i class="material-symbols-outlined">
                        delete
                    </i>
                </button>
                <button type="button" mat-button [matMenuTriggerFor]="emailHeaderMenu">
                    <i class="material-symbols-outlined">
                        more_horiz
                    </i>
                </button>
                <mat-menu #emailHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                    <button mat-menu-item>
                        Recent
                    </button>
                    <button mat-menu-item>
                        Unread
                    </button>
                    <button mat-menu-item>
                        Mark All Read
                    </button>
                    <button mat-menu-item>
                        Spam
                    </button>
                    <button mat-menu-item>
                        Delete All
                    </button>
                </mat-menu>
            </div>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="emails-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <td mat-cell *matCellDef="let row" class="pl-0">
                            <div class="d-flex align-items-center">
                                <mat-checkbox
                                    color="primary"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                >
                                </mat-checkbox>
                                <div class="icon">
                                    <i class="ri-star-fill"></i>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Title Column -->
                    <ng-container matColumnDef="title">
                        <td mat-cell *matCellDef="let element">
                            <a routerLink="/apps/email/read" class="fw-semibold fs-15 heading-font-family">
                                {{element.title}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Description Column -->
                    <ng-container matColumnDef="description">
                        <td mat-cell *matCellDef="let element" style="white-space: normal;">
                            <p class="text-black">
                                {{element.description}}
                            </p>
                        </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            {{element.date}}
                        </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="'/apps/email/read'"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>