<div class="row">
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="title">
                        <span class="d-block text-gray">
                            Total Leads
                        </span>
                        <h5 class="m-0">
                            2.05k
                        </h5>
                    </div>
                    <div class="icon d-flex align-items-center rounded-circle justify-content-center">
                        <i class="ri-bar-chart-line"></i>
                    </div>
                </div>
                <div class="info d-flex align-items-center">
                    <span class="trending d-block fw-medium position-relative">
                        3.74% <i class="ri-arrow-up-s-line"></i>
                    </span>
                    <span class="d-block text-black">
                        Leads this month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="title">
                        <span class="d-block text-gray">
                            Open
                        </span>
                        <h5 class="m-0">
                            1.85k
                        </h5>
                    </div>
                    <div class="icon d-flex align-items-center rounded-circle justify-content-center">
                        <i class="ri-building-line"></i>
                    </div>
                </div>
                <div class="info d-flex align-items-center">
                    <span class="trending d-block fw-medium position-relative">
                        2.80% <i class="ri-arrow-up-s-line"></i>
                    </span>
                    <span class="d-block text-black">
                        Leads this month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="title">
                        <span class="d-block text-gray">
                            Closed
                        </span>
                        <h5 class="m-0">
                            6.04K
                        </h5>
                    </div>
                    <div class="icon d-flex align-items-center rounded-circle justify-content-center">
                        <i class="ri-check-double-line"></i>
                    </div>
                </div>
                <div class="info d-flex align-items-center">
                    <span class="trending down d-block fw-medium position-relative">
                        1.03% <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span class="d-block text-black">
                        Leads this month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="title">
                        <span class="d-block text-gray">
                            Annual Profit
                        </span>
                        <h5 class="m-0">
                            $258k
                        </h5>
                    </div>
                    <div class="icon d-flex align-items-center rounded-circle justify-content-center">
                        <i class="ri-money-euro-circle-line"></i>
                    </div>
                </div>
                <div class="info d-flex align-items-center">
                    <span class="trending d-block fw-medium position-relative">
                        5.70% <i class="ri-arrow-up-s-line"></i>
                    </span>
                    <span class="d-block text-black">
                        Leads this month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>