<div class="row">
    <div class="col-lg-12 col-xxxl-12">
        <mat-card
            class="trinta-card chat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="chat-header d-md-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <div class="img position-relative">
                            <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                            <span class="active-status"></span>
                        </div>
                        <div class="title">
                            <span class="d-block fw-semibold heading-font-family">
                                Sarah Rodriguez
                            </span>
                            <span class="d-block text-body">
                                Active Now
                            </span>
                        </div>
                    </div>
                    <div class="buttons-list d-flex align-items-center">
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                videocam
                            </i>
                        </button>
                        <button mat-button>
                            <i class="material-symbols-outlined">
                                call
                            </i>
                        </button>
                        <button type="button" mat-button [matMenuTriggerFor]="chatHeaderMenu">
                            <i class="material-symbols-outlined">
                                more_horiz
                            </i>
                        </button>
                        <mat-menu #chatHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                            <button mat-menu-item>
                                Mute Chat
                            </button>
                            <button mat-menu-item>
                                Delete
                            </button>
                            <button mat-menu-item>
                                Block
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="chat-body">
                    <ul class="list-unstyled mb-0 mt-0">
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Hey Micheals, have you had a chance to check out the new admin dashboard?
                                    </p>
                                </div>
                                <div>
                                    <p class="text-black">
                                        Oh, they've got this Kanban board for task management. You can drag and drop tasks between columns – it's so intuitive. Makes managing tasks a breeze. 🔥
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:10 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        Oh, you mean the one for project management?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:20 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Yeah, that's the one! It's got a sleek Material Design, and the features are pretty robust.
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:20 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        Nice! What features are you finding interesting?
                                    </p>
                                </div>
                                <div>
                                    <p class="text-white">
                                        Hey Micheals, have you had a chance to check out the new admin dashboard?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:21 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Well, it has a project overview with all the key metrics on the landing page – project progress, pending tasks, and even a Gantt chart.
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:22 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        That sounds handy. How about the task management features?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:25 AM
                            </span>
                        </li>
                        <li class="position-relative">
                            <img src="assets/images/users/user1.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-black">
                                        Oh, they've got this Kanban board for task management. You can drag and drop tasks between columns – it's so intuitive. Makes managing tasks a breeze. 🔥
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:30 AM
                            </span>
                        </li>
                        <li class="position-relative right">
                            <img src="assets/images/admin.jpg" width="35" class="rounded-circle user" alt="user">
                            <div class="message">
                                <div>
                                    <p class="text-white">
                                        Sweet! What about team collaboration?
                                    </p>
                                </div>
                            </div>
                            <span class="time d-block text-body">
                                09:31 AM
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="chat-footer border-radius d-md-flex align-items-center">
                    <div class="buttons-list d-flex align-items-center">
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                sentiment_satisfied
                            </i>
                        </button>
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                attach_file
                            </i>
                        </button>
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                mic_none
                            </i>
                        </button>
                        <button mat-button class="text-black">
                            <i class="material-symbols-outlined">
                                image
                            </i>
                        </button>
                    </div>
                    <form class="position-relative">
                        <mat-form-field>
                            <mat-label>Type something....</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <button mat-button type="button">
                            <i class="material-symbols-outlined">
                                send
                            </i>
                        </button>
                    </form>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>