<mat-card
    class="trinta-card tables-of-content-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Tables Of Content
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row align-items-center">
            <div class="col-xxl-8">
                <div class="tables-of-content-list">
                    <div class="d-flex align-items-center">
                        <span class="number fw-semibold bg-primary rounded-circle text-white d-flex align-items-center justify-content-center">
                            1
                        </span>
                        <h4 class="mb-0">
                            Getting Started With Angular
                        </h4>
                    </div>
                    <mat-accordion>
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Course Overview
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="p-0 m-0 list-unstyled">
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="info"></i-feather>
                                        Introduction
                                    </span>
                                    <span class="d-block">
                                        8m 42s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="play-circle"></i-feather>
                                        Introduction To TypeScript
                                    </span>
                                    <span class="d-block">
                                        40m 50s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="list"></i-feather>
                                        Comparing Angular to AngularJS
                                    </span>
                                    <span class="d-block">
                                        5hr 42m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="star"></i-feather>
                                        Quiz: Getting Started With Angular
                                    </span>
                                    <span class="d-block">
                                        7hr 10m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Angular Fundamentals
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="p-0 m-0 list-unstyled">
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="info"></i-feather>
                                        Introduction
                                    </span>
                                    <span class="d-block">
                                        8m 42s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="play-circle"></i-feather>
                                        Introduction To TypeScript
                                    </span>
                                    <span class="d-block">
                                        40m 50s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="list"></i-feather>
                                        Comparing Angular to AngularJS
                                    </span>
                                    <span class="d-block">
                                        5hr 42m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="star"></i-feather>
                                        Quiz: Getting Started With Angular
                                    </span>
                                    <span class="d-block">
                                        7hr 10m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Creating And Communicating Between Angular Components
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="p-0 m-0 list-unstyled">
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="info"></i-feather>
                                        Introduction
                                    </span>
                                    <span class="d-block">
                                        8m 42s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="play-circle"></i-feather>
                                        Introduction To TypeScript
                                    </span>
                                    <span class="d-block">
                                        40m 50s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="list"></i-feather>
                                        Comparing Angular to AngularJS
                                    </span>
                                    <span class="d-block">
                                        5hr 42m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="star"></i-feather>
                                        Quiz: Getting Started With Angular
                                    </span>
                                    <span class="d-block">
                                        7hr 10m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Exploring The Angular Template Syntax
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="p-0 m-0 list-unstyled">
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="info"></i-feather>
                                        Introduction
                                    </span>
                                    <span class="d-block">
                                        8m 42s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="play-circle"></i-feather>
                                        Introduction To TypeScript
                                    </span>
                                    <span class="d-block">
                                        40m 50s
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="list"></i-feather>
                                        Comparing Angular to AngularJS
                                    </span>
                                    <span class="d-block">
                                        5hr 42m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                                <li class="position-relative text-black d-md-flex align-items-center justify-content-between">
                                    <span class="d-block">
                                        <i-feather name="star"></i-feather>
                                        Quiz: Getting Started With Angular
                                    </span>
                                    <span class="d-block">
                                        7hr 10m
                                    </span>
                                    <a routerLink="/lms-page/lesson-preview" class="position-absolute top-0 left-0 right-0 bottom-0 z-1"></a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="col-xxl-4">
                <div class="unlock-library-box text-center bg-primary">
                    <h5 class="text-white">
                        Unlock Library
                    </h5>
                    <p class="fs-15">
                        Get access to all videos in the library
                    </p>
                    <a routerLink="/" mat-button>
                        Sign Up - Only $120/mo
                    </a>
                    <p>
                        Have an account? <a routerLink="/" class="text-white">Login</a>
                    </p>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>