<mat-card class="trinta-card mb-25 bg-white border-none d-block routessection">

  <div class="row">

    <div class="col-xl-6 col-lg-6 col-sm-6 ">
      <span class="routetitle">Routes</span>
      </div>

    <div class="col-xl-6 col-lg-6 col-sm-6 " style="text-align: right;">
      <div class="details-container"  style="display: flex; justify-content: right; align-items: center; padding: 2px; border-radius: 7px;">
        <div class="detail"  [routerLink]="['/tools']" 
        [queryParams]="{ tab: 1 }"   style="display: flex; align-items: center; color: #000000; font-size: 16px; font-family: Libre Franklin; font-weight: normal; cursor: pointer;">
            See More
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
          
        </div>
      </div>
        </div>
  </div>

  <div style="height: 200px; overflow-y: scroll; overflow-x: hidden;">
      <div class="row" *ngFor="let route of routes">
      <div class="col-xl-6 col-lg-6 col-sm-6">
          <p class="nametxt">{{ route.name }}</p>
          </div>
          <div class="col-xl-5 col-lg-6 col-sm-6 " style="text-align: right;">
            <p class="milestxt">12 miles</p>
            </div>
      </div>
     
  </div>


</mat-card>

<mat-card class="trinta-card mb-25 bg-white border-none d-block routessection">


 


  <div class="row">

    <div class="col-xl-6 col-lg-6 col-sm-6 ">
      <span class="routetitle">Drafts</span>
      </div>

    <div class="col-xl-6 col-lg-6 col-sm-6 " style="text-align: right;">
      <div class="details-container"  style="display: flex; justify-content: right; align-items: center; padding: 2px; border-radius: 7px;">
        <div class="detail"   [routerLink]="['/activities']" 
        [queryParams]="{ tab: 3 }"   style="display: flex; align-items: center; color: #000000; font-size: 16px; font-family: Libre Franklin; font-weight: normal; cursor: pointer;">
            See More
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
          
        </div>
      </div>
        </div>

  </div>

  <div style="height: 200px;">
      <div class="row" *ngFor="let draft of drafts">
      <div class="col-xl-6 col-lg-6 col-sm-6">
          <p class="nametxt">{{ draft.name }}</p>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-6 " style="text-align: right;">
            <p><svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5.5" r="5" fill="#1FBBCA"/>
              </svg>
              </p>
            </div>
      </div>
     
  </div>

</mat-card>

