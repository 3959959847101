import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { FeathericonsModule } from '../../../icons/feathericons/feathericons.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgFor } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { EditgroupdetailsComponent } from "../../../forms/create-event-types/editgroupdetails/editgroupdetails.component";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { NgIf } from '@angular/common';
import { DatePipe, NgClass, CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { GroupdetailsdeleteComponent } from '../groupdetailsdelete/groupdetailsdelete.component';
import { ToastService, ToastType } from '../../../../app/toast.service'; // adjust the path accordingly


@Component({
  selector: 'app-groupdetails',
  standalone: true,
  imports: [
    RouterLink,CommonModule,
    HttpClientModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    FeathericonsModule,
    CarouselModule,
    NgFor,
    MatProgressBarModule,
    MatMenuModule,NgIf,
    MatMenuTrigger
  ],
  templateUrl: './groupdetails.component.html',
  styleUrls: ['./groupdetails.component.scss'],
  providers: [
    DatePipe
  ]
})
export class GroupdetailsComponent implements OnInit {
  description: string = '';
  name: string = '';
  invitedRidersOnly: boolean = false;
  adminApproval: boolean = false;
  socialLinks: string = '';
  locationComment: string = '';
  amount: number = 0;
  stateId: string = '';
  cityId: string = '';
  logo: string = '';
  image: string = '';
  la_TypicalPace_ID: string = '';
  la_RidingGroup_ID: string = '';
  url: string = '';
  la_RidingLevel_ID: string = '';
  la_AnnualMileage_ID: string = '';
  la_RidingTypes: string = '';
  la_RidingType: string = '';
  la_RidingType_ID: string = '';
  invites: string = '';
  invitesvalue: boolean = false;
  allowMemberPost: boolean = false;
  x: string = '';
  instagram: string = '';
  faceBook: string = '';
  groupAdmin: string = '';
  ridingLevels: string = '';
  ridingTypes: string = '';
  datecreated: string = '';
  city: string = '';
  state: string = '';
  groupName: string = 'The Night Riders'; // Replace this with actual logic to get the group name
  profilePhoto: string = '';
  genderIDList: string = '';
  groupOwnerName: string = '';
  groupOwnerphoto: string = '';
  la_TypicalPace: string = '';
  la_AnnualMileage: string = '';
  genderNameList: string = '';
  la_RidingLevels: string = '';
  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;

  // constructor(public dialog: MatDialog) {}


  productImages = [
    { url: 'assets/images/products/product-details1.jpg' },
    { url: 'assets/images/products/product-details2.jpg' },
    { url: 'assets/images/products/product-details3.jpg' },
    { url: 'assets/images/products/product-details4.jpg' }
  ];
  selectedImage: string = this.productImages[0].url;

  constructor(private http: HttpClient, private router: Router,public dialog: MatDialog, private datePipe: DatePipe,
    private toastService: ToastService,

  ) {
    
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp);
    const formattedDate = this.datePipe.transform(date, 'MMMM yyyy');
    
    return formattedDate ? formattedDate : '';
    // Or, alternatively, you could use:
    // return formattedDate || 'Invalid date';
  }
  

  showFullDescription = false;

  toggleDescription() {
    this.showFullDescription = !this.showFullDescription;
  }
  
  ngOnInit(): void {
    this.getUserProfile();

    this.fetchgroupdetails();
  }



  showSuccess() {
    this.toastService.showToast('Group deleted successfully!', ToastType.Success);
  }

  showError() {
    this.toastService.showToast("You don't have permission to delete the group", ToastType.Error);
  }

  showWarning() {
    this.toastService.showToast('This is a warning message.', ToastType.Warning);
  }

  changeImage(image: string): void {
    this.selectedImage = image;
  }

  initials: string = '';
  backgroundColor: string = '';

 
  secondaryColors: string[] = ['#14E50', '#F2A343', '#5E4389', '#1F499F', '#1FBBCA']; // Add your secondary colors


  generateInitials() {
    if (!this.logo || this.logo.trim() === '') {
      // Exclude articles and split the group name into words
      const nameParts = this.name
        .replace(/^(The|A|An)\s+/i, '') // Exclude articles
        .split(' ');

      // Get the first letter of each part, limit to 2 initials
      this.initials = nameParts.map(part => part.charAt(0)).join('').substring(0, 2).toUpperCase();

      // Randomly select a secondary color
      this.backgroundColor = this.secondaryColors[Math.floor(Math.random() * this.secondaryColors.length)];
    }
  }

  fetchgroupdetails(): void {


    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    const groupdId = localStorage.getItem('selectedGroupId');

    if(groupdId == null){
      console.log("res.tokenres.token", localStorage.getItem('angular17token'));
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.get(BASE_URL + ApiEndpoints.getGroupsByUser, { headers: headers }).subscribe(
        (data: any) => {
          console.log("group data", data);

          localStorage.setItem('selectedGroupId', data[0].la_RidingGroup_ID);
          this.fetchgroupdetails();
        },
        error => {
          console.log('Error fetching data:', error);
        }
      );
    }else{
    this.http.get<any[]>(`${BASE_URL}${ApiEndpoints.getGroupsByID}${groupdId}`, { headers })
      .subscribe(
        (data) => {

          if (data && data.length > 0) {
            this.description = data[0].description;
            this.name = data[0].name;
            this.generateInitials();
            if(data[0].invitedRidersOnly == true){
              this.invites = "Invites Only";
              this.invitesvalue = true;

              console.log("this.invitesvalue now",  this.invitesvalue);

            }
            
            if(data[0].adminApproval == true){
              this.invites = "Admin Approval";
              this.invitesvalue = true;
            }
            
            if(data[0].adminApproval == false && data[0].invitedRidersOnly == false || data[0].adminApproval == "false" && data[0].invitedRidersOnly == "false"){
              // this.invites = "Admin Approval";
              this.invitesvalue = false;

              console.log(" this.invitesvalue = false false",  this.invitesvalue = false);

            }

            if(data[0].allowMemberPost == true){
              this.allowMemberPost = true;

            }else{
              // this.invites = "Open";
              this.allowMemberPost = false;
            }



            

            // console.log("data[0].image", data[0].image)

            if(data[0].image == null || data[0].image == '' ){
              data[0].image = "";
            }else{
              data[0].image = "data:image/png;base64,"+data[0].image;
            }

            if(data[0].logo == null || data[0].logo == " "){
              data[0].logo = "";
            }else{
              data[0].logo = "data:image/png;base64,"+data[0].logo;
            }

            if(data[0].groupOwnerphoto == null || data[0].groupOwnerphoto == " "){
              data[0].groupOwnerphoto = "";
            }else{
              data[0].groupOwnerphoto = "data:image/png;base64,"+data[0].groupOwnerphoto;
            }


            this.logo = `${data[0].logo}`;
            this.image = `${data[0].image}`;

            this.invitedRidersOnly = data[0].invitedRidersOnly;
            this.socialLinks = data[0].socialLinks;
            this.locationComment = data[0].locationComment;
            this.la_RidingType = data[0].la_RidingType;
            this.adminApproval = data[0].adminApproval;
            this.amount = data[0].amount;
            // this.logo = data[0].logo;
            this.stateId = data[0].stateId;
            this.cityId = data[0].cityId;
            this.la_RidingGroup_ID = data[0].la_RidingGroup_ID;
            this.la_TypicalPace_ID = data[0].la_TypicalPace_ID;
            this.url = data[0].url;
            this.la_RidingLevel_ID = data[0].la_RidingLevel_ID;
            this.la_AnnualMileage_ID = data[0].la_AnnualMileage_ID;
            this.la_RidingType_ID = data[0].la_RidingType_ID;
            this.allowMemberPost = data[0].allowMemberPost;
            this.x = data[0].x;
            this.instagram = data[0].instagram;
            this.faceBook = data[0].faceBook;
            this.groupAdmin = data[0].groupAdmin;
            this.ridingLevels = data[0].ridingLevels;
            this.ridingTypes = data[0].ridingTypes;
            this.profilePhoto = this.profilePhoto
            this.la_RidingGroup_ID =  data[0].la_RidingGroup_ID;
            this.la_RidingTypes = data[0].la_RidingTypes;
            this.city = data[0].city;
            this.state = data[0].state;
            this.genderIDList = data[0].genderIDList;
            this.groupOwnerName = data[0].groupOwnerName;
            this.groupOwnerphoto = data[0].groupOwnerphoto;
            this.datecreated  = this.formatDate(data[0].dateTime); // Output: "May 2024"
            this.la_TypicalPace = data[0].la_TypicalPace;
            this.la_AnnualMileage = data[0].la_AnnualMileage;
            this.genderNameList = data[0].genderNameList;
            this.la_RidingLevels = data[0].la_RidingLevels;

            

          }
          console.log("group data", data);
          
        },
        (error) => {
          console.error('Error fetching group details:', error);
        }
      );
  }

}



getUserProfile() {
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get(BASE_URL + ApiEndpoints.getUserProfile, { headers: headers }).subscribe(
    (data: any) => {
    },
    error => {
      console.log('Error fetching purposes:', error);
    }
  );
}


deeleteroup(groupId: string): void {
  const dialogRef = this.dialog.open(GroupdetailsdeleteComponent, {
    restoreFocus: false,
    panelClass: 'custom-dialog',
    width: '460px',
    disableClose: true,
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Call your delete function here
      this.deeleteroupConfirm(groupId);
    }
  });
}

deeleteroupConfirm(groupId: string): void {
  console.log(groupId);

  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.post(BASE_URL + ApiEndpoints.deleteGroupById + groupId, "", { headers: headers }).subscribe(
    (res: any) => {
      if (res.statusCode == 400) {
        this.showError();

        
      } else if (res.statusCode == 200) {
        this.showSuccess();
        setTimeout(() => {
          this.router.navigate(['/authentication']);
        }, 4000);
        
      } {
      
      }
      
    },
    (error) => {
      if (error.status === 401) {
        // Handle 401 Unauthorized error
        console.log("Unauthorized:", error.status);
        alert("Unauthorized");
      } else if (error.status === 500) {
        // Handle 500 Internal Server Error
        console.log("Internal Server Error:", error.status);
        alert("Internal server error");
      } else if(error.status = 200 ) {
        this.showSuccess();
          setTimeout(() => {
            this.router.navigate(['/authentication']);
          }, 4000);
      
      }else if(error.status = 400 ) {
          // setTimeout(() => {
          //   this.router.navigate(['/authentication']);
          // }, 4000);
      
      }else {
        // alert(error.status)
        // Handle other errors
        console.error("Error deleting group:", error);
        this.showError();
      }
    }
  );
}


openDialog(componentType: Type<any>) {
  const dialogRef = this.dialog.open(componentType, { 
    restoreFocus: false, 
    width: "45%",
    height: "100vh",
    position: { right: '0' },
    data: { ...this },
    disableClose: true,
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the edited data here
      this.name = result.name;
      this.description = result.description;
      this.invitedRidersOnly = result.invitedRidersOnly;
      this.socialLinks = result.socialLinks;
      this.locationComment = result.locationComment;
      this.amount = result.amount;
      this.la_RidingGroup_ID = result.la_RidingGroup_ID;
      this.la_RidingLevel_ID = result.la_RidingLevel_ID;
      this.la_AnnualMileage_ID = result.la_AnnualMileage_ID;
      this.la_RidingType_ID = result.la_RidingType_ID;
      this.adminApproval = result.adminApproval;
      this.stateId = result.stateId;
      this.cityId = result.cityId;
      this.la_TypicalPace_ID = result.la_TypicalPace_ID
      this.url = result.url;
      this.allowMemberPost = result.allowMemberPost;
      this.x = result.x;
      this.ridingLevels = result.ridingLevels;
      this.ridingTypes = result.ridingTypes;
      this.instagram = result.instagram;
      this.faceBook = result.faceBook;
      this.logo = `data:image/png;base64,${result.logo}`;
      this.image = `data:image/png;base64,${result.image}`;
      this.profilePhoto = `data:image/png;base64,${result.profilePhoto}`;
      this.la_RidingTypes = result.la_RidingTypes;
      this.datecreated = result.dateTime;
      this.city = result.city;
      this.state = result.state;
      this.genderIDList = result.genderIDList;
      this.groupOwnerName = result.groupOwnerName;
      this.groupOwnerphoto = `data:image/png;base64,${result.groupOwnerphoto}`;
      this.la_TypicalPace = result.la_TypicalPace;
      this.la_AnnualMileage = result.la_AnnualMileage;
      this.genderNameList = result.genderNameList;
      this.la_RidingLevels = result.la_RidingLevels;
    }
    // this.menuTrigger.focus();
  });

   dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the edited data here
        this.name = result.name;
        this.description = result.description;
        this.invitedRidersOnly = result.invitedRidersOnly;
        this.adminApproval = result.adminApproval;
        this.stateId = result.stateId;
        this.cityId = result.cityId;
        this.socialLinks = result.socialLinks;
        this.locationComment = result.locationComment;
        this.amount = result.amount;
        this.la_TypicalPace_ID = result.la_TypicalPace_ID
        this.url = result.url;
        this.la_RidingGroup_ID =  result.la_RidingGroup_ID;
        this.la_RidingLevel_ID = result.la_RidingLevel_ID;
        this.la_AnnualMileage_ID = result.la_AnnualMileage_ID;
        this.la_RidingType_ID = result.la_RidingType_ID;
        this.allowMemberPost = result.allowMemberPost;
        this.x = result.x;
        this.ridingLevels = result.ridingLevels;
        this.ridingTypes = result.ridingTypes;
        this.instagram = result.instagram;
        this.faceBook = result.faceBook;
        this.logo = `data:image/png;base64,${result.logo}`;
        this.image = `data:image/png;base64,${result.image}`;
        this.profilePhoto = `data:image/png;base64,${result.profilePhoto}`;
        this.la_RidingTypes = result.la_RidingTypes;
        this.datecreated = result.dateTime
        this.city = result.city;
        this.state = result.state;
        this.genderIDList = result.genderIDList;
        this.groupOwnerName = result.groupOwnerName;
        this.groupOwnerphoto = `data:image/png;base64,${result.groupOwnerphoto}`;
        this.la_TypicalPace = result.la_TypicalPace;
        this.la_AnnualMileage = result.la_AnnualMileage;
        this.genderNameList = result.genderNameList;
        this.la_RidingLevels = result.la_RidingLevels;

        
        console.log('Edited data:', result);
      }
      // this.menuTrigger.focus();
    });
  
}

protected readonly EditgroupdetailsComponent = EditgroupdetailsComponent;

}


