<label id="example-radio-group-label">Pick your favorite season</label>
<mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="favoriteSeason"
>
    @for (season of seasons; track season) {
        <mat-radio-button class="example-radio-button" [value]="season">{{season}}</mat-radio-button>
    }
</mat-radio-group>
<div>Your favorite season is: {{favoriteSeason}}</div>