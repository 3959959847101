
<div class="row bg-white mt-20 pd-inline-16">
  <div class="col-md-6">
    <div class="row">
      <div class="col-sm-4">
        <mat-card class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center shadow-light">
          <mat-card-content>
            <div class = "card-title">
              <span class="d-block fw-500">
                      Avg Speed
              </span>
              <div class="icon d-flex align-items-center justify-content-center ml-auto ">
                <mat-icon svgIcon="speedometer" aria-hidden="false" aria-label="speedometer icon"></mat-icon>
              </div>

            </div>
            <h5 class="fw-semibold">
              12 m/h

            </h5>

          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-4">
        <mat-card class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center shadow-light">
          <mat-card-content>
            <div class = "card-title">
              <span class="d-block">Best Time</span>
              <div class="icon d-flex align-items-center justify-content-center ml-auto ">
                <mat-icon svgIcon="clock" aria-hidden="false" aria-label="clockSVG icon"></mat-icon>
              </div>

            </div>
            <h5 class="fw-semibold">
              1:03:22

            </h5>

          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-4">
        <mat-card class="trinta-card pm-stat-card mb-25 bg-white border-none d-block text-center shadow-light">
          <mat-card-content>
            <div class = "card-title">
          <span class="d-block">
                      Avg Ride Duration
                  </span>
              <div class="icon d-flex align-items-center justify-content-center ml-auto">
                <mat-icon svgIcon="time_outline" aria-hidden="false" aria-label="clockSVG icon"></mat-icon>



              </div>

            </div>
            <h5 class="fw-semibold">
              1:45:11

            </h5>

          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <app-route-avg-speed-chart/>
  </div>
  <div class="col-md-6">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.94539481518!2d-74.26675559025065!3d40.69739290398433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1678355274384!5m2!1sen!2sbd" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

  </div>

</div>
<div class="row bg-white mt-20 ">
  <app-route-ride-comparison-table/>
</div>
