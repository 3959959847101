import { Component } from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {RideRouteCardComponent} from "./ride-route-card/ride-route-card.component";
import {RideRouteListComponent} from "./ride-route-list/ride-route-list.component";
import {MatButton} from "@angular/material/button";
import {routes} from "../../../app.routes";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-ride-routes',
  standalone: true,
  imports: [
    RideRouteCardComponent, RideRouteListComponent, MatButton, MatCard, MatCardContent, RouterLink
  ],
  templateUrl: './ride-routes.component.html',
  styleUrl: './ride-routes.component.scss'
})
export class RideRoutesComponent {
  protected readonly routes = routes;
}
