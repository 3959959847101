<!-- Horizontal Wizard -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Horizontal Wizard
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-horizontal></app-horizontal>
    </mat-card-content>
</mat-card>

<!-- Vertical Wizard -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Vertical Wizard
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-vertical></app-vertical>
    </mat-card-content>
</mat-card>