<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Zoomable Line Chart
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <apx-chart
            [series]="series!"
            [chart]="chart!"
            [dataLabels]="dataLabels!"
            [markers]="markers!"
            [title]="title!"
            [fill]="fill!"
            [yaxis]="yaxis!"
            [xaxis]="xaxis!"
            [grid]="grid!"
            [tooltip]="tooltip!"
            [autoUpdateSeries]="true!"
        ></apx-chart>
    </mat-card-content>
</mat-card>