<mat-card
    class="trinta-card error-card mb-25 bg-white border-none d-block text-center"
>
    <mat-card-content>
        <img src="assets/images/error.png" alt="error-image">
        <h3>
            <span class="text-primary">OOPS!</span> Page Not Found
        </h3>
        <p class="text-black fs-15">
            But no worries! Our team is looking everywhere while you wait safely.
        </p>
        <a routerLink="/" mat-button>
            Back To Home
            <i class="ri-send-plane-fill"></i>
        </a>
    </mat-card-content>
</mat-card>