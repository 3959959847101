<mat-card
    class="trinta-card about-project-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                About Project
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <div class="action">
                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                    <i-feather name="edit-3"></i-feather>
                </button>
                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                    <i-feather name="trash-2"></i-feather>
                </button>
            </div>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <h4 class="text-primary">
            Cloud Solutions Architect
        </h4>
        <p class="fs-15 text-black">
            Adipisicing eu magna velit est exercitation et consequat Lorem laboris nulla. Laborum exercitation minim id ea ea. Minim cillum magna excepteur laboris duis labore pariatur Lorem aute cupidatat velit sunt non. Est laborum anim aliqua in elit consequat elit elit cupidatat. Nulla excepteur laborum voluptate nisi eiusmod nostrud sit. Aute aliquip sit non consectetur laborum velit in exercitation laboris officia adipisicing deserunt. Sint laboris aute minim aliqua aute culpa laboris ad amet dolor ea Lorem sit.
        </p>
        <p class="fs-15 text-black">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
        </p>
        <ul class="mb-0 p-0 list-unstyled">
            <li class="d-inline-block">
                <span class="d-block text-body fw-medium">
                    Client
                </span>
                <h6 class="mb-0 fw-medium">
                    Thomas Adison
                </h6>
            </li>
            <li class="d-inline-block">
                <span class="d-block text-body fw-medium">
                    Start Date
                </span>
                <h6 class="mb-0 fw-medium">
                    13 Mar 2024
                </h6>
            </li>
            <li class="d-inline-block">
                <span class="d-block text-body fw-medium">
                    Deadline
                </span>
                <h6 class="mb-0 fw-medium">
                    14 Jun 2024
                </h6>
            </li>
            <li class="d-inline-block">
                <span class="d-block text-body fw-medium">
                    Budget
                </span>
                <h6 class="mb-0 fw-medium">
                    $10.5k
                </h6>
            </li>
        </ul>
    </mat-card-content>
</mat-card>