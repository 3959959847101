<mat-card class="trinta-card recent-orders-card  bg-white border-none d-block">
    <mat-card-header>

        <div class="row">
            <div _ngcontent-ng-c972605003="" class="col-xl-4 col-lg-4 col-sm-12" style="display: flex; align-items: center;">
                <span _ngcontent-ng-c972605003="" style="color: #000; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal; align-self: center; text-align: left;">
                    Invites
                </span>
            </div>
            
        
            <div class="col-xl-8 col-lg-8 col-sm-12">
                <div class="details-container"
                    style="display: flex; justify-content: flex-end; align-items: center; padding: 2px; border-radius: 7px;">
                    <div class="detail" (click)="openDialog(InviteMemberComponent)"
                        style="color: #DA1884; font-size: 16px; font-family: Libre Franklin; font-weight: 600; cursor: pointer; display: flex; align-items: center;">
                        <mat-icon style="color: #fff; background-color: #DA1884; border-radius: 4px; margin-right: 4px;">add</mat-icon>
                        Invite
                    </div>
                    <div class="detail" style="margin-left: 16px;">
                        <div class="details-container"  style="display: flex; justify-content: center; align-items: center; padding: 2px; border-radius: 7px;">
                            <div class="detail" (click)="openRouteListPage()"  style="display: flex; align-items: center; color: #000000; font-size: 16px; font-family: Libre Franklin; font-weight: normal; cursor: pointer;">
                        
                                See More
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right">
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                  </svg>
                                  
                                
                                
              
                              
                              
                            </div>
                          </div>
                        <!-- <svg (click)="openRouteListPage()" style="cursor: pointer;" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 7H19" stroke="#1F2A44" stroke-linecap="round" />
                            <path d="M5 12H19" stroke="#1F2A44" stroke-linecap="round" />
                            <path d="M5 17H19" stroke="#1F2A44" stroke-linecap="round" />
                        </svg> -->
                    </div>
                </div>
            </div>
        </div>
        

    </mat-card-header>
    <mat-card-content>
        <div class="recent-orders-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource.data">

                    <ng-container matColumnDef="la_RidingGroup_ID">
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <img src="../../../../assets/profile.png" alt="product-image" style="display: flex;
                                width: 40px;
                                height: 40px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;">

                            </div>
                        </td>
                    </ng-container>


                    <!-- Quantity Column -->
                    <ng-container matColumnDef="firstName">
                        <td mat-cell *matCellDef="let element">
                            {{element.firstName}} {{element.lastName}}<br>
                            <p style="font-size: 12px;">{{element.email}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastName">
                        <td mat-cell *matCellDef="let element">

                        </td>
                    </ng-container>


                    <ng-container matColumnDef="accepted">
                        <td mat-cell *matCellDef="let element">
                            <p class="approvenot"> {{ element.accepted ? 'Accepted' : 'Pending' }}</p>
                        </td>
                    </ng-container>


                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns" class="headersection"></tr> -->
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator> -->
        </div>
    </mat-card-content>
</mat-card>