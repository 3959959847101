<div class="row ml-0 mr-0">
    <div class="col-lg-3 col-sm-6 pl-0 pr-0">
        <mat-card
            class="trinta-card hd-stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-md-flex d-lg-block d-xxl-flex">
                    <div class="icon d-flex align-items-center rounded-circle transition justify-content-center">
                        <i class="ri-ticket-line"></i>
                    </div>
                    <div class="title">
                        <span class="sub-title d-block text-gray">
                            Solved Tickets
                        </span>
                        <h5 class="m-0">
                            258k
                        </h5>
                        <div class="info d-flex align-items-center">
                            <span class="trending d-block fw-medium position-relative">
                                4.09% <i class="ri-arrow-up-s-line"></i>
                            </span>
                            <span class="d-block text-body">
                                Leads this month
                            </span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 pl-0 pr-0">
        <mat-card
            class="trinta-card hd-stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-md-flex d-lg-block d-xxl-flex">
                    <div class="icon d-flex align-items-center rounded-circle transition justify-content-center">
                        <i class="ri-game-line"></i>
                    </div>
                    <div class="title">
                        <span class="sub-title d-block text-gray">
                            Open Tickets
                        </span>
                        <h5 class="m-0">
                            1.8k
                        </h5>
                        <div class="info d-flex align-items-center">
                            <span class="trending d-block fw-medium position-relative">
                                1.05% <i class="ri-arrow-up-s-line"></i>
                            </span>
                            <span class="d-block text-body">
                                Leads this month
                            </span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 pl-0 pr-0">
        <mat-card
            class="trinta-card hd-stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-md-flex d-lg-block d-xxl-flex">
                    <div class="icon d-flex align-items-center rounded-circle transition justify-content-center">
                        <i class="ri-arrow-left-right-line"></i>
                    </div>
                    <div class="title">
                        <span class="sub-title d-block text-gray">
                            Support On Hold
                        </span>
                        <h5 class="m-0">
                            3.24k
                        </h5>
                        <div class="info d-flex align-items-center">
                            <span class="trending down d-block fw-medium position-relative">
                                2.09% <i class="ri-arrow-down-s-line"></i>
                            </span>
                            <span class="d-block text-body">
                                Leads this month
                            </span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 pl-0 pr-0">
        <mat-card
            class="trinta-card hd-stat-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-md-flex d-lg-block d-xxl-flex">
                    <div class="icon d-flex align-items-center rounded-circle transition justify-content-center">
                        <i class="ri-list-check-3"></i>
                    </div>
                    <div class="title">
                        <span class="sub-title d-block text-gray">
                            Unassigned
                        </span>
                        <h5 class="m-0">
                            0.88k
                        </h5>
                        <div class="info d-flex align-items-center">
                            <span class="trending d-block fw-medium position-relative">
                                6.24% <i class="ri-arrow-up-s-line"></i>
                            </span>
                            <span class="d-block text-body">
                                Leads this month
                            </span>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>