<mat-card
    class="trinta-card add-product-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Add A Product
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Product Title
                    </label>
                    <mat-form-field>
                        <i-feather name="edit-3"></i-feather>
                        <mat-label>Enter product title</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Product Description
                    </label>
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor [editor]="editor" [ngModel]="html" name="html" [disabled]="false" [placeholder]="'Some demo text...'"></ngx-editor>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Product Price
                    </label>
                    <mat-form-field>
                        <i-feather name="dollar-sign"></i-feather>
                        <mat-label>Enter price</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Publish Schedule
                    </label>
                    <mat-form-field>
                        <i-feather name="calendar"></i-feather>
                        <mat-label>Enter publish date</mat-label>
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <label class="label d-block fw-medium mb-10">
                        Product In Stocks
                    </label>
                    <mat-form-field>
                        <i-feather name="database"></i-feather>
                        <mat-label>Number in stock</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <label class="label d-block fw-medium mb-10">
                        Offer / Discount
                    </label>
                    <mat-form-field>
                        <i-feather name="percent"></i-feather>
                        <mat-label>Enter offer price</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <label class="label d-block fw-medium mb-10">
                        Orders
                    </label>
                    <mat-form-field>
                        <i-feather name="shopping-bag"></i-feather>
                        <mat-label>Enter number</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Select Category
                    </label>
                    <mat-form-field>
                        <i-feather name="shopping-bag"></i-feather>
                        <mat-label>Select product category</mat-label>
                        <mat-select>
                            <mat-option value="volvo">Volvo</mat-option>
                            <mat-option value="saab">Saab</mat-option>
                            <mat-option value="mercedes">Mercedes</mat-option>
                            <mat-option value="audi">Audi</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Select Tags
                    </label>
                    <mat-form-field>
                        <i-feather name="tag"></i-feather>
                        <mat-label>Select product tags</mat-label>
                        <mat-select multiple>
                            <mat-option value="volvo">Volvo</mat-option>
                            <mat-option value="saab">Saab</mat-option>
                            <mat-option value="mercedes">Mercedes</mat-option>
                            <mat-option value="audi">Audi</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Manufacturer Brand
                    </label>
                    <mat-form-field>
                        <i-feather name="minimize"></i-feather>
                        <mat-label>Enter manufacturer brand</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <label class="label d-block fw-medium mb-10">
                        Product Images
                    </label>
                    <file-upload class="file-uploader"></file-upload>
                </div>
            </div>
            <div class="create-product-btn">
                <button mat-button type="button">
                    Create Product
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>