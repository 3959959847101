import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { title } from 'process';
import { UpcomingeventsdeleteconfirmComponent } from '../upcomingeventsdeleteconfirm/upcomingeventsdeleteconfirm.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-upcomingeventsview',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatRadioGroup,
    MatCardModule,
    MatCardModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule,
    MatRadioButton,
    MatInput,
    MatDialogModule,
    MatButtonModule,
    MatLabel,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
  ],

  providers: [provideNativeDateAdapter()],
  templateUrl: './upcomingeventsview.component.html',
  styleUrl: './upcomingeventsview.component.scss'
})
export class UpcomingeventsviewComponent {
  displayedColumns: string[] = ['profilePhoto', 'name'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  paces: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  purposes: any[] = [];
  levels: any[] = [];
  recurrences: any[] = [];
  inoutdoors: any[] = [];
  groups: any[] = [];
  rideTypes: any[] = [];
  selectedFile: File | null = null;
  base64String: string = '';
  displayDate: string | null = null;
  rideId: string | null = null;
  rideType: string = '';
  recurrence: string = '';
  pace: string = '';
  indooroutdoor: string = '';
  image: string = '';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpcomingeventsviewComponent>,
    private http: HttpClient,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    this.createRideForm = this.fb.group({
      title: [this.data.title],
      date: [this.data?.date || ''],
      time: [this.data?.time],
      location: [this.data?.location || ''],
      amount: [this.data?.amount || ''],
      description: [this.data?.description || ''],
      organizer: [this.data?.organizer || ''],
      groupSize: [this.data?.groupSize || ''],
      address: [this.data?.address || ''],
      city: [this.data?.city || ''],
      state: [this.data?.state || ''],
      endDate: [this.data?.endDate || ''],
      url: [this.data?.url || ''],
      image: [this.data?.image],

    });
  }






  extractNumberFromString(input: string): number | null {
    const match = input.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  async onSubmit() {

  }


  cancelUpcomingEvent(): void {
    const dialogRef = this.dialog.open(UpcomingeventsdeleteconfirmComponent, {
      restoreFocus: false,
      panelClass: 'custom-dialog',
      width: '500px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Call your delete function here
        this.deleteUser();
      }
    });
  }


  
  deleteUser() {

    const id = this.data?.upcomingEventId;
    const token = localStorage.getItem('angular17token');

    if (token) {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

      this.http.post(BASE_URL + ApiEndpoints.cancelEvent + id, {}, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            // alert("Event canceled successfully");
            window.location.reload();
          } else {
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            console.log("Unauthorized:", error.status);
            alert("Unauthorized");
          } else if (error.status === 500) {
            alert("Internal server error");
          } else if (error.status === 200) {
            alert("Event cancelled successfully");
            window.location.reload();
          } else {
            alert("Failed. Please try again later.");
          }
        }
      );
    } else {
      console.error("No token found in localStorage");
      alert("Please login again.");
    }
  }




  formatTimeTo12Hour(time: string): string {
    if (!time) return '';

    const [hourString, minuteString] = time.split(':');
    let hour = parseInt(hourString, 10);
    const minute = parseInt(minuteString, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';

    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'

    const minuteFormatted = minute < 10 ? '0' + minute : minute;
    return `${hour}:${minuteFormatted} ${ampm}`;
  }
  
  // formatDate(dateString: string): string {
  //   const dateParts = dateString.split('-'); // Split the date string by '-'
  //   const year = dateParts[0];
  //   const month = dateParts[1];
  //   const day = dateParts[2];
  //   return `${day}/${year}`; // Return in dd/mm/yyyy format
  // }

  formatDate(dateString: string): string {
    const dateParts = dateString.split('-'); // Split the date string by '-'
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is 0-based in JavaScript
    const day = parseInt(dateParts[2], 10);

    const date = new Date(year, month, day);

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    };

    // Format the date
    return date.toLocaleDateString('en-US', options);
}



  submitForm() {

  }

  ngOnInit(): void {
    this.fetchgroupdetails();
  }

  // Password Hide
  hide = true;
  createRideForm: FormGroup;

  // Form
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.createRideForm.value);
  }


  fetchgroupdetails() {
    const upcomingEventId = this.data.upcomingEventId;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get<any>(`${BASE_URL}${ApiEndpoints.getEventMembers}${upcomingEventId}`, { headers: headers }).subscribe(
      (data) => {
        this.dataSource.data = data.entity; // Assign fetched data to the data source
        this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
      },
      (error) => {
        console.error('Error fetching rides data:', error);
      }
    );
  }
}

