import { Component, ViewChild } from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader, MatCardModule, MatCardTitle} from '@angular/material/card';
import { RouterLink } from '@angular/router';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexGrid,
  ApexTooltip,
  NgApexchartsModule
} from "ng-apexcharts";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  colors: any;
  legend: ApexLegend;
};

@Component({
  selector: 'app-avg-speed-chart',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    NgApexchartsModule,
    MatButton,
    MatIcon
  ],
  templateUrl: './avg-speed-chart.component.html',
  styleUrl: './avg-speed-chart.component.scss'
})
export class AvgSpeedChartComponent {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Speed",
          data: [21, 26, 24, 17, 15, 9, 18, 29, 30,31,25,18]
        }
      ],
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      colors: ['#1FBBCA'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 4,
        show: true,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [

          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
        ],
        axisBorder: {
          show: false,
          color: '#a9a9c8'
        },
        axisTicks: {
          show: true,
          color: '#a9a9c8',
          borderType: 'dotted'
        },
        labels: {
          show: true,
          style: {
            colors: "#262626",
            fontSize: "13px"
          }
        }
      },
      yaxis: {
        title: {
          text: "(mi/h)",
          style: {
            color: "#5b5b98",
            fontSize: "14px",
            fontWeight: 500
          }
        },
        labels: {
          show: true,
          style: {
            colors: "#a9a9c8",
            fontSize: "13px"
          }
        },
        axisBorder: {
          show: false,
          color: '#edeff5'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: [ '#1F499F'],
          shadeIntensity: 1,
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        },
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + "mi/h";
          }
        }
      },
      legend: {
        show: true,
        offsetY: 10,
        fontSize: '13px',
        position: "bottom",
        horizontalAlign: "center",
        labels: {
          colors: '#77838f',
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        }
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        borderColor: "#edeff5"
      }
    };
  }

}
