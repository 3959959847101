<div class="finished-courses-table">
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource">

            <!-- Course Name Column -->
            <ng-container matColumnDef="course">
                <th mat-header-cell *matHeaderCellDef class="pl-0">
                    Course Name
                </th>
                <td mat-cell *matCellDef="let element" class="pl-0">
                    <div class="course-info d-flex align-items-center">
                        <a routerLink="/" class="image d-block">
                            <img [src]="element.course.img" alt="course-image">
                        </a>
                        <div class="info">
                            <a routerLink="/" class="d-block fw-semibold">
                                {{element.course.title}}
                            </a>
                            <span class="d-block text-body">
                                Hours - <span class="text-black">{{element.course.hours}}</span>
                            </span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Result Column -->
            <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef>
                    Result
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="d-block text-black">
                        {{element.result}}%
                    </span>
                    <mat-progress-bar mode="determinate" value="{{element.result}}"></mat-progress-bar>
                </td>
            </ng-container>

            <!-- Instructor Column -->
            <ng-container matColumnDef="instructor">
                <th mat-header-cell *matHeaderCellDef>
                    Instructor
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.instructor}}
                </td>
            </ng-container>

            <!-- Students Column -->
            <ng-container matColumnDef="students">
                <th mat-header-cell *matHeaderCellDef>
                    Students
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.students}}
                </td>
            </ng-container>

            <!-- Lessons Column -->
            <ng-container matColumnDef="lessons">
                <th mat-header-cell *matHeaderCellDef>
                    Lessons
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.lessons}}
                </td>
            </ng-container>

            <!-- Start Date Column -->
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>
                    Start Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.startDate}}
                </td>
            </ng-container>

            <!-- End Date Column -->
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>
                    End Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.endDate}}
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-end pr-0"></th>
                <td mat-cell *matCellDef="let element" class="text-end pr-0">
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i class="{{element.action.edit}}"></i>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i class="{{element.action.delete}}"></i>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
</div>