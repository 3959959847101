<!-- Connected Accounts -->
<mat-card
    class="trinta-card connected-accounts-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Connected Accounts
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ul class="p-0 m-0 list-unstyled">
            <li class="d-md-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <img src="assets/images/icons/google.svg" alt="google">
                    <div>
                        <h6>
                            Google
                        </h6>
                        <span class="d-block text-black">
                            Calendar and Contacts
                        </span>
                    </div>
                </div>
                <span class="d-block fs-15 text-primary heading-font-family fw-semibold">
                    Connected
                </span>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <img src="assets/images/icons/slack.svg" alt="slack">
                    <div>
                        <h6>
                            Slack
                        </h6>
                        <span class="d-block text-black">
                            Communications
                        </span>
                    </div>
                </div>
                <span class="d-block fs-15 text-primary heading-font-family fw-semibold">
                    Connected
                </span>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <img src="assets/images/icons/github.svg" alt="github">
                    <div>
                        <h6>
                            GitHub
                        </h6>
                        <span class="d-block text-black">
                            Manage your Git repositories
                        </span>
                    </div>
                </div>
                <span class="d-block fs-15 text-primary heading-font-family fw-semibold">
                    Connected
                </span>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <img src="assets/images/icons/mailchimp.svg" alt="mailchimp">
                    <div>
                        <h6>
                            Mailchimp
                        </h6>
                        <span class="d-block text-black">
                            Email marketing service
                        </span>
                    </div>
                </div>
                <span class="d-block fs-15 text-body heading-font-family fw-semibold">
                    Not Connected
                </span>
            </li>
            <li class="d-md-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <img src="assets/images/icons/asana.svg" alt="asana">
                    <div>
                        <h6>
                            Asana
                        </h6>
                        <span class="d-block text-black">
                            Communication
                        </span>
                    </div>
                </div>
                <span class="d-block fs-15 text-primary heading-font-family fw-semibold">
                    Connected
                </span>
            </li>
        </ul>
    </mat-card-content>
</mat-card>

<!-- Add New Connection -->
<mat-card
    class="trinta-card add-new-connection-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Add New Connection
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <div class="row">
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Facebook Link
                    </label>
                    <mat-form-field>
                        <i-feather name="facebook"></i-feather>
                        <mat-label>facebook&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Twitter Link
                    </label>
                    <mat-form-field>
                        <i-feather name="twitter"></i-feather>
                        <mat-label>twitter&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        YouTube Link
                    </label>
                    <mat-form-field>
                        <i-feather name="youtube"></i-feather>
                        <mat-label>youtube&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        LinkedIn Link
                    </label>
                    <mat-form-field>
                        <i-feather name="linkedin"></i-feather>
                        <mat-label>linkedin&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Dribbble Link
                    </label>
                    <mat-form-field>
                        <i-feather name="dribbble"></i-feather>
                        <mat-label>dribbble&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">
                    <label class="label d-block fw-medium mb-10">
                        Twitch Link
                    </label>
                    <mat-form-field>
                        <i-feather name="twitch"></i-feather>
                        <mat-label>behance&#64;example.com</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
            </div>
            <div class="save-info-btn">
                <button mat-button type="button">
                    Save Information
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>