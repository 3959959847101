<mat-card
    class="trinta-card recent-orders-card mb-25 bg-white border-none d-block"
><mat-card-header>
    <div class="row groupsfiltersection">
        <div class="col-lg-4">
            <mat-form-field>
                <mat-label>Indoor Ride</mat-label>
                <mat-select>
                    @for (indoor of indoors; track indoor) {
                        <mat-option [value]="indoor.value">{{indoor.viewValue}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-lg-4">
            <mat-form-field>
                <mat-label>Last week</mat-label>
                <mat-select>
                    @for (indoor of indoors; track indoor) {
                        <mat-option [value]="indoor.value">{{indoor.viewValue}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-4">
            <mat-form-field>
                <mat-label>Distance</mat-label>
                <mat-select>
                    @for (distance of distances; track distance) {
                        <mat-option [value]="distance.value">{{distance.viewValue}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- <mat-card-subtitle>
        <mat-form-field appearance="fill" class="card-header-dropdown">
            <mat-select [(value)]="selectedFilter">
                <mat-option value="day">This Day</mat-option>
                <mat-option value="week">This Week</mat-option>
                <mat-option value="month">This Month</mat-option>
                <mat-option value="year">This Year</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-subtitle> -->
</mat-card-header>
    <mat-card-content>
        <div class="recent-orders-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Tracking No Column -->
                    <ng-container matColumnDef="trackingID">
                        <th mat-header-cell *matHeaderCellDef class="text-primary pl-0">
                            Rank
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary fw-medium pl-0">
                            {{element.trackingID}}
                        </td>
                    </ng-container>

                    <!-- Product Column -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef>
                            
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a routerLink="/" class="image d-block">
                                    <img [src]="element.product.img" alt="product-image" style="width: 36px; height: 36px;">
                                </a>
                                
                            </div>
                        </td>
                    </ng-container>

                    <!-- Customer Column -->
                    <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.customer}}
                        </td>
                    </ng-container>

                    <!-- Price Column -->
                 

                    <!-- Quantity Column -->
                 

                    <!-- Vendor Column -->
                

                    <!-- Status Column -->
                    <ng-container matColumnDef="distance">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Distance
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            {{element.distance}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>