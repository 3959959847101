<!-- Stats -->
<app-stats />

<div class="row">
    <div class="col-xxxl-6 col-md-12">

        <!-- Issues Summary -->
        <app-issues-summary />

    </div>
    <div class="col-xxxl-6 col-md-12">

        <!-- Customer Satisfaction -->
        <app-customer-satisfaction />
        
    </div>
    <div class="col-xxxl-12 col-md-12">

        <!-- Tickets Status -->
        <app-tickets-status />

    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">

        <!-- Avg. Response Time -->
        <app-average-response-time />
        
    </div>
    <div class="col-xxxl-4 col-lg-6 col-md-12">
        
        <!-- Support Status -->
        <app-support-status />

    </div>
    <div class="col-xxxl-4 col-md-12">

        <!-- Live Calls -->
        <app-live-calls />
        
    </div>
</div>

<!-- Agents List -->
<app-agents-list />