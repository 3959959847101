<div class="row">
    <div class="col-md-6 col-xxl-4">
        <mat-card
            class="trinta-card team-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="m-0">
                        Laravel Team
                    </h6>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <p class="fs-15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div class="users d-flex align-items-center">
                    <div class="user position-relative">
                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user24.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user16.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="number rounded-circle d-flex align-items-center justify-content-center">
                        +4
                    </div>
                </div>
                <ul class="list p-0 m-0 list-unstyled">
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Completed
                        </span>
                        <span class="d-block text-body fw-semibold">
                            1246
                        </span>
                    </li>
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Running
                        </span>
                        <span class="d-block text-body fw-semibold">
                            98
                        </span>
                    </li>
                </ul>
                <div class="info d-xxxl-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user9.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Martine Jeck
                            </h6>
                            <span class="d-block text-body">
                                Team Lead
                            </span>
                        </div>
                    </div>
                    <a routerLink="/project-management-page/teams" class="default-btn" mat-button>
                        View Details
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card
            class="trinta-card team-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="m-0">
                        Frontend Developer Team
                    </h6>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <p class="fs-15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div class="users d-flex align-items-center">
                    <div class="user position-relative">
                        <img src="assets/images/users/user16.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user24.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="number rounded-circle d-flex align-items-center justify-content-center">
                        +2
                    </div>
                </div>
                <ul class="list p-0 m-0 list-unstyled">
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Completed
                        </span>
                        <span class="d-block text-body fw-semibold">
                            3214
                        </span>
                    </li>
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Running
                        </span>
                        <span class="d-block text-body fw-semibold">
                            23
                        </span>
                    </li>
                </ul>
                <div class="info d-xxxl-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                William Anderson
                            </h6>
                            <span class="d-block text-body">
                                Team Lead
                            </span>
                        </div>
                    </div>
                    <a routerLink="/project-management-page/teams" class="default-btn" mat-button>
                        View Details
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card
            class="trinta-card team-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="m-0">
                        Web Design Team
                    </h6>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <p class="fs-15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div class="users d-flex align-items-center">
                    <div class="user position-relative">
                        <img src="assets/images/users/user24.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user16.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="number rounded-circle d-flex align-items-center justify-content-center">
                        +3
                    </div>
                </div>
                <ul class="list p-0 m-0 list-unstyled">
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Completed
                        </span>
                        <span class="d-block text-body fw-semibold">
                            1231
                        </span>
                    </li>
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Running
                        </span>
                        <span class="d-block text-body fw-semibold">
                            22
                        </span>
                    </li>
                </ul>
                <div class="info d-xxxl-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user16.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Marcus Davis
                            </h6>
                            <span class="d-block text-body">
                                Team Lead
                            </span>
                        </div>
                    </div>
                    <a routerLink="/project-management-page/teams" class="default-btn" mat-button>
                        View Details
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card
            class="trinta-card team-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="m-0">
                        ReactJS Team
                    </h6>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <p class="fs-15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div class="users d-flex align-items-center">
                    <div class="user position-relative">
                        <img src="assets/images/users/user16.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user5.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user10.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user24.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="number rounded-circle d-flex align-items-center justify-content-center">
                        +5
                    </div>
                </div>
                <ul class="list p-0 m-0 list-unstyled">
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Completed
                        </span>
                        <span class="d-block text-body fw-semibold">
                            123
                        </span>
                    </li>
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Running
                        </span>
                        <span class="d-block text-body fw-semibold">
                            2
                        </span>
                    </li>
                </ul>
                <div class="info d-xxxl-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user24.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Charlotte Lee
                            </h6>
                            <span class="d-block text-body">
                                Team Lead
                            </span>
                        </div>
                    </div>
                    <a routerLink="/project-management-page/teams" class="default-btn" mat-button>
                        View Details
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card
            class="trinta-card team-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="m-0">
                        Angular Team
                    </h6>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <p class="fs-15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div class="users d-flex align-items-center">
                    <div class="user position-relative">
                        <img src="assets/images/users/user1.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user2.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user3.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user4.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="number rounded-circle d-flex align-items-center justify-content-center">
                        +1
                    </div>
                </div>
                <ul class="list p-0 m-0 list-unstyled">
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Completed
                        </span>
                        <span class="d-block text-body fw-semibold">
                            1324
                        </span>
                    </li>
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Running
                        </span>
                        <span class="d-block text-body fw-semibold">
                            54
                        </span>
                    </li>
                </ul>
                <div class="info d-xxxl-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user6.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Thomas Johnson
                            </h6>
                            <span class="d-block text-body">
                                Team Lead
                            </span>
                        </div>
                    </div>
                    <a routerLink="/project-management-page/teams" class="default-btn" mat-button>
                        View Details
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4">
        <mat-card
            class="trinta-card team-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="m-0">
                        Vue.js Team
                    </h6>
                    <div class="action">
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="edit-3"></i-feather>
                        </button>
                        <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                            <i-feather name="trash-2"></i-feather>
                        </button>
                    </div>
                </div>
                <p class="fs-15">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                <div class="users d-flex align-items-center">
                    <div class="user position-relative">
                        <img src="assets/images/users/user12.jpg" class="rounded-circle" alt="user-image">
                        <div class="dot bg-primary rounded-circle"></div>
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user11.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user13.jpg" class="rounded-circle" alt="user-image">
                    </div>
                    <div class="user position-relative">
                        <img src="assets/images/users/user17.jpg" class="rounded-circle" alt="user-image">
                    </div>
                </div>
                <ul class="list p-0 m-0 list-unstyled">
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Completed
                        </span>
                        <span class="d-block text-body fw-semibold">
                            1253
                        </span>
                    </li>
                    <li class="d-inline-block">
                        <span class="d-block heading-font-family fw-medium">
                            Task Running
                        </span>
                        <span class="d-block text-body fw-semibold">
                            65
                        </span>
                    </li>
                </ul>
                <div class="info d-xxxl-flex align-items-center justify-content-between">
                    <div class="user d-flex align-items-center">
                        <img src="assets/images/users/user3.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h6>
                                Emily Johnson
                            </h6>
                            <span class="d-block text-body">
                                Team Lead
                            </span>
                        </div>
                    </div>
                    <a routerLink="/project-management-page/teams" class="default-btn" mat-button>
                        View Details
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>