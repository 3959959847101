<div class="row">
    <div class="col-lg-8">
        <mat-card
            class="trinta-card shopping-cart-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Shopping Cart
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="shopping-cart-table">
                    <div class="table-responsive">
                        <table mat-table [dataSource]="dataSource">

                            <!-- Product Column -->
                            <ng-container matColumnDef="product">
                                <th mat-header-cell *matHeaderCellDef class="pl-0">
                                    Product
                                </th>
                                <td mat-cell *matCellDef="let element" class="pl-0">
                                    <div class="product-info d-flex align-items-center">
                                        <a routerLink="/" class="image d-block">
                                            <img [src]="element.product.img" alt="product-image">
                                        </a>
                                        <div class="info">
                                            <a routerLink="/" class="d-block fw-semibold">
                                                {{element.product.title}}
                                            </a>
                                            <span class="d-block text-body">
                                                {{element.product.date}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Price Column -->
                            <ng-container matColumnDef="price">
                                <th mat-header-cell *matHeaderCellDef>
                                    Price
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.price}}
                                </td>
                            </ng-container>

                            <!-- Size Column -->
                            <ng-container matColumnDef="size">
                                <th mat-header-cell *matHeaderCellDef>
                                    Size
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.size}}
                                </td>
                            </ng-container>

                            <!-- Quantity Column -->
                            <ng-container matColumnDef="quantity">
                                <th mat-header-cell *matHeaderCellDef>
                                    Quantity
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.quantity}}
                                </td>
                            </ng-container>

                            <!-- Total Column -->
                            <ng-container matColumnDef="total">
                                <th mat-header-cell *matHeaderCellDef>
                                    Total
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.total}}
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef class="text-end pr-0"></th>
                                <td mat-cell *matCellDef="let element" class="text-end pr-0">
                                    <button mat-button>
                                        <i-feather name="{{element.action}}"></i-feather>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>
                    </div>
                </div>
                <form class="add-note">
                    <label class="d-block fw-medium">
                        Add A Note:
                    </label>
                    <textarea class="d-block w-100" cols="30" rows="4" placeholder="Write some note..."></textarea>
                    <div class="checkout-btn">
                        <button mat-button type="button">
                            Checkout
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4">
        <mat-card
            class="trinta-card order-summary-card mb-25 bg-white border-none d-block"
        >
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Order Summary
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul class="p-0 m-0 list-unstyled">
                    <li class="d-flex justify-content-between text-dark">
                        <span class="d-block fw-medium">
                            Description
                        </span>
                        <span class="d-block fw-medium">
                            Price
                        </span>
                    </li>
                    <li class="d-flex justify-content-between text-black">
                        <span class="d-block">
                            Grand Total :
                        </span>
                        <span class="d-block">
                            $1641
                        </span>
                    </li>
                    <li class="d-flex justify-content-between text-black">
                        <span class="d-block">
                            Shipping Charge :
                        </span>
                        <span class="d-block">
                            $120
                        </span>
                    </li>
                    <li class="d-flex justify-content-between text-black">
                        <span class="d-block">
                            Discount :
                        </span>
                        <span class="d-block">
                            $40
                        </span>
                    </li>
                    <li class="d-flex justify-content-between text-black">
                        <span class="d-block">
                            Estimated Tax :
                        </span>
                        <span class="d-block">
                            $250
                        </span>
                    </li>
                    <li class="d-flex justify-content-between text-dark">
                        <span class="d-block fw-medium">
                            Total :
                        </span>
                        <span class="d-block fw-medium">
                            $2405.00
                        </span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
        <mat-card
            class="trinta-card shopping-cart-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="coupon-info">
                    Use coupon code <strong class="fw-semibold">Trinta-D5</strong> and get 20% discount !
                </div>
                <form class="coupon-code position-relative">
                    <input type="text" class="d-block w-100" placeholder="Coupon Code..">
                    <button mat-button type="button">
                        Apply
                    </button>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>