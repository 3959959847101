<div class="title text-center">
    <button mat-mini-fab color="primary" (click)="onNoClick()" class="close" style="cursor: pointer; float: right; margin-right: 16px;     margin-right: 4px;
    margin-top: 4px;" >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="viewDetailsData">
        
        <!-- <img [src]="photoimg ? 'data:image/png;base64,' + photoimg : '../../../../assets/pimg.png'"
         style="    width: 100%;
        height: 100%;
        height: 200px;
        object-fit: contain;
        margin: auto;
        display: flex; margin-bottom: 16px;"> -->
        <img [src]="photoimg ? 'data:image/png;base64,' + photoimg : '../../../../assets/pimg.png'"
        class="profile-img" style="    width: 400px;
        height: 100%;
        height: 100%;
        margin: auto; margin-bottom: 16px;
        display: flex;">

        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-3">
                <label class="labeltime">Time: <span class="datavalue">{{
                        formatTimeTo12Hour(createRideForm.get('time')?.value) }}</span></label>
            </div>

            <div class="col-md-6">
                <h2 style="text-align: center;" class="titleheadersec">
                    {{ createRideForm.get('inDoor')?.value === true ? 'Indoor' : 'Outdoor' }}
                </h2>
            </div>
            

            <div class="col-md-3 datavaluedesc">
                <label class="label"><span class="datavaluemiles">{{ createRideForm.get('distance')?.value }} miles</span></label>
            </div>
        </div>
        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Starting Location</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('startLocation')?.value }}</span>
            </div>
        </div>

        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Ride Leader</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('rideLeader')?.value }}</span>
            </div>
        </div>



        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Ride Type</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('preferredBikeTypes')?.value }}</span>
            </div>
        </div>



        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Pace</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('pace')?.value }}</span>
            </div>
        </div>


        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Purpose</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('purpose')?.value }}</span>
            </div>
        </div>



        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="col-md-6">
                <label class="label">Level</label>
            </div>

            <div class="col-md-6 datavaluedesc">
                <span>{{ createRideForm.get('level')?.value }}</span>
            </div>
        </div>


        <div class="row col-xxxl-12 col-lg-12 col-md-12">

            <div class="row col-md-12">
                <label class="label">Description</label>
            </div>

            <div class="row col-md-12">
                <span>{{ createRideForm.get('description')?.value }}</span>
            </div>
        </div>


    </div>



    <!-- Map view -->

    <!-- <div class="mapview">
        <img src="assets/map.png" class="profile-img" style="width: 100%; height: 200px;">
    </div> -->


  

    <mat-tab-group class="ridestabs">
        <mat-tab label="Registrants">
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource.data" class="registrants-table">
              <ng-container matColumnDef="profilePhoto">
                <th mat-header-cell *matHeaderCellDef>Profile</th>
                <td mat-cell *matCellDef="let element">
                  <div class="product-info d-flex align-items-center">
                    <a class="image d-block">
                      <ng-container *ngIf="element.profilePhoto && element.profilePhoto.trim() !== ''; else defaultImage">
                        <img [src]="'data:image/png;base64,' + element.profilePhoto" class="profile-img" style="height: 60px; border-radius: 50%;">
                      </ng-container>
                      <ng-template #defaultImage>
                        <img src="../../../../assets/profile.png" alt="product-image" class="profile-img" style="height: 60px; border-radius: 50%;">
                      </ng-template>
                    </a>
                    <div class="info">
                      <a class="d-block fw-semibold">{{element.name}}</a>
                    </div>
                  </div>
                </td>
              </ng-container>
      
              <ng-container matColumnDef="firstname">
                <th mat-header-cell *matHeaderCellDef>First Name</th>
                <td mat-cell *matCellDef="let element">{{element.firstname}}</td>
              </ng-container>
      
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                <td mat-cell *matCellDef="let element">{{element.phone}}</td>
              </ng-container>
      
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div class="paginator-container">
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
          </div>
        </mat-tab>
        
        <mat-tab label="Unregistered">
          <p>Content for Tab 2.</p>
        </mat-tab>
        
        <mat-tab label="Waitlist">
          <p>Content for Tab 3.</p>
        </mat-tab>
      </mat-tab-group>
      
      


  
</div>