<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Videos
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Videos
        </li>
    </ol>
</div>

<!-- Videos -->
<div class="row">
    <div class="col-lg-6 col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        YouTube Video (16x9)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/mVjYG9TSN88" title="Welcome to Angular’s renaissance — angular.dev" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Vimeo Video (16x9)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="ratio ratio-16x9">
                    <iframe src="https://player.vimeo.com/video/45830194?h=2c5541d8bc&color=ffffff&title=0&byline=0&portrait=0&badge=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        YouTube Video (21x9)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="ratio ratio-21x9">
                    <iframe src="https://www.youtube.com/embed/mVjYG9TSN88" title="Welcome to Angular’s renaissance — angular.dev" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Vimeo Video (21x9)
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="ratio ratio-21x9">
                    <iframe src="https://player.vimeo.com/video/45830194?h=2c5541d8bc&color=ffffff&title=0&byline=0&portrait=0&badge=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>