<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Slider
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Slider
        </li>
    </ol>
</div>

<!-- Slider -->
<div class="row">
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Basic Slider
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-slider>
                    <input matSliderThumb>
                </mat-slider>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Range Slider
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-slider min="200" max="500">
                    <input value="300" matSliderStartThumb>
                    <input value="400" matSliderEndThumb>
                </mat-slider>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Slider with Custom Thumb Label Formatting
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-slider min="0" max="100000" step="1000" showTickMarks discrete [displayWith]="formatLabel">
                    <input matSliderThumb>
                </mat-slider>
            </mat-card-content>
        </mat-card>
    </div>
</div>