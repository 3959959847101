<label class="example-listbox-label fw-medium" id="example-shirt-size-label">
    Shirt Size
</label>
<ul cdkListbox
    cdkListboxOrientation="horizontal"
    aria-labelledby="example-shirt-size-label"
    class="example-listbox"
>
    @for (size of sizes; track size) {
        <li [cdkOption]="size" class="example-option text-black">{{size}}</li>
    }
</ul>