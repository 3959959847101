<mat-card
    class="trinta-card invoices-list-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Invoices List
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="invoices-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Tracking No Column -->
                    <ng-container matColumnDef="trackingNo">
                        <th mat-header-cell *matHeaderCellDef class="pl-0 text-primary">
                            Tracking No
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0 text-primary fw-medium">
                            {{element.trackingNo}}
                        </td>
                    </ng-container>

                    <!-- Client Column -->
                    <ng-container matColumnDef="client">
                        <th mat-header-cell *matHeaderCellDef>
                            Client
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="client-info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.client.img" alt="client-image">
                                </div>
                                <div class="info">
                                    <h6 class="mt-0">
                                        {{element.client.name}}
                                    </h6>
                                    <span class="d-block text-body">
                                        {{element.client.cardName}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Issues Date Column -->
                    <ng-container matColumnDef="issuesDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Issues Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.issuesDate}}
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>
                            Amount
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary">
                            {{element.amount}}
                        </td>
                    </ng-container>

                    <!-- Projects Column -->
                    <ng-container matColumnDef="projects">
                        <th mat-header-cell *matHeaderCellDef>
                            Projects
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.projects}}
                        </td>
                    </ng-container>

                    <!-- Balance Column -->
                    <ng-container matColumnDef="balance">
                        <th mat-header-cell *matHeaderCellDef>
                            Balance
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="trinta-badge fw-medium d-inline-block" *ngIf="element.balance.paid">
                                {{element.balance.paid}}
                            </span>
                            <span class="trinta-badge fw-medium due d-inline-block" *ngIf="element.balance.due">
                                {{element.balance.due}}
                            </span>
                            <span class="trinta-badge fw-medium waiting d-inline-block" *ngIf="element.balance.waiting">
                                {{element.balance.waiting}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0">
                            Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.download}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.edit}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.delete}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[9, 18, 27]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>