import { MatCard, MatCardContent } from "@angular/material/card";
import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabGroup } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { MatSuffix } from "@angular/material/form-field";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../../app/constants';
import { ApiEndpoints } from '../../../../../app/api-endpoints';
import { Router } from '@angular/router';



@Component({
  selector: 'app-group-overview-stats',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent
  ],
  templateUrl: './group-overview-stats.component.html',
  styleUrl: './group-overview-stats.component.scss'
})
export class GroupOverviewStatsComponent
  implements OnInit {
  totalGroupMembers: string = '';

  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  selectedTabIndex: number = 0;

  constructor(public dialog: MatDialog, private http: HttpClient,  private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadTotalgroups();
  }




  loadTotalgroups() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    const groupid =  localStorage.getItem('selectedGroupId');
    this.http.get(BASE_URL + ApiEndpoints.usersCountPerGroup+"?group_id="+groupid, { headers: headers }).subscribe(
      (data: any) => {
        console.log("totalGroupMembers", data.totalUsersInTheGroup)
        this.totalGroupMembers = data.totalUsersInTheGroup;
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }

  navigatetomemberspage(){
    this.router.navigate(['/tools']);

  }
}