  <div class="memberconfirm">
    <div class="modalheader" >
      <div  class="titletxt">Cancel</div>
        <img (click)="onNoClick()" class="closeicon" src="../../../../assets/clx.png">
    </div>
    

    <div class="modalbodytext">Closing this form will delete your new ride. Are you sure you want to exit?</div>
    
    <div class="actionbtns">

      <div class="btnssection">
        <div class="confirmbtnssubmit" (click)="onConfirm()" > 
    
          <h2>OK</h2>
      
        </div>
      </div>
    </div>

  </div>
  
  
  