<!-- Gallery Style - 1 -->
<mat-card class="trinta-card gallery-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Gallery Style - 1
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row gallery-list">
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery1.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery2.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery3.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery4.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery5.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery6.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery7.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery8.jpg" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Gallery Style - 2 -->
<mat-card class="trinta-card gallery-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Gallery Style - 2
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row gallery-list">
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery1.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery2.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery3.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery4.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery5.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery6.jpg" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Gallery Style - 3 -->
<mat-card class="trinta-card gallery-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Gallery Style - 3
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row gallery-list">
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery1.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery2.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery3.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-6 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery4.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-6 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery5.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery6.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery7.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery8.jpg" class="border-radius" alt="gallery-image">
            </div>
            <div class="col-lg-3 col-sm-4 col-md-4 mb-25">
                <img src="assets/images/gallery/gallery9.jpg" class="border-radius" alt="gallery-image">
            </div>
        </div>
    </mat-card-content>
</mat-card>