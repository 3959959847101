import { Component,OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RiderescheduleComponent } from '../../../../app/forms/create-event-types/ridereschedule/ridereschedule.component';
import { RidereditrouteComponent } from '../../../../app/forms/create-event-types/ridereditroute/ridereditroute.component';
import { ViewrouteComponent } from '../viewroute/viewroute.component';
import { UpcomingridesviewdetailsComponent } from '../upcomingridesviewdetails/upcomingridesviewdetails.component';
import { MatInput } from "@angular/material/input";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UpcomingeventseditdraftComponent } from '../../../../app/dashboard/ecommerce/upcomingeventseditdraft/upcomingeventseditdraft.component';
import { UpcomingeventsviewComponent } from '../upcomingeventsview/upcomingeventsview.component';
import { RidedraftEditComponent } from '../../../../app/forms/create-event-types/ridedraft-edit/ridedraft-edit.component';
import { TimezoneService } from '../../../timezone'; // Adjust the path as needed




@Component({
  selector: 'app-draftrides',
  standalone: true,
    imports: [MatCardModule,MatInput,MatTabsModule,UpcomingeventseditdraftComponent,MatFormFieldModule, MatInputModule, FeatherModule,MatIcon, MatIconModule, MatSidenavModule, HttpClientModule, NgIf, MatExpansionModule, CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
    templateUrl: './draftrides.component.html',
  styleUrl: './draftrides.component.scss',
  providers: [DatePipe]
})
export class DraftridesComponent  implements OnInit {
  groupdID: string | null = null;
  selectedTab: string = 'Upcoming'; // Default to the 'Upcoming' tab

  // displayedColumns: string[] = ['date','timelocation', 'name', 'outindoor', 'groupride'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  displayedColumns: string[] = ['name'];
  dataSource = new MatTableDataSource<any>(); 
  historyDataSource = new MatTableDataSource<any>(); 
  eventsDataSource = new MatTableDataSource<any>(); 
  userTimeZone: string;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sidenav') sidenav!: MatSidenav;

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.historyDataSource.paginator = this.paginator;
      this.eventsDataSource.paginator = this.paginator;
  }
  

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel; // Update selectedTab based on the tab label
  }

 
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.historyDataSource.filter = filterValue.trim().toLowerCase();
    this.eventsDataSource.filter = filterValue.trim().toLowerCase();


  }
  toggleSidenav() {

    this.sidenav.toggle();
}

editEvent(event: any) {
  // Handle the edit action here
  console.log("Edit event:", event);
}

editEventEditDialog(element: any): void {
  const dialogRef = this.dialog.open(UpcomingeventseditdraftComponent, {
    // width: '34%',
    data: { ...element },
    restoreFocus: false, 
    width: '100%',  // Set to 100% for responsiveness
    maxWidth: '700px',  // Maximum width for larger screens
    minWidth: '400px',  // Minimum width for smaller screens
    panelClass: 'responsive-dialog',
    disableClose: true,

  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}


constructor(private http: HttpClient, private datePipe: DatePipe, public dialog: MatDialog, private timezoneService: TimezoneService,) { }

ngOnInit(): void {
  this.fetchRidesData();
  this.fetchEventsDraftshistoryData();
  this.userTimeZone = this.timezoneService.getUserTimeZone();

}
fetchRideshistoryData() {
  this.groupdID = localStorage.getItem('selectedGroupId');
  this.userTimeZone = this.timezoneService.getUserTimeZone();

  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.ridesHistoryPerGroup+this.groupdID+"&time_zone="+this.userTimeZone, { headers: headers }).subscribe(
    (data) => {
      this.historyDataSource.data = data; // Assign fetched data to the data source
      this.historyDataSource.paginator = this.paginator; // Assign paginator after fetching data
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}

fetchEventsDraftshistoryData() {
  this.userTimeZone = this.timezoneService.getUserTimeZone();

  // Make HTTP GET request to fetch rides data
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.draftUpcomingEventsPerGroup+this.groupdID+"?time_zone="+this.userTimeZone, { headers: headers }).subscribe(
    (data) => {
      this.eventsDataSource.data = data; // Assign fetched data to the data source
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(RidedraftEditComponent, {
      width: '100%',  // Set to 100% for responsiveness
      maxWidth: '700px',  // Maximum width for larger screens
      minWidth: '400px',  // Minimum width for smaller screens
      panelClass: 'responsive-dialog',
      data: { ...element },
      disableClose: true,

    });
    console.log('element Dialog result:', element);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Handle the result from the dialog (e.g., update the record)
        console.log('Dialog result:', result);
      }
    });
  }







openRescheduleDialog(element: any): void {
  const dialogRef = this.dialog.open(RiderescheduleComponent, {
    width: '100%',  // Set to 100% for responsiveness
    maxWidth: '700px',  // Maximum width for larger screens
    minWidth: '400px',  // Minimum width for smaller screens
    data: { ...element },
    panelClass: 'responsive-dialog',
    disableClose: true,
  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}



openUpcomingridesviewdetailsComponentDialog(element: any): void {
  const dialogRef = this.dialog.open(UpcomingridesviewdetailsComponent, {
    data: { ...element },
    restoreFocus: false, 
    width: '100%',  // Set to 100% for responsiveness
    maxWidth: '700px',  // Maximum width for larger screens
    minWidth: '400px',  // Minimum width for smaller screens
    panelClass: 'responsive-dialog',
    height: "100vh",
    position: { right: '0' },
    disableClose: true,

  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}



openUpcomingEventsviewdetailsComponentDialog(element: any): void {
  const dialogRef = this.dialog.open(UpcomingeventseditdraftComponent, {
    data: { ...element },
    restoreFocus: false, 
    width: '100%',  // Set to 100% for responsiveness
    maxWidth: '700px',  // Maximum width for larger screens
    minWidth: '400px',  // Minimum width for smaller screens
    panelClass: 'responsive-dialog',
    disableClose: true,
  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}





openViewRouteDialog(element: any): void {
  const dialogRef = this.dialog.open(ViewrouteComponent, {
    // width: '34%',

    data: { ...element },
    restoreFocus: false, 
    width: '100%',  // Set to 100% for responsiveness
    maxWidth: '700px',  // Maximum width for larger screens
    minWidth: '400px',  // Minimum width for smaller screens
    panelClass: 'responsive-dialog',
    height: "100vh",
    disableClose: true,

  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}


openEditRouteDialog(element: any): void {
  const dialogRef = this.dialog.open(RidereditrouteComponent, {
    data: { ...element },
    restoreFocus: false, 
    width: '100%',  // Set to 100% for responsiveness
    maxWidth: '700px',  // Maximum width for larger screens
    minWidth: '400px',  // Minimum width for smaller screens
    panelClass: 'responsive-dialog',
    height: "100vh",
    disableClose: true,


  });
  console.log('element Dialog result:', element);

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result from the dialog (e.g., update the record)
      console.log('Dialog result:', result);
    }
  });
}


fetchRidesData() {
  this.groupdID = localStorage.getItem('selectedGroupId');
  // Make HTTP GET request to fetch rides data
  this.userTimeZone = this.timezoneService.getUserTimeZone();
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  this.http.get<any>(BASE_URL + ApiEndpoints.draftRidesPerGroup+this.groupdID +"&time_zone="+this.userTimeZone, { headers: headers }).subscribe(
    (data) => {
      console.log('Draft rides data:', data);

      this.dataSource.data = data; // Assign fetched data to the data source
      this.dataSource.paginator = this.paginator; // Assign paginator after fetching data
    },
    (error) => {
      console.error('Error fetching rides data:', error);
    }
  );
}


formatDate(dateString: string, timezone: string = 'UTC'): { dayOfWeek: string; day: string; month: string } {
  const date = new Date(dateString);
  
  // Format the date with Intl.DateTimeFormat, using the specified timezone
  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    timeZone: timezone,  // Specify timezone
  });
  const parts = formatter.formatToParts(date);

  // Extract day of the week, day, and month
  const dayOfWeek = parts.find(part => part.type === 'weekday')?.value?.toUpperCase() || '';
  const day = parts.find(part => part.type === 'day')?.value || '';
  const month = parts.find(part => part.type === 'month')?.value?.toUpperCase() || '';

  return {
    dayOfWeek,
    day,
    month
  };
}





formatTimeTo12Hour(time: string): string {
  if (!time) return '';

  const [hourString, minuteString] = time.split(':');
  let hour = parseInt(hourString, 10);
  const minute = parseInt(minuteString, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const minuteFormatted = minute < 10 ? '0' + minute : minute;
  return `${hour}:${minuteFormatted} ${ampm}`;
}


cancelRide(element: any) { 
const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
this.http.post(BASE_URL + ApiEndpoints.cancelRide+element.rideId, '', { headers: headers }).subscribe(
  (res: any) => {
    if (res) {
      alert("Ride cancelled successfully");
      window.location.reload();
    } else {
      console.error("Unexpected response:", res);
      alert("Error, Failed");
    }
  },
  (error) => {
    if (error.status === 401) {
      // Handle 401 Unauthorized error
      console.log("Unauthorized:", error.status);
      alert("Unauthorized");
    } else if (error.status === 500) {
      // Handle 401 Unauthorized error
      console.log("Unauthorized:", error.status);
      alert("Internal server error");
    } else {
      // Handle other errors
      console.error("Error logging in:", error);
      alert("Failed creating record. Please try again later.");
    }
  }
);
}
}

