import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTabGroup } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';

// Import your components
import { WebsiteOverviewComponent } from './website-overview/website-overview.component';
import { TopSellingProductsComponent } from './top-selling-products/top-selling-products.component';
import { RevenueOverviewComponent } from './revenue-overview/revenue-overview.component';
import { VisitsByWeekComponent } from './visits-by-week/visits-by-week.component';
import { OrderStatisticsComponent } from './order-statistics/order-statistics.component';
import { SalesOverviewComponent } from './sales-overview/sales-overview.component';
import { RecentOrdersComponent } from './recent-orders/recent-orders.component';
import { TransactionsHistoryComponent } from './transactions-history/transactions-history.component';
import { SalesByLocationsComponent } from './sales-by-locations/sales-by-locations.component';
import { EarningsReportsComponent } from './earnings-reports/earnings-reports.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { MembersComponent } from './members/members.component';
import { ActivitiesComponent } from './activities/activities.component';
import { GroupOverviewStatsComponent } from "./group-admin-stats/group-overview-stats/group-overview-stats.component";
import { RideComponent } from "../../forms/create-event-types/ride/ride.component";
import { EventComponent } from "../../forms/create-event-types/event/event.component";
import { InviteMemberComponent } from "../../forms/create-event-types/invite-member/invite-member.component";
import { RouteComponent } from "../../forms/create-event-types/route/route.component";
import { CreategroupComponent } from "../../forms/create-event-types/creategroup/creategroup.component";
import { EngagementStatsComponent } from "./group-admin-stats/engagement-stats/engagement-stats.component";
import { PaymentStatsComponent } from "./group-admin-stats/payment-stats/payment-stats.component";
import { GoProWidgetComponent } from "./go-pro-widget/go-pro-widget.component";
import { AvgSpeedChartComponent } from "./group-activity-charts/avg-speed-chart/avg-speed-chart.component";
import { GroupActivityChartsComponent } from "./group-activity-charts/group-activity-charts.component";
import { LatestpostComponent } from './latestpost/latestpost.component';
import { RidcalendarComponent } from './ridcalendar/ridcalendar.component';
import {RideRoutesComponent} from "./ride-routes/ride-routes.component";
import {MatSuffix} from "@angular/material/form-field";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../app/constants';
import { ApiEndpoints } from '../../../app/api-endpoints';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-ecommerce',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    RouterModule,
    MatTabsModule,
    MatIconModule,
    MatMenuTrigger,
    MatMenu,
    MatButtonModule,
    MatMenu,
    HttpClientModule,
    MatMenuItem,
    WebsiteOverviewComponent,
    TopSellingProductsComponent,
    RevenueOverviewComponent,
    VisitsByWeekComponent,
    OrderStatisticsComponent,
    SalesOverviewComponent,
    RecentOrdersComponent,
    TransactionsHistoryComponent,
    SalesByLocationsComponent,
    EarningsReportsComponent,
    UserprofileComponent,
    MembersComponent,
    ActivitiesComponent,
    GroupOverviewStatsComponent,
    RideComponent,
    EngagementStatsComponent,
    PaymentStatsComponent,
    GoProWidgetComponent,
    AvgSpeedChartComponent,
    GroupActivityChartsComponent,
    LatestpostComponent,
    RidcalendarComponent,
    RideRoutesComponent,
    MatSuffix
  ],
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {
  totalGroupMembers: string = '';

  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  selectedTabIndex: number = 0;

  constructor(public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadSelectedTabIndex();
    // this.loadTotalgroups();
  }

  loadSelectedTabIndex(): void {
    const storedTabIndex = localStorage.getItem('selectedTabIndex');
    if (storedTabIndex !== null) {
      // this.selectedTabIndex = parseInt(storedTabIndex, 10);
    }
  }

  onTabChange(index: number): void {
    this.selectedTabIndex = index;
    // localStorage.setItem('selectedTabIndex', index.toString());
  }

  openDialog(componentType: Type<any>) {
    const dialogRef = this.dialog.open(componentType, { restoreFocus: false, width: "34%" });
    dialogRef.afterClosed().subscribe(() => this.menuTrigger.focus());
  }

  protected readonly RideComponent = RideComponent;
  protected readonly EventComponent = EventComponent;
  protected readonly InviteMemberComponent = InviteMemberComponent;
  protected readonly RouteComponent = RouteComponent;
  protected readonly CreategroupComponent = CreategroupComponent;


 
}
