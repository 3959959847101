import { Component } from '@angular/core';
import {ElevationGainChartComponent} from "./elevation-gain-chart/elevation-gain-chart.component";
import {AvgSpeedChartComponent} from "./avg-speed-chart/avg-speed-chart.component";

@Component({
  selector: 'app-group-activity-charts',
  standalone: true,
  imports: [ElevationGainChartComponent,AvgSpeedChartComponent],
  templateUrl: './group-activity-charts.component.html',
  styleUrl: './group-activity-charts.component.scss'
})
export class GroupActivityChartsComponent {

}
