<!-- <p class="ridestitle">Members</p> -->
<mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">

    <mat-card-content>
        <div class="recent-orders-table">

            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource.data" matSort>
                    <!-- <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox color="primary"></mat-checkbox>
                        </td>
                    </ng-container> -->

                    <!-- user Column -->
                    <ng-container matColumnDef="profilePhoto">
                        <th mat-header-cell *matHeaderCellDef style="width: 60px;">
                            
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="product-info d-flex align-items-center">
                                <a  class="image d-block">
                                    <!-- <img [src]="'data:image/png;base64,' + element.profilePhoto" class="profile-img" style="border-radius: 50%;">
                                    <img src="../../../../assets/pr1.png" alt="product-image"> -->
                                    <img *ngIf="element.profilePhoto; else defaultImage"
                                        [src]="'data:image/png;base64,' + element.profilePhoto" class="profile-img"
                                        style="border-radius: 50%; width: 50px; height: 50px;">
                                    <ng-template #defaultImage>
                                        <img src="../../../../assets/profile.png" alt="product-image"
                                            class="profile-img" style="border-radius: 50%; width: 50px;">
                                    </ng-template>

                                </a>
                                <!-- <div class="info">
                                    <a  class="d-block fw-semibold">
                                        {{element.name}}
                                    </a>

                                </div> -->
                            </div>
                        </td>
                    </ng-container>
                   
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef>
                          Name
                      </th>
                      <td mat-cell *matCellDef="let element">
                          {{element.name}}
                      </td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="otherNames">
                    <th mat-header-cell *matHeaderCellDef>
                        Last Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.otherNames}}
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>
                      Email
                  </th>
                  <td mat-cell *matCellDef="let element">
                      {{element.email}}
                  </td>
              </ng-container>
                  <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef> Members Type </th>
                    <td mat-cell *matCellDef="let element">
                      <!-- <p style="font-size: 14px;"> -->
                        {{ element.owner ? 'Owner' : '' }}
                      <!-- </p> -->
                      <!-- <p style="font-size: 14px; "> -->
                        {{ element.admin ? 'Admin' : 'Member' }}
                      <!-- </p> -->
                     
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">

                      <!-- <p style="font-size: 14px; "> -->
                        {{ element.active ? 'Active' : 'Inactive' }}
                      <!-- </p> -->
                    </td>
                  </ng-container>


                    
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <div
                              class="flex justify-start items-start flex-col gap-2 py-[13px] px-2 bg-[#FFFFFF] border-solid border-[#A2AAAD] border rounded-[3px] shadow-[_0px_2px_4px_0px_rgba(0,0,0,0.08),0px_0px_6px_0px_rgba(0,0,0,0.02)]">
                              <div class="flex justify-start items-start flex-col gap-1">
                                <!-- Group Admin Option -->
                                <div  (click)="makegroupadmin(element)" style="padding-top: 6px; cursor: pointer;"
                                  class="flex self-stretch justify-start items-center flex-row gap-4 px-[5px] h-[25px]">
                                  <svg width="16" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.8333 7.16665C12.8333 8.73145 11.5648 9.99998 9.99996 9.99998C8.43515 9.99998 7.16663 8.73145 7.16663 7.16665C7.16663 5.60184 8.43515 4.33331 9.99996 4.33331C11.5648 4.33331 12.8333 5.60184 12.8333 7.16665Z" stroke="#1F2A44" stroke-linecap="round" />
                                    <path d="M3.89557 15.2476C4.35607 12.8614 6.8314 11.75 9.26167 11.75H10.7383C13.1686 11.75 15.6439 12.8614 16.1044 15.2476C16.1361 15.4116 16.1634 15.58 16.1856 15.7523C16.256 16.3001 15.8023 16.75 15.25 16.75H4.75C4.19771 16.75 3.74395 16.3001 3.81441 15.7523C3.83657 15.58 3.86392 15.4116 3.89557 15.2476Z" stroke="#1F2A44" stroke-linecap="round" />
                                  </svg>
                                  <span style="font-size: 14px; font-weight: 500; padding-left: 6px;">Group Admin</span>
                                </div>
                      
                                <!-- Remove Option (shown only if element is active) -->
                                <div *ngIf="element.active" (click)="onDelete(element)" style="padding-top: 8px; cursor: pointer;"
                                  class="flex self-stretch justify-start items-center flex-row gap-4 px-[5px] h-[25px]">
                                  <svg width="16" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="20" height="20" rx="3" fill="white" />
                                    <circle cx="10" cy="10.5" r="7.5" stroke="#F14E50" />
                                    <path d="M6.25 10.5H13.75" stroke="#F14E50" />
                                  </svg>
                                  <span style="font-size: 14px; font-weight: 500; padding-left: 6px;">Remove</span>
                                </div>
                              </div>
                            </div>
                          </mat-menu>
                        </td>
                      </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="headersection"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <div class="paginator-container">
              <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
            </div>
        </div>
    </mat-card-content>
</mat-card>