import { Component } from '@angular/core';
import {EngagementStatsComponent} from "../../group-admin-stats/engagement-stats/engagement-stats.component";
import {PaymentStatsComponent} from "../../group-admin-stats/payment-stats/payment-stats.component";
import {MatCard, MatCardContent} from "@angular/material/card";
import {MatIcon} from "@angular/material/icon";
import {RouteAvgSpeedChartComponent} from "./route-avg-speed-chart/route-avg-speed-chart.component";
import {RouteRideComparisonTableComponent} from "./route-ride-comparison-table/route-ride-comparison-table.component";

@Component({
  selector: 'app-route-history',
  standalone: true,
  imports: [
    EngagementStatsComponent,
    PaymentStatsComponent,
    MatCard,
    MatCardContent,
    MatIcon,
    RouteAvgSpeedChartComponent,
    RouteRideComparisonTableComponent
  ],
  templateUrl: './route-history.component.html',
  styleUrl: './route-history.component.scss'
})
export class RouteHistoryComponent {

}
