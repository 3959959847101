<mat-card
    class="trinta-card billing-information-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Billing Information
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h6>
            Payment By Credit Card
        </h6>
        <ul class="p-0 m-0 list-unstyled">
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Payment Type :
                </span>
                Credit Card
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Provider :
                </span>
                Visa Master Card
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    Valid Date :
                </span>
                April 22th, 2024
            </li>
            <li class="text-black position-relative">
                <span class="fw-medium">
                    CVV :
                </span>
                XXX
            </li>
        </ul>
    </mat-card-content>
</mat-card>