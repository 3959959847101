<mat-card
    class="trinta-card all-events-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                All Events
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="all-events-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">
        
                    <!-- Event Name Column -->
                    <ng-container matColumnDef="event">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Event Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0">
                            <div class="event-info d-flex align-items-center">
                                <a routerLink="/" class="image d-block">
                                    <img [src]="element.event.img" alt="event-image">
                                </a>
                                <div class="info">
                                    <a routerLink="/" class="d-block fw-semibold">
                                        {{element.event.title}}
                                    </a>
                                    <span class="d-block text-body">
                                        Hours - <span class="text-black">{{element.event.hours}}</span>
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
        
                    <!-- Price Column -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary">
                            {{element.price}}
                        </td>
                    </ng-container>
        
                    <!-- Host Column -->
                    <ng-container matColumnDef="host">
                        <th mat-header-cell *matHeaderCellDef>
                            Host
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.host}}
                        </td>
                    </ng-container>
        
                    <!-- Booked Column -->
                    <ng-container matColumnDef="booked">
                        <th mat-header-cell *matHeaderCellDef>
                            Booked
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.booked}}
                        </td>
                    </ng-container>
        
                    <!-- Start Time Column -->
                    <ng-container matColumnDef="startTime">
                        <th mat-header-cell *matHeaderCellDef>
                            Start Time
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.startTime}}
                        </td>
                    </ng-container>
        
                    <!-- Event Date Column -->
                    <ng-container matColumnDef="eventDate">
                        <th mat-header-cell *matHeaderCellDef>
                            Event Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.eventDate}}
                        </td>
                    </ng-container>
        
                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0"></th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.edit}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.delete}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[9, 18, 27]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>