<mat-card
    class="trinta-card all-courses-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                All Courses
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group animationDuration="0ms">
            <mat-tab label="All">
                <app-t-all />
            </mat-tab>
            <mat-tab label="In Progress">
                <app-t-in-progress />
            </mat-tab>
            <mat-tab label="Finished">
                <app-t-finished />
            </mat-tab>
            <mat-tab label="Passed">
                <app-t-passed />
            </mat-tab>
            <mat-tab label="Failed">
                <app-t-failed />
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>