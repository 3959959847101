<div class="row">
    <div class="col-lg-6">
        <h5 class="mb-25">Basic mat-select</h5>
        <mat-form-field>
            <i-feather name="list"></i-feather>
            <mat-label>Favorite food</mat-label>
            <mat-select>
                @for (food of foods; track food) {
                    <mat-option [value]="food.value">{{food.viewValue}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-lg-6">
        <h5 class="mb-25">Basic native select</h5>
        <mat-form-field>
            <i-feather name="list"></i-feather>
            <mat-label>Cars</mat-label>
            <select matNativeControl required>
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
            </select>
        </mat-form-field>
    </div>
</div>