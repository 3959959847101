import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule



@Component({
  selector: 'app-groupmembership',
  standalone: true,
  imports: [MatCardModule,NgIf,CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
  templateUrl: './groupmembership.component.html',
  styleUrl: './groupmembership.component.scss'
})
export class GroupmembershipComponent {
  displayedColumns: string[] = ['image','name', 'date', 'amount'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }
}


export interface PeriodicElement {
  image: string;
  name: string;
  date: string;
  amount: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
      image: 'assets/images/users/Avatar_2.png',
      name: 'Bessie Cooper',
      date: 'Friday, 20th Apr',
      amount: "$125"

  },
  {
    image: 'assets/images/users/Avatar.png',
    name: 'Ilja Bivol',
    date: 'Friday, 26th Apr',
    amount: "$125"

},

];
