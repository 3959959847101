import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RidecloseComponent } from '../rideclose/rideclose.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastService, ToastType } from '../../../../app/toast.service'; // adjust the path accordingly
import { TimezoneService } from '../../../timezone'; // Adjust the path as needed



@Component({
  selector: 'app-ride',
  standalone: true,
  imports: [
    ReactiveFormsModule,MatCheckboxModule,
    MatFormField,
    MatRadioGroup,
    MatRadioButton,
    MatInput,
    MatDialogModule,
    MatButtonModule,
    MatLabel,MatSlideToggleModule,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.scss']
})
export class RideComponent implements OnInit {
  paces: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  purposes: any[] = [];
  levels: any[] = [];
  recurrences: any[] = [];
  inoutdoors: any[] = [];
  groups: any[] = [];
  rideTypes: any[] = [];
  selectedFile: File | null = null;
  base64String: string = '';
  displayDate: string | null = null;
  privacyride: string = '';
  minDate: Date; // Add this property to set the minimum date
  isSaving: boolean = false;
  ispOSTSaving: boolean = false;
  members: any[] = [];
  out_door: boolean = false;
  in_door: boolean = false;
  userTimeZone: string;

  constructor(
    public dialogRef: MatDialogRef<RideComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private dateAdapter: DateAdapter<Date>,
    private dialog: MatDialog,
    private toastService: ToastService,
    private timezoneService: TimezoneService


  ) {

    
    this.minDate = new Date(); // Set minDate to today's date
    this.createRideForm = this.fb.group({
      rideName: ['', [Validators.required]],
      ridinggroupId: ['', [Validators.required]],
      startLocation: ['', [Validators.required]], // Add default values or validators as needed
      cityId: ['', [Validators.required]],
      stateId: ['', [Validators.required]],
      route: [''],
      limit: [''],
      endTime: ['', [Validators.required]],
      privacyId: [''],
      levelId: ['', [Validators.required]],
      distance: ['', [Validators.required]],
      description: ['', [Validators.required]],
      link: [''],
      paceId: ['', [Validators.required]],
      purposeId: ['', [Validators.required]],
      date: ['', [Validators.required]],
      time: ['', [Validators.required]],
      recurrenceId: [''],
      preferredBikeTypesId: [''],
      logo: [''],
      outDoor: [false], // Assuming optional
      inDoor: [false],
      rideLeader: ['', [Validators.required]], // Add default values or validators as needed
    });
    // this.dateAdapter.setLocale('en-GB');
    // this.dateAdapter.setLocale('en-US'); // Set the locale to en-US to ensure the date format
   
  }




  // Password Hide
  hide = true;

  // Form
  createRideForm: FormGroup;

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
    }
  }
  onDateChange(event: any): void {
    // const date = event.value;
    // console.log('Raw date value:', date); // Debugging log
    // if (date) {
      // const formattedDate = this.formatDate(date);
    //   console.log('Formatted date value:', formattedDate); // Debugging log
    //   // this.displayDate = formattedDate;
    //   // this.createRideForm.get('date')?.setValue(formattedDate);
    // }
  }

  privacyId: boolean | null = null; // Track the state of Yes/No checkbox

 

  onCheckboxChangeVal(value: boolean) {
    this.privacyId = value; // Set the value based on the clicked checkbox
  }



  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  async onSubmit() {
    if (this.createRideForm.valid) {
      this.ispOSTSaving = true;
      if (this.selectedFile) {
        try {
          this.base64String = await this.convertFileToBase64(this.selectedFile);
          console.log('Base64 conversion successful:', this.base64String);
          this.createRideForm.patchValue({
            rideImage: this.base64String
          });
          this.submitForm();

        } catch (error) {
          console.error('Error converting file to base64:', error);
          this.submitForm();
        }
      } else {
        console.log('No file selected');
        this.submitForm(); // Proceed without the image if no file is selected
      }
    } else {
      this.createRideForm.markAllAsTouched(); // Mark all fields as touched to display validation errors

      console.log('Form is invalid. Please check the fields.');
    }
  }

  async onSubmitDraft() {
    console.log('Form submit initiated.');
    if (this.createRideForm.valid) {
      this.isSaving = true;
      if (this.selectedFile) {
        try {
          this.base64String = await this.convertFileToBase64(this.selectedFile);
          console.log('Base64 conversion successful:', this.base64String);
          this.createRideForm.patchValue({
            rideImage: this.base64String
          });
          this.createDraftEvent();
          
        } catch (error) {
          console.error('Error converting file to base64:', error);
          this.createDraftEvent();
        }
      } else {
        console.log('No file selected');
        this.createDraftEvent(); // Proceed without the image if no file is selected
      }
    } else {
      this.createRideForm.markAllAsTouched(); // Mark all fields as touched to display validation errors

      console.log('Form is invalid. Please check the fields.');
    }
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  onCheckboxChange(controlName: string, event: any): void {
    // Check or uncheck based on the selected option
    if (controlName === 'outDoor' && event.checked) {
      this.createRideForm.patchValue({ inDoor: false });
    } else if (controlName === 'inDoor' && event.checked) {
      this.createRideForm.patchValue({ outDoor: false });
    }
  }

  onRadioChange(event: any) {
    const selectedValue = event.value;
    console.log('Selected Location Type:', selectedValue);

    if(selectedValue == "outDoor"){

      this.out_door = true;
      this.in_door = false;
    }else if(selectedValue == "inDoor"){
      this.out_door = false;
      this.in_door = true;
    }
  }

  onToggleChange(checked: boolean) {
    console.log("checked", checked)
    this.privacyId = checked;
  }
  

  createDraftEvent(){
    const ridinggroupId = this.createRideForm.get('ridinggroupId')?.value;
    const rideName = this.createRideForm.get('rideName')?.value;
    const startLocation = this.createRideForm.get('startLocation')?.value;
    const cityId = this.createRideForm.get('cityId')?.value;
    const stateId = this.createRideForm.get('stateId')?.value;
    const route = this.createRideForm.get('route')?.value;
    const limit = this.createRideForm.get('limit')?.value;
    const privacyId = this.createRideForm.get('privacyId')?.value;
    const preferredBikeTypesId = this.createRideForm.get('preferredBikeTypesId')?.value;
    const levelId = this.createRideForm.get('levelId')?.value;
    const distance = this.createRideForm.get('distance')?.value;
    const paceId = this.createRideForm.get('paceId')?.value;
    const purposeId = this.createRideForm.get('purposeId')?.value;
    const date = this.createRideForm.get('date')?.value;
    const time = this.createRideForm.get('time')?.value;
    const endTime = this.createRideForm.get('endTime')?.value;
    const link = this.createRideForm.get('link')?.value;
    const rideLeader = this.createRideForm.get('rideLeader')?.value;
    const description = this.createRideForm.get('description')?.value;
    const outDoor = this.createRideForm.get('outDoor')?.value;
    const inDoor = this.createRideForm.get('inDoor')?.value;



    const formattedDate = this.formatDate(date);

    if(privacyId == true){
      this.privacyride = "1000000";
    }else{
      this.privacyride = "1000001";
    }

    var cbPartnerId = "";
    var userobj = localStorage.getItem("userObj");
    if (userobj) {
      try {
        var parsedUserObj = JSON.parse(userobj);
        cbPartnerId = parsedUserObj.cbPartnerId;
        console.log(parsedUserObj);
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    } else {
      console.warn("No userObj found in localStorage.");
    }
    this.userTimeZone = this.timezoneService.getUserTimeZone();

    // Construct the request body
    const requestBody = {
      ridinggroupId: ridinggroupId,
      rideName: rideName,
      startLocation: startLocation,
      cityId: cityId,
      stateId: stateId,
      route: route,
      limit: limit,
      privacyId: 1000000,
      preferredBikeTypesId: preferredBikeTypesId,
      levelId: levelId,
      distance: distance,
      paceId: paceId,
      endTime: formattedDate+"T"+endTime,
      purposeId: purposeId,
      cbpartnerId: cbPartnerId,
      recurrenceId: 1000000,
      date: formattedDate+"T"+time,
      time: formattedDate+ "T"+time,
      isPost: false,
      link: link,
      rideLeader: rideLeader,
      description: description,
      logo: this.base64String,
      outDoor: this.out_door,
      inDoor: this.in_door,
      notify: false,
      timeZone: this.userTimeZone
    };

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.post(BASE_URL + ApiEndpoints.createRide, requestBody, { headers: headers }).subscribe(
      (res: any) => {
        if (res) {
          // alert("Your draft has been saved.");
          this.showSuccess("Your draft has been saved.");
          this.isSaving = false;
          setTimeout(() => {
            window.location.reload();
            this.dialogRef.close(this.createRideForm.value);
            this.router.navigate(['/activities']);
          }, 2000);
          
        } else {
          console.error("Unexpected response:", res);
          alert("Error, Failed");
        }
      },
      (error) => {
        if (error.status === 401) {
          console.log("Unauthorized:", error.status);
          this.showSuccess("Unauthorized: Invalid username or password");

        } else if (error.status === 500) {
          console.log("Internal server error:", error.status);
          this.showSuccess("Internal server error");
        }else if (error.status === 200) {
          this.showSuccess("Sorry, the group doesn't allow members to post.");
        } else {
          this.showSuccess("Error creating ride failed. Please try again later.");
        }
      }
    );
  }

  submitForm() {
    const ridinggroupId = this.createRideForm.get('ridinggroupId')?.value;
    const rideName = this.createRideForm.get('rideName')?.value;
    const startLocation = this.createRideForm.get('startLocation')?.value;
    const cityId = this.createRideForm.get('cityId')?.value;
    const stateId = this.createRideForm.get('stateId')?.value;
    const route = this.createRideForm.get('route')?.value;
    const limit = this.createRideForm.get('limit')?.value;
    const privacyId = this.createRideForm.get('privacyId')?.value;
    const preferredBikeTypesId = this.createRideForm.get('preferredBikeTypesId')?.value;
    const levelId = this.createRideForm.get('levelId')?.value;
    const distance = this.createRideForm.get('distance')?.value;
    const paceId = this.createRideForm.get('paceId')?.value;
    const purposeId = this.createRideForm.get('purposeId')?.value;
    const date = this.createRideForm.get('date')?.value;
    const time = this.createRideForm.get('time')?.value;
    const endTime = this.createRideForm.get('endTime')?.value;
    const link = this.createRideForm.get('link')?.value;
    const rideLeader = this.createRideForm.get('rideLeader')?.value;
    const description = this.createRideForm.get('description')?.value;
    const outDoor = this.createRideForm.get('outDoor')?.value;
    const inDoor = this.createRideForm.get('inDoor')?.value;

    const formattedDate = this.formatDate(date);

    if(privacyId == true){
      this.privacyride = "1000000";
    }else{
      this.privacyride = "1000001";
    }

    var cbPartnerId = "";
    var userobj = localStorage.getItem("userObj");
    if (userobj) {
      try {
        var parsedUserObj = JSON.parse(userobj);
        cbPartnerId = parsedUserObj.cbPartnerId;
        console.log(parsedUserObj);
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    } else {
      console.warn("No userObj found in localStorage.");
    }
    this.userTimeZone = this.timezoneService.getUserTimeZone();


    // Construct the request body
    const requestBody = {
      ridinggroupId: ridinggroupId,
      rideName: rideName,
      startLocation: startLocation,
      cityId: cityId,
      stateId: stateId,
      route: route,
      limit: limit,
      privacyId: 1000000,
      preferredBikeTypesId: preferredBikeTypesId,
      levelId: levelId,
      distance: distance,
      paceId: paceId,
      endTime: formattedDate+"T"+endTime,
      purposeId: purposeId,
      cbpartnerId: cbPartnerId,
      recurrenceId: 1000000,
      date: formattedDate+"T"+time,
      time: formattedDate+ "T"+time,
      isPost: true,
      link: link,
      rideLeader: rideLeader,
      description: description,
      logo: this.base64String,
      outDoor: this.out_door,
      inDoor: this.in_door,
      notify: false,


      timeZone: this.userTimeZone

    };

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.post(BASE_URL + ApiEndpoints.createRide, requestBody, { headers: headers }).subscribe(
      (res: any) => {
        if (res) {
          if (res) {
            // alert("Ride updated successfully");
            this.showSuccess("Ride created successfully");
            console.log("autojoinEventautojoinEvent", res)
            // this.autojoinEvent(res.upcomingEventId);
            // setTimeout(() => {
            //   this.refreshPage();
            // }, 2000);
  
          } else {
            console.error("Unexpected response:", res);
            this.showSuccess("Error, Failed");
  
          }
         
        } else {
          console.error("Unexpected response:", res);
          alert("Error, Failed");
        }
      },
      (error) => {
        if (error.status === 401) {
          console.log("Unauthorized:", error.status);
          this.showSuccess("Unauthorized: Invalid username or password");

        } else if (error.status === 500) {
          console.log("Internal server error:", error.status);
          this.showSuccess("Internal server error");
        }else if (error.status === 200) {
          console.log("Internal server error:", error.status);
          this.showSuccess("Sorry, the group doesn't allow members to post.");
        } else {
          this.showSuccess("Error creating ride failed. Please try again later.");
        }
      }
    );
  }


  autojoinEvent(ride_id: String) {

    var cbPartnerId = "";
    var userobj = localStorage.getItem("userObj");
    if (userobj) {
      try {
        var parsedUserObj = JSON.parse(userobj);

        cbPartnerId = parsedUserObj.cbPartnerId;
        console.log(parsedUserObj);
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    } else {
      console.warn("No userObj found in localStorage.");
    }
  var jsondata = {
    "cbPartner_id": cbPartnerId,
    "ride_id": ride_id

  }
  var obj =
  console.log("userobjuserobj", userobj)
const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

  this.http.post(BASE_URL + ApiEndpoints.joinRide, jsondata, { headers: headers }).subscribe(
    (res: any) => {
     
    },
    (error) => {
     
    }
  );

}

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/activities', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }


  refreshPage(): void {
    this.router.navigate(['/activities']);

    this.dialogRef.close(this.createRideForm.value);
    history.go(0); // This will reload the current page
    // this.router.navigate([this.router.url]);
  }

  refreshPageQueries(): void {
    this.router.navigate([], { queryParams: { refresh: new Date().getTime() } });
    
  }

  onRestrictionChange(controlName: string, event: any): void {
    if (controlName === 'outDoor' && event.value === true) {
      this.createRideForm.patchValue({ inDoor: false });
    } else if (controlName === 'inDoor' && event.value === true) {
      this.createRideForm.patchValue({ outDoor: false });
    }
  }

  // Correctly place the onNoClick method outside the constructor
  onNoClick() {
    // EventcloseComponent
    const title = this.createRideForm.get('rideName')?.value;
    if (!title) {
      this.dialogRef.close();

    }else{
      this.showalertdialog();
    }
  }


  showalertdialog(): void {
    const dialogRef = this.dialog.open(RidecloseComponent, {
      restoreFocus: false,
      panelClass: 'custom-dialog',
      width: '460px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close();
        // Call your delete function here
        // this.deleteUser();
      }
    });
  }

  ngOnInit(): void {
    this.loadMygroups();
    this.loadPaces();
    this.loadstates();
    this.loadCity();
    this.loadPurpose();
    this.loadlevels();
    this.loadOutinDoor();
    this.ridetypes();
    this.listmembers();

    this.userTimeZone = this.timezoneService.getUserTimeZone();

    console.log("userTimeZone", this.userTimeZone)


  }

  listmembers() {
    const storedGroupId = localStorage.getItem('selectedGroupId');

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getGroupMembers+storedGroupId, { headers: headers }).subscribe(
      (data: any) => {
        // console.log("My getGroupMembers", data.entity[0])
        this.members = data.entity; // Assuming data is an array of objects with properties 'value' and 'label'
      },


      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  loadPaces() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActivePace, { headers: headers }).subscribe(
      (data: any) => {
        this.paces = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );
  }

  ridetypes() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActivePreferredType, { headers: headers }).subscribe(
      (data: any) => {
        this.rideTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  loadstates() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
      (data: any) => {
        this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadCity() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
      (data: any) => {
        this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadPurpose() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.purpose, { headers: headers }).subscribe(
      (data: any) => {
        this.purposes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  loadlevels() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.levels, { headers: headers }).subscribe(
      (data: any) => {
        this.levels = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadOutinDoor() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.recurrence, { headers: headers }).subscribe(
      (data: any) => {
        this.inoutdoors = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }



  loadMygroups() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.groupsPerUserSummary, { headers: headers }).subscribe(
      (data: any) => {
        this.groups = data.entity; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  showSuccess(message: string) {
    this.toastService.showToast(message, ToastType.Success);
  }
  

  showError() {
    this.toastService.showToast('An error occurred!', ToastType.Error);
  }

  showWarning() {
    this.toastService.showToast('This is a warning message.', ToastType.Warning);
  }
}