// import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { Component, OnInit } from '@angular/core';
import {  ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../app/constants';
import { ApiEndpoints } from '../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangepasswordconfirmComponent } from '../changepasswordconfirm/changepasswordconfirm.component';
import { NgIf } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ToastService, ToastType } from '../../../app/toast.service'; // adjust the path accordingly


@Component({
    selector: 'app-change-password',
    standalone: true,
    imports: [RouterLink, MatCardModule,NgIf, HttpClientModule, ReactiveFormsModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, FeathericonsModule],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {
    changePasswordForm: FormGroup;
    // Password Hide
    hide = true;
    hide2 = true;
    hide3 = true;

    issubmit: boolean = false;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private dialog: MatDialog,
        private toastService: ToastService,
    ) { }

   ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      password: ['',  [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)  // Custom regex for password strength
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)  // Custom regex for password strength
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)  // Custom regex for password strength
      ]]
    }, { validator: this.passwordMatchValidator });
  }



    passwordMatchValidator(form: FormGroup) {
        return form.get('newPassword')?.value === form.get('confirmPassword')?.value
            ? null : { mismatch: true };
    }

    passwordStrengthValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (!value) {
                return null;
            }

            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumeric = /[0-9]/.test(value);
            const isValidLength = value.length >= 8;

            const valid = hasUpperCase && hasLowerCase && hasNumeric && isValidLength;
            return !valid ? {
                passwordStrength: {
                    hasUpperCase,
                    hasLowerCase,
                    hasNumeric,
                    isValidLength
                }
            } : null;
        };
    }

    submitrequest() {

        const dialogRef = this.dialog.open(ChangepasswordconfirmComponent, {
            restoreFocus: false,
            width: '100%',  // Set to 100% for responsiveness
            maxWidth: '700px',  // Maximum width for larger screens
            minWidth: '460px',  // Minimum width for smaller screens
            panelClass: 'responsive-dialog',
            height: "100vh",
            position: { right: '0' },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // Call your delete function here
                this.submitrequestBody();
            }
        });
    }

    submitrequestBody() {
        const userObj = localStorage.getItem('userObj');
        if (userObj) {
            const formValue = this.changePasswordForm.value;
    
            // Validate that the new password and confirm password are the same
            // if (formValue.newPassword !== formValue.confirmPassword) {
            //     alert("New password and confirm password do not match. Please try again.");
            //     return;
            // }
    
            // // Validate that the old password and new password are not the same
            // if (formValue.password === formValue.newPassword) {
            //     alert("New password cannot be the same as the old password. Please choose a different password.");
            //     return;
            // }
    
            const requestBody = {
                password: formValue.password,
                newPassword: formValue.newPassword,
                confirmPassword: formValue.confirmPassword,
            };
            const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    
            this.http.post(BASE_URL + ApiEndpoints.resetpassword, requestBody, { headers: headers, observe: 'response' }).subscribe(
                (response) => {
                    if (response.status === 202) {
                        console.log("Password reset request accepted", response.status);
                        // alert("Sorry! Old password is not correct");
                        // Uncomment the desired action below
                        // this.router.navigate(['/authentication']);
                        // window.location.reload();
                    } else if (response.status === 200) {
                        this.showSuccess("Password changed successfully. Please check your email for a temporary password.");

                        // console.log("Password reset request accepted", response.status);
                        // alert("Password successfully changed.");
                        setTimeout(() => {
                            this.router.navigate(['/authentication']);
                            localStorage.clear();
                          }, 
                          5000);
                        // window.location.reload();
                    } else {
                        this.showSuccess("Unexpected response. Please try again later.");

                        console.error("Unexpected status code:", response.status);
                        // alert("Unexpected response. Please try again later.");
                    }
                },
                (error) => {
                    if (error.status === 401) {
                        console.log("Unauthorized:", error.status);
                        // alert("Unauthorized: Invalid credentials.");
                        this.showSuccess("Unauthorized: Invalid credentials.");

                    } else if (error.status === 500) {
                        console.log("Internal server error:", error.status);
                        this.showSuccess("Internal server error. Please try again later.");

                        // alert("Internal server error. Please try again later.");
                    } else {
                        this.showSuccess("Failed to change password. Please try again later.");

                        console.error("Error changing password:", error);
                        // alert("Failed to change password. Please try again later.");
                    }
                }
            );
        }
    }
    



    showSuccess(message: string) {
        this.toastService.showToast(message, ToastType.Success);
      }
      
    
      showError() {
        this.toastService.showToast('An error occurred!', ToastType.Error);
      }
    
      showWarning() {
        this.toastService.showToast('This is a warning message.', ToastType.Warning);
      }


    onSubmit(): void {
        if (this.changePasswordForm.invalid) {
            return;
        }
        const dialogRef = this.dialog.open(ChangepasswordconfirmComponent, {
            restoreFocus: false,
            panelClass: 'custom-dialog', 
            width: '460px', 
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.submitrequestBody();
            }
        });

    }

}