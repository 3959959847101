import { Component, ViewChild, Type, ViewEncapsulation, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router } from "@angular/router";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from '@angular/material/dialog';
import { InviteMemberComponent } from "../../../forms/create-event-types/invite-member/invite-member.component";
import { MatIcon } from "@angular/material/icon";




@Component({
  selector: 'app-invites',
  standalone: true,
  imports: [MatCardModule,NgIf,MatCheckboxModule,MatIcon, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
  templateUrl: './invites.component.html',
  styleUrl: './invites.component.scss'
})
export class InvitesComponent implements OnInit {
//   displayedColumns: string[] = ['user', 'status'];
//   dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

//   @ViewChild(MatPaginator) paginator: MatPaginator;
//   ngAfterViewInit() {
//       this.dataSource.paginator = this.paginator;
//   }
groupdID: string | null = null;
//   displayedColumns: string[] = ['user', 'longestride', 'allrides', 'groupride', 'status'];
//   dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
//   @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['la_RidingGroup_ID',  'firstName','lastName','accepted'];
  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('menuTrigger', { static: true }) menuTrigger: MatMenuTrigger;



  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }

  openRouteListPage() {
    this.router.navigate(['/members-invites'])
  }

  constructor(private http: HttpClient, private router: Router,public dialog: MatDialog ) { }

  ngOnInit(): void {
    this.fetmemberslist();
  }

  fetmemberslist() {
   this.groupdID = localStorage.getItem('selectedGroupId');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get<any>(BASE_URL + ApiEndpoints.invitedMembersByGroup+ this.groupdID, { headers: headers }).subscribe(
      (data) => {
        this.dataSource.data = data; 
        this.dataSource.paginator = this.paginator; 
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }


  protected readonly InviteMemberComponent = InviteMemberComponent;

  openDialog(componentType: Type<any>) {
    const dialogRef = this.dialog.open(componentType, {
      restoreFocus: false, width: '100%',  // Set to 100% for responsiveness
      maxWidth: '700px',  // Maximum width for larger screens
      minWidth: '400px',  // Minimum width for smaller screens
      panelClass: 'responsive-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(() => this.menuTrigger.focus());
  }
}





