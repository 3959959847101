<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Tree
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Tree
        </li>
    </ol>
</div>

<!-- Tree with Flat Nodes -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Tree with Flat Nodes
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-flat-nodes></app-tw-flat-nodes>
    </mat-card-content>
</mat-card>

<!-- Tree with Nested Nodes -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Tree with Nested Nodes
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-nested-nodes></app-tw-nested-nodes>
    </mat-card-content>
</mat-card>

<!-- Tree with Dynamic Data -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Tree with Dynamic Data
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-dynamic-data></app-tw-dynamic-data>
    </mat-card-content>
</mat-card>

<!-- Tree with Partially Loaded Data -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Tree with Partially Loaded Data
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-tw-partially-loaded-data></app-tw-partially-loaded-data>
    </mat-card-content>
</mat-card>