<mat-vertical-stepper [linear]="false" #stepper animationDuration="1000">
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field>
                <i-feather name="list"></i-feather>
                <mat-label>Last name, First name</mat-label>
                <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
            </mat-form-field>
            <div>
                <button mat-flat-button color="primary" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Fill out your address</ng-template>
            <mat-form-field>
                <i-feather name="list"></i-feather>
                <mat-label>Address</mat-label>
                <input matInput placeholder="Address" formControlName="secondCtrl" required>
            </mat-form-field>
            <div>
                <button mat-flat-button color="warn" class="mr-15" matStepperPrevious>Back</button>
                <button mat-flat-button color="primary" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <span class="d-block text-body mb-15">
            You are now done.
        </span>
        <div>
            <button mat-flat-button color="primary" class="mr-15" matStepperPrevious>Back</button>
            <button mat-flat-button color="warn" (click)="stepper.reset()">Reset</button>
        </div>
    </mat-step>
</mat-vertical-stepper>