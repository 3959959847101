<mat-card-content>
    <div class="container membershipectiontab">
        <div class="row">
            <p class="ridestitle">Drafts</p>

            <div class="col-xl-6 col-lg-6 col-sm-6 ">
                <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
                    <p class="ridestitle">Rides</p>
                    <mat-card-content>

                        
                        <div *ngFor="let element of dataSource.filteredData" class="event-item"
                            style="border-radius: 7px;
                            background: #FFF;
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
                            margin-bottom: 6px;">
                            <div class="header-content d-flex align-items-center justify-content-between">
                                <div class="date-container">
                                    <!-- <div class="formatted-date"> -->
                                      <div class="dayweek">{{ formatDate(element.date).dayOfWeek }}</div>
                                      <div class="daydate">{{ formatDate(element.date).day }}</div>
                                      <div class="monthyyyy">{{ formatDate(element.date).month }}</div>
                                    <!-- </div> -->
                                  </div>

                                  <div class="ridesectioncontainer">
                                    <div class="ridetitle">{{ element.name }}</div>
                                    <div class="lowersec" style="display: flex; align-items: center;">
                                      <img class="dateimg" src="../../../../assets/clock.png">
                                      <span class="lowertxt">{{ formatTimeTo12Hour(element.time) }}</span>
                                      <span style="margin: 0 5px;"></span>
                                      <img class="imgimage"  src="../../../../assets/pin.png">
                                      <span class="lowertxt" >{{ element.startlocation }}</span>
                                      <span style="margin: 0 5px;"></span>
                                      <img class="imgimage"  src="../../../../assets/mp.png">
                                      <span class="lowertxt" >{{ element.distance }} miles</span>
                                      <span style="margin: 0 5px;"></span>
                                      <img class="imgimage" src="../../../../assets/sun.png">
                                      <span class="lowertxt" >{{ element.outDoor ? 'Outdoor' : 'Indoor' }}</span>
                                    </div>
                                  </div>
                                  

                                <img (click)="openEditDialog(element)" src="../../../../assets/pen.png"
                                    style="height: 13px; cursor: pointer;">
                            </div>
                        </div>

                        <div class="paginator-container">
                            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
                          </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-6 ">
                <mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
                    <p class="ridestitle">Events</p>

                    <mat-card-content>

                        <div *ngFor="let element of eventsDataSource.filteredData" class="event-item"
                            style="border-bottom: 0px solid #ddd; padding: 10px 0;">
                            <div class="header-content d-flex align-items-center justify-content-between eventdaata">
                                <div class="date-container" style="background-color: #1FBBCA !important">
                                    <!-- <div class="formatted-date"> -->
                                      <div class="dayweek">{{ formatDate(element.date).dayOfWeek }}</div>
                                      <div class="daydate">{{ formatDate(element.date).day }}</div>
                                      <div class="monthyyyy">{{ formatDate(element.date).month }}</div>
                                    <!-- </div> -->
                                  </div>
                                <div class="ridesectioncontainer"
                                    style="width: 70%; padding-left: 8px; padding-right: 8px;">
                                    <div style="color: black; font-weight: 600; font-size: 12px;" class="cardtittle">{{
                                        element.title }}</div>
                                    <div class="lowersec">
                                        <img src="../../../../assets/clock.png" style="height: 13px;">
                                        {{ formatTimeTo12Hour(element.time) }} |
                                        <img src="../../../../assets/location.png" style="height: 13px;">
                                        {{ element.location }}
                                    </div>
                                </div>



                                <img (click)="openUpcomingEventsviewdetailsComponentDialog(element)" src="../../../../assets/pen.png" style="height: 13px; cursor: pointer;">
                            </div>
                        </div>

                        <div class="paginator-container">
                            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
                          </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</mat-card-content>