import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  showToast(message: string, type: ToastType, duration: number = 4000) {
    const snackBarRef = this.snackBar.open(message, 'Close', {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: this.getPanelClass(type) // Set the panel class based on type
    });
  }

  private getPanelClass(type: ToastType): string {
    switch (type) {
      case ToastType.Success:
        return 'toast-success';
      case ToastType.Error:
        return 'toast-error';
      case ToastType.Warning:
        return 'toast-warning';
      default:
        return '';
    }
  }
}
