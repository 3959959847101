import { MembersComponent } from '../members/members.component';
import { Component,OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RideEditComponent } from '../../../../app/forms/create-event-types/ride-edit/ride-edit.component';
import { RiderescheduleComponent } from '../../../../app/forms/create-event-types/ridereschedule/ridereschedule.component';
import { RidereditrouteComponent } from '../../../../app/forms/create-event-types/ridereditroute/ridereditroute.component';
import { ViewrouteComponent } from '../viewroute/viewroute.component';
import { UpcomingridesviewdetailsComponent } from '../upcomingridesviewdetails/upcomingridesviewdetails.component';
import { MatInput } from "@angular/material/input";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { InvitesComponent } from '../invites/invites.component';
import { MembersstatisticsComponent } from '../membersstatistics/membersstatistics.component';



@Component({
  selector: 'app-memberslist',
  standalone: true,
  imports: [MatCardModule,InvitesComponent,MembersstatisticsComponent,MembersComponent,MatInput,MatTabsModule,MatFormFieldModule, MatInputModule, FeatherModule,MatIcon, MatIconModule, MatSidenavModule, HttpClientModule, NgIf, MatExpansionModule, CommonModule, MatMenuModule, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],

  templateUrl: './memberslist.component.html',
  styleUrl: './memberslist.component.scss'
})
export class MemberslistComponent {

}
