<div class="logout-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="trinta-form ml-auto mr-auto text-center bg-white position-relative z-1 border-radius"
            >
                <h3>
                    Welcome To
                </h3>
                <div class="logo">
                    <img src="assets/images/logo-big.svg" alt="logo">
                    <img src="assets/images/white-logo-big.svg" alt="logo">
                </div>
                <p class="text-black fs-15">
                    You Are Logged Out
                </p>
                <a routerLink="/authentication" mat-button>
                    Sign In
                </a>
                <div class="shape1">
                    <img src="assets/images/icons/star1.svg" alt="icon">
                </div>
                <div class="shape2">
                    <img src="assets/images/icons/star2.svg" alt="icon">
                </div>
                <div class="shape3">
                    <img src="assets/images/icons/star2.svg" alt="icon">
                </div>
                <div class="shape4">
                    <img src="assets/images/icons/star1.svg" alt="icon">
                </div>
            </div>
        </div>
    </div>
</div>