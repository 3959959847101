import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from "@angular/material/datepicker";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { slideInRightAnimation } from '../../../../app/slide-in-right.animation';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastService, ToastType } from '../../../../app/toast.service'; // adjust the path accordingly


@Component({
  selector: 'app-editgroupdetails',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatRadioGroup, FileUploadModule,
    MatRadioButton,
    MatInput,
    MatDialogModule, MatCheckboxModule,
    MatButtonModule,
    MatLabel,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink
  ],
  templateUrl: './editgroupdetails.component.html',
  styleUrl: './editgroupdetails.component.scss',
  animations: [slideInRightAnimation]
})
export class EditgroupdetailsComponent implements OnInit {
  states: any[] = [];
  cities: any[] = [];
  typicalpaces: any[] = [];
  rideTypes: any[] = [];
  levels: any[] = [];
  genders: any[] = [];
  mileages: any[] = [];
  logoBase64: string | null = null;
  coverPhotoBase64: string | null = null; // Added cover photo base64
  la_RidingGroupID: string | null = null;


  public multiple: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<EditgroupdetailsComponent>,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createRideForm = this.fb.group({
      la_RidingGroup_ID: [data.la_RidingGroup_ID],
      name: [data.name, [Validators.required]],
      description: [data.description, [Validators.required]],
      locationComment: [data.locationComment],
      la_TypicalPace_ID: ['', Validators.required],
      ridingType: [data.ridingTypes],
      ridingLevel: [data.ridingLevels],
      la_AnnualMileage_ID: [''],
      invitedRidersOnly: [data.invitedRidersOnly || false], // Ensures it's either true or false
      adminApproval: [data.adminApproval || false], // Ensures it's either true or false
      allowMemberPost: [data.allowMemberPost],
      allowMemberPostNo: [!data.allowMemberPost], // Invert value of allowMemberPost
      amount: [this.data.amount || 0],
      logo: [this.data.logo],
      coverphoto: [this.data.coverphoto || 0],
      image: [this.data.coverphoto || 0],
      url: [data.url],
      socialLinks: [data.socialLinks],
      // compisitionID: ['', Validators.required],
      stateId: [this.data.stateId || '', [Validators.required, Validators.min(0)]],
      cityId: [this.data.cityId || '', [Validators.required, Validators.min(0)]],
      x: [this.data.x],
      instagram: [this.data.instagram],
      faceBook: [this.data.faceBook],
      groupAdmin: [this.data.groupAdmin],
      compisitionID: [data.genderIDList[0]],
      
    });
    this.la_RidingGroupID = data.la_RidingGroup_ID;
    this.createRideForm.get('allowMemberPost')?.valueChanges.subscribe(value => {
      if (value === true) {
        // When allowMemberPost is true, set allowMemberPostNo to false
        this.createRideForm.patchValue({ allowMemberPostNo: false });
      } else {
        // When allowMemberPost is false, set allowMemberPostNo to true
        this.createRideForm.patchValue({ allowMemberPostNo: true });
      }
    });

    console.log("data.genderIDList", data)
  }

  onCheckboxChange(controlName: string, event: any): void {
    if (controlName === 'invitedRidersOnly' && event.checked) {
      this.createRideForm.patchValue({ adminApproval: false });
    } else if (controlName === 'adminApproval' && event.checked) {
      this.createRideForm.patchValue({ invitedRidersOnly: false });
    }
  }


  allowMemberPost: boolean | null = null; // Track the state of Yes/No checkbox
  onCheckboxCjhangeVal(value: boolean) {
    this.allowMemberPost = value; // Set the value based on the clicked checkbox
  }

  onCheckboxChangeVal(isYes: boolean): void {
    if (isYes) {
      // If "Yes" is checked, set allowMemberPost to true and allowMemberPostNo to false
      this.createRideForm.patchValue({
        allowMemberPost: true,
        allowMemberPostNo: false
      });
    } else {
      // If "No" is checked, set allowMemberPost to false and allowMemberPostNo to true
      this.createRideForm.patchValue({
        allowMemberPost: false,
        allowMemberPostNo: true
      });
    }
  }

  isYesChecked = false;
  isNoChecked = false;

  // Called when a checkbox is clicked
  onCheckboxChangedata(value: boolean): void {
    if (value) {
      // Yes is checked
      this.isYesChecked = true;
      this.isNoChecked = false;
    } else {
      // No is checked
      this.isYesChecked = false;
      this.isNoChecked = true;
    }

    // You can also update your form control if needed
    this.createRideForm.controls['allowMemberPost'].setValue(value);
  }
  // onRestrictionChange(controlName: string, event: any): void {
  //   // if (controlName === 'invitedRidersOnly' && event.value === true) {
  //   //   this.createRideForm.patchValue({ adminApproval: false });
  //   // } else if (controlName === 'adminApproval' && event.value === true) {
  //   //   this.createRideForm.patchValue({ invitedRidersOnly: false });
  //   // }


  // }

  // Password Hide
  hide = true;
  // Form
  createRideForm: FormGroup;
  onFileSelected(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.createRideForm.patchValue({
          [controlName]: base64String
        });
        console.log(`${controlName} base64String:`, base64String);
        if (controlName === 'logo') {
          this.logoBase64 = base64String;
        } else if (controlName === 'coverphoto') {
          this.coverPhotoBase64 = base64String; // Assign base64String to cover photo
        }
      };
      reader.readAsDataURL(file);
    }
  }


  onSubmit() {
    if (this.createRideForm.valid) {
      console.log(this.createRideForm.value);
      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }

      if (this.logoBase64 == null) {
        this.logoBase64 = "";
      } else {
        this.logoBase64 = this.logoBase64;
      }


      // Construct the login object
      const requestBody = {
        "amount": this.createRideForm.get('amount')?.value,
        "description": this.createRideForm.get('description')?.value,
        "image": this.coverPhotoBase64, // You may need to handle image upload separately
        "allowMemberPost": this.createRideForm.get('allowMemberPost')?.value,
        "bankAccount": true, // Assuming this is hardcoded to true
        "locationComment": this.createRideForm.get('locationComment')?.value,
        "logo": this.logoBase64, // You may need to handle logo upload separately
        "name": this.createRideForm.get('name')?.value,
        "socialLinks": this.createRideForm.get('socialLinks')?.value,
        "coverphoto": this.coverPhotoBase64, // Use cover photo base64 here
        "url": this.createRideForm.get('url')?.value,
        "la_TypicalPace_ID": this.createRideForm.get('la_TypicalPace_ID')?.value,
        "adminApproval": this.createRideForm.get('adminApproval')?.value,
        "invitedRidersOnly": this.createRideForm.get('invitedRidersOnly')?.value,
        "la_AnnualMileage_ID": this.createRideForm.get('la_AnnualMileage_ID')?.value,
        "cityId": this.createRideForm.get('cityId')?.value,
        "stateId": this.createRideForm.get('stateId')?.value,
        "la_RidingGroup_ID": this.la_RidingGroupID,
        "x": this.createRideForm.get('x')?.value,
        "faceBook": this.createRideForm.get('faceBook')?.value,
        "instagram": this.createRideForm.get('instagram')?.value,
        "ridingLevels": this.createRideForm.get('ridingLevel')?.value,
        "ridingTypes": this.createRideForm.get('ridingType')?.value,
        "groupAdmin": this.createRideForm.get('groupAdmin')?.value,
        "genderIDList": [
          this.createRideForm.get('compisitionID')?.value,
        ],
      };



      var obj = console.log("userobjuserobj", userobj)
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.post(BASE_URL + ApiEndpoints.createGroup, requestBody, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            // alert("Ride updated successfully");
            this.showSuccess("Group updated successfully");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
  
          } else {
            console.error("Unexpected response:", res);
            this.showSuccess("Error, Failed updating");
  
          }
        },
        (error) => {
          if (error.status === 401) {
            console.log("Unauthorized:", error.status);
            this.showSuccess("Unauthorized");
          } else if (error.status === 500) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Internal server error");
          }else if (error.status === 200) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Sorry, Failed updating");
          } else {
            this.showSuccess("Error, Failed updating");
          }
        }
       
       
       
       
       
       
       
       
        
      );

    } else {
      this.createRideForm.markAllAsTouched(); // Mark all fields as touched to display validation errors
      console.log('Form is invalid. Please check the fields.');
    }
  }



  showSuccess(message: string) {
    this.toastService.showToast(message, ToastType.Success);
  }
  

  showError() {
    this.toastService.showToast('An error occurred!', ToastType.Error);
  }

  showWarning() {
    this.toastService.showToast('This is a warning message.', ToastType.Warning);
  }

  // Correctly place the onNoClick method outside the constructor
  onNoClick() {
    this.dialogRef.close();
    // this.loadPurposes()
  }

  ngOnInit(): void {
    this.loadstates();
    this.loadCity();
    this.loadtypicalPaces();
    this.loadRideType();
    this.loadLevel();
    this.loadMileage();
    this.getActivegenders();
    if (this.data && this.data) {
      this.populateForm(this.data);
    }
  }

  populateForm(record: any): void {
    this.createRideForm.patchValue({
      name: record.name,
      description: record.description,
      stateId: record.stateId,
      cityId: record.cityId,
      locationComment: record.locationComment,
      la_TypicalPace_ID: record.la_TypicalPace_ID,
      la_RidingType_ID: record.la_RidingType_ID,
      la_RidingLevel_ID: record.la_RidingLevel_ID,
      la_AnnualMileage_ID: record.la_AnnualMileage_ID,
      invitedRidersOnly: record.invitedRidersOnly,
      adminApproval: record.adminApproval,
      allowMemberPost: record.allowMemberPost,
      amount: record.amount,
      logo: record.logo,
      coverphoto: record.coverphoto,
      url: record.url,
      socialLinks: record.socialLinks,
      ridingType: record.ridingTypes,
      ridingLevel: record.ridingLevels
    });
  }



  loadstates() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
      (data: any) => {
        this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadCity() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
      (data: any) => {
        this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadtypicalPaces() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveridingPaceList, { headers: headers }).subscribe(
      (data: any) => {
        this.typicalpaces = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  loadRideType() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveridingTypeList, { headers: headers }).subscribe(
      (data: any) => {
        this.rideTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadLevel() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActiveridingLevelList, { headers: headers }).subscribe(
      (data: any) => {
        this.levels = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }



  loadMileage() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getActiveAnnualMileage, { headers: headers }).subscribe(
      (data: any) => {
        this.mileages = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  getActivegenders() {
    this.http.get(BASE_URL + ApiEndpoints.getActiveGender).subscribe(
      (data: any) => {
        this.genders = data; // Assuming data is an array of objects with properties 'id' and 'name'
        console.log('paces data:', data);
      },
      error => {
        console.log('Error fetching pace:', error);
      }
    );
  }

}
