import {Component, ViewChild} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from "@angular/material/card";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatPaginator} from "@angular/material/paginator";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-route-ride-comparison-table',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatMenu,
    MatMenuItem,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatTable,
    NgIf,
    MatMenuTrigger,
    MatHeaderCellDef
  ],
  templateUrl: './route-ride-comparison-table.component.html',
  styleUrl: './route-ride-comparison-table.component.scss'
})
export class RouteRideComparisonTableComponent {
  displayedColumns: string[] = ['date', 'rideName', 'distanceCovered', 'avgSpeed', 'avgRideDuration', 'movingTime','members'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

}

export interface PeriodicElement {
  date: any;
  rideName: string;
  distanceCovered: string;
  avgSpeed: string;
  avgRideDuration: string;
  movingTime: string;
  members:  number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    date: '08/05/2024',
    rideName: 'Campton Hills Double',
    distanceCovered: '$9 miles',
    avgSpeed: '8.2 mi/h',
    avgRideDuration:'1:03:21',
    movingTime:'52:06',
    members:9
  },

];

