<mat-card
    class="trinta-card total-orders-card mb-25 bg-white border-none d-block"
>
    <mat-card-content>
        <span class="sub-title text-gray d-block">
            Total Orders
        </span>
        <h3>
            25.47k
        </h3>
        <span class="trending down d-inline-block fw-medium position-relative">
            5.20% <i class="ri-arrow-down-s-line"></i>
        </span>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [plotOptions]="chartOptions.plotOptions!"
                [labels]="chartOptions.labels!"
                [colors]="chartOptions.colors!"
                [stroke]="chartOptions.stroke!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>