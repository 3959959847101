import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';

import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from "@angular/material/icon";


@Component({
  selector: 'app-upcomingeventsdeleteconfirm',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIcon],
  templateUrl: './upcomingeventsdeleteconfirm.component.html',
  styleUrl: './upcomingeventsdeleteconfirm.component.scss'
})
export class UpcomingeventsdeleteconfirmComponent {

  constructor(public dialogRef: MatDialogRef<UpcomingeventsdeleteconfirmComponent>) {}

  onCancel(): void {
    this.dialogRef.close(false);  // Close and return false
  }

  onNoClick() {
    this.dialogRef.close();
  }



  onConfirm(): void {
    this.dialogRef.close(true);  // Close and return true
  }
}
