<mat-card
    class="trinta-card terms-conditions-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Terms & Conditions
            </h5>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h6>
            Desktop, Email, Chat, Purchase Notifications
        </h6>
        <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
        </p>
        <h6>
            Backup Codes
        </h6>
        <p>
            Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of lorem ipsum.
        </p>
        <h6>
            Two-Factor Authentication
        </h6>
        <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of.
        </p>
        <h6>
            Secondary Verification
        </h6>
        <p>
            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.
        </p>
        <h6>
            Delete This Account :
        </h6>
        <p>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
        </p>
    </mat-card-content>
</mat-card>