<mat-card
    class="trinta-card payment-history-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Payment History
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-dot-btn lh-1 p-0 min-w-auto h-auto" [matMenuTriggerFor]="cardHeaderMenu">
                <i class="ri-more-2-fill"></i>
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="payment-history-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">

                    <!-- Card Column -->
                    <ng-container matColumnDef="card">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Card
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0">
                            <div class="card-info d-flex align-items-center">
                                <div class="image">
                                    <img [src]="element.card.cardImg" alt="card-image">
                                </div>
                                <div class="info">
                                    <h6 class="mt-0 fw-medium">
                                        {{element.card.cardNumber}}
                                    </h6>
                                    <span class="d-block text-body">
                                        {{element.card.cardType}}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>
                            Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.date}}
                        </td>
                    </ng-container>

                    <!-- Amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>
                            Amount
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-primary">
                            {{element.amount}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0"></th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.download}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>