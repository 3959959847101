<div class="row">
    <div class="col-xxxl-8">
        <mat-card
            class="trinta-card full-calendar-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <full-calendar [options]="calendarOptions"></full-calendar>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxxl-4 col-lg-5 col-md-12">
        <app-working-schedule></app-working-schedule>
    </div>
</div>