<mat-card
    class="trinta-card contact-leads-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Contact Leads
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                This Week
            </button>
            <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item>
                    This Day
                </button>
                <button mat-menu-item>
                    This Week
                </button>
                <button mat-menu-item>
                    This Month
                </button>
                <button mat-menu-item>
                    This Year
                </button>
            </mat-menu>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <ul class="p-0 m-0 list-unstyled">
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="user d-flex align-items-center">
                    <img src="assets/images/users/user6.jpg" alt="user-image">
                    <div>
                        <h6 class="fw-medium">
                            Benjamin Clark
                        </h6>
                        <span class="d-block text-body">
                            clark&#64;example.com
                        </span>
                    </div>
                </div>
                <span class="trinta-badge fw-medium d-inline-block">
                    Won Lead
                </span>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="user d-flex align-items-center">
                    <img src="assets/images/users/user8.jpg" alt="user-image">
                    <div>
                        <h6 class="fw-medium">
                            Charlotte Lee
                        </h6>
                        <span class="d-block text-body">
                            lee&#64;example.com
                        </span>
                    </div>
                </div>
                <span class="trinta-badge lost fw-medium d-inline-block">
                    Lost Lead
                </span>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="user d-flex align-items-center">
                    <img src="assets/images/users/user4.jpg" alt="user-image">
                    <div>
                        <h6 class="fw-medium">
                            James Walker
                        </h6>
                        <span class="d-block text-body">
                            walker&#64;example.com
                        </span>
                    </div>
                </div>
                <span class="trinta-badge lost fw-medium d-inline-block">
                    Lost Lead
                </span>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="user d-flex align-items-center">
                    <img src="assets/images/users/user7.jpg" alt="user-image">
                    <div>
                        <h6 class="fw-medium">
                            Amelia Rodriguez
                        </h6>
                        <span class="d-block text-body">
                            rodriguez&#64;example.com
                        </span>
                    </div>
                </div>
                <span class="trinta-badge fw-medium d-inline-block">
                    Won Lead
                </span>
            </li>
            <li class="d-sm-flex align-items-center justify-content-between">
                <div class="user d-flex align-items-center">
                    <img src="assets/images/users/user5.jpg" alt="user-image">
                    <div>
                        <h6 class="fw-medium">
                            Mia Garcia
                        </h6>
                        <span class="d-block text-body">
                            garcia&#64;example.com
                        </span>
                    </div>
                </div>
                <span class="trinta-badge converted fw-medium d-inline-block">
                    Converted
                </span>
            </li>
        </ul>
    </mat-card-content>
</mat-card>