import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NgIf } from '@angular/common';
@Component({
  selector: 'app-waitlist',
  standalone: true,
  imports: [MatCardModule, MatMenuModule, NgIf, MatButtonModule, RouterLink, MatTableModule, MatPaginatorModule],
  templateUrl: './waitlist.component.html',
  styleUrl: './waitlist.component.scss'
})
export class WaitlistComponent {
  displayedColumns: string[] = ['user', 'status'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }
}



export interface PeriodicElement {
  user: any;
  longestride: string;
  allrides: string;
  groupride: string;
  status: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    user: {
          img: 'assets/pr1.png',
          title: 'Comforta Armchair',
          date: 'Dec 16, 08:30 PM'
      },
      longestride: '508km',
      allrides: '32',
      groupride: '10',
      status: {
        pending: 'Approve',
          // delivered : 'Delivered'
      }
  },
  {
      user: {
          img: 'assets/pr2.png',
          title: 'Comforta Sofa EDM',
          date: 'Dec 15, 02:20 PM'
      },
      longestride: '400km',
      allrides: '26',
      groupride: '6',
      status: {
          // pending: 'Pending',
          pending : 'Approve'
      }
  },
  {
      user: {
          img: 'assets/pr3.png',
          title: 'Electric Bicycle',
          date: 'Dec 14, 10:00 AM'
      },
      longestride: '240km',
      allrides: '23',
      groupride: '7',
      status: {
          // pending: 'Pending',
          pending : 'Approve'
      }
  },
  {
      user: {
          img: 'assets/pr4.png',
          title: 'Sport Shoes',
          date: 'Dec 13, 03:43 AM'
      },
      longestride: '233km',
      allrides: '21',
      groupride: '8',
      status: {
          pending: 'Approve',
          // delivered : 'Delivered'
      }
  },
];