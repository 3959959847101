<div class="row">
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card pricing-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <h3>
                            Basic Plan
                        </h3>
                        <p class="text-black">
                            A simple start for everyone
                        </p>
                    </div>
                    <div>
                        <img src="assets/images/icons/shield.svg" alt="icon">
                    </div>
                </div>
                <div class="price lh-1 fw-semibold">
                    $50/ <span class="fs-14 text-body fw-normal">per month</span>
                </div>
                <button type="button" mat-button>
                    By This Plan
                </button>
                <ul class="features-list mb-0 p-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Access to essential angular 17 functionalities.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Limited usage or usage for a single user.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Basic customer support through email or chat.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Response within a specified time frame.
                    </li>
                    <li class="position-relative text-black close">
                        <i-feather name="x"></i-feather>
                        Limited storage space for user data.
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card pricing-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <h3>
                            Standard Plan
                        </h3>
                        <p class="text-black">
                            A simple start for everyone
                        </p>
                    </div>
                    <div>
                        <img src="assets/images/icons/shield.svg" alt="icon">
                    </div>
                </div>
                <div class="price lh-1 fw-semibold">
                    $120/ <span class="fs-14 text-body fw-normal">per month</span>
                </div>
                <button type="button" mat-button>
                    By This Plan
                </button>
                <ul class="features-list mb-0 p-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        More advanced features than the basic plan.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Expanded capabilities for increased productivity.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Faster response times.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Access to a dedicated support channel.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Access to in-depth analytics and reporting tools.
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card pricing-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <h3>
                            Premium Plan
                        </h3>
                        <p class="text-black">
                            A simple start for everyone
                        </p>
                    </div>
                    <div>
                        <img src="assets/images/icons/shield.svg" alt="icon">
                    </div>
                </div>
                <div class="price lh-1 fw-semibold">
                    $189/ <span class="fs-14 text-body fw-normal">per month</span>
                </div>
                <button type="button" mat-button>
                    By This Plan
                </button>
                <ul class="features-list mb-0 p-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Includes all features from the standard plan.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        No restrictions on data storage.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Tailored solutions to meet specific needs.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Advanced security measures to protect sensitive data.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Compatibility with a wide range of third-party apps and services.
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6 col-xxl-4 col-xxxl-3">
        <mat-card
            class="trinta-card pricing-card mb-25 bg-white border-none d-block"
        >
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <h3>
                            Additional Considerations
                        </h3>
                        <p class="text-black">
                            A simple start for everyone
                        </p>
                    </div>
                    <div>
                        <img src="assets/images/icons/shield.svg" alt="icon">
                    </div>
                </div>
                <div class="price lh-1 fw-semibold">
                    Free <span class="fs-14 text-body fw-normal">per month</span>
                </div>
                <button type="button" mat-button>
                    By This Plan
                </button>
                <ul class="features-list mb-0 p-0 list-unstyled">
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Offer a free trial period for users to experience the product/service.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Provide a discount for customers who choose to pay annually.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Clearly outline how the pricing scales as the user's needs grow.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Clearly communicate any additional fees or charges.
                    </li>
                    <li class="position-relative text-black">
                        <i-feather name="check"></i-feather>
                        Limited storage space for user data.
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>
</div>