<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Utilities
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item position-relative">
            UI Elements
        </li>
        <li class="breadcrumb-item position-relative">
            Utilities
        </li>
    </ol>
</div>

<!-- Utilities -->
<mat-card class="trinta-card mb-25 bg-white border-none d-block">
    <mat-card-content>
        <h6 class="mb-0">
            Please go to the <code>src/_utilities.scss</code> file. You will find all default CSS class names.
        </h6>
    </mat-card-content>
</mat-card>