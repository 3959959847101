<div class="reset-password-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div
                class="trinta-form ml-auto mr-auto bg-white border-radius"
            >
                <div class="title text-center">
                    <h3>
                        Reset Password
                    </h3>
                    <p class="text-black fs-15">
                        Enter the email address you used when you joined and we'll send you instructions to reset your password.
                    </p>
                </div>
                <form>
                    <div class="form-group">
                        <label class="label d-block fw-medium">
                            Your Password
                        </label>
                        <mat-form-field>
                            <i-feather name="lock"></i-feather>
                            <mat-label>Enter your password</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <span class="material-symbols-outlined">
                                    {{hide ? 'visibility_off' : 'visibility'}}
                                </span>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <label class="label d-block fw-medium">
                            Confirm Password
                        </label>
                        <mat-form-field>
                            <i-feather name="lock"></i-feather>
                            <mat-label>Enter password</mat-label>
                            <input matInput [type]="hide2 ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                <span class="material-symbols-outlined">
                                    {{hide2 ? 'visibility_off' : 'visibility'}}
                                </span>
                            </button>
                        </mat-form-field>
                    </div>
                    <button mat-button type="submit">
                        Set New Password
                    </button>
                    <div class="text-center">
                        <a routerLink="/authentication" class="text-primary">
                            Back To Sign In
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>