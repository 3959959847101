<mat-form-field>
    <i-feather name="list"></i-feather>
    <mat-label>Pokemon</mat-label>
    <mat-select [formControl]="pokemonControl">
        <mat-option>-- None --</mat-option>
        @for (group of pokemonGroups; track group) {
            <mat-optgroup [label]="group.name" [disabled]="group.disabled">
                @for (pokemon of group.pokemon; track pokemon) {
                    <mat-option [value]="pokemon.value">{{pokemon.viewValue}}</mat-option>
                }
            </mat-optgroup>
        }
    </mat-select>
</mat-form-field>