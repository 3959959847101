import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CustomValidators } from './custom-validators'; // Adjust the import path accordingly
import { MatButton } from '@angular/material/button';
import { MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '../../../app/constants';
import { MatCardModule } from '@angular/material/card';
import { HttpHeaders } from '@angular/common/http';
import { ApiEndpoints } from '../../../app/api-endpoints';
import { FormsModule } from '@angular/forms';  // <-- Import FormsModule here
import { MatDialog } from '@angular/material/dialog';
import { SigninmessageComponent } from '../signinmessage/signinmessage.component';



@Component({
    selector: 'app-sign-in',
    standalone: true,
    imports: [
        MatButton,
        MatIconButton,
        MatFormFieldModule,
        MatInputModule,
        FeathericonsModule,
        MatCheckboxModule,
        HttpClientModule, FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatCardModule
    ],
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
    authForm: FormGroup;
    hide = true;
    // hide = true;
    hide2 = true;
    hide3 = true;
    rememberMe: boolean = false;

    passwordConditions = [
        { condition: 'Minimum 8 characters', isValid: false },
        { condition: 'At least one uppercase letter', isValid: false },
        { condition: 'At least one lowercase letter', isValid: false },
        { condition: 'At least one numerical digit', isValid: false }
    ];

    constructor(private http: HttpClient, private router: Router, private fb: FormBuilder, private dialog: MatDialog) {
        this.authForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator.bind(this)]],
            rememberMe: ['']
        });

        this.authForm.get('password')?.valueChanges.subscribe(value => {
            this.passwordConditions[0].isValid = value.length >= 8;
            this.passwordConditions[1].isValid = /[A-Z]/.test(value);
            this.passwordConditions[2].isValid = /[a-z]/.test(value);
            this.passwordConditions[3].isValid = /\d/.test(value);
        });
    }

    ngOnInit(): void {

        localStorage.setItem("no", "0");
        const isRememberMe = localStorage.getItem("isremeberme");  // Use camelCase for consistency
        const remeberme = localStorage.getItem("remeberme");  // Use camelCase for consistency
        console.log('Remember Me:', isRememberMe);
        if (remeberme === "1") {
            console.log('Remember Me is set to true');
            this.router.navigate(['/members']);
        } else if (isRememberMe === "2") {
            console.log('Remember Me is set to false');
            localStorage.setItem("remeberme", "2");  // Use camelCase for consistency

        } else {
            console.log('Remember Me is not set or has an unexpected value');
        }

    }


    showerrorMessage(): void {
        const dialogRef = this.dialog.open(SigninmessageComponent, {
            width: '400px',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                
                // alert("You are not a group admin. Do you want to create one?");
                // this.router.navigate(['/authentication/registergroup']);
            window.open('https://lacorsa.app/group-membership', '_blank');

            }
        });
    }


    onRememberMeChange(event: any): void {
        this.rememberMe = event.checked;

        if (this.rememberMe == true) {
            localStorage.setItem("remeberme", "1");  // Use camelCase for consistency

        } else {
            localStorage.setItem("remeberme", "2");  // Use camelCase for consistency
        }


        localStorage.setItem("isremeberme", String(this.rememberMe));


    }



    passwordValidator(control: any) {
        const value = control.value || '';
        const conditionsMet = [
            value.length >= 8,
            /[A-Z]/.test(value),
            /[a-z]/.test(value),
            /\d/.test(value)
        ];
        return conditionsMet.every(cond => cond) ? null : { passwordStrength: true };
    }

    onSubmit() {
        if (this.authForm.valid) {
            const username = this.authForm.get('email')?.value;
            const password = this.authForm.get('password')?.value;

            const loginObj = {
                username: username,
                password: password
            };

            this.http.post(BASE_URL + 'accounts/login', loginObj).subscribe(
                (res: any) => {
                    if (res) {


                        const remeberme = localStorage.getItem("remeberme");  // Use camelCase for consistency
                        console.log('Remember Me:', remeberme);
                        if (remeberme === null) {
                            console.log('Remember Me is set to false');
                            localStorage.setItem("remeberme", "2");  // Use camelCase for consistency
                            // this.router.navigate(['/authentication']);

                        } else {
                            // console.log('Remember Me is not set or has an unexpected value');
                        }

                        console.log("Login successful:", res);
                        localStorage.setItem('angular17token', res.token);
                        localStorage.setItem('userObj', JSON.stringify(res));
                        this.fetchUserGroupsByUser();

                    } else {
                        console.error("Unexpected response:", res);
                        alert("Login failed");
                    }
                },
                (error) => {
                    if (error.status === 401) {
                        console.log("Unauthorized:", error.status);
                        alert("Unauthorized: Invalid username or password");
                    } else if (error.status === 500) {
                        console.log("Internal server error:", error.status);
                        alert("Internal server error");
                    } else {
                        console.error("Error logging in:", error);
                        alert("Login failed. Please try again later.");
                    }
                }
            );
        } else {
            this.authForm.markAllAsTouched();
            console.log('Form is invalid. Please check the fields.');
        }
    }



    fetchUserGroupsByUser(): void {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
        this.http.get(BASE_URL + ApiEndpoints.getGroupsCreatedByUser, { headers: headers }).subscribe(
            (data: any) => {
                if (data.length >= 1) {
                    this.router.navigate(['/members']);
                    localStorage.setItem('selectedGroupId', data[0].la_RidingGroup_ID);
                } else {
                   
                    this.showerrorMessage();
                }
            },
            error => {
                alert("Sorry! You are have not joined any group")
            }
        );
    }
}
