import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { FeatherModule } from "angular-feather";
import { MatIcon } from "@angular/material/icon";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatOption } from "@angular/material/autocomplete";
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ToastService, ToastType } from '../../../../app/toast.service'; // adjust the path accordingly
import { TimezoneService } from '../../../timezone'; // Adjust the path as needed



@Component({
  selector: 'app-upcomingeventseditdraft',
  standalone: true,
  imports: [  ReactiveFormsModule,
    MatFormField,
    MatRadioGroup,
    MatRadioButton,
    MatInput,
    MatDialogModule,
    MatButtonModule,
    MatLabel,
    MatIcon,
    MatSlideToggle,
    MatOption,
    MatSelect,
    MatTooltip,
    MatSuffix,
    HttpClientModule,
    CommonModule,
    RouterLink,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,],
    providers: [provideNativeDateAdapter()],

  templateUrl: './upcomingeventseditdraft.component.html',
  styleUrl: './upcomingeventseditdraft.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // <--- Add this to allow custom elements

})
export class UpcomingeventseditdraftComponent implements OnInit {
  eventTypes: any[] = [];
  logoBase64: string | null = null;
  displayDate: string | null = null;
  displayEndDate: string | null = null;
  minDate: Date; // Add this property to set the minimum date
  createEventForm: FormGroup;
  selectedDate: Date;
  isstartdate: boolean = false;
  issenddate: boolean = false;
  members: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  genders: any[] = [];
  dateError: string | null = null;
  formattedDate:  any;
  userTimeZone: string;


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpcomingeventseditdraftComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastService: ToastService,
    private timezoneService: TimezoneService


  ) {
    this.minDate = new Date(); // Set minDate to today's date

    this.createEventForm = this.fb.group({
      upcomingEventId: [this.data?.upcomingEventId || '', Validators.required],
      title: [this.data?.title || '', Validators.required],
      description: [this.data?.description || '', Validators.required], // Assuming optional
      amount: [this.data?.amount, Validators.required],// Assuming optional
      la_Event_Type_ID: 1000000,
      url: [this.data?.url || ''],
      longitude: [''] ,
      latitude: [''], // Assuming optional
      location: [this.data?.location || ''],
      image: [''] ,
      date: [this.data?.date || '', [Validators.required]],
      isRecurrent: [this.data?.isRecurrent], // Assuming optional
      organizer: [this.data?.organizer || '', [Validators.required]],
      time: [this.data?.time || '', [Validators.required]],
      groupSize: [this.data?.groupSize || '', [Validators.required]],
      address: [this.data?.address],
      endDate: [this.data?.endDate || '', [Validators.required]],// Add default values or validators as needed
      stateId: [this.data?.stateId || '', Validators.required],
      cityId: [this.data?.cityId || '', Validators.required],
    }, { validator: this.dateRangeValidator });
    // this.dateAdapter.setLocale('en-US'); // Set the locale to en-US to ensure the date format

    this.createEventForm.get('date')?.valueChanges.subscribe(() => {
      // this.validateDates();
    });
    this.createEventForm.get('endDate')?.valueChanges.subscribe(() => {
      // this.validateDates();
    });
    // this.dateAdapter.setLocale('en-US'); // Set the locale to en-US to ensure the date format
  }



  validateDates() {
    const startDate = this.createEventForm.get('date')?.value;
    const endDate = this.createEventForm.get('endDate')?.value;

    if (startDate && endDate && startDate > endDate) {
      // Display error message and clear end date
      this.dateError = 'End date cannot be earlier than the start date.';
      this.createEventForm.get('endDate')?.setValue(null);
    } else {
      this.dateError = null;
    }
  }
  

  dateRangeValidator(formGroup: FormGroup) {
    const startDate = formGroup.get('date')?.value;
    const endDate = formGroup.get('endDate')?.value;
    return startDate && endDate && startDate > endDate ? { dateRangeInvalid: true } : null;
  }

  ngOnInit(): void {
  this.loadeventTypes();
  this.listmembers();
  this.loadstates();
  this.loadCity();
  }


  loadstates() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
      (data: any) => {
        this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  loadCity() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
      (data: any) => {
        this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  listmembers() {
    const storedGroupId = localStorage.getItem('selectedGroupId');

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getGroupMembers+storedGroupId, { headers: headers }).subscribe(
      (data: any) => {
        console.log("My getGroupMembers", data.entity[0])
        this.members = data.entity; // Assuming data is an array of objects with properties 'value' and 'label'
      },


      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  loadeventTypes() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.eventTypes, { headers: headers }).subscribe(
      (data: any) => {
        this.eventTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  onSave(): void {
    // Handle save action
    this.dialogRef.close(this.createEventForm.value);
  }

  CancelEvent(){
    console.log("test")
    const id = this.data?.upcomingEventId;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.post(BASE_URL + ApiEndpoints.cancelEvent+id, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            alert("Event canceled successfully");
            window.location.reload();
          } else {
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Unauthorized");
          } else if (error.status === 500) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Internal server error");
          } else {
            // Handle other errors
            console.error("Error logging in:", error);
            alert("Failed. Please try again later.");
          }
        }
      );

  
  }


  autojoinEvent(eventid: String) {

      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }
    var jsondata = {
      "cbPartner_id": cbPartnerId,
      "upcomingEvent_id": eventid
  
    }
    var obj =
    console.log("userobjuserobj", userobj)
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));

    this.http.post(BASE_URL + ApiEndpoints.joinEvent, jsondata, { headers: headers }).subscribe(
      (res: any) => {
       
      },
      (error) => {
       
      }
    );

  }

  postEvent(){
    if (this.createEventForm.valid) {
      console.log(this.createEventForm.value);
      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }
      if(this.isstartdate == true){

        this.displayDate = this.displayDate;
      }else{
        this.displayDate = this.data.date;

      }


      if(this.issenddate == true){

        this.displayEndDate = this.displayDate;
      }else{
        this.displayEndDate = this.data.endDate;

      }

      

      this.userTimeZone = this.timezoneService.getUserTimeZone();



      const storedGroupId = localStorage.getItem('selectedGroupId');

      // Construct the login object
      const requestBody = {
        "upcomingEventId":this.data.upcomingEventId,
        "title": this.createEventForm.get('title')?.value,
        "description": this.createEventForm.get('description')?.value,
        "image": this.logoBase64, // You may need to handle image upload separately
        "longitude": 0,
        "latitude": 0, // Assuming this is hardcoded to true
        "location": this.createEventForm.get('location')?.value,
        "amount":  this.createEventForm.get('amount')?.value, // You may need to handle logo upload separately
        "date": this.formatDate(this.createEventForm.get('date')?.value) +"T"+this.createEventForm.get('time')?.value + ":00",
        "la_Event_Type_ID": this.createEventForm.get('la_Event_Type_ID')?.value,
        "isRecurrent": this.createEventForm.get('isRecurrent')?.value,
        "url": this.createEventForm.get('url')?.value,
        "organizer": this.createEventForm.get('organizer')?.value,
        "time": this.formatDate(this.createEventForm.get('date')?.value)+"T"+this.createEventForm.get('time')?.value + ":00",
        "address": this.createEventForm.get('address')?.value,
        "endDate": this.displayEndDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "groupSize": this.createEventForm.get('groupSize')?.value,
        "la_ridinggroup_id": storedGroupId,
        "cityId": this.createEventForm.get('cityId')?.value,
        "stateId": this.createEventForm.get('stateId')?.value,
        "timeZone": this.userTimeZone

      };


      


      // var obj =
      console.log("userobjuserobj", userobj)
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.post(BASE_URL + ApiEndpoints.createEvent+"?is_draft=false", requestBody, { headers: headers }).subscribe(
       (res: any) => {
          if (res) {
            if (res) {
              this.showSuccess("Your posted successfully");
  
              console.log("autojoinEventautojoinEvent", res.upcomingEventId)
              this.autojoinEvent(res.upcomingEventId);
              // window.location.reload();
              setTimeout(() => {
                window.location.reload();
                // this.dialogRef.close(this.createRideForm.value);
              }, 2000);
    
            } else {
              console.error("Unexpected response:", res);
              this.showSuccess("Error, Failed");
    
            }
           
          } else {
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            console.log("Unauthorized:", error.status);
            this.showSuccess("Unauthorized: Invalid username or password");
  
          } else if (error.status === 500) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Internal server error");
          }else if (error.status === 200) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Sorry, the group doesn't allow members to post.");
          } else {
            this.showSuccess("Error creating ride failed. Please try again later.");
          }
        }
      );

    } else {

      console.log('Form is invalid. Please check the fields.');
    }
  }
  onSubmit() {
    if (this.createEventForm.valid) {
      console.log(this.createEventForm.value);
      var cbPartnerId = "";
      var userobj = localStorage.getItem("userObj");
      if (userobj) {
        try {
          var parsedUserObj = JSON.parse(userobj);

          cbPartnerId = parsedUserObj.cbPartnerId;
          console.log(parsedUserObj);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
        }
      } else {
        console.warn("No userObj found in localStorage.");
      }
      if(this.isstartdate == true){

        this.displayDate = this.displayDate;
      }else{
        this.displayDate = this.data.date;

      }

      if(this.issenddate == true){

        this.displayEndDate = this.displayEndDate;
      }else{
        this.displayEndDate = this.data.endDate;

      }


      const storedGroupId = localStorage.getItem('selectedGroupId');

      // Construct the login object
      const requestBody = {
        "upcomingEventId":this.data.upcomingEventId,
        "title": this.createEventForm.get('title')?.value,
        "description": this.createEventForm.get('description')?.value,
        "image": this.logoBase64, // You may need to handle image upload separately
        "longitude": 0,
        "latitude": 0, // Assuming this is hardcoded to true
        "location": this.createEventForm.get('location')?.value,
        "amount":  this.createEventForm.get('amount')?.value, // You may need to handle logo upload separately
        "date": this.formatDate(this.createEventForm.get('date')?.value) +"T"+this.createEventForm.get('time')?.value + ":00",
        "la_Event_Type_ID": this.createEventForm.get('la_Event_Type_ID')?.value,
        "isRecurrent": this.createEventForm.get('isRecurrent')?.value,
        "url": this.createEventForm.get('url')?.value,
        "organizer": this.createEventForm.get('organizer')?.value,
        "time": this.formatDate(this.createEventForm.get('date')?.value)+"T"+this.createEventForm.get('time')?.value + ":00",
        "address": this.createEventForm.get('address')?.value,
        "endDate": this.displayEndDate +"T"+this.createEventForm.get('time')?.value + ":00",
        "groupSize": this.createEventForm.get('groupSize')?.value,
        "la_ridinggroup_id": storedGroupId,
        "cityId": this.createEventForm.get('cityId')?.value,
        "stateId": this.createEventForm.get('stateId')?.value,
        "timeZone": this.userTimeZone
      };



      // var obj =
      console.log("userobjuserobj", userobj)
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.post(BASE_URL + ApiEndpoints.createEvent+"?is_draft=true", requestBody, { headers: headers }).subscribe(
        (res: any) => {
          if (res) {
            if (res) {
              this.showSuccess("Draft updated successfully");
  
              // window.location.reload();
              setTimeout(() => {
                window.location.reload();
                // this.dialogRef.close(this.createRideForm.value);
              }, 2000);
    
            } else {
              console.error("Unexpected response:", res);
              this.showSuccess("Error, Failed");
    
            }
           
          } else {
            console.error("Unexpected response:", res);
            alert("Error, Failed");
          }
        },
        (error) => {
          if (error.status === 401) {
            console.log("Unauthorized:", error.status);
            this.showSuccess("Unauthorized: Invalid username or password");
  
          } else if (error.status === 500) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Internal server error");
          }else if (error.status === 200) {
            console.log("Internal server error:", error.status);
            this.showSuccess("Sorry, the group doesn't allow members to post.");
          } else {
            this.showSuccess("Error creating ride failed. Please try again later.");
          }
        }
      
      );


    } else {

      console.log('Form is invalid. Please check the fields.');
    }
  }

  onDateChange(event: any): void {
    const date = event.value;
    // if (date) {
    //   const formattedDate = this.formatDate(date);
    //   this.displayDate = formattedDate;
    //   console.log("formattedDate", formattedDate)
    //   this.createEventForm.get('date')?.setValue(date);
    //   this.isstartdate = true;
    // }
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onDateChangeEnddate(event: any): void {
    const date = event.value;
    // if (date) {
    //   const formattedEndDate = this.formatDate(date);
    //   this.displayEndDate = formattedEndDate;
    //   console.log("formattedEndDate", formattedEndDate)
    //   this.createEventForm.get('endDate')?.setValue(date);
    //   this.issenddate = true;
    // }
  }

  onFileSelected(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        this.createEventForm.patchValue({
          [controlName]: base64String
        });
        console.log(`${controlName} base64String:`, base64String);
        if (controlName === 'logo') {
          this.logoBase64 = base64String;
        } 
      };
      reader.readAsDataURL(file);
    }
  }



  showSuccess(message: string) {
    this.toastService.showToast(message, ToastType.Success);
  }
  

  showError() {
    this.toastService.showToast('An error occurred!', ToastType.Error);
  }

  showWarning() {
    this.toastService.showToast('This is a warning message.', ToastType.Warning);
  }

}

