import { Component, OnInit } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink, Router } from '@angular/router';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { BASE_URL } from '../../../app/constants';
import { ApiEndpoints } from '../../../app/api-endpoints';
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatTooltip } from "@angular/material/tooltip";
import { CommonModule } from '@angular/common';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { imageIcon, SVGIcon } from "@progress/kendo-svg-icons";




@Component({
    selector: 'app-sign-up',
    standalone: true,
    imports: [
        MatButton, MatStepperModule, MatButtonModule, MatFormField, MatSlideToggleModule,
        MatIconButton, CommonModule, MatInput,
        MatFormFieldModule, MatOption,
        MatInputModule,
        FeathericonsModule,
        MatCheckboxModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgIf,
        MatCardModule,
        FormsModule,
        MatFormField,
        MatRadioGroup, FileUploadModule,
        MatRadioButton,
        MatInput,
        MatLabel,
        MatSelect,
        MatTooltip,
        HttpClientModule,
        CommonModule,
        RouterLink

    ],

    templateUrl: './sign-up.component.html',
    styleUrl: './sign-up.component.scss'
})
export class SignUpComponent implements OnInit {
    public value: Date;
    public imageSVG: SVGIcon = imageIcon;
    typicalpaces: any[] = [];
    rideTypes: any[] = [];
    levels: any[] = [];
    mileages: any[] = [];
    logoBase64: string | null = null;
    coverPhotoBase64: string | null = null; // Added cover photo base64
    isSaving: boolean = false; // Track the saving stat
    states: any[] = [];
    cities: any[] = [];
    genders: any[] = [];
  
  
  
    public multiple: boolean = false;
  
    constructor(
      private fb: FormBuilder,
      private http: HttpClient,
      private router: Router,
    ) {
      this.createRideForm = this.fb.group({
        name: ['', [Validators.required]],
        description: ['', Validators.required],
        la_TypicalPace_ID: ['', Validators.required], // Assuming required
        ridingType: ['', Validators.required], // Assuming required
        ridingLevel: ['', Validators.required], // Assuming required
        la_AnnualMileage_ID: ['', Validators.required], // Assuming required
        invitedRidersOnly: [false], // Assuming optional
        adminApproval: [false], // Assuming optional
        allowMemberPost: [false], // Assuming optional
        amount: [''], // Assuming required and must be >= 0
        logo: [''], // Assuming optional
        coverphoto: [''], // Assuming optional
        locationComment: [''], // Assuming optional
        url: [''],  // Assuming optional
        stateId: ['', Validators.required],
        cityId: ['', Validators.required],
        socialLinks: [''],
        x: [''],
        instagram: [''],
        faceBook: [''],
        groupAdmin: [''],
        compisitionID: [''],
      });
    }
  
  
    // Password Hide
    hide = true;
  
    // Form
    createRideForm: FormGroup;
  
    onFileSelected(event: Event, controlName: string): void {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length) {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          this.createRideForm.patchValue({
            [controlName]: base64String
          });
          console.log(`${controlName} base64String:`, base64String);
          if (controlName === 'logo') {
            this.logoBase64 = base64String;
          } else if (controlName === 'coverphoto') {
            this.coverPhotoBase64 = base64String; // Assign base64String to cover photo
          }
        };
        reader.readAsDataURL(file);
      }
    }
  
    onCheckboxChange(controlName: string, event: any): void {
      // Check or uncheck based on the selected option
      if (controlName === 'invitedRidersOnly' && event.checked) {
        this.createRideForm.patchValue({ adminApproval: false });
      } else if (controlName === 'adminApproval' && event.checked) {
        this.createRideForm.patchValue({ invitedRidersOnly: false });
      }
    }
    allowMemberPost: boolean | null = null; // Track the state of Yes/No checkbox
  
  
  
    onCheckboxChangeVal(value: boolean) {
      this.allowMemberPost = value; // Set the value based on the clicked checkbox
    }
    // onRestrictionChange(controlName: string, event: any): void {
    //   if (controlName === 'invitedRidersOnly' && event.value === true) {
    //     this.createRideForm.patchValue({ adminApproval: false });
    //   } else if (controlName === 'adminApproval' && event.value === true) {
    //     this.createRideForm.patchValue({ invitedRidersOnly: false });
    //   }
    // }
  
    onSubmit() {
      this.isSaving = true;
  
      if (this.createRideForm.valid) {
  
        // Disable the button and change the text
  
        // console.log(this.createRideForm.value);
        var cbPartnerId = "";
        var userobj = localStorage.getItem("userObj");
        if (userobj) {
          try {
            var parsedUserObj = JSON.parse(userobj);
  
            cbPartnerId = parsedUserObj.cbPartnerId;
            console.log(parsedUserObj);
          } catch (error) {
            console.error("Error parsing JSON from localStorage:", error);
          }
        } else {
          console.warn("No userObj found in localStorage.");
        }
  
        if (this.logoBase64 == null) {
          this.logoBase64 = "";
        } else {
          this.logoBase64 = this.logoBase64;
        }
  
        if (this.coverPhotoBase64 == null) {
          this.coverPhotoBase64 = "";
        } else {
          this.coverPhotoBase64 = this.coverPhotoBase64;
        }
  
  
  
  
        // Construct the login object
        const requestBody = {
          "amount": this.createRideForm.get('amount')?.value,
          "description": this.createRideForm.get('description')?.value,
          "image": this.coverPhotoBase64, // You may need to handle image upload separately
          "allowMemberPost": this.createRideForm.get('allowMemberPost')?.value,
          "bankAccount": true, // Assuming this is hardcoded to true
          "locationComment": "N/AA",
          "logo": this.logoBase64, // You may need to handle logo upload separately
          "name": this.createRideForm.get('name')?.value,
          "socialLinks": this.createRideForm.get('socialLinks')?.value,
          "coverphoto": this.coverPhotoBase64, // Use cover photo base64 here
          "url": this.createRideForm.get('url')?.value,
          "la_TypicalPace_ID": this.createRideForm.get('la_TypicalPace_ID')?.value,
          "adminApproval": this.createRideForm.get('adminApproval')?.value,
          "invitedRidersOnly": this.createRideForm.get('invitedRidersOnly')?.value,
          "la_AnnualMileage_ID": this.createRideForm.get('la_AnnualMileage_ID')?.value,
          "cityId": this.createRideForm.get('cityId')?.value,
          "stateId": this.createRideForm.get('stateId')?.value,
          "la_RidingGroup_ID": 0,
          "x": this.createRideForm.get('x')?.value,
          "faceBook": this.createRideForm.get('faceBook')?.value,
          "instagram": this.createRideForm.get('instagram')?.value,
          "ridingLevels": this.createRideForm.get('ridingLevel')?.value,
          "ridingTypes": this.createRideForm.get('ridingType')?.value,
          "groupAdmin": this.createRideForm.get('groupAdmin')?.value,
          "genderIDList": [
            this.createRideForm.value.compisitionID || 0,
          ],
        };
  



        // {
        //   "ridingLevel": [],
        //   "ridingType": []
        // }
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
        this.http.post(BASE_URL + ApiEndpoints.createGroup, requestBody, { headers: headers }).subscribe(
          (res: any) => {
            if (res) {
              alert("Group created successfully");
              // this.router.navigate(['/dashboard']);
            //   window.location.reload();
              this.fetchUserGroupsByUser()
              this.isSaving = false; // Enable the button after saving
  
            } else {
              // Handle other cases where response is not as expected
              console.error("Unexpected response:", res);
              alert("Error, Failed");
            }
          },
          (error) => {
            if (error.status === 401) {
              this.isSaving = false; // Enable the button after saving
              // Handle 401 Unauthorized error
              console.log("Unauthorized:", error.status);
              alert("Unauthorized");
            } else if (error.status === 500) {
              // Handle 401 Unauthorized error
              console.log("Unauthorized:", error.status);
              alert("Internal server error");
              this.isSaving = false; // Enable the button after saving
            } else {
              // Handle other errors
              console.error("Error logging in:", error);
              alert("Failed creating record. Please try again later.");
            }
          }
  
          
        );
        // Simulate save logic or call your API  
      } else {
        this.createRideForm.markAllAsTouched(); // Mark all fields as touched to display validation errors
        console.log('Form is invalid. Please check the fields.');
        setTimeout(() => {
          this.isSaving = false;
        }, 500);
      }
    }
  


  

     
    fetchUserGroupsByUser(): void {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
        this.http.get(BASE_URL + ApiEndpoints.getGroupsCreatedByUser, { headers: headers }).subscribe(
            (data: any) => {
                if (data.length >= 1) {
                    this.router.navigate(['/members']);
                    localStorage.setItem('selectedGroupId', data[0].la_RidingGroup_ID);
                }else{
                    alert("You are not a group admin. Do you want to create one?");
                    this.router.navigate(['/authentication/registergroup']);
                }
            },
            error => {
                alert("Sorry! You are have not joined any group")
            }
        );
    }

    
    // Correctly place the onNoClick method outside the construct
  
    ngOnInit(): void {
      this.loadtypicalPaces();
      this.loadRideType();
      this.loadLevel();
      this.loadMileage();
      this.loadstates();
      this.loadCity();
      this.getActivegenders();
  
    }
  
    getActivegenders() {
      this.http.get(BASE_URL + ApiEndpoints.getActiveGender).subscribe(
          (data: any) => {
              this.genders = data; // Assuming data is an array of objects with properties 'id' and 'name'
  
              console.log('paces data:', data);
  
          },
          error => {
              console.log('Error fetching pace:', error);
          }
      );
  }
  
    loadstates() {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  
      this.http.get(BASE_URL + ApiEndpoints.getActiveStates, { headers: headers }).subscribe(
        (data: any) => {
          this.states = data; // Assuming data is an array of objects with properties 'value' and 'label'
        },
        error => {
          console.log('Error fetching purposes:', error);
        }
      );
  
    }
  
  
    loadCity() {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  
      this.http.get(BASE_URL + ApiEndpoints.getActiveCity, { headers: headers }).subscribe(
        (data: any) => {
          this.cities = data; // Assuming data is an array of objects with properties 'value' and 'label'
        },
        error => {
          console.log('Error fetching purposes:', error);
        }
      );
  
    }
  
  
    loadtypicalPaces() {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  
      this.http.get(BASE_URL + ApiEndpoints.getActiveridingPaceList, { headers: headers }).subscribe(
        (data: any) => {
          this.typicalpaces = data; // Assuming data is an array of objects with properties 'value' and 'label'
        },
        error => {
          console.log('Error fetching purposes:', error);
        }
      );
  
    }
  
    loadRideType() {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  
      this.http.get(BASE_URL + ApiEndpoints.getActiveridingTypeList, { headers: headers }).subscribe(
        (data: any) => {
          this.rideTypes = data; // Assuming data is an array of objects with properties 'value' and 'label'
        },
        error => {
          console.log('Error fetching purposes:', error);
        }
      );
  
    }
  
  
    loadLevel() {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  
      this.http.get(BASE_URL + ApiEndpoints.getActiveridingLevelList, { headers: headers }).subscribe(
        (data: any) => {
          this.levels = data; // Assuming data is an array of objects with properties 'value' and 'label'
        },
        error => {
          console.log('Error fetching purposes:', error);
        }
      );
  
    }
  
  
  
    loadMileage() {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
  
      this.http.get(BASE_URL + ApiEndpoints.getActiveAnnualMileage, { headers: headers }).subscribe(
        (data: any) => {
          this.mileages = data; // Assuming data is an array of objects with properties 'value' and 'label'
        },
        error => {
          console.log('Error fetching purposes:', error);
        }
      );
  
    }
  
  
  
  
  }
  