<div class="row">
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <div class="title">
                    <span class="d-block text-gray">
                        Total Orders
                    </span>
                    <h5 class="m-0">
                        5.88k
                    </h5>
                </div>
                <div class="info d-flex justify-content-center align-items-center">
                    <span class="trending d-block fw-medium position-relative">
                        2.80% <i class="ri-arrow-up-s-line"></i>
                    </span>
                    <span class="d-block text-body">
                        This month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <div class="title">
                    <span class="d-block text-gray">
                        Total Earnings
                    </span>
                    <h5 class="m-0">
                        $280K
                    </h5>
                </div>
                <div class="info d-flex justify-content-center align-items-center">
                    <span class="trending down d-block fw-medium position-relative">
                        0.60% <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span class="d-block text-body">
                        This month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <div class="title">
                    <span class="d-block text-gray">
                        Refunds
                    </span>
                    <h5 class="m-0">
                        1.07K
                    </h5>
                </div>
                <div class="info d-flex justify-content-center align-items-center">
                    <span class="trending d-block fw-medium position-relative">
                        3.55% <i class="ri-arrow-up-s-line"></i>
                    </span>
                    <span class="d-block text-body">
                        This month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-xxl-3 col-sm-6">
        <mat-card
            class="trinta-card stat-card mb-25 bg-white border-none d-block text-center"
        >
            <mat-card-content>
                <div class="title">
                    <span class="d-block text-gray">
                        Conversation Ratio
                    </span>
                    <h5 class="m-0">
                        22%
                    </h5>
                </div>
                <div class="info d-flex justify-content-center align-items-center">
                    <span class="trending d-block fw-medium position-relative">
                        1.70% <i class="ri-arrow-up-s-line"></i>
                    </span>
                    <span class="d-block text-body">
                        This month
                    </span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>