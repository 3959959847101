<div class="row">
    <div class="col-sm-6 col-xxxl-3">

        <!-- Annual Profit -->
        <app-annual-profit />

        <!-- Total Growth -->
        <app-total-growth />

    </div>
    <div class="col-sm-6 col-xxxl-3">

        <!-- Daily Average Income -->
        <app-daily-average-income />

        <!-- Total Revenue -->
        <app-total-revenue />
        
    </div>
    <div class="col-sm-12 col-lg-6 col-xxxl-3">
        <div class="row">
            <div class="col-lg-12 col-sm-6">

                <!-- Lead Conversations -->
                <app-lead-conversations />

            </div>
            <div class="col-lg-12 col-sm-6">

                <!-- Total Orders -->
                <app-total-orders />

            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6 col-xxxl-3">

        <!-- Users Activity -->
        <app-user-activity />
        
    </div>
</div>