<!-- Feathericons Usage -->
<mat-card
    class="trinta-card mb-25 bg-white border-none d-block"
>
    <mat-card-content>
        <code>
            &lt;<span class="red">i-feather</span><span class="green">name</span>=<span class="blue">&quot;activity&quot;</span>&gt;&lt;/<span class="red">i-feather</span>&gt;
        </code>
    </mat-card-content>
</mat-card>

<!-- Icons -->
<div class="row">
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="activity"></i-feather>
                <span class="title d-block text-body">
                    activity
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="camera"></i-feather>
                <span class="title d-block text-body">
                    camera
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="heart"></i-feather>
                <span class="title d-block text-body">
                    heart
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="lock"></i-feather>
                <span class="title d-block text-body">
                    lock
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="github"></i-feather>
                <span class="title d-block text-body">
                    github
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="align-left"></i-feather>
                <span class="title d-block text-body">
                    align-left
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="external-link"></i-feather>
                <span class="title d-block text-body">
                    external-link
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="facebook"></i-feather>
                <span class="title d-block text-body">
                    facebook
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="user-plus"></i-feather>
                <span class="title d-block text-body">
                    user-plus
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="smile"></i-feather>
                <span class="title d-block text-body">
                    smile
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="settings"></i-feather>
                <span class="title d-block text-body">
                    settings
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="shopping-bag"></i-feather>
                <span class="title d-block text-body">
                    shopping-bag
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="x-octagon"></i-feather>
                <span class="title d-block text-body">
                    x-octagon
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="map-pin"></i-feather>
                <span class="title d-block text-body">
                    map-pin
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="command"></i-feather>
                <span class="title d-block text-body">
                    command
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="file-text"></i-feather>
                <span class="title d-block text-body">
                    file-text
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="copy"></i-feather>
                <span class="title d-block text-body">
                    copy
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="flag"></i-feather>
                <span class="title d-block text-body">
                    flag
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="share-2"></i-feather>
                <span class="title d-block text-body">
                    share-2
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="play-circle"></i-feather>
                <span class="title d-block text-body">
                    play-circle
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="book-open"></i-feather>
                <span class="title d-block text-body">
                    book-open
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="key"></i-feather>
                <span class="title d-block text-body">
                    key
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="coffee"></i-feather>
                <span class="title d-block text-body">
                    coffee
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="code"></i-feather>
                <span class="title d-block text-body">
                    code
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="star"></i-feather>
                <span class="title d-block text-body">
                    star
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="headphones"></i-feather>
                <span class="title d-block text-body">
                    headphones
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="moon"></i-feather>
                <span class="title d-block text-body">
                    moon
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="briefcase"></i-feather>
                <span class="title d-block text-body">
                    briefcase
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="bar-chart-2"></i-feather>
                <span class="title d-block text-body">
                    bar-chart-2
                </span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6 col-xxxl-2">
        <mat-card class="trinta-card icon-card mb-25 bg-white border-none d-block text-center">
            <mat-card-content>
                <i-feather name="bell"></i-feather>
                <span class="title d-block text-body">
                    bell
                </span>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<!-- View All Icons Link -->
<div class="mb-25 text-center">
    <a mat-button href="https://www.npmjs.com/package/angular-feather#available-icons" target="_blank">
        View All Icons
    </a>
</div>