import { Component } from '@angular/core';
import {BasicFormComponent} from "../../basic-elements/basic-form/basic-form.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton, MatMiniFabButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {SimpleFileUploaderComponent} from "../../file-uploader/simple-file-uploader/simple-file-uploader.component";
import {Router} from "@angular/router";
import {MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '../../../../app/constants';
import { ApiEndpoints } from '../../../../app/api-endpoints';
import {  OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {FeatherModule} from "angular-feather";
import { MatIconButton} from "@angular/material/button";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatCheckbox} from "@angular/material/checkbox";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-invite-member',
  standalone: true,
  imports: [MatFormFieldModule,NgMultiSelectDropDownModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    BasicFormComponent,
    FormsModule,
    MatButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatSlideToggle,
    SimpleFileUploaderComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatMiniFabButton,
    HttpClientModule
  ],
  templateUrl: './invite-member.component.html',
  styleUrl: './invite-member.component.scss'
})
export class InviteMemberComponent  implements OnInit {
  professionControl = new FormControl();
  professions: string[] = ['Doctor', 'Engineer', 'Teacher', 'Artist', 'Musician', 'Lawyer'];
  filteredProfessions: Observable<string[]>;
  usersmembers: any[] = [];



  cities: any[] = [];
  selectedItem: any[] = [];
  dropdownSettings: any = {};
  closeDropdownSelection=false;
  disabled=false;


  onItemSelect(item: any) {
      console.log('onItemSelect', item);
  }

  toggleCloseDropdownSelection() {
      this.closeDropdownSelection = !this.closeDropdownSelection;
      this.dropdownSettings = Object.assign({}, this.dropdownSettings,{closeDropDownOnSelection: this.closeDropdownSelection});
  }






  constructor(
    public dialogRef: MatDialogRef<InviteMemberComponent>,
    private fb: FormBuilder,
    private http: HttpClient,

  ) {
    this.inviteMemberForm = this.fb.group({
      email: ['',Validators.required],
      firstName:['',Validators.required],
      lastNameString: ['',Validators.required],
      phone: [''],
    });
  }

  // Password Hide
  hide = true;

  // Form
  inviteMemberForm: FormGroup;

  
  onSubmit() {
    if (this.inviteMemberForm.valid) {
      console.log(this.inviteMemberForm.value);
      const groupdId = localStorage.getItem('selectedGroupId');

      const requestBody = {
        "email": this.inviteMemberForm.get('email')?.value,
        "firstName": this.inviteMemberForm.get('firstName')?.value,
        "phone": "", 
        "lastName": this.inviteMemberForm.get('lastNameString')?.value,
        "groupID": groupdId
      };

      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
      this.http.post(BASE_URL + ApiEndpoints.invitemember, requestBody, { headers: headers }).subscribe(
        (res: any) => {

          console.log("resresres", res)

          if(res.statusCode == 200){
            alert("Invitation sent successfully");
            this.dialogRef.close();
            window.location.reload();        
          }else if(res.statusCode == 400) {
            alert(res.message);
            this.dialogRef.close();

          }else if(res.statusCode == 500) {
            alert(res.message);
            this.dialogRef.close();

          }
            // window.location.reload();        
        },
        (error) => {
          if (error.status === 401) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Unauthorized");
          } else if (error.status === 500) {
            // Handle 401 Unauthorized error
            console.log("Unauthorized:", error.status);
            alert("Internal server error");
          } else if(error.statusCode == 200){
            alert("Invitation sent successfully");
            this.dialogRef.close();
            window.location.reload();        
          }else if(error.statusCode == 400) {
            alert("Sorry! Member already invited");
            this.dialogRef.close();

          } else {
            // Handle other errors
            console.error("Error logging in:", error);
            alert("Failed creating record. Please try again later.");
          }
        }
      );
    } else {
      console.log('Form is invalid. Please check the fields.');
    }
  }

  // Correctly place the onNoClick method outside the constructor
  onNoClick() {
    this.dialogRef.close();
  }
  getALLMembers() {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('angular17token'));
    this.http.get(BASE_URL + ApiEndpoints.getALLMembers, { headers: headers }).subscribe(
      (data: any) => {
        this.usersmembers = data; // Assuming data is an array of objects with properties 'value' and 'label'
      },
      error => {
        console.log('Error fetching purposes:', error);
      }
    );

  }

  ngOnInit(): void {
    this.getALLMembers();
    // this.inviteMemberForm = this.fb.group({
    //   firstName: [''],
    //   email: [''],
    //   profession: ['']
    // });

    // Set up filtered professions
    this.filteredProfessions = this.professionControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterProfessions(value))
    );

    this.cities = ['Mumbai', 'New Delhi', 'Bangaluru', 'Pune', 'Navsari'];
      this.selectedItem = ['Pune'];
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        allowSearchFilter: true,
        itemsShowLimit: 5,
        maxHeight: 200,
        searchPlaceholderText: 'Search city',
        noDataAvailablePlaceholderText: 'No cities available',
        enableCheckAll: true,
        limitSelection: 3,
          closeDropDownOnSelection: this.closeDropdownSelection
      };
  }

  private _filterProfessions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.professions.filter(profession => profession.toLowerCase().includes(filterValue));
  }

}
