<mat-card class="trinta-card recent-orders-card mb-25 bg-white border-none d-block">
  <mat-card-header>
    <mat-card-title>
        <h5 class="mt-0 mb-0">
            Group membership fee
        </h5>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
      <div class="recent-orders-table">
          <div class="table-responsive">
              <table mat-table [dataSource]="dataSource">

                  <!-- user Column -->

                  <!-- Customer Column -->
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <img [src]="element.image" alt="User Image">
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef>

                      </th>

                      <td mat-cell *matCellDef="let element">
                          {{element.name}}
                      </td>

                  </ng-container>



                  <!-- Quantity Column -->
                   <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                          <div class="date-container">
                              <div class="date">{{ element.date}}</div>
                          </div>
                      </td>
                  </ng-container>

                  <!-- groupride Column -->
                  <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef>

                      </th>
                      <td mat-cell *matCellDef="let element">

                          <div class="amount">{{ element.amount}}</div>

                      </td>
                  </ng-container>

                  <!-- Status Column -->


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
       <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
      </div>
  </mat-card-content>
</mat-card>
